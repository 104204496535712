import React, { useRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Link,
  Paper,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import MaterialTable from "material-table";
import CopyIcon from "@material-ui/icons/FileCopy";
import { ExpandMore, PictureAsPdf } from "@material-ui/icons";

import { NavLink } from "react-router-dom";
import { useApp } from "../../../../AppProvider";

import { copyToClipboard, dateFormatter } from "../../../../utils";
import BasicPermitInfo from "./BasicPermitInfo";
import { usePermits } from "./usePermits";
import LinkedWellsManagement from "./LinkedWellsManagement";
import { usePrintPdf } from "./usePrintPdf";
import AttachedDocuments from "./AttachedDocuments";
import AmendmentsManagement from "./Amendments/AmendmentsManagement";
import MonthlyDistributions from "./MonthlyDistributions";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;
function PermitsManagement() {
  const tableRef = useRef(null);
  const { setSelectedItems, pdfMarkup } = usePrintPdf(
    "ui-list-permits-certificate-print-info"
  );

  const handlePrintPermits = (selectedPermitIndices) => {
    setSelectedItems(selectedPermitIndices);
  };

  const handlePrintAllFiltered = () => {
    if (tableRef?.current) {
      const filteredPermitIndices =
        tableRef.current.dataManager.filteredData.map(
          ({ permit_ndx }) => permit_ndx
        );
      setSelectedItems(filteredPermitIndices);
    }
  };

  const {
    selectedPermit,
    setSelectedPermit,
    data,
    isFetching,
    error,
    refetch,
    PERMITS_TABLE_COLUMNS,
  } = usePermits();
  const { doToast } = useApp();
  const targetRef = useRef(null);

  const scrollToTarget = () => {
    if (targetRef.current) {
      const yOffset = targetRef.current.getBoundingClientRect().top - 100;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  const handleScroll = () => {
    setTimeout(scrollToTarget, 1000); // Delay the scroll by 1 second (1000 milliseconds)
  };

  const [triggerRefetch, setTriggerRefetch] = React.useState(false);
  const handleTriggerRefetch = () => setTriggerRefetch(!triggerRefetch);
  const handleRefetch = () => {
    refetch().then(() => {
      handleTriggerRefetch();
    });
  };

  const [triggerRefetchPermit, setTriggerRefetchPermit] = React.useState(false);

  const getRowStyle = React.useCallback((rowData) => {
    return {
      backgroundColor: rowData.is_terminated ? "#f6f6f6" : "inherit",
    };
  }, []);

  if (error) return "An error has occurred: " + error.message;
  return (
    <React.Fragment>
      <Helmet title="Permits Management" />
      <Typography variant="h3" gutterBottom display="inline">
        Permits Management
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Permits Management</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Box>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box style={{ width: "100%" }}>
              <Typography variant="h3">Permits List</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <TableWrapper>
              <MaterialTable
                id="Permits Management"
                title={`Permits Management ${dateFormatter(
                  new Date(),
                  "MM/DD/YYYY, h:mm A"
                )}`}
                loading={isFetching || !data}
                columns={PERMITS_TABLE_COLUMNS}
                data={data}
                tableRef={tableRef}
                localization={{
                  toolbar: { searchPlaceholder: "Permits List" },
                }}
                components={{
                  Container: (props) => <div {...props} />,
                }}
                actions={[
                  {
                    icon: CopyIcon,
                    tooltip: "Copy Data",
                    isFreeAction: true,
                    onClick: () => {
                      try {
                        copyToClipboard(data, PERMITS_TABLE_COLUMNS, () =>
                          doToast(
                            "success",
                            "Data was copied to your clipboard."
                          )
                        );
                      } catch (error) {
                        const message =
                          error?.message ?? "Something went wrong";
                        doToast("error", message);
                      }
                    },
                  },
                  () => ({
                    icon: "edit",
                    tooltip: "Edit",
                    onClick: (event, rowData) => {
                      setSelectedPermit(rowData?.permit_ndx);
                      handleScroll();
                    },
                  }),
                  {
                    icon: "add_box",
                    tooltip: "Add",
                    isFreeAction: true,
                    onClick: () => {
                      setSelectedPermit(0);
                      handleScroll();
                    },
                  },
                  (rowData) => ({
                    icon: "picture_as_pdf",
                    tooltip: `Print Permit Certificate: ${rowData.permit_number}`,
                    disabled: !rowData.permit_number,
                    onClick: () => handlePrintPermits([rowData.permit_ndx]),
                  }),
                  {
                    icon: PictureAsPdf,
                    tooltip: "Print All Filtered Permits",
                    isFreeAction: true,
                    onClick: handlePrintAllFiltered,
                  },
                ]}
                options={{
                  filtering: true,
                  emptyRowsWhenPaging: false,
                  showTitle: false,
                  columnsButton: true,
                  exportButton: true,
                  exportAllData: true,
                  addRowPosition: "first",
                  pageSize: 30,
                  pageSizeOptions: [10, 30, 50, 100, 200],
                  padding: "dense",
                  searchFieldAlignment: "left",
                  minBodyHeight: "450px",
                  maxBodyHeight:
                    "calc(100vh - 64px - 48px - 106px - 48px - 64px - 204px - 50px)",
                  rowStyle: getRowStyle,
                }}
              />
            </TableWrapper>
          </AccordionDetails>
        </Accordion>

        <Box
          style={{
            display: selectedPermit || selectedPermit === 0 ? "block" : "none",
          }}
        >
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4">
                {selectedPermit === 0
                  ? "Creating New Permit"
                  : "Editing Existing Permit"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedPermit(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
          <div ref={targetRef} />
          <BasicPermitInfo
            selectedPermit={selectedPermit}
            setSelectedPermit={setSelectedPermit}
            refetchPermits={handleRefetch}
            triggerRefetch={triggerRefetch}
            triggerRefetchPermit={triggerRefetchPermit}
          />
          <MonthlyDistributions
            selectedPermit={selectedPermit}
            triggerRefetch={triggerRefetch}
          />
          <LinkedWellsManagement
            selectedPermit={selectedPermit}
            refetchBoxTables={handleRefetch}
          />
          <AmendmentsManagement
            handleTriggerRefetch={handleTriggerRefetch}
            triggerRefetch={triggerRefetch}
            selectedPermit={selectedPermit}
            setTriggerRefetchPermit={setTriggerRefetchPermit}
          />
          <AttachedDocuments selectedPermit={selectedPermit} />
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4">
                {selectedPermit === 0
                  ? "Creating New Permit"
                  : "Editing Existing Permit"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedPermit(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
      {pdfMarkup}
    </React.Fragment>
  );
}

export default PermitsManagement;
