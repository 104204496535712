import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";

export const useMeters = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedMeter, setSelectedMeter] = useStateIfMounted(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["ui-meters-management-top-table"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-meters-management-top-table`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const METERS_TABLE_COLUMNS = [
    {
      title: "Owner",
      field: "owner_info",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Permit",
      field: "permit_name",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Meter ID",
      field: "meter_id",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Active?",
      field: "is_active",
      type: "boolean",
      defaultFilter: "checked",
    },
    {
      title: "Joined Date",
      field: "joined_date",
      type: "date",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Retired Date",
      field: "retired_date",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Notes",
      field: "notes",
      cellStyle: {
        width: 400,
        minWidth: 400,
      },
    },
  ];

  return {
    selectedMeter,
    setSelectedMeter,
    data,
    isFetching,
    error,
    refetch,
    METERS_TABLE_COLUMNS,
  };
};
