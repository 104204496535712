import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";

import Loader from "../../../../components/Loader";
import {
  LINKED_PERMITS_TABLE_COLUMNS,
  LINKED_WELLS_TABLE_COLUMNS,
} from "./ContactsConstants";
import { useSubmitData } from "../../../../hooks/useSubmitData";
import { useBasicContactInfo } from "./useBasicContactInfo";
import { getButtonIcon, getButtonText } from "../wellsManagement/utils";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MaterialTable from "material-table";

function BasicContactInfo({
  selectedContact,
  setSelectedContact,
  refetchContacts = () => {},
  isEditMode = false,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
    lookups,
    tables,
  } = useBasicContactInfo(selectedContact);

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedContact,
    setSelectedContact,
    state,
    "list-rolodex",
    "rolo_ndx",
    refetch,
    refetchContacts
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.rolo_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.rolo_ndx) ? "none" : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Basic Contact Information</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={8}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Name"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("r_name", e.target.value)}
                value={state.r_name ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={2}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={state.is_permitee}
                    onChange={(e) =>
                      handleUpdateState("is_permitee", e.target.checked)
                    }
                    // color="primary"
                    style={{
                      color: "rgba(0, 0, 0, 0.54)", // Replace with your desired color
                    }}
                  />
                }
                label={
                  <span
                  // style={{ color: "rgba(0, 0, 0, 0.87)" }}
                  >
                    Permittee?
                  </span>
                }
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={2}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!state.rolo_ndx}
                    checked={state.removed}
                    onChange={(e) =>
                      handleUpdateState("removed", e.target.checked)
                    }
                    color="primary"
                  />
                }
                label="REMOVED"
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                backgroundColor: "#F0FFF0",
              }}
            >
              <TextField
                variant="outlined"
                label="Organization"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("r_organization", e.target.value)
                }
                value={state.r_organization ?? ""}
                InputProps={{
                  style: {
                    fontWeight: "600",
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontWeight: "600",
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Email"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("r_email", e.target.value)}
                value={state.r_email ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Phone"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("r_phone", e.target.value)}
                value={state.r_phone ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={2}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Zip Code"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("r_zipcode", e.target.value)}
                value={state.r_zipcode ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={7}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Mailing Address"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("r_mailing_address", e.target.value)
                }
                value={state.r_mailing_address ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="City"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("r_city", e.target.value)}
                value={state.r_city ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={2}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="State"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("r_state", e.target.value)}
                value={state.r_state ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.directorPrecincts.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="precinct">Precinct</InputLabel>
                    <Select
                      labelId="precinct"
                      id="precinct"
                      label="Precinct"
                      name="precinct_num"
                      value={state.precinct_num ?? ""}
                      onChange={(e) => {
                        handleUpdateState("precinct_num", e.target.value);
                      }}
                    >
                      {lookups.directorPrecincts.map((option) => (
                        <MenuItem
                          key={option.precinct_ndx}
                          value={option.precinct_ndx}
                        >
                          {option.precinct_label}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              xl={8}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Notes"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("notes", e.target.value)}
                value={state.notes ?? ""}
              />
            </Grid>

            {state?.rolo_ndx && (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl style={{ width: "100%" }}>
                      <KeyboardDatePicker
                        disabled
                        format="LLLL do, yyyy"
                        inputVariant="outlined"
                        autoOk
                        id="created-on"
                        label="Created On"
                        value={state?.created_timestamp}
                        onChange={(date) => {
                          handleUpdateState("created_timestamp", date);
                        }}
                        InputAdornmentProps={{
                          "aria-label": "change created date",
                        }}
                        InputProps={{
                          style: {
                            color: "rgba(0,0,0,1)",
                          },
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <TextField
                    disabled
                    variant="outlined"
                    label="Created By"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateState("created_by", e.target.value)
                    }
                    value={state.created_by ?? ""}
                    InputProps={{
                      style: {
                        color: "rgba(0,0,0,1)",
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl style={{ width: "100%" }}>
                      <KeyboardDatePicker
                        disabled
                        format="LLLL do, yyyy"
                        inputVariant="outlined"
                        autoOk
                        id="updated-on"
                        label="Updated On"
                        value={state?.updated_timestamp}
                        onChange={(date) => {
                          handleUpdateState("updated_timestamp", date);
                        }}
                        InputAdornmentProps={{
                          "aria-label": "change updated date",
                        }}
                        InputProps={{
                          style: {
                            color: "rgba(0,0,0,1)",
                          },
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <TextField
                    disabled
                    variant="outlined"
                    label="Updated By"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateState("updated_by", e.target.value)
                    }
                    value={state.updated_by ?? ""}
                    InputProps={{
                      style: {
                        color: "rgba(0,0,0,1)",
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl style={{ width: "100%" }}>
                      <KeyboardDatePicker
                        disabled
                        format="LLLL do, yyyy"
                        inputVariant="outlined"
                        autoOk
                        id="removed-on"
                        label="Removed On"
                        value={state?.removed_timestamp}
                        onChange={(date) => {
                          handleUpdateState("removed_timestamp", date);
                        }}
                        InputAdornmentProps={{
                          "aria-label": "change removed date",
                        }}
                        InputProps={{
                          style: {
                            color: "rgba(0,0,0,1)",
                          },
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <TextField
                    disabled
                    variant="outlined"
                    label="Removed By"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateState("removed_by", e.target.value)
                    }
                    value={state.removed_by ?? ""}
                    InputProps={{
                      style: {
                        color: "rgba(0,0,0,1)",
                      },
                    }}
                  />
                </Grid>
              </>
            )}

            {state?.rolo_ndx && (
              <>
                <Grid item xs={12} sm={6}>
                  <MaterialTable
                    style={{
                      position: "relative",
                      border: "solid gray",
                      height: "214px",
                      overflow: "auto",
                    }}
                    id="linked-wells"
                    title="Wells"
                    loading={
                      tables.linkedWells.isFetching || !tables.linkedWells.data
                    }
                    columns={LINKED_WELLS_TABLE_COLUMNS}
                    data={tables?.linkedWells?.data ?? []}
                    options={{
                      showTitle: false,
                      toolbar: false,
                      columnsButton: false,
                      exportButton: false,
                      exportAllData: false,
                      padding: "dense",
                      search: false,
                      paging: false,
                      cellStyle: { border: "1px solid #eee" },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MaterialTable
                    style={{
                      position: "relative",
                      border: "solid gray",
                      height: "214px",
                      overflow: "auto",
                    }}
                    id="linked-permits"
                    title="Linked Permits"
                    loading={
                      tables.linkedPermits.isFetching ||
                      !tables.linkedPermits.data
                    }
                    columns={LINKED_PERMITS_TABLE_COLUMNS}
                    data={tables?.linkedPermits?.data ?? []}
                    options={{
                      showTitle: false,
                      toolbar: false,
                      columnsButton: false,
                      exportButton: false,
                      exportAllData: false,
                      padding: "dense",
                      search: false,
                      paging: false,
                      cellStyle: { border: "1px solid #eee" },
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={handleResetState}>
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isDirty || isFormSubmitting}
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting, selectedContact === 0)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default BasicContactInfo;
