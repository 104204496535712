import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Loader from "../../../../../../components/Loader";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import React from "react";

function Setup({ lookups, state, handleUpdateState, selectedAmendment }) {
  return (
    <>
      <Grid
        container
        spacing={3}
        style={{
          border: "3px solid gray",
          marginBottom: "15px",
          borderRadius: "4px",
          marginTop: "5px",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            position: "relative",
          }}
        >
          <Typography variant="h5">Amendment Setup</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          xl={3}
          style={{
            position: "relative",
          }}
        >
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            error={!state.pamend_type_ndx}
            required
          >
            {!lookups?.amendmentTypes.length > 0 ? (
              <Loader />
            ) : (
              <>
                <InputLabel id="amendment-type">Amendment Type</InputLabel>
                <Select
                  disabled={!!selectedAmendment}
                  labelId="amendment type"
                  id="amendment-type"
                  label="Amendment Type"
                  name="pamend_type_ndx"
                  value={state.pamend_type_ndx ?? ""}
                  onChange={(e) => {
                    handleUpdateState("pamend_type_ndx", e.target.value);
                  }}
                >
                  {lookups.amendmentTypes.map((option) => (
                    <MenuItem
                      key={option.pamend_type_ndx}
                      value={option.pamend_type_ndx}
                    >
                      {option.pamend_type_desc}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
            {!state.pamend_type_ndx && (
              <FormHelperText error>*Required field</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          xl={6}
          style={{
            position: "relative",
          }}
        >
          <TextField
            variant="outlined"
            label="Staff Reviewer"
            style={{ width: "100%" }}
            onChange={(e) =>
              handleUpdateState("action_staff_reviewer", e.target.value)
            }
            value={state.action_staff_reviewer ?? ""}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          xl={3}
          style={{
            position: "relative",
          }}
        >
          <FormControl
            component="fieldset"
            style={{ marginRight: "10px", width: "100%" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={state?.action_complete}
                onChange={(date) => handleUpdateState("action_complete", date)}
                id="action-complete"
                variant="inline"
                format="LLLL do, yyyy"
                autoOk
                inputVariant="outlined"
                label="Complete Date"
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          xl={3}
          style={{
            position: "relative",
          }}
        >
          <FormControl variant="outlined" style={{ width: "100%" }}>
            {!lookups?.amendmentStatus.length > 0 ? (
              <Loader />
            ) : (
              <>
                <InputLabel id="amendment-status">Amendment Status</InputLabel>
                <Select
                  labelId="amendment status"
                  id="amendment-status"
                  label="Amendment Status"
                  name="pamend_status_ndx"
                  value={state.pamend_status_ndx ?? ""}
                  onChange={(e) => {
                    handleUpdateState("pamend_status_ndx", e.target.value);
                  }}
                >
                  {lookups.amendmentStatus.map((option) => (
                    <MenuItem
                      key={option.pamend_status_ndx}
                      value={option.pamend_status_ndx}
                    >
                      {option.pamend_status_desc}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          xl={6}
          style={{
            position: "relative",
          }}
        >
          <TextField
            variant="outlined"
            label="General Manager"
            style={{ width: "100%" }}
            onChange={(e) =>
              handleUpdateState("action_general_manager", e.target.value)
            }
            value={state.action_general_manager ?? ""}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          xl={3}
          style={{
            position: "relative",
          }}
        >
          <FormControl
            component="fieldset"
            style={{ marginRight: "10px", width: "100%" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={state?.action_complete_extension}
                onChange={(date) =>
                  handleUpdateState("action_complete_extension", date)
                }
                id="action-complete-extension"
                variant="inline"
                format="LLLL do, yyyy"
                autoOk
                inputVariant="outlined"
                label="Complete Extension Date"
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          xl={3}
          style={{
            position: "relative",
          }}
        >
          <FormControl
            component="fieldset"
            style={{ marginRight: "10px", width: "100%" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                required
                value={state?.effective_date}
                onChange={(date) => handleUpdateState("effective_date", date)}
                id="effective-date"
                variant="inline"
                format="LLLL do, yyyy"
                autoOk
                inputVariant="outlined"
                label="Effective Date"
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          xl={6}
          style={{
            position: "relative",
          }}
        >
          <TextField
            variant="outlined"
            label="Notes"
            style={{ width: "100%" }}
            onChange={(e) =>
              handleUpdateState("amendment_notes", e.target.value)
            }
            value={state.amendment_notes ?? ""}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          xl={3}
          style={{
            position: "relative",
          }}
        >
          <FormControl
            component="fieldset"
            style={{ marginRight: "10px", width: "100%" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={state?.action_incomplete_expired}
                onChange={(date) =>
                  handleUpdateState("action_incomplete_expired", date)
                }
                id="action-incomplete-expired"
                variant="inline"
                format="LLLL do, yyyy"
                autoOk
                inputVariant="outlined"
                label="Incomplete Expired Date"
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default Setup;
