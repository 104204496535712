import React from "react";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components/macro";

const Root = styled.div`
  background-color: rgba(49, 49, 49, 0.75);
  border-radius: 4px;
  position: absolute;
  z-index: 2;
  top: 60px;
  right: 10px;
  padding: 15px;
  padding-top: 0;
  padding-bottom: 0;
  max-width: 300px;
`;

const LegendSubtitle = styled(Typography)`
  color: white;
`;

const LegendListItem = styled.div`
  margin-top: 5px;
  display: flex;
  color: white;
  align-items: center;
`;

const LegendSymbol = styled.div`
  width: 24px;
  height: 24px;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 5px;
`;

const LegendText = styled.div`
  margin-left: 5px;
  font-size: 0.8rem;
`;

const LegendList = styled.div`
  margin-bottom: 15px;
`;

const legendColors = [
  {
    name: `IPP Wells`,
    imageUrl: "/static/img/mapbox/square-white-border.png",
  },
  {
    name: `LPP Wells`,
    imageUrl: "/static/img/mapbox/square-yellow-border.png",
  },
  {
    name: `Active Exempt Wells & Springs`,
    imageUrl: "/static/img/mapbox/circle-white-border.png",
  },
  {
    name: `Monitoring Wells`,
    imageUrl: "/static/img/mapbox/triangle-white-border.png",
  },
  {
    name: `Westbay Multiport Wells`,
    imageUrl: "/static/img/mapbox/star-white-border.png",
  },
  {
    name: `Abandoned, Capped & Re-Tagged Wells`,
    imageUrl: "/static/img/mapbox/circle-red-border.png",
  },
  {
    name: `Plugged Wells`,
    imageUrl: "/static/img/mapbox/circle-black-border.png",
  },
];

const Legend = () => {
  return (
    <Root id="map-legend">
      <Typography variant="h3" style={{ color: "#42A5F4" }}>
        Legend
      </Typography>
      <LegendSubtitle variant="caption">
        Icon Shapes & Border Reference
      </LegendSubtitle>
      <LegendList>
        {legendColors.map((data) => (
          <LegendListItem key={data.name}>
            <LegendSymbol imageUrl={data.imageUrl} />
            <LegendText>{data.name}</LegendText>
          </LegendListItem>
        ))}
      </LegendList>
    </Root>
  );
};

export default Legend;
