import React, { useRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip,
  Divider as MuiDivider,
  Link,
  Paper,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useWells } from "./hooks/useWells";
import MaterialTable from "material-table";
import { copyToClipboard, dateFormatter } from "../../../../utils";
import CopyIcon from "@material-ui/icons/FileCopy";
import { useApp } from "../../../../AppProvider";
import BasicWellInfo from "./sections/BasicWellInfo";
import { ExpandMore } from "@material-ui/icons";
import AdditionalLocationDetails from "./sections/AdditionalLocationDetails";
import WellDetails from "./sections/WellDetails";
import Hydrogeology from "./sections/Hydrogeology";
import GeologicalContacts from "./sections/GeologicalContacts";
import WellCompletion from "./sections/WellCompletion";
import WellConstruction from "./sections/WellConstruction";
import { NavLink } from "react-router-dom";
import AttachedDocumentsAndPhotos from "./sections/AttachedDocumentsAndPhotos";
import ContactsManagement from "./sections/ContactsManagement";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;
function WellsManagement() {
  const {
    selectedWell,
    setSelectedWell,
    data,
    isFetching,
    error,
    refetch,
    selectedWellLabel,
    setSelectedWellLabel,
    WELLS_TABLE_COLUMNS,
  } = useWells();
  const { doToast } = useApp();
  const targetRef = useRef(null);

  const scrollToTarget = () => {
    if (targetRef.current) {
      const yOffset = targetRef.current.getBoundingClientRect().top - 100;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  const handleScroll = () => {
    setTimeout(scrollToTarget, 1000); // Delay the scroll by 1 second (1000 milliseconds)
  };

  if (error) return "An error has occurred: " + error.message;
  return (
    <React.Fragment>
      <Helmet title="Wells Management" />
      <Typography variant="h3" gutterBottom display="inline">
        Wells Management
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Wells Management</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Box>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box style={{ width: "100%" }}>
              <Typography variant="h3"> BSEACD Wells List</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <TableWrapper>
              <MaterialTable
                id="Wells Management"
                title={`Wells Management ${dateFormatter(
                  new Date(),
                  "MM/DD/YYYY, h:mm A"
                )}`}
                loading={isFetching || !data}
                columns={WELLS_TABLE_COLUMNS}
                data={data}
                localization={{
                  toolbar: { searchPlaceholder: "BSEACD Wells List" },
                }}
                components={{
                  Container: (props) => <div {...props} />,
                }}
                actions={[
                  {
                    icon: CopyIcon,
                    tooltip: "Copy Data",
                    isFreeAction: true,
                    onClick: () => {
                      try {
                        copyToClipboard(data, WELLS_TABLE_COLUMNS, () =>
                          doToast(
                            "success",
                            "Data was copied to your clipboard."
                          )
                        );
                      } catch (error) {
                        const message =
                          error?.message ?? "Something went wrong";
                        doToast("error", message);
                      }
                    },
                  },
                  () => ({
                    icon: "edit",
                    tooltip: "Edit",
                    onClick: (event, rowData) => {
                      setSelectedWell(rowData.well_ndx);
                      setSelectedWellLabel(rowData.well_name);
                      handleScroll();
                    },
                  }),
                  {
                    icon: () => (
                      <Chip
                        color="secondary"
                        label={
                          <Typography
                            variant="inherit"
                            style={{ fontWeight: "bold" }}
                          >
                            Register Well
                          </Typography>
                        }
                        style={{ cursor: "pointer" }}
                      />
                    ),
                    isFreeAction: true,
                    onClick: () => {
                      setSelectedWell(0);
                      setSelectedWellLabel(null);
                      handleScroll();
                    },
                  },
                ]}
                options={{
                  filtering: true,
                  emptyRowsWhenPaging: false,
                  showTitle: false,
                  columnsButton: true,
                  exportButton: true,
                  exportAllData: true,
                  addRowPosition: "first",
                  pageSize: 30,
                  pageSizeOptions: [10, 30, 50, 100, 200],
                  padding: "dense",
                  searchFieldAlignment: "left",
                  minBodyHeight: "450px",
                  maxBodyHeight:
                    "calc(100vh - 64px - 48px - 106px - 48px - 64px - 204px - 50px)",
                }}
              />
            </TableWrapper>
          </AccordionDetails>
        </Accordion>

        <Box
          style={{
            display: selectedWell || selectedWell === 0 ? "block" : "none",
          }}
        >
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4">
                {selectedWell === 0
                  ? "Creating New Well"
                  : "Editing Existing Well"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedWell(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
          <div ref={targetRef} />
          <BasicWellInfo
            selectedWell={selectedWell}
            setSelectedWell={setSelectedWell}
            refetchWells={refetch}
            selectedWellLabel={selectedWellLabel}
            setSelectedWellLabel={setSelectedWellLabel}
          />
          <AdditionalLocationDetails
            selectedWell={selectedWell}
            setSelectedWell={setSelectedWell}
            selectedWellLabel={selectedWellLabel}
          />
          <WellDetails
            selectedWell={selectedWell}
            setSelectedWell={setSelectedWell}
            selectedWellLabel={selectedWellLabel}
          />
          <ContactsManagement
            selectedWell={selectedWell}
            selectedWellLabel={selectedWellLabel}
          />
          <Hydrogeology
            selectedWell={selectedWell}
            selectedWellLabel={selectedWellLabel}
          />
          <GeologicalContacts
            selectedWell={selectedWell}
            selectedWellLabel={selectedWellLabel}
          />
          <WellCompletion
            selectedWell={selectedWell}
            setSelectedWell={setSelectedWell}
            selectedWellLabel={selectedWellLabel}
          />
          <WellConstruction
            selectedWell={selectedWell}
            selectedWellLabel={selectedWellLabel}
          />
          <AttachedDocumentsAndPhotos
            selectedWell={selectedWell}
            setSelectedWell={setSelectedWell}
            selectedWellLabel={selectedWellLabel}
          />
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4">
                {selectedWell === 0
                  ? "Creating New Well"
                  : "Editing Existing Well"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedWell(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default WellsManagement;
