import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Grid as MuiGrid,
  Typography as MuiTypography,
  Tooltip,
  withWidth,
  isWidthDown,
} from "@material-ui/core";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { spacing } from "@material-ui/system";
import { lineColors } from "../../../utils";
import DatePicker from "../../../components/pickers/DatePicker";
import Panel from "../../../components/panels/Panel";
import ExportDataButton from "../../../components/graphs/ExportDataButton";
import SaveRefButton from "../../../components/graphs/SaveRefButton";
import IconButton from "@material-ui/core/IconButton";
import { Close, Room } from "@material-ui/icons";
import ExpandButton from "../../../components/graphs/ExpandButton";
import TimeseriesLineChartDataViz from "../../../components/graphs/TimeseriesLineChartDataViz";
import { isWidthUp } from "@material-ui/core/withWidth";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 0;
  }

  to {
    transform: scale(.25);
    opacity: 1;
  }
`;

const OuterContainer = styled(Box)`
  margin-left: ${({ dynamicleftmargin }) => dynamicleftmargin};
  bottom: ${({ dynamicbottommargin }) => dynamicbottommargin};
  z-index: 3;
  position: absolute;
  max-height: 100%;
  width: ${({ dynamicleftmargin }) =>
    `calc(100% - ${dynamicleftmargin} - ${dynamicleftmargin})`};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  animation: ${({ open }) => (open ? fadeIn : fadeOut)} 0.5s linear;
  transition: visibility 0.5s linear;
`;

const Viz = styled.div`
  height: ${({ height }) => height};
  max-width: 100%;
`;

const TimeseriesWrapper = styled.div`
  height: calc(100% - 96px);
  width: 100%;
`;

const TimeseriesContainer = styled.div`
  height: calc(${({ height }) => height} - 164px);
  width: 100%;
`;

const CircleMarker = styled.div`
  text-align: center;
  border-radius: 50%;
  color: white;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  width: 50px;
  height: 50px;
  line-height: 66px;
  margin-right: 13px;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: -10px;
`;

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const endpoint = {
  "Production Data": "ui-graph-production-final",
};

const DataViz = ({
  open = false,
  dataVizWell,
  dataVizGraphType,
  onClose,
  width,
}) => {
  const divSaveRef = useRef(null);
  const graphSaveRef = useRef(null);
  //date filter defaults
  const defaultFilterValues = {
    startDate: new Date().setMonth(new Date().getMonth() - 18),
    endDate: new Date(),
  };

  const [filterValues, setFilterValues] = useState(defaultFilterValues);
  const changeFilterValues = (name, value) => {
    setFilterValues((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const [currentSelectedTimeseriesData, setCurrentSelectedTimeseriesData] =
    useState(null);
  const [sortedAndFilteredData, setSortedAndFilteredData] = useState(null);
  useEffect(() => {
    if (dataVizWell && dataVizGraphType) {
      setFetchedData([]);
      async function send() {
        try {
          const { data: results } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/${endpoint[dataVizGraphType]}/${dataVizWell.well_ndx}`
          );

          if (results.length) {
            setCurrentSelectedTimeseriesData(results);
            const sortedAndFiltered = results
              .filter((item) => item.production_gallons !== null)
              .sort((a, b) => {
                // Sort by c_year in descending order
                if (b.c_year !== a.c_year) {
                  return b.c_year - a.c_year;
                }
                // If c_year is the same, sort by calendar_month in descending order
                return b.c_month - a.c_month;
              });
            setSortedAndFilteredData(sortedAndFiltered);
          } else {
            setCurrentSelectedTimeseriesData(null);
            setSortedAndFilteredData(null);
          }
        } catch (err) {
          // Is this error because we cancelled it ourselves?
          if (axios.isCancel(err)) {
            console.log(`call was cancelled`);
          } else {
            console.error(err);
          }
        }
      }
      send();
    }
  }, [dataVizWell, dataVizGraphType]); // eslint-disable-line

  const [filteredMutatedGraphData, setFilteredMutatedGraphData] = useState({});
  useEffect(() => {
    if (currentSelectedTimeseriesData?.length) {
      //mutate data for chartJS to use
      let graphData;
      if (dataVizGraphType === "Production Data") {
        graphData = {
          labels: currentSelectedTimeseriesData.map(
            (item) => new Date(item.graph_date)
          ),
          test: currentSelectedTimeseriesData.map(
            (item) => new Date(item.calendar_month)
          ),
          datasets: [
            {
              label: "Allocation Gallons",
              customTooltipLabel: "Annual Allocation",
              type: "line",
              yAxisID: "yR",
              pointStyle: "line",
              backgroundColor: lineColors.darkGray,
              borderColor: lineColors.darkGray,
              data: currentSelectedTimeseriesData.map(
                (item) => item.allocation_gallons
              ),
              pointRadius: 0,
              pointHoverRadius: 0,
              borderWidth: 4,
              spanGaps: true,
              hidden: false,
              popupInfo: currentSelectedTimeseriesData.map((item) => {
                return {
                  "Calendar Year": item.c_year,
                  Month: item.calendar_month,
                  "Fiscal Year": item.report_year,
                };
              }),
            },
            {
              label: "Cumulative Annual Gallons",
              customTooltipLabel: "YTD Pumped",
              type: "line",
              yAxisID: "yR",
              pointStyle: "rect",
              stepped: "middle",
              backgroundColor: "rgba(141, 144, 147, .5)",
              borderColor: lineColors.gray,
              data: currentSelectedTimeseriesData.map(
                (item) => item.cum_production_gallons
              ),
              fill: true,
              pointRadius: 0,
              pointHoverRadius: 0,
              borderWidth: 2,
              spanGaps: true,
              hidden: false,
              popupInfo: currentSelectedTimeseriesData.map((item) => {
                return {
                  "Calendar Year": item.c_year,
                  Month: item.calendar_month,
                  "Fiscal Year": item.report_year,
                };
              }),
            },
            {
              label: "Monthly Gallons",
              customTooltipLabel: "Gallons Pumped",
              type: "bar",
              yAxisID: "yL",
              pointStyle: "rect",
              backgroundColor: "rgba(67, 99, 215, .7)",
              borderColor: lineColors.blue,
              data: currentSelectedTimeseriesData.map(
                (item) => item.production_gallons
              ),
              borderWidth: 2,
              spanGaps: true,
              hidden: false,
              barPercentage: 0.2,
              popupInfo: currentSelectedTimeseriesData.map((item) => {
                return {
                  "Calendar Year": item.c_year,
                  Month: item.calendar_month,
                  "Fiscal Year": item.report_year,
                };
              }),
            },
          ],
        };
      }
      setFilteredMutatedGraphData(graphData);
    } else {
      setFilteredMutatedGraphData(null);
    }
  }, [currentSelectedTimeseriesData, dataVizGraphType]);

  const ScrollButton = ({ targetRef, label }) => {
    const scrollToComponent = () => {
      // Scroll to the start of the component
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
      <Button variant="contained" color="primary" onClick={scrollToComponent}>
        {label}
      </Button>
    );
  };

  const formatTableTitle = (graphType) => {
    if (["Production Data"].includes(graphType)) {
      return (
        <>
          <Typography variant="h4" style={{ lineHeight: 1.3 }}>
            Reported Well Production for Well:{" "}
            <strong>{dataVizWell?.well_id ?? "NA"}</strong>
          </Typography>
          <Typography variant="subtitle1" style={{ lineHeight: 1.3 }}>
            <Box>
              Aquifer: <strong>{dataVizWell?.aquifer ?? "NA"}</strong>
            </Box>
          </Typography>
          <Typography variant="subtitle1" style={{ lineHeight: 1.3 }}>
            <Box>
              Owner: <strong>{dataVizWell?.owner_info ?? "NA"}</strong>
            </Box>
          </Typography>
          <ScrollButton
            targetRef={componentTopTableRef}
            label="Scroll Down for Data Tables"
          />
        </>
      );
    } else {
      return null;
    }
  };

  const [dataVizHeight, setDataVizHeight] = useState({
    viz: isWidthDown("sm", width) ? "360px" : "460px",
    timeSeries: isWidthDown("sm", width) ? "465px" : "500px",
  });

  const handleExpand = () => {
    let newState = { ...dataVizHeight };
    if (
      ["460px", "360px"].includes(newState.viz) &&
      ["465px", "500px"].includes(newState.timeSeries)
    ) {
      newState.viz = isWidthDown("sm", width) ? "60vh" : "70vh";
      newState.timeSeries = isWidthDown("sm", width) ? "600px" : "860px";
    } else {
      newState.viz = isWidthDown("sm", width) ? "360px" : "460px";
      newState.timeSeries = isWidthDown("sm", width) ? "465px" : "500px";
    }
    setDataVizHeight(newState);
  };

  const adjustHeight = () => {
    let newState = { ...dataVizHeight };
    if (
      ["460px", "360px"].includes(newState.viz) &&
      ["465px", "500px"].includes(newState.timeSeries)
    ) {
      newState.viz = isWidthDown("sm", width) ? "360px" : "460px";
      newState.timeSeries = isWidthDown("sm", width) ? "465px" : "500px";
    } else {
      newState.viz = isWidthDown("sm", width) ? "60vh" : "70vh";
      newState.timeSeries = isWidthDown("sm", width) ? "600px" : "860px";
    }
    setDataVizHeight(newState);
  };

  useEffect(() => {
    adjustHeight();
  }, [width]); //eslint-disable-line

  const [fetchedData, setFetchedData] = useState([]);
  const handleCustomAction = async (rowData) => {
    try {
      const { well_ndx, c_year, c_month } = rowData;
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/ui-graph-production-meter-readings/${well_ndx}/${c_year}/${c_month}`
      );
      setFetchedData(response.data); // Update fetchedData state with the results
    } catch (error) {
      console.error(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "Meter Readings",
        field: "customAction",
        cellStyle: {
          width: 160,
          minWidth: 160,
        },
        render: (rowData) => (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              handleCustomAction(rowData);
              componentBottomTableRef.current.scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            Meter Readings
          </Button>
        ),
        sorting: false,
      },
      {
        title: "Well ID",
        field: "district_well_id",
      },
      {
        title: "Calendar Year",
        field: "c_year",
      },
      {
        title: "Month",
        field: "calendar_month",
      },
      {
        title: "Fiscal Year",
        field: "report_year",
      },
      {
        title: "Gallons Pumped",
        field: "production_gallons",
      },
      {
        title: "YTD Pumped",
        field: "cum_production_gallons",
      },
      {
        title: "Annual Allocation",
        field: "allocation_gallons",
      },
      {
        title: "Meter Adjustments",
        field: "adjustments_applied",
      },
    ],
    [] // Empty dependency array ensures that the columns are memoized and do not change
  );

  const columns2 = [
    {
      title: "Well ID",
      field: "district_well_id",
    },
    {
      title: "Meter ID",
      field: "meter_id",
    },
    {
      title: "Year",
      field: "c_year",
    },
    {
      title: "Month",
      field: "c_month",
    },
    {
      title: "Metered Gallons",
      field: "production_gallons",
    },
    {
      title: "Meter Adjustment",
      field: "adjustment_applied",
    },
    {
      title: "Adjustment Reason",
      field: "adjustment_reason",
    },
    {
      title: "Meter Reading",
      field: "meter_reading",
    },
    {
      title: "Previous Reading",
      field: "prev_meter_reading",
    },
  ];

  const componentTopTableRef = useRef(null);
  const componentBottomTableRef = useRef(null);

  return (
    <OuterContainer
      bgcolor="#ffffff"
      boxShadow="0 0 0 2px rgba(0,0,0,.1)"
      borderRadius={4}
      open={open}
      dynamicleftmargin={isWidthUp("sm", width) ? "49px" : "10px"}
      dynamicbottommargin={isWidthUp("sm", width) ? "49px" : "28px"}
    >
      <Viz height={dataVizHeight.viz}>
        <CloseContainer>
          <ExpandButton
            handleExpand={handleExpand}
            expanded={!["460px", "360px"].includes(dataVizHeight.viz)}
          />
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={onClose}
              style={{ marginLeft: "4px" }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </CloseContainer>
        {filteredMutatedGraphData && currentSelectedTimeseriesData && (
          <Panel overflowY="scroll" overflowX="hidden">
            <TimeseriesContainer
              height={dataVizHeight.timeSeries}
              ref={divSaveRef}
            >
              <Box ml={4} pt={2} pb={2} display="flex" alignItems="center">
                {isWidthUp("sm", width) && (
                  <CircleMarker>
                    <Room />
                  </CircleMarker>
                )}

                <Box flexDirection="column" display="flex">
                  {formatTableTitle(dataVizGraphType)}
                </Box>

                <Box
                  style={{ marginLeft: "auto" }}
                  data-html2canvas-ignore={"true"}
                  display="flex"
                >
                  <ExportDataButton
                    title="district_well_id"
                    data={currentSelectedTimeseriesData}
                    filterValues={filterValues}
                  />
                  <SaveRefButton
                    data-html2canvas-ignore
                    ref={divSaveRef}
                    title={dataVizWell.well_id}
                  />
                </Box>
              </Box>

              <TimeseriesWrapper>
                <TimeseriesLineChartDataViz
                  data={filteredMutatedGraphData}
                  yLLabel="Monthly Production, Gallons"
                  yRLabel="Annual Production & Allocations, Gallons"
                  reverseLegend={true}
                  enableLegendClick={true}
                  ref={graphSaveRef}
                  tooltipFormat="MM-DD-YYYY"
                  filterValues={filterValues}
                  type="line"
                  displayLegend={true}
                  xLabelUnit="month"
                  maxTicksX={isWidthUp("sm", width) ? 12 : 6}
                  maxTicksYL={6}
                  maxTicksYR={5}
                  align="start"
                />
              </TimeseriesWrapper>
            </TimeseriesContainer>

            <Grid
              container
              spacing={6}
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <Typography variant="h6">Graph Date Filters</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  size={isWidthDown("xs", width) ? "small" : "medium"}
                  label="Start Date"
                  name="startDate"
                  selectedDate={filterValues.startDate}
                  setSelectedDate={changeFilterValues}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  size={isWidthDown("xs", width) ? "small" : "medium"}
                  label="End Date"
                  name="endDate"
                  selectedDate={filterValues.endDate}
                  setSelectedDate={changeFilterValues}
                />
              </Grid>
            </Grid>

            <div ref={componentTopTableRef}>
              <MaterialTable
                data={sortedAndFilteredData}
                columns={columns}
                title="Reported Well Production"
                components={{
                  Container: (props) => <div {...props} />,
                }}
                options={{
                  emptyRowsWhenPaging: false,
                  exportAllData: true,
                  columnsButton: true,
                  exportButton: { csv: true },
                  padding: "dense",
                  searchFieldAlignment: "right",
                  showTitle: true,
                  pageSize: 20,
                  pageSizeOptions: [10, 20, 50, 100, 200],
                  sorting: true, // Enable sorting
                }}
              />
            </div>
            <div ref={componentBottomTableRef}>
              <Box flexGrow={1}>
                <Alert icon={false} severity="info">
                  Select a row above to view the metered data for that month.
                </Alert>
              </Box>
              <MaterialTable
                data={fetchedData}
                columns={columns2}
                title="Meter Readings"
                components={{
                  Container: (props) => <div {...props} />,
                }}
                options={{
                  emptyRowsWhenPaging: false,
                  exportAllData: true,
                  columnsButton: true,
                  exportButton: { csv: true },
                  padding: "dense",
                  searchFieldAlignment: "right",
                  showTitle: true,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100, 200],
                }}
              />
            </div>
          </Panel>
        )}
      </Viz>
    </OuterContainer>
  );
};

export default withWidth()(DataViz);
