import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import useFetchData from "../../../../hooks/useFetchData";
import { useMemo } from "react";

export const usePermits = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedPermit, setSelectedPermit] = useStateIfMounted(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["ui-list-permits-management-toptable"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-list-permits-management-toptable`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [permitTypesLookup] = useFetchData(
    "ui-list-permits-permit-types",
    [],
    true
  );
  const permitTypesLookupForTable = useMemo(() => {
    let converted = {};
    if (permitTypesLookup?.length > 0) {
      permitTypesLookup.forEach((item) => {
        converted[item.permit_type_ndx] = item.permit_type_desc;
      });
    }
    return converted;
  }, [permitTypesLookup]);

  const [permitStatusLookup] = useFetchData(
    "ui-list-permits-permit-status",
    [],
    true
  );
  const permitStatusLookupForTable = useMemo(() => {
    let converted = {};
    if (permitStatusLookup?.length > 0) {
      permitStatusLookup.forEach((item) => {
        converted[item.permit_status_ndx] = item.permit_status_desc;
      });
    }
    return converted;
  }, [permitStatusLookup]);

  const [aquiferGroupsLookup] = useFetchData(
    "ui-list-permits-aquifer-groups",
    [],
    true
  );
  const aquiferGroupsLookupForTable = useMemo(() => {
    let converted = {};
    if (aquiferGroupsLookup?.length > 0) {
      aquiferGroupsLookup.forEach((item) => {
        converted[item.aquifer_group_ndx] = item.aquifer_group_name;
      });
    }
    return converted;
  }, [aquiferGroupsLookup]);

  const [permitUsesLookup] = useFetchData("ui-list-permits-uses", [], true);
  const permitUsesLookupForTable = useMemo(() => {
    let converted = {};
    if (permitUsesLookup?.length > 0) {
      permitUsesLookup.forEach((item) => {
        converted[item.well_use_ndx] = item.well_use_name;
      });
    }
    return converted;
  }, [permitUsesLookup]);

  const [allocationDistributionsLookup] = useFetchData(
    "list-allocation-distributions",
    [],
    true
  );
  const allocationDistributionsLookupForTable = useMemo(() => {
    let converted = {};
    if (allocationDistributionsLookup?.length > 0) {
      allocationDistributionsLookup.forEach((item) => {
        converted[item.allocation_distribution_ndx] =
          item.allocation_distribution_desc;
      });
    }
    return converted;
  }, [allocationDistributionsLookup]);

  const PERMITS_TABLE_COLUMNS = [
    {
      title: "Inactive",
      field: "is_terminated",
      type: "boolean",
      defaultFilter: "unchecked",
    },
    {
      title: "Permit Num",
      field: "permit_number",
      cellStyle: {
        width: 175,
        minWidth: 175,
      },
    },
    {
      title: "Legacy Permit Num",
      field: "legacy_permit_number",
      cellStyle: {
        width: 175,
        minWidth: 175,
      },
    },
    {
      title: "Authorized Vol (gal)",
      field: "authorized_volume_gal",
      cellStyle: {
        width: 175,
        minWidth: 175,
      },
    },
    {
      title: "Permit Name",
      field: "permit_name",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
    },
    {
      title: "Permittee",
      field: "owner_org",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
    },
    {
      title: "Permit Type",
      field: "permit_type_ndx",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
      lookup: permitTypesLookupForTable,
    },
    {
      title: "Permit Status",
      field: "permit_status_ndx",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
      lookup: permitStatusLookupForTable,
      // defaultFilter: ["1", "2", "4", "6", "8", "998", "999"],
    },
    {
      title: "Aquifer",
      field: "aquifer_group_ndx",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
      lookup: aquiferGroupsLookupForTable,
    },
    {
      title: "Contact",
      field: "owner_name",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
    },
    {
      title: "Primary Use",
      field: "primary_use_ndx",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
      lookup: permitUsesLookupForTable,
    },
    {
      title: "Allocation Distribution",
      field: "allocation_distribution_ndx",
      lookup: allocationDistributionsLookupForTable,
    },
    {
      title: "Deleted",
      field: "removed",
      type: "boolean",
      defaultFilter: "unchecked",
    },
  ];

  return {
    selectedPermit,
    setSelectedPermit,
    data,
    isFetching,
    error,
    refetch,
    PERMITS_TABLE_COLUMNS,
  };
};
