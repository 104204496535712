import React, { forwardRef, memo, useMemo } from "react";
import {
  Grid,
  Typography,
  Paper,
  Divider as MuiDivider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles({
  container: {
    pageBreakAfter: "always",
    position: "relative",
    margin: "40px",
    padding: "20px",
    backgroundColor: "#fff",
    fontFamily: "Times New Roman",
  },
  text: {
    fontFamily: "Times New Roman",
  },
  headerImage: {
    height: "100px",
  },
  headerTitle: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  smallText: {
    fontSize: "11px",
    fontWeight: "bold",
  },
  mainTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "20px",
  },
  sectionTitle: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  contentText: {
    fontSize: "12px",
  },
  footerText: {
    fontSize: "8px",
    textAlign: "right",
    marginTop: "60px",
  },
  listItem: {
    fontSize: "9px",
    marginBottom: "10px",
  },
  underlineText: {
    fontSize: "9px",
    textDecoration: "underline",
  },
  list: {
    paddingLeft: "20px",
  },
  gridItemTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: "13px",
    fontWeight: "bold",
  },
  gridItemContent: {
    fontSize: "12px",
  },
  footerContainer: {
    marginTop: "60px",
  },
});

const Divider = memo(({ mt, mb }) => (
  <MuiDivider style={{ marginTop: mt, marginBottom: mb }} />
));

const Header = memo(() => {
  const classes = useStyles();
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <img
            src="/static/img/pdf-print-logo.png"
            alt="Company Logo"
            className={classes.headerImage}
          />
        </Grid>
        <Grid item xs={8} style={{ textAlign: "right" }}>
          <Typography className={classes.headerTitle}>
            Barton Springs/Edwards Aquifer <br />
            Conservation District
          </Typography>
          <Typography className={classes.smallText}>
            1124 Regal Row Austin, TX 78748
          </Typography>
          <Typography className={classes.smallText}>(512) 282-8441</Typography>
        </Grid>
      </Grid>
      <Divider mt={2} mb={3} />
    </>
  );
});

const GridItem = memo(({ title, content }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={3}>
        <Typography className={classes.gridItemTitle}>{title}</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography className={classes.gridItemContent}>{content}</Typography>
      </Grid>
    </>
  );
});

const StandardGridItem = memo(({ field, title, item }) => (
  <GridItem title={title} content={item[field]} />
));

const MailAddressGridItem = memo(({ title, item }) => (
  <GridItem
    title={title}
    content={
      <>
        {item.owner_address_line_1}
        <br />
        {item.owner_address_line_2}
      </>
    }
  />
));

const TERMS_TEXT =
  "Expires August 31 following the date of issuance. Failure to pay fees, report pumpage, or abide by Rules, Bylaws, or Special Provisions of issuance, will subject this agreement to revocation. Permittee is subject to the enforcement mechanisms available to the District including but not limited to those set out in Rules 3-1.11, 3-1.13, 3-7.11, 3-8.5, 3-8.9 for noncompliance with District Drought Rules including but not limited to mandatory reduction goals.";

const TermsGridItem = memo(({ title }) => (
  <GridItem title={title} content={TERMS_TEXT} />
));

const AuthorizedGroundwaterGridItem = memo(({ title, item }) => (
  <GridItem
    title={title}
    content={
      <>
        Only that amount of water which is required without being wasteful
        during the term of this agreement, but not to exceed{" "}
        <u>{item.authorized_volume_gal}</u> gallons.
      </>
    }
  />
));

const Footer = memo(({ permitIssueDate }) => {
  const classes = useStyles();
  return (
    <div className={classes.footerContainer}>
      <Typography className={classes.contentText}>
        This Permit is hereby issued this <u>{permitIssueDate}</u>.
      </Typography>
      <Typography className={classes.contentText}>
        By: ___________________________________________________________, General
        Manager
      </Typography>
      <Typography className={classes.footerText}>
        Printed on: {new Date().toLocaleDateString("en-US")}
      </Typography>
    </div>
  );
});

const PermitPage = memo(({ item, permitIssueDate }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.container}>
      <Header />
      <Typography className={classes.mainTitle}>
        Historic Production Permit
      </Typography>
      <Grid container spacing={3}>
        {gridItemsConfig.map(({ field, title, Component }) => (
          <Component key={field} field={field} title={title} item={item} />
        ))}
      </Grid>
      <Footer permitIssueDate={permitIssueDate} />
    </Paper>
  );
});

const ConditionsAndRequirementsPage = memo(() => {
  const classes = useStyles();
  return (
    <Paper className={classes.container}>
      <Typography className={classes.underlineText}>
        PERMIT CONDITIONS AND REQUIREMENTS.
      </Typography>
      <Typography className={`${classes.text} ${classes.listItem}`}>
        All permits are granted subject to the Rules, regulations, Orders,
        special provisions, and other requirements of the Board, and the laws of
        the State of Texas. In addition, each permit issued shall be subject to
        the following conditions and requirements:
      </Typography>
      <ol className={classes.list}>
        {conditionsConfig.map((condition) => (
          <li key={condition.id} className={classes.listItem}>
            <Typography className={`${classes.text} ${classes.listItem}`}>
              {condition.text}
            </Typography>
          </li>
        ))}
      </ol>
    </Paper>
  );
});

const gridItemsConfig = [
  { field: "permit_number", title: "Permit No:", Component: StandardGridItem },
  { field: "owner_name", title: "Permit Owner:", Component: StandardGridItem },
  {
    field: "permit_group_name",
    title: "System/Group:",
    Component: StandardGridItem,
  },
  {
    field: "owner_address",
    title: "Mail Address:",
    Component: MailAddressGridItem,
  },
  {
    field: "mgmt_zone",
    title: "Management Zone:",
    Component: StandardGridItem,
  },
  {
    field: "well_count",
    title: "Number of Wells:",
    Component: StandardGridItem,
  },
  {
    field: "well_list",
    title: "State Well No(s):",
    Component: StandardGridItem,
  },
  { field: "terms", title: "Terms:", Component: TermsGridItem },
  {
    field: "authorized_volume_gal",
    title: "Authorized Groundwater Withdraw:",
    Component: AuthorizedGroundwaterGridItem,
  },
  {
    field: "special_provisions",
    title: "Special Provisions:",
    Component: StandardGridItem,
  },
];

const conditionsConfig = [
  {
    id: 1,
    text: "The permit is granted in accordance with the provisions of S.B. 988 of the 70th Texas Legislature in conjunction with Chapter 36, Texas Water Code, and the Rules, regulations and Orders of the District as may be in effect from time to time, and acceptance of the permit constitutes an acknowledgment and agreement that the permittee will comply with all the terms, provisions, conditions, requirements, limitations, and restrictions embodied in the permit and with the Rules, regulations, and Orders of the District.",
  },
  {
    id: 2,
    text: "The permit confers no vested rights in the holder and the permit is non-transferable. Written notice must be given to the District by the permittee prior to any sale or lease of the well covered by the permit. The permit may be revoked or suspended for failure to comply with its terms, which may be modified or amended pursuant to the requirements of the Act and any applicable Rules, regulations and Orders of the District.",
  },
  {
    id: 3,
    text: "A permit shall be subject to amendment by the District of the amount of water authorized for pumpage based upon a review of the District’s sustainable yield model and a determination by the District that an amendment is necessary after considering adequate water levels in water supply wells and degradation of water quality that could result from low water levels and low spring discharge.",
  },
  {
    id: 4,
    text: "The drilling and operation of the well for the authorized use shall be conducted in such a manner as to avoid waste, pollution, or harm to the aquifer.",
  },
  {
    id: 5,
    text: "The permittee shall keep accurate records and meter readings, on a monthly basis, of the amount of groundwater withdrawn, the purpose of the withdrawal, and, for any transporting of water outside the District, the amount of water transported and the identity and location of the recipients. Such records shall be submitted to the District office on a monthly basis, unless some other reporting period is specified in the permit, even if there is zero pumpage or transport for the time period and shall also be available for inspection at the permittee's principal place of business by District representatives. Immediate written notice shall be given to the District in the event a withdrawal or transporting of water exceeds the quantity authorized by the permit or Rules. Unless the permittee can present evidence that the pumpage or transport which exceeded the permitted amount is due to an isolated incident that is not likely to be repeated and/or would not result in continued higher demands, the permittee must immediately submit an application to increase the permitted pumpage or transport volume based on the amount of pumpage or transport which exceeded the permitted amount projected for the remainder of the fiscal year.",
  },
  {
    id: 6,
    text: "The well site or transport facilities shall be accessible to District representatives for inspection during normal business hours and during emergencies. The permittee agrees to cooperate fully in any reasonable inspection of the well site or transport facilities and related monitoring or sampling by District representatives. The well owner shall provide a 24-hour emergency contact to the District.",
  },
  {
    id: 7,
    text: "The application pursuant to which a permit has been issued is incorporated therein, and the permit is granted on the basis of and contingent upon the accuracy of the information supplied in that application and in any amendments thereof. A finding that false information has been supplied shall be grounds for immediate revocation of a permit. In the event of conflict between the provisions of the permit and the contents of the application, the provisions of the permit shall prevail.",
  },
  {
    id: 8,
    text: "Driller's logs must be submitted within 60 days of the drilling of a well.",
  },
  {
    id: 9,
    text: "For all new public water supply wells, a 150-feet radius sanitary control easement around the well must be recorded with county of record and evidence of said easement or a variance from TCEQ shall be provided to the District 60 days upon completion of the well.",
  },
  {
    id: 10,
    text: "Monitoring of groundwater pumpage is to be accomplished in the manner specified in the District's metering policy and any modifications thereto.",
  },
  {
    id: 11,
    text: "Violation of the permit's terms, conditions, requirements, or special provisions, including pumping amounts in excess of authorized withdrawal or transporting amounts outside of the District in excess of the amount authorized for transport, shall be punishable by civil penalties as provided by the Act and these Rules.",
  },
  {
    id: 12,
    text: "If special provisions are inconsistent with other provisions or regulations of the District, the Special Provisions shall prevail.",
  },
  {
    id: 13,
    text: "A Transport Permit may contain any term, condition, or limitation determined to be warranted by the District's Board based on the factors set forth in Rule 3-1. 6(A), and Section 36.122(d) of the Act.",
  },
  {
    id: 14,
    text: "Permittees will notify the District upon filing an application with the TCEQ to obtain or modify CCN to provide water or wastewater service in a service area that lies wholly or partly within the District or for which water shall be supplied from a well located inside the District.",
  },
  {
    id: 15,
    text: "Upon request of the District, permittees that are water utilities and that are not in compliance with their permit conditions concerning water use, are required to furnish the District the individual monthly water usage of all end-user customers that exceed the presumptive excessive-use criteria set forth in Section 3-3.7(C) of these Rules.",
  },
  {
    id: 16,
    text: "Permittees holding Class B or Class C Conditional Production Permits under Rule 3-1.24(D)(E) must maintain at all times the certain ability and binding commitment to switch from the to-be-permitted volume of groundwater to some alternative water supply source(s) on a 100% basis, including all necessary physical infrastructure and supporting agreements, rates, and tariffs required for such substitution, and the commitment to use the alternative supply as warranted by District-declared drought conditions.",
  },
  {
    id: 17,
    text: "A Permit does not authorize use on property within the District other than the well owner’s property without prior approval from the District for Multi-user Wells. For Permits approved for Multi-user Wells, the well owner shall be considered the sole permittee and shall be solely responsible for compliance with all applicable rules, permit conditions, and requirements including the multi-user well metering and reporting requirements pursuant to District Rule 3-2.",
  },
  {
    id: 18,
    text: "After notice and an opportunity for a hearing, the Permit may be reduced if the authorized withdrawal volume is deemed to be no longer commensurate with reasonable non-speculative demand or if actual production from a well is substantially less than the authorized permit amount for multiple years without any rationale that reasonably relates to efforts to utilize alternative water supplies, conserve, or improve water use efficiency.",
  },
  {
    id: 19,
    text: "After notice and an opportunity for a hearing, the Permit may be reduced or curtailed if the authorized withdrawal volume is determined to cause unreasonable impacts or failure to achieve the applicable DFC of the aquifers.",
  },
  {
    id: 20,
    text: "Wells must be maintained in good non-deteriorated condition and in compliance with Rule 5 related to District Well Construction Standards.",
  },
  {
    id: 21,
    text: "After receiving official notification from the District, the permittee shall implement the approved mitigation plan.",
  },
];

const PdfContent = forwardRef(({ data }, ref) => {
  const permitIssueDate = useMemo(
    () =>
      moment(`9/1/${new Date().getFullYear()}`, "M/D/YYYY").format(
        "Do [day of] MMMM, YYYY"
      ),
    []
  );

  return (
    <div style={{ display: "none" }}>
      <div ref={ref}>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <PermitPage item={item} permitIssueDate={permitIssueDate} />
            <ConditionsAndRequirementsPage />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
});

export default memo(PdfContent);
