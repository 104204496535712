import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";

export const usePermitGroups = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedPermitGroup, setSelectedPermitGroup] = useStateIfMounted(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["list-permit-groups"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-permit-groups`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const PERMIT_GROUPS_TABLE_COLUMNS = [
    {
      title: "Permit Grouop",
      field: "permit_group_name",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
    },
    {
      title: "Notes",
      field: "notes",
      cellStyle: {
        width: 400,
        minWidth: 400,
      },
    },
    {
      title: "Removed?",
      field: "removed",
      type: "boolean",
      defaultFilter: "unchecked",
    },
  ];

  return {
    selectedPermitGroup,
    setSelectedPermitGroup,
    data,
    isFetching,
    error,
    refetch,
    PERMIT_GROUPS_TABLE_COLUMNS,
  };
};
