import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Clear, ExpandMore } from "@material-ui/icons";
import React from "react";

import Loader from "../../../../components/Loader";
import { useSubmitData } from "../../../../hooks/useSubmitData";
import { useBasicMeterInfo } from "./useBasicMeterInfo";
import { getButtonIcon, getButtonText } from "../wellsManagement/utils";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import WellsFuzzySearch from "./WellsFuzzySearch";
import MetersFuzzySearch from "./MetersFuzzySearch";

function BasicMeterInfo({
  selectedMeter,
  setSelectedMeter,
  refetchMeters = () => {},
  isEditMode = false,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
    lookups,
  } = useBasicMeterInfo(selectedMeter);

  const handleRefreshAfterSubmit = () => {
    refetchMeters();
  };

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedMeter,
    setSelectedMeter,
    state,
    "list-meters",
    "meter_ndx",
    refetch,
    handleRefreshAfterSubmit
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.meter_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.meter_ndx) ? "none" : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Basic Meter Information</Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container spacing={3}>
            {!state?.meter_ndx && (
              <Grid
                item
                xs={12}
                xl={6}
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {!lookups?.setupWell.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <WellsFuzzySearch
                      options={lookups?.setupWell}
                      onSelect={(e) => {
                        handleUpdateState("setup_well_ndx", e);
                      }}
                    />

                    <div
                      style={{
                        width: "100%",
                        border: !state?.setup_well_ndx
                          ? "solid 1px red"
                          : "inherit",
                        borderRadius: !state?.setup_well_ndx
                          ? "5px"
                          : "inherit",
                        marginTop: "4px",
                      }}
                    >
                      <InputLabel
                        style={{ marginTop: "6px", marginLeft: "15px" }}
                        id="selected-meter"
                        required
                        error={!state?.setup_well_ndx}
                      >
                        Associated Well for Setup (manage multiple well links
                        below after saving meter)
                      </InputLabel>
                      {state?.setup_well_ndx ? (
                        <Link
                          color="secondary"
                          style={{
                            fontSize: "18px",
                            fontWeight: "700",
                            marginTop: "5px",
                            marginLeft: "15px",
                          }}
                          href={`/data-management/wells/wells-management/${state.setup_well_ndx}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {lookups?.setupWell?.find(
                            (well) => well.well_ndx === state.setup_well_ndx
                          )?.concatenated_well_info ?? ""}
                        </Link>
                      ) : (
                        <Typography
                          color="primary"
                          style={{
                            fontSize: "18px",
                            fontWeight: "700",
                            marginTop: "5px",
                            marginLeft: "15px",
                          }}
                        >
                          ** No Well Selected **
                        </Typography>
                      )}
                    </div>
                    {!state?.setup_well_ndx && (
                      <FormHelperText
                        style={{ marginLeft: "14px", marginTop: "4px" }}
                        error
                      >
                        *Required field
                      </FormHelperText>
                    )}
                  </>
                )}
              </Grid>
            )}

            {!state?.meter_ndx && (
              <Grid
                item
                xs={12}
                xl={6}
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {!lookups?.meters.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <MetersFuzzySearch
                      options={lookups?.meters}
                      onSelect={(e) => {
                        handleUpdateState("replaces_meter_ndx", e);
                      }}
                    />

                    <div
                      style={{
                        width: "100%",
                        marginTop: "4px",
                      }}
                    >
                      <InputLabel
                        style={{ marginTop: "6px", marginLeft: "15px" }}
                        id="replace-meter"
                      >
                        Replacement For:
                      </InputLabel>
                      {state?.replaces_meter_ndx ? (
                        <Link
                          color="secondary"
                          style={{
                            fontSize: "18px",
                            fontWeight: "700",
                            marginTop: "5px",
                            marginLeft: "15px",
                          }}
                          href={`/data-management/meter-readings/add-manage-meters/${state.replaces_meter_ndx}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {lookups?.meters?.find(
                            (well) =>
                              well.meter_ndx === state.replaces_meter_ndx
                          )?.meter_id ?? ""}
                        </Link>
                      ) : (
                        <Typography
                          color="primary"
                          style={{
                            fontSize: "18px",
                            fontWeight: "700",
                            marginTop: "5px",
                            marginLeft: "15px",
                          }}
                        >
                          ** No Meter Selected **
                        </Typography>
                      )}
                    </div>
                  </>
                )}
              </Grid>
            )}

            <Grid
              item
              xs={12}
              md={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                disabled={!state.meter_ndx}
                variant="outlined"
                label={
                  state.meter_ndx
                    ? "Meter ID"
                    : "**Meter ID will be Auto-generaged**"
                }
                style={{
                  width: "100%",
                }}
                onChange={(e) => handleUpdateState("meter_id", e.target.value)}
                value={state.meter_id ?? ""}
                InputLabelProps={{
                  style: {
                    color: state.meter_ndx ? "inherit" : "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={state.is_active}
                    onChange={(e) =>
                      handleUpdateState("is_active", e.target.checked)
                    }
                    // color="primary"
                    style={{
                      color: "rgba(0, 0, 0, 0.54)", // Replace with your desired color
                    }}
                  />
                }
                label={
                  <span
                  // style={{ color: "rgba(0, 0, 0, 0.87)" }}
                  >
                    Active?
                  </span>
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                component="fieldset"
                style={{ marginRight: "10px", width: "100%" }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    value={state?.joined_date}
                    onChange={(date) => handleUpdateState("joined_date", date)}
                    id="joined-date"
                    variant="inline"
                    format="LLLL do, yyyy"
                    autoOk
                    inputVariant="outlined"
                    label="Joined Date"
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                component="fieldset"
                style={{ width: "calc(100% - 67px)" }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    value={state?.retired_date}
                    onChange={(date) => handleUpdateState("retired_date", date)}
                    id="retired-date"
                    variant="inline"
                    format="LLLL do, yyyy"
                    autoOk
                    inputVariant="outlined"
                    label="Retired Date"
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <IconButton
                disabled={!state?.retired_date}
                style={{ margin: "0 10px" }}
                color="primary"
                aria-label="clear date"
                onClick={() => handleUpdateState("retired_date", null)}
              >
                <Clear fontSize="inherit" />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Meter Type"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("meter_type", e.target.value)
                }
                value={state.meter_type ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Brand"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("brand", e.target.value)}
                value={state.brand ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Meter Serial Number"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("meter_serial_number", e.target.value)
                }
                value={state.meter_serial_number ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Notes"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("notes", e.target.value)}
                value={state.notes ?? ""}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={handleResetState}>
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isDirty || isFormSubmitting}
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting, selectedMeter === 0)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default BasicMeterInfo;
