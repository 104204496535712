import React from "react";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper,
  Link as MuiLink,
  Typography,
  Container,
  Button,
} from "@material-ui/core";

import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";
import { Link } from "react-router-dom";

import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Success = () => {
  return (
    <>
      <Helmet title="Success - Public Well Registration" />
      <Typography variant="h3" gutterBottom display="inline">
        Success - Public Well Registration
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <MuiLink component={NavLink} exact to={`/dashboard`}>
          Dashboard
        </MuiLink>
        <MuiLink component={NavLink} exact to={`/public-well-registration`}>
          Well Registration
        </MuiLink>
        <Typography>Success</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Paper style={{ padding: "15px" }}>
        <Container maxWidth="md">
          <Typography variant="h5" gutterBottom>
            Thank you for registering your well.
          </Typography>
          <Typography gutterBottom>
            You will receive a confirmation email letting you know your
            registration was received.
          </Typography>
          <Typography gutterBottom>
            If you have multiple wells, please return to the{" "}
            <Link to="/public-well-registration">Well Registration Page</Link>{" "}
            to submit separate registrations for each well.
          </Typography>
          <Button
            style={{ marginTop: "15px" }}
            component={Link}
            to="/public-well-registration"
            variant="contained"
            color="primary"
          >
            Return to Well Registration Page
          </Button>
        </Container>
      </Paper>
    </>
  );
};

export default Success;
