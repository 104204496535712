import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Loader from "../../../../../../components/Loader";

import React from "react";
import { Info } from "@material-ui/icons";

function PermittedUse({
  currentPermit,
  state,
  handleUpdateState,
  lookups,
  selectedAmendment,
}) {
  return (
    <>
      <Grid
        container
        spacing={3}
        style={{
          border: "3px solid gray",
          marginBottom: "15px",
          borderRadius: "4px",
          marginTop: "5px",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            position: "relative",
          }}
        >
          <Typography variant="h5">Proposed Change in Permitted Use</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          xl={6}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControl variant="outlined" style={{ width: "100%" }}>
            {!lookups?.permitUses.length > 0 ? (
              <Loader />
            ) : (
              <>
                <TextField
                  disabled
                  variant="outlined"
                  label="Previous Primary Use"
                  style={{ width: "100%" }}
                  value={
                    lookups.permitUses.find(
                      (item) =>
                        item.well_use_ndx ===
                        (selectedAmendment
                          ? state?.saved_previous_primary_use_ndx
                          : currentPermit?.primary_use_ndx)
                    )?.well_use_name ?? "N/A"
                  }
                  InputProps={{
                    style: {
                      color: "rgba(0,0,0,1)",
                    },
                  }}
                />
              </>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          xl={6}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip
            title={
              <>
                Changing the{" "}
                <u>
                  <i>primary</i>
                </u>{" "}
                use of the permit will change the primary use of all the linked
                wells.
              </>
            }
          >
            <Info color="secondary" style={{ marginRight: "5px" }} />
          </Tooltip>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            {!lookups?.permitUses.length > 0 ? (
              <Loader />
            ) : (
              <>
                <InputLabel id="new-primary-use">New Primary Use</InputLabel>
                <Select
                  labelId="new primary use"
                  id="new-primary-use"
                  label="New Primary Use"
                  name="primary-use-ndx"
                  value={state.proposed_primary_use_ndx ?? ""}
                  onChange={(e) => {
                    handleUpdateState(
                      "proposed_primary_use_ndx",
                      e.target.value
                    );
                  }}
                >
                  {lookups.permitUses.map((option) => (
                    <MenuItem
                      key={option.well_use_ndx}
                      value={option.well_use_ndx}
                    >
                      {option.well_use_name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          xl={6}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControl variant="outlined" style={{ width: "100%" }}>
            {!lookups?.permitUses.length > 0 ? (
              <Loader />
            ) : (
              <>
                <TextField
                  disabled
                  variant="outlined"
                  label="Previous Secondary Use"
                  style={{ width: "100%" }}
                  value={
                    lookups.permitUses.find(
                      (item) =>
                        item.well_use_ndx ===
                        (selectedAmendment
                          ? state?.saved_previous_secondary_use_ndx
                          : currentPermit?.secondary_use_ndx)
                    )?.well_use_name ?? "N/A"
                  }
                  InputProps={{
                    style: {
                      color: "rgba(0,0,0,1)",
                    },
                  }}
                />
              </>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          xl={6}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip
            title={
              <div>
                Changing the{" "}
                <u>
                  <i>secondary</i>
                </u>{" "}
                use of the permit will not affect well use settings.
              </div>
            }
          >
            <Info color="secondary" style={{ marginRight: "5px" }} />
          </Tooltip>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            {!lookups?.permitUses.length > 0 ? (
              <Loader />
            ) : (
              <>
                <InputLabel id="new-secondary-use">
                  New Secondary Use
                </InputLabel>
                <Select
                  labelId="new secondary use"
                  id="new-secondary-use"
                  label="New Secondary Use"
                  name="seconeary-use-ndx"
                  value={state.proposed_secondary_use_ndx ?? ""}
                  onChange={(e) => {
                    handleUpdateState(
                      "proposed_secondary_use_ndx",
                      e.target.value
                    );
                  }}
                >
                  {lookups.permitUses.map((option) => (
                    <MenuItem
                      key={option.well_use_ndx}
                      value={option.well_use_ndx}
                    >
                      {option.well_use_name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default PermittedUse;
