import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";

export const useContacts = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedContact, setSelectedContact] = useStateIfMounted(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["list-rolodex"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-rolodex`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    selectedContact,
    setSelectedContact,
    data,
    isFetching,
    error,
    refetch,
  };
};
