import React from "react";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import MaterialTable from "material-table";
import CopyIcon from "@material-ui/icons/FileCopy";
import { ExpandMore } from "@material-ui/icons";

import { useApp } from "../../../../../AppProvider";
import { copyToClipboard, dateFormatter } from "../../../../../utils";
import { useAmendments } from "./useAmendments";
import Loader from "../../../../../components/Loader";
import Amendment from "./Amendment";

const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;
function AmendmentsManagement({
  selectedPermit,
  setTriggerRefetchPermit,
  triggerRefetch,
  handleTriggerRefetch,
  isEditMode = false,
}) {
  const {
    selectedAmendment,
    setSelectedAmendment,
    data,
    isFetching,
    error,
    refetch,
    AMENDMENTS_TABLE_COLUMNS,
  } = useAmendments(selectedPermit);
  const { doToast } = useApp();

  const handleRefetch = () => {
    setTriggerRefetchPermit((prevState) => !prevState);
    refetch();
    handleTriggerRefetch();
  };

  const getRowStyle = React.useCallback((rowData) => {
    return {
      backgroundColor: rowData.pamend_status_ndx === 2 ? "#f6f6f6" : "inherit",
    };
  }, []);

  if (error) return "An error has occurred: " + error.message;
  return (
    <React.Fragment>
      {isFetching && (
        <Paper style={{ width: "100%", margin: "8px 0" }}>
          <Loader />
        </Paper>
      )}
      <Accordion
        defaultExpanded={isEditMode}
        disabled={!selectedPermit}
        style={{
          display: isFetching ? "none" : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Permit Amendments</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <TableWrapper>
            <MaterialTable
              id="Permit Amendments Management"
              title={`Permit Amendments ${dateFormatter(
                new Date(),
                "MM/DD/YYYY, h:mm A"
              )}`}
              loading={isFetching || !data}
              columns={AMENDMENTS_TABLE_COLUMNS}
              data={data}
              localization={{
                toolbar: { searchPlaceholder: "Permit Amendments Selection" },
              }}
              components={{
                Container: (props) => <div {...props} />,
              }}
              actions={[
                {
                  icon: CopyIcon,
                  tooltip: "Copy Data",
                  isFreeAction: true,
                  onClick: () => {
                    try {
                      copyToClipboard(data, AMENDMENTS_TABLE_COLUMNS, () =>
                        doToast("success", "Data was copied to your clipboard.")
                      );
                    } catch (error) {
                      const message = error?.message ?? "Something went wrong";
                      doToast("error", message);
                    }
                  },
                },
                () => ({
                  icon: "edit",
                  tooltip: "Edit",
                  onClick: (event, rowData) => {
                    setSelectedAmendment(rowData?.pamend_ndx);
                  },
                }),
                ...(selectedPermit
                  ? [
                      {
                        icon: "add_box",
                        tooltip: "Add",
                        isFreeAction: true,
                        onClick: () => {
                          setSelectedAmendment(0);
                        },
                      },
                    ]
                  : []),
              ]}
              options={{
                filtering: true,
                emptyRowsWhenPaging: false,
                showTitle: false,
                columnsButton: true,
                exportButton: true,
                exportAllData: true,
                addRowPosition: "first",
                pageSize: 30,
                pageSizeOptions: [10, 30, 50, 100, 200],
                padding: "dense",
                searchFieldAlignment: "left",
                minBodyHeight: "250px",
                maxBodyHeight: "400px",
                rowStyle: getRowStyle,
              }}
            />
          </TableWrapper>
        </AccordionDetails>
      </Accordion>

      {!!selectedPermit && (
        <Box
          style={{
            display:
              selectedAmendment || selectedAmendment === 0 ? "block" : "none",
          }}
        >
          <Amendment
            triggerRefetch={triggerRefetch}
            handleRefetch={handleRefetch}
            selectedPermit={selectedPermit}
            selectedAmendment={selectedAmendment}
            setSelectedAmendment={setSelectedAmendment}
          />
        </Box>
      )}
    </React.Fragment>
  );
}

export default AmendmentsManagement;
