import React, { useMemo } from "react";
import { useQueryAndDownload } from "./useQueryAndDownload";
import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Typography as MuiTypography,
} from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { FormProvider } from "react-hook-form";
import { SimpleSelect } from "../../../components/inputs/Selects";
import { ChipSelect } from "../../../components/inputs/Chips";
import Loader from "../../../components/Loader";
import {
  SimpleCheckbox,
  TriStateCheckbox,
} from "../../../components/inputs/CheckBoxes";
import { FocusButtons } from "./FocusButtons";
import CONFIG from "./config";

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Paper = styled(MuiPaper)(spacing);

const ActionButton = styled(Button)`
  height: 52px;
`;

const CountValue = styled.span`
  font-weight: bold;
  font-size: 2rem;
`;

const OptionsHeader = ({
  text,
  color = "secondary",
  variant = "subtitle1",
  ...rest
}) => (
  <Typography color={color} variant={variant} {...rest}>
    {text}
  </Typography>
);

const FormSection = ({ children, title }) => (
  <>
    <Grid item xs={12}>
      <OptionsHeader text={title} />
    </Grid>
    {children}
  </>
);

const QueryAndDownload = () => {
  const {
    currentFocus,
    handleSelectFocus,
    formMethods,
    handleSubmission,
    handleReset,
    isLoading,
    error,
    inputs,
    searchInput,
    currentCount,
    isLoadingCurrentCount,
  } = useQueryAndDownload();

  const { formState } = formMethods;

  const { tristateCheckboxes, multiselects, checkboxes, searches } =
    useMemo(() => {
      const tristateCheckboxes = [];
      const multiselects = [];
      const checkboxes = [];
      const searches = [];
      currentFocus.inputs.forEach((input) => {
        switch (input.type) {
          case "tristateCheckbox":
            tristateCheckboxes.push(input);
            break;
          case "multiselect":
            multiselects.push(input);
            break;
          case "checkbox":
            checkboxes.push(input);
            break;
          case "search":
            searches.push(input);
            break;
          default:
            break;
        }
      });
      return { tristateCheckboxes, multiselects, checkboxes, searches };
    }, [currentFocus.inputs]);

  if (error) {
    return <p>Error fetching data: {error.message}</p>;
  }

  return (
    <>
      <Helmet title="Query & Download" />
      <Typography variant="h3" gutterBottom display="inline">
        Query & Download
      </Typography>

      <Divider my={6} />

      <Paper p={4}>
        <form key={currentFocus.name} onSubmit={handleSubmission}>
          <FormProvider {...formMethods}>
            <Grid container spacing={3}>
              <FormSection title="Query Focus">
                <Grid item xs={12}>
                  <FocusButtons
                    options={CONFIG}
                    selectedFocus={currentFocus.ndx}
                    setCurrentFocus={handleSelectFocus}
                  />
                </Grid>
              </FormSection>

              <FormSection title="Filters">
                {tristateCheckboxes.map(({ formName, label }) => (
                  <Grid item xs={12} sm={3} key={formName}>
                    <TriStateCheckbox name={formName} label={label} />
                  </Grid>
                ))}

                {multiselects.map(
                  ({ formName, label, valueField, displayField }) => (
                    <Grid item xs={12} sm={6} key={formName}>
                      <SimpleSelect
                        name={formName}
                        label={label}
                        valueField={valueField}
                        displayField={displayField}
                        options={inputs[formName] ? inputs[formName].data : []}
                        isLoading={
                          inputs[formName] ? inputs[formName].isLoading : true
                        }
                        multiple
                      />
                    </Grid>
                  )
                )}
              </FormSection>

              <FormSection title="Inclusions">
                {checkboxes.map(({ formName, label }) => (
                  <Grid item xs={12} sm={3} key={formName}>
                    <SimpleCheckbox name={formName} label={label} />
                  </Grid>
                ))}
              </FormSection>

              <FormSection title={currentFocus.label}>
                {searches.map(
                  ({
                    formName,
                    label,
                    valueField,
                    displayField,
                    tooltipField,
                    fuseKeys,
                  }) => (
                    <Grid item xs={12} key={formName}>
                      <ChipSelect
                        name={formName}
                        label={label}
                        valueField={valueField}
                        displayField={displayField}
                        tooltipField={tooltipField}
                        options={searchInput ? searchInput.data : []}
                        isLoading={searchInput ? searchInput.isLoading : true}
                        fuseKeys={fuseKeys}
                      />
                    </Grid>
                  )
                )}
              </FormSection>

              <FormSection title="Available Records">
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    {isLoadingCurrentCount && <Loader />}
                    <Typography>
                      {currentCount !== null ? (
                        <>
                          <CountValue>
                            {!isLoadingCurrentCount && currentCount}
                          </CountValue>{" "}
                          records available for download
                        </>
                      ) : (
                        "Please fill out the form to see available records"
                      )}
                    </Typography>
                  </Box>
                </Grid>

                <Grid container spacing={3} mt={2} alignItems="center">
                  <Grid item xs>
                    <ActionButton
                      fullWidth
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={
                        formState.isSubmitting ||
                        !formState.isDirty ||
                        isLoading
                      }
                      startIcon={
                        formState.isSubmitting || isLoading ? (
                          <Loader color="inherit" />
                        ) : null
                      }
                    >
                      {formState.isSubmitting || isLoading
                        ? "Preparing..."
                        : "Download"}
                    </ActionButton>
                    {error && <p>Error downloading file.</p>}
                  </Grid>
                  <Grid item>
                    <ActionButton
                      onClick={handleReset}
                      color="secondary"
                      variant="outlined"
                    >
                      Clear
                    </ActionButton>
                  </Grid>
                  {error && (
                    <Grid item xs={12}>
                      <p>Error downloading file.</p>
                    </Grid>
                  )}
                </Grid>
              </FormSection>
            </Grid>
          </FormProvider>
        </form>
      </Paper>
    </>
  );
};

export default QueryAndDownload;
