import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { AccordionActions } from "@mui/material";
import React from "react";
import Loader from "../../../../../components/Loader";
import { useHydrogeology } from "../hooks/useHydrogeology";
import { useSubmitData } from "../../../../../hooks/useSubmitData";
import { getButtonIcon, getButtonText } from "../utils";

function Hydrogeology({
  selectedWell,
  setSelectedWell,
  isEditMode = false,
  selectedWellLabel,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
    lookups,
  } = useHydrogeology(selectedWell);

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedWell,
    setSelectedWell,
    state,
    "list-wells-hydrogeology",
    "well_ndx",
    refetch
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.well_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded={isEditMode}
        disabled={!selectedWell}
        style={{
          display:
            isLoading || (isEditMode && !state?.well_ndx) ? "none" : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">{`Hydrogeology ${
              selectedWellLabel ? `(${selectedWellLabel})` : ""
            }`}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.aquiferCompletion.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="aquifer-completion">
                      Aquifer Completion
                    </InputLabel>
                    <Select
                      disabled={!selectedWell}
                      labelId="aquifer-completion"
                      id="aquifer-completion"
                      label="Aquifer Completion"
                      name="aq_completion_ndx"
                      value={state?.aq_completion_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("aq_completion_ndx", e.target.value);
                      }}
                    >
                      {lookups.aquiferCompletion.map((option) => (
                        <MenuItem
                          key={option.aq_completion_ndx}
                          value={option.aq_completion_ndx}
                        >
                          {option.aq_completion_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.aquiferProductionZone.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="aquifer-production-zone">
                      Aquifer Production Zone
                    </InputLabel>
                    <Select
                      disabled={!selectedWell}
                      labelId="aquifer-production-zone"
                      id="aquifer-production-zone"
                      label="Aquifer Production Zone"
                      name="aquifer_ndx"
                      value={state?.aquifer_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("aquifer_ndx", e.target.value);
                      }}
                    >
                      {lookups.aquiferProductionZone.map((option) => (
                        <MenuItem
                          key={option.aquifer_ndx}
                          value={option.aquifer_ndx}
                        >
                          {option.aquifer_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.aquiferSetting.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="aquifer-setting">
                      Aquifer Setting
                    </InputLabel>
                    <Select
                      disabled={!selectedWell}
                      labelId="aquifer-setting"
                      id="aquifer-setting"
                      label="Aquifer Setting"
                      name="aq_setting_ndx"
                      value={state?.aq_setting_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("aq_setting_ndx", e.target.value);
                      }}
                    >
                      {lookups.aquiferSetting.map((option) => (
                        <MenuItem
                          key={option.aq_setting_ndx}
                          value={option.aq_setting_ndx}
                        >
                          {option.aq_setting_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.surfaceGeology.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="surface-geology">
                      Surface Geology
                    </InputLabel>
                    <Select
                      disabled={!selectedWell}
                      labelId="surface-geology"
                      id="surface-geology"
                      label="Surface Geology"
                      name="formation_ndx"
                      value={state?.formation_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("formation_ndx", e.target.value);
                      }}
                    >
                      {lookups.surfaceGeology.map((option) => (
                        <MenuItem
                          key={option.formation_ndx}
                          value={option.formation_ndx}
                        >
                          {option.formation_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.watershed.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="watershed">Watershed</InputLabel>
                    <Select
                      disabled={!selectedWell}
                      labelId="watershed"
                      id="watershed"
                      label="Watershed"
                      name="watershed_ndx"
                      value={state?.watershed_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("watershed_ndx", e.target.value);
                      }}
                    >
                      {lookups.watershed.map((option) => (
                        <MenuItem
                          key={option.watershed_ndx}
                          value={option.watershed_ndx}
                        >
                          {option.watershed_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.logTypes.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="surface-geology">
                      Available Log Types
                    </InputLabel>
                    <Select
                      multiple
                      disabled={!selectedWell}
                      labelId="available-log-types"
                      id="available-log-types"
                      label="Available Log Types"
                      name="available_logs_list"
                      value={state?.available_logs_list ?? []}
                      onChange={(e) => {
                        handleUpdateState(
                          "available_logs_list",
                          e.target.value
                        );
                      }}
                    >
                      {lookups.logTypes.map((option) => (
                        <MenuItem
                          key={option.well_log_type_ndx}
                          value={option.well_log_type_ndx}
                        >
                          {option.well_log_type_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button
            disabled={!selectedWell}
            size="small"
            onClick={handleResetState}
          >
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isDirty || isFormSubmitting}
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default Hydrogeology;
