import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { NavLink, useParams } from "react-router-dom";

import BasicPermitInfo from "./BasicPermitInfo";
import LinkedWellsManagement from "./LinkedWellsManagement";
import { useStateIfMounted } from "use-state-if-mounted";
import AttachedDocuments from "./AttachedDocuments";
import AmendmentsManagement from "./Amendments/AmendmentsManagement";
import MonthlyDistributions from "./MonthlyDistributions";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

function PermitsManagement() {
  let { id } = useParams();
  const [selectedPermit, setSelectedPermit] = useStateIfMounted(id);

  const [triggerRefetch, setTriggerRefetch] = React.useState(false);
  const handleTriggerRefetch = () => setTriggerRefetch(!triggerRefetch);
  const handleRefetch = () => {
    handleTriggerRefetch();
  };

  const [triggerRefetchPermit, setTriggerRefetchPermit] = React.useState(false);

  return (
    <React.Fragment>
      <Helmet title="Permit Management" />
      <Typography variant="h3" gutterBottom display="inline">
        Permit Management
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Link
          component={NavLink}
          exact
          to="/data-management/permit-records/permits-management"
        >
          Permits Management
        </Link>
        <Typography>Edit Permit</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <BasicPermitInfo
        selectedPermit={selectedPermit}
        setSelectedPermit={setSelectedPermit}
        isEditMode={true}
        refetchPermits={handleRefetch}
        triggerRefetch={triggerRefetch}
        triggerRefetchPermit={triggerRefetchPermit}
      />
      <MonthlyDistributions
        selectedPermit={selectedPermit}
        triggerRefetch={triggerRefetch}
        isEditMode={true}
      />
      <LinkedWellsManagement
        selectedPermit={selectedPermit}
        isEditMode={true}
        refetchBoxTables={handleRefetch}
      />
      <AmendmentsManagement
        handleTriggerRefetch={handleTriggerRefetch}
        triggerRefetch={triggerRefetch}
        selectedPermit={selectedPermit}
        setTriggerRefetchPermit={setTriggerRefetchPermit}
        isEditMode={true}
      />
      <AttachedDocuments selectedPermit={selectedPermit} isEditMode={true} />
    </React.Fragment>
  );
}

export default PermitsManagement;
