import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TableContainer,
  TextField,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import useFetchData from "../../../../hooks/useFetchData";
import Loader from "../../../../components/Loader";
import { Alert } from "@material-ui/lab";
import { copyToClipboard, formatter } from "../../../../utils";
import CopyIcon from "@material-ui/icons/FileCopy";
import MaterialTable from "material-table";
import { useApp } from "../../../../AppProvider";
import DataAdminTableBulkUpdateMeterReadings from "../../../../components/DataAdminTableBulkUpdateMeterReadings";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function getDefaultYear() {
  const today = new Date();
  let year = today.getFullYear();

  if (today.getMonth() >= 8) {
    year += 1;
  }

  return year;
}

const defaultFilters = {
  selectedYear: getDefaultYear(),
  selectedMonth: new Date().getMonth() + 1,
  selectedPermitgrouping: null,
};

//388px
const MeterReadingsManagement = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();

  const [filters, setFilters] = useState(defaultFilters);

  const [yearsLookup] = useFetchData("ui-lookup-mr-filters-years", [], true);

  const [permitgroupingsLookup] = useFetchData(
    "ui-lookup-mr-filters-permitgroupings",
    [],
    true
  );

  const [adjustmentReasonLookup] = useFetchData(
    "ui-list-meter-reading-adjustment-reason",
    [],
    true
  );
  const adjustmentReasonLookupForTable = useMemo(() => {
    let converted = {};
    if (adjustmentReasonLookup?.length > 0) {
      adjustmentReasonLookup.forEach((item) => {
        converted[item.mr_adjustment_reason_ndx] =
          item.mr_adjustment_reason_desc;
      });
    }
    return converted;
  }, [adjustmentReasonLookup]);

  const [reportStatusLookup] = useFetchData(
    "ui-list-meter-reading-report-status",
    [],
    true
  );
  const reportStatusLookupForTable = useMemo(() => {
    let converted = {};
    if (reportStatusLookup?.length > 0) {
      reportStatusLookup.forEach((item) => {
        converted[item.mr_report_status_ndx] = item.mr_report_status_desc;
      });
    }
    return converted;
  }, [reportStatusLookup]);

  const [monthsLookup] = useFetchData("list-months", [], true);
  const monthsLookupForTable = useMemo(() => {
    let converted = {};
    if (monthsLookup?.length > 0) {
      monthsLookup.forEach((item) => {
        converted[item.month_number] = item.month_name;
      });
    }
    return converted;
  }, [monthsLookup]);

  const { data, isFetching, error, refetch } = useQuery(
    [
      "ui-meter-readings-edits",
      filters.selectedYear,
      filters.selectedMonth,
      filters.selectedPermitgrouping,
    ],
    async () => {
      if (!filters.selectedPermitgrouping) {
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-meter-readings-edits/${filters.selectedYear}/${filters.selectedMonth}/${filters.selectedPermitgrouping}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  //ui_meter_readings_entry_top_table
  const [pumpingSummary] = useFetchData(
    "ui-meter-readings-entry-top-table",
    [],
    true
  );

  const columns = useMemo(
    () => [
      {
        title: "Calendar Year",
        field: "c_year",
        editable: "never",
        filtering: false,
      },
      {
        title: "Calendar Month",
        field: "c_month",
        editable: "never",
        lookup: monthsLookupForTable,
      },
      {
        title: "Meter ID",
        field: "meter_id",
        editable: "never",
        cellStyle: {
          width: 150,
          minWidth: 150,
        },
      },
      {
        title: "Last Reading",
        field: "prev_meter_reading",
        editable: "never",
        filtering: false,
        render: (rowData) => formatter(rowData.prev_meter_reading, 0) ?? "",
        cellStyle: {
          width: 150,
          minWidth: 150,
        },
      },
      {
        title: "New Reading",
        field: "meter_reading",
        filtering: false,
        render: (rowData) => formatter(rowData.meter_reading, 0) ?? "",
        editComponent: (props) => {
          return (
            <TextField
              value={props.value ?? ""}
              type="number"
              onChange={(e) => {
                props.onChange(e.target.value === "" ? null : e.target.value);
              }}
              InputProps={{
                inputProps: {
                  onKeyPress: (e) => {
                    if (["e", "."].includes(e.key)) {
                      e.preventDefault();
                    }
                  },
                },
              }}
            />
          );
        },
        emptyValue: "(empty)",
        cellStyle: {
          width: 100,
          minWidth: 100,
        },
      },
      {
        title: "Adjustment",
        field: "adjustment",
        filtering: false,
        render: (rowData) => formatter(rowData.adjustment, 0) ?? "",
        editComponent: (props) => {
          return (
            <TextField
              value={props.value}
              type="number"
              onChange={(e) => {
                props.onChange(e.target.value);
              }}
              InputProps={{
                inputProps: {
                  onKeyPress: (e) => {
                    if (["e", "."].includes(e.key)) {
                      e.preventDefault();
                    }
                  },
                },
              }}
              error={!!props.helperText}
              helperText={props.helperText}
            />
          );
        },
        validate: (rowData) =>
          rowData.adjustment === ""
            ? {
                isValid: false,
                helperText: "This value is required",
              }
            : true,
        emptyValue: "(empty)",
        cellStyle: {
          width: 100,
          minWidth: 100,
        },
      },
      {
        title: "Adjustment Reason",
        field: "mr_adjustment_reason_ndx",
        lookup: adjustmentReasonLookupForTable,
        filtering: false,
        editComponent: (props) => {
          return (
            <>
              <Select
                value={props.value ?? ""}
                onChange={(e) => {
                  props.onChange(e.target.value);
                }}
                style={{ width: "100%" }}
                error={!!props.helperText}
              >
                <MenuItem value={null}>(empty)</MenuItem>
                {adjustmentReasonLookup.map((option) => (
                  <MenuItem
                    key={option.mr_adjustment_reason_ndx}
                    value={option.mr_adjustment_reason_ndx}
                  >
                    {option.mr_adjustment_reason_desc}
                  </MenuItem>
                ))}
              </Select>
              {!!props.helperText && (
                <FormHelperText error>{props.helperText}</FormHelperText>
              )}
            </>
          );
        },
        validate: (rowData) => {
          return ![0, "0", ""].includes(rowData.adjustment) &&
            !rowData.mr_adjustment_reason_ndx
            ? {
                isValid: false,
                helperText:
                  "There has been adjustment; select an adjustment reason",
              }
            : [0, "0"].includes(rowData.adjustment) &&
              rowData.mr_adjustment_reason_ndx
            ? {
                isValid: false,
                helperText:
                  "There cannot be an adjustment reason for a zero adjustment",
              }
            : true;
        },
        emptyValue: "(empty)",
        cellStyle: {
          width: 300,
          minWidth: 300,
        },
      },
      {
        title: "Reporting Status",
        field: "mr_report_status_ndx",
        lookup: reportStatusLookupForTable,
        filtering: false,
        editComponent: (props) => {
          return (
            <Select
              value={props.value ?? ""}
              onChange={(e) => props.onChange(e.target.value)}
              style={{ width: "100%" }}
            >
              {reportStatusLookup.map((option) => (
                <MenuItem
                  key={option.mr_report_status_ndx}
                  value={option.mr_report_status_ndx}
                >
                  {option.mr_report_status_desc}
                </MenuItem>
              ))}
            </Select>
          );
        },
        emptyValue: "(empty)",
        cellStyle: {
          width: 200,
          minWidth: 200,
        },
      },
      {
        title: "Calculated Gallons",
        field: "calculated_gallons",
        editable: "never",
        filtering: false,
        render: (rowData) => formatter(rowData.calculated_gallons, 0) ?? "",
        cellStyle: {
          width: 100,
          minWidth: 100,
        },
      },
      {
        title: "Notes",
        field: "notes",
        filtering: false,
        editComponent: (props) => {
          return (
            <TextField
              type="text"
              value={props.value ?? ""} // Use empty string for null or undefined values
              onChange={(e) => props.onChange(e.target.value)}
              fullWidth
            />
          );
        },
        emptyValue: "(empty)",
        cellStyle: {
          width: 400,
          minWidth: 400,
        },
      },
    ],
    //eslint-disable-next-line
    [
      monthsLookupForTable,
      adjustmentReasonLookupForTable,
      reportStatusLookupForTable,
      data,
    ]
  );

  const columnsSummary = [
    {
      title: "Fiscal Year",
      field: "fiscal_year",
    },
    {
      title: "Owner/Permit Name",
      field: "grouping_name",
    },
    {
      title: "Annual Allocation",
      field: "permitted_gallons",
      render: (rowData) => formatter(rowData.permitted_gallons, 0) ?? "",
    },
    {
      title: "YTD Metered",
      field: "metered_gallons",
      render: (rowData) => formatter(rowData.metered_gallons, 0) ?? "",
    },
    {
      title: "% Pumped",
      field: "pct_pumped",
      render: (rowData) =>
        rowData.pct_pumped !== null
          ? (rowData.pct_pumped * 100).toFixed(2)
          : "",
    },
    {
      title: "Over Annual",
      field: "is_overpumped",
      render: (rowData) =>
        rowData.is_overpumped === true
          ? "Yes"
          : rowData.is_overpumped === false
          ? "No"
          : "N/A",
      cellStyle: (value) => {
        return {
          backgroundColor: value ? "orange" : "inherit",
          color: value ? "white" : "inherit",
          fontWeight: value ? 600 : "inherit",
        };
      },
    },
    {
      title: "Over 80%",
      field: "is_over80pct",
      render: (rowData) =>
        rowData.is_over80pct === true
          ? "Yes"
          : rowData.is_over80pct === false
          ? "No"
          : "N/A",
      cellStyle: (value) => {
        return {
          backgroundColor: value ? "yellow" : "inherit",
          fontWeight: value ? 600 : "inherit",
        };
      },
    },
  ];

  const handleUpdateState = (name, value) => {
    setFilters((prevState) => {
      let newFilterValues = { ...prevState };

      // Update the changed item
      newFilterValues[name] = value;

      // Logic to check if the selected owner is still a valid option
      if (name === "selectedYear") {
        const isValidPermitgrouping = checkIfPermitgroupingIsValid(
          value,
          newFilterValues.selectedPermitgrouping
        );
        if (!isValidPermitgrouping) {
          newFilterValues["selectedPermitgrouping"] = null;
        }
      }

      return newFilterValues;
    });
  };

  // These functions should contain the logic to determine if an owner or a permit is still valid
  function checkIfPermitgroupingIsValid(selectedYear, selectedPermitgrouping) {
    const availablePermitgroupings = permitgroupingsLookup.filter(
      (permitgrouping) => permitgrouping.fiscal_year === selectedYear
    );
    return availablePermitgroupings.some(
      (permitgrouping) => permitgrouping.grouping_ndx === selectedPermitgrouping
    );
  }

  const [currentSummary, setcurrentSummary] = useState([]);
  useEffect(() => {
    if (
      pumpingSummary?.length > 0 &&
      filters.selectedYear &&
      filters.selectedPermitgrouping
    ) {
      setcurrentSummary(
        pumpingSummary.filter(
          (item) =>
            item.fiscal_year === filters.selectedYear &&
            item.grouping_ndx === filters.selectedPermitgrouping
        )
      );
    }
  }, [pumpingSummary, filters.selectedYear, filters.selectedPermitgrouping]);

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <Helmet title="Meter Readings Entry" />
      <Typography variant="h3" gutterBottom display="inline">
        Meter Readings Entry
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Meter Readings Entry</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <div>
        <TableContainer
          component={Paper}
          style={{
            marginBottom: "12px",
            border: "solid gray 3px",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{ padding: "16px", fontWeight: 600 }}
          >
            Meter Reading Summary
          </Typography>
          {currentSummary.length > 0 && (
            <Alert severity="info">
              <Typography variant="h6" component="div">
                Summary is a recap of the <strong>fiscal year</strong> to date,
                regardless of month selected below.
              </Typography>
            </Alert>
          )}
          {filters.selectedYear &&
          filters.selectedPermitgrouping &&
          !currentSummary.length > 0 ? (
            <Alert severity="warning">
              <Typography variant="h6" component="div">
                No summary available for current selection.
              </Typography>
            </Alert>
          ) : !filters.selectedYear || !filters.selectedPermitgrouping ? (
            <Box flexGrow={1} mb={3}>
              <Alert severity="info">
                <Typography
                  variant="h6"
                  component="div"
                  style={{ padding: "16px" }}
                >
                  Select a year, month(s) and a permit grouping to view summary
                  results.
                </Typography>
              </Alert>
            </Box>
          ) : (
            <MaterialTable
              id="meter-reading-summary"
              title="Meter Reading Summary"
              columns={columnsSummary}
              data={currentSummary}
              actions={[
                {
                  icon: CopyIcon,
                  tooltip: "Copy Data",
                  isFreeAction: true,
                  onClick: () => {
                    try {
                      copyToClipboard(data, columns, () =>
                        doToast("success", "Data was copied to your clipboard.")
                      );
                    } catch (error) {
                      const message = error?.message ?? "Something went wrong";
                      doToast("error", message);
                    }
                  },
                },
              ]}
              options={{
                columnsButton: true,
                exportButton: { csv: true },
                padding: "dense",
                search: false,
                showTitle: false,
                paging: false,
              }}
            />
          )}
        </TableContainer>
      </div>

      <Paper style={{ padding: "10px" }}>
        <Grid
          container
          spacing={3}
          style={{
            display: "flex",
          }}
        >
          <Grid
            item
            xs={12}
            lg={4}
            style={{
              position: "relative",
            }}
          >
            <FormControl variant="outlined" style={{ width: "100%" }}>
              {!yearsLookup.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="Meters">Fiscal Year</InputLabel>
                  <Select
                    labelId="fiscal-year"
                    id="fiscal-year"
                    label="Fiscal Year"
                    name="fiscal_year"
                    value={filters.selectedYear ?? ""}
                    onChange={(e) => {
                      handleUpdateState("selectedYear", e.target.value);
                    }}
                  >
                    {yearsLookup.map((option) => (
                      <MenuItem
                        key={option.fiscal_year}
                        value={option.fiscal_year}
                      >
                        {option.fiscal_year}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            lg={4}
            style={{
              position: "relative",
            }}
          >
            <FormControl variant="outlined" style={{ width: "100%" }}>
              {!monthsLookup.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="Meters">Month</InputLabel>
                  <Select
                    labelId="month"
                    id="month"
                    label="Month"
                    name="c_month"
                    value={filters.selectedMonth}
                    onChange={(e) => {
                      handleUpdateState("selectedMonth", e.target.value);
                    }}
                  >
                    <MenuItem value={0}>All</MenuItem>
                    {monthsLookup.map((option) => (
                      <MenuItem
                        key={option.month_number}
                        value={option.month_number}
                      >
                        {option.month_name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            lg={4}
            style={{
              position: "relative",
            }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              error={!filters.selectedPermitgrouping}
              required
            >
              {!permitgroupingsLookup.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="Meters">Permit Owner/Group</InputLabel>
                  <Select
                    labelId="permitgrouping"
                    id="permitgrouping"
                    label="Permit Owner/Group"
                    name="grouping_name"
                    value={filters.selectedPermitgrouping ?? ""}
                    onChange={(e) => {
                      handleUpdateState(
                        "selectedPermitgrouping",
                        e.target.value
                      );
                    }}
                  >
                    {permitgroupingsLookup
                      .filter(
                        (item) => item.fiscal_year === filters.selectedYear
                      )
                      .map((option) => (
                        <MenuItem
                          key={option.grouping_ndx}
                          value={option.grouping_ndx}
                        >
                          {option.grouping_name}
                        </MenuItem>
                      ))}
                  </Select>
                </>
              )}
              {!filters.selectedPermitgrouping && (
                <FormHelperText error>*Required field</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        {filters.selectedPermitgrouping ? (
          <TableWrapper>
            <Typography
              variant="h6"
              component="div"
              style={{ padding: "16px", fontWeight: 600 }}
            >
              Meter Readings
            </Typography>
            <DataAdminTableBulkUpdateMeterReadings
              pageSize={60}
              isLoading={isFetching}
              label="Meter Readings"
              columns={columns}
              data={data}
              height="100%"
              endpoint="ui-meter-readings-edits/bulk-update"
              ndxField="meter_reading_ndx"
              handleRefresh={refetch}
              search={false}
            />
          </TableWrapper>
        ) : (
          <Alert severity="info">
            <Typography
              variant="h6"
              component="div"
              style={{ padding: "16px" }}
            >
              Select a year, month(s) and a permit grouping to view/enter Meter
              Readings.
            </Typography>
          </Alert>
        )}
      </Paper>
    </>
  );
};

export default MeterReadingsManagement;
