import React, { useEffect, useState } from "react";
// import "./styles.css";
import styled from "styled-components/macro";
import { isNullOrUndef } from "chart.js/helpers";
import { formatBooleanTrueFalse } from "../../../utils";
import Button from "@material-ui/core/Button";
import { Pagination } from "@material-ui/lab";
import { titleize } from "inflected";
import {
  Divider as MuiDivider,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

const PopupWrap = styled.div`
  height: ${({ height }) => height};
  overflow-y: scroll;
  width: ${({ width }) => width};
`;

const PopupTable = styled.table`
  border-radius: 5px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  width: 100%;
`;

const PopupRow = styled.tr`
  border-radius: 5px;
  &:nth-child(even) {
    background-color: #eee;
  }
`;

const PopupCell = styled.td`
  padding: 3px 6px;
  margin: 0;
`;

const PopupUl = styled.ul`
  list-style-type: none;
  margin: 0 !important;
  padding: 3px 0;
`;

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Popup = ({
  setDataVizVisible,
  setDataVizWell,
  setDataVizGraphType,
  features,
  layers,
  currentUser,
  renderGraphButtons = true,
  height = "200px",
  width = "380px",
  size = "medium",
}) => {
  const dataVizTypes = ["Production Data"];

  function getUniqueFeatures(array, comparatorProperty1, comparatorProperty2) {
    const existingFeatureKeys = {};
    // Because features come from tiled vector data, feature geometries may be split
    // or duplicated across tile boundaries and, as a result, features may appear
    // multiple times in query results.
    //concat two ids to make a unique id
    return array.filter((el) => {
      if (
        existingFeatureKeys[
          el[comparatorProperty1] + el.layer[comparatorProperty2]
        ]
      ) {
        return false;
      } else {
        existingFeatureKeys[
          el[comparatorProperty1] + el.layer[comparatorProperty2]
        ] = true;
        return true;
      }
    });
  }

  const uniqueFeatures = getUniqueFeatures(features, "id", "id");

  const [page, setPage] = useState(1);
  const [feature, setFeature] = useState(uniqueFeatures?.[0]);
  const [excludeFields, setExcludeFields] = useState([]);
  const [titleField, setTitleField] = useState("");

  const handlePageChange = (e, p) => {
    setPage(p);
  };

  useEffect(() => {
    if (uniqueFeatures?.length) {
      setFeature(uniqueFeatures[page - 1]);
    }
  }, [uniqueFeatures, page]);

  useEffect(() => {
    const excludedFields = [
      ...(layers?.find((layer) => layer?.id === feature?.layer?.id)
        ?.lreProperties?.popup?.excludeFields || []),
      ...(currentUser?.isAdmin || currentUser?.isDeveloper
        ? []
        : layers?.find((layer) => layer?.id === feature?.layer?.id)
            ?.lreProperties?.popup?.adminOnlyFields || []),
    ];
    setExcludeFields(excludedFields);

    const title = layers?.find((layer) => layer?.id === feature?.layer?.id)
      ?.lreProperties?.popup?.titleField;
    setTitleField(
      (title && feature?.properties[title] && (
        <>
          <Typography variant="h4" style={{ fontWeight: 600 }}>
            {titleize(feature?.layer?.source)}:
          </Typography>
          <Divider my={1} mx={2} />
          <Typography mb={2} variant="h6">
            {feature?.properties[title]}
          </Typography>
        </>
      )) || (
        <Typography variant="h4" style={{ fontWeight: 600 }}>
          {titleize(feature?.layer?.source)}
        </Typography>
      )
    );
  }, [feature, layers, currentUser]);

  const addViewDataVizButtons = (key, value) => {
    if (value && renderGraphButtons && dataVizTypes.includes(key)) {
      return (
        <>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              disabled={!value}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                setDataVizVisible(true);
                setDataVizWell(feature.properties);
                setDataVizGraphType(key);
              }}
            >
              View Data
            </Button>
          </span>
        </>
      );
    }
    return value;
  };

  const popupData = excludeFields
    ? Object.entries(feature?.properties).reduce((acc, [key, value]) => {
        //MJB also removing entry if the value is an empty string, null, or undefined
        if (
          !excludeFields.includes(key) &&
          value !== "" &&
          !isNullOrUndef(value)
        ) {
          acc.push([key, value]);
        }
        return acc;
      }, [])
    : Object.entries(feature?.properties);

  const popupFormat = (key) => {
    if (key === "well_id") {
      return "SWN/District ID";
    }
    if (key === "well_status") {
      return "Status";
    }
    if (key === "well_class") {
      return "Classification";
    }
    if (key === "well_subclass") {
      return "Subclassification";
    }
    if (key === "primary_use") {
      return "Use Type";
    }
    if (key === "lat_dd") {
      return "Latitude";
    }
    if (key === "lon_dd") {
      return "Longitude";
    }
    if (key === "owner_info") {
      return "Owner";
    }

    return titleize(key);
  };

  if (!popupData) return null;
  return (
    <>
      <PopupWrap height={height} width={width}>
        {titleField}
        <PopupTable>
          <tbody>
            {(currentUser?.isAdmin || currentUser?.isDeveloper) &&
              ["bartonsprings-wells", "locations"].includes(feature.source) && (
                <PopupRow>
                  <PopupCell>
                    <strong>Edit Well</strong>
                  </PopupCell>
                  <PopupCell>
                    <a
                      href={`/data-management/wells/wells-management/${feature?.properties?.well_ndx}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Link
                    </a>
                  </PopupCell>
                </PopupRow>
              )}
            {popupData?.map(([key, value]) => {
              return (
                <PopupRow key={key}>
                  <PopupCell>
                    <strong>{popupFormat(key)}</strong>
                  </PopupCell>
                  <PopupCell>
                    {typeof value === "string" &&
                    value.startsWith("https://") ? (
                      <PopupUl>
                        {value.split(",").map((item) => (
                          <Link
                            target="_blank"
                            rel="noreferrer"
                            key={item}
                            href={item}
                          >
                            More Info
                          </Link>
                        ))}
                      </PopupUl>
                    ) : ["attached_documents", "attached_photos"].includes(
                        key
                      ) ? (
                      value.split(",").map((item) => (
                        <div key={item}>
                          <a
                            target="_blank"
                            href={item.split(";")[1]}
                            rel="noreferrer"
                          >
                            {item.split(";")[0]}
                          </a>
                        </div>
                      ))
                    ) : (
                      formatBooleanTrueFalse(addViewDataVizButtons(key, value))
                    )}
                  </PopupCell>
                </PopupRow>
              );
            })}
          </tbody>
        </PopupTable>
      </PopupWrap>
      <Pagination
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
        count={uniqueFeatures.length}
        size={size}
        page={page}
        variant="outlined"
        shape="rounded"
        color="primary"
        onChange={handlePageChange}
      />
    </>
  );
};

export default Popup;
