const owners = {
  name: "meters",
  ndx: 4,
  field: "meter_ndx",
  label: "Meters",
  endpoint: "query-and-download/q-d-data-meters",
  icon: "Speed",
  inputs: [],
};

export default owners;
