import axios from "axios";
import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { USE_QUERY_OPTIONS } from "./constants";
import * as yup from "yup";
import * as Icons from "@material-ui/icons";
import { Category } from "@material-ui/icons"; // Import all icons

export const generateAuthHeaders = async (
  getAccessTokenSilently,
  isAuthenticated
) => {
  let headers = {};
  if (isAuthenticated) {
    try {
      const token = await getAccessTokenSilently();
      headers["Authorization"] = `Bearer ${token}`;
    } catch (error) {
      console.error("Error getting access token:", error);
    }
  }
  return headers;
};

export const getData = async (url, headers = {}) => {
  try {
    const { data } = await axios.get(url, { headers });
    return data;
  } catch (error) {
    throw new Error(`Failed to fetch data: ${error.message}`);
  }
};

export const useCustomQuery = (key, endpoint, onSuccess) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const fetchConditionallyWithToken = async () => {
    const headers = await generateAuthHeaders(
      getAccessTokenSilently,
      isAuthenticated
    );

    return getData(
      `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`,
      headers
    );
  };

  // Merge any custom onSuccess into the existing query options
  const queryOptions = {
    ...USE_QUERY_OPTIONS,
    onSuccess: onSuccess ? (data) => onSuccess(data) : undefined,
  };

  return useQuery(key, fetchConditionallyWithToken, queryOptions);
};

export const triggerDownload = (
  blob,
  filename = `data_download_${new Date()}.csv`
) => {
  const blobUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(blobUrl);
};

// This function updates the values for a specific field in a form based on filtered data. It ensures that only those
// values that match the criteria represented by the filtered data are retained in the form field. This function is
// particularly useful for dynamically adjusting form selections based on changes in related fields or external
// filters. It removes any selections that are no longer valid given the current state of filtered data.
export const updateFilteredSelections = (
  field,
  filteredData,
  getId = (item) => item.id,
  getValues,
  setValue
) => {
  const currentValues = getValues(field);
  const newValues = currentValues?.filter((value) =>
    filteredData.some((item) => getId(item) === value)
  );

  if (newValues?.length !== currentValues?.length) {
    setValue(field, newValues);
  }
};

// This function checks whether an item's associated parameters matches the currently selected parameters. If a
// selectedParameters is provided, it returns true if the item's associated parameters includes any of the selected parameters, otherwise
// true by default, implying that without a specific filter, all items are considered to match.
const matchesSelectedParameters = (item, selectedParameters) => {
  if ([null, undefined].includes(selectedParameters)) return true;
  const selectedParametersSet = new Set(selectedParameters);
  return item.assoc_parameter_ndx.some((parameter) =>
    selectedParametersSet.has(parameter)
  );
};

// This function filters a dataset based on given criteria (a mapping of field names to expected values) and an
// optional selected data category. It first filters items that match all the provided criteria, and then further
// filters those items based on whether they match the selected data category, if one is provided. This function is
// useful for applying multiple filters to a dataset, where the filters might include both specific field values and
// broader category inclusion.
export const filterDataWithCriteria = (data, criteria, selectedParameters) => {
  return (
    data?.filter((item) => {
      const matchesCriteria = Object.entries(criteria).every(([key, values]) =>
        values.includes(item[key])
      );
      return (
        matchesCriteria && matchesSelectedParameters(item, selectedParameters)
      );
    }) || []
  );
};

// Filters an array of data objects based on a set of criteria. Each criterion can either be an array of
// acceptable values or a boolean indicating a required state. For array criteria, an object in the data array
// is included in the result if the corresponding property value is present in the criterion array. For boolean
// criteria, an object is included if its property value matches the boolean criterion. This allows for flexible
// filtering that can accommodate both exact value matches and checks for boolean states.
export const filterData = (data, criteria) => {
  return (
    data?.filter((item) => {
      const matchesCriteria = Object.entries(criteria).every(
        ([key, values]) => {
          // If the values are null, ignore this criterion and return true
          if (values === null) return true;

          // Special handling for boolean criteria
          if (typeof values === "boolean") {
            return values === true
              ? item[key] === true
              : item[key] === false || item[key] === null;
          }

          // Handle arrays and other data types
          return Array.isArray(values)
            ? values.includes(item[key])
            : values === item[key];
        }
      );
      return matchesCriteria;
    }) || []
  );
};

// This function filters a dataset to include only those items that match a given selected data category. If no
// specific category is selected, it returns all items. This is a specialized filter function focusing solely on the
// aspect of categorization based on a selectedDataCategory, making it useful for scenarios where the filtering needs
// to be based solely on an item's associated category.
export const filterBySelectedParameters = (data, selectedParameters) => {
  return (
    data?.filter((item) =>
      matchesSelectedParameters(item, selectedParameters)
    ) || []
  );
};

const nonEmptyArray = (message = "This field is required") =>
  yup.array().min(1, message).required(message);

const tristateBoolean = (
  message = "This field must be true, false, or not set"
) => {
  return yup
    .mixed()
    .oneOf([true, false, null], message)
    .nullable()
    .default(null);
};

const optionalBoolean = () => yup.boolean();

export const createValidationSchemaWithDefaults = ({ inputs }) => {
  let schemaFields = {};

  inputs.forEach(({ type, formName, label, defaultFormValue }) => {
    if (type === "tristateCheckbox") {
      schemaFields[formName] = tristateBoolean(
        `${label} must be true, false, or not set`
      ).default(defaultFormValue ?? null);
    }
    if (type === "multiselect") {
      schemaFields[formName] = nonEmptyArray(`${label} is required`).default(
        defaultFormValue ?? []
      );
    }
    if (type === "checkbox") {
      schemaFields[formName] = optionalBoolean().default(
        defaultFormValue ?? true
      );
    }
    if (type === "search") {
      schemaFields[formName] = nonEmptyArray(`${label} is required`).default(
        defaultFormValue ?? []
      );
    }
  });

  return yup.object().shape(schemaFields);
};

// checks to see if form is filled out to allow count fetch
export const hasEmptyValues = (values) => {
  return Object.values(values).some((value) => {
    if (Array.isArray(value)) return value.length === 0;
    if (typeof value === "string") return value.trim() === "";
    return false;
  });
};

// Function to extract values from an array of single key-value pair objects
export const extractValues = (array) => {
  return array.map((obj) => Object.values(obj)[0]);
};

export const extractValuesWithKey = (array, key) => {
  return array.map((obj) => obj[key]);
};

export const filterByCondition = (conditionFunc) => {
  return (data) => data.filter(conditionFunc);
};

export const extractDefaultFormValues = (schema) => {
  const defaults = {};
  for (const [key, value] of Object.entries(schema.fields)) {
    if (value.spec.default !== undefined) {
      defaults[key] =
        typeof value.spec.default === "function"
          ? value.spec.default()
          : value.spec.default;
    }
  }
  return defaults;
};

export const getIcon = (iconName) => {
  const IconComponent = Icons[iconName]; // Dynamically access the icon by name
  return IconComponent ? <IconComponent /> : <Category />; // Fallback to a default icon
};
