import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { AccordionActions } from "@mui/material";
import React from "react";
import { useBasicWellInfo } from "../hooks/useBasicWellInfo";
import Loader from "../../../../../components/Loader";
import { useSubmitData } from "../../../../../hooks/useSubmitData";
import LatLongMap from "../../../../../components/map/LatLongMap";
import { getButtonIcon, getButtonText, NumberFormatCustom } from "../utils";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function BasicWellInfo({
  selectedWell,
  setSelectedWell,
  refetchWells = () => {},
  isEditMode = false,
  selectedWellLabel,
  setSelectedWellLabel,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
    lookups,
  } = useBasicWellInfo(selectedWell);

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedWell,
    setSelectedWell,
    state,
    "list-wells",
    "well_ndx",
    refetch,
    refetchWells,
    setSelectedWellLabel
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.well_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.well_ndx) ? "none" : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">{`Basic Well Information ${
              selectedWellLabel ? `(${selectedWellLabel})` : ""
            }`}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={10}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <Grid
              item
              xs={12}
              sm={2}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!state.well_ndx}
                    checked={state.removed}
                    onChange={(e) =>
                      handleUpdateState("removed", e.target.checked)
                    }
                    color="primary"
                  />
                }
                label="REMOVED"
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="State Well #"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("state_well_num", e.target.value)
                }
                value={state.state_well_num ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="District Well Id"
                style={{ width: "100%" }}
                disabled
                onChange={(e) =>
                  handleUpdateState("district_well_id", e.target.value)
                }
                value={state.district_well_id ?? ""}
                inputProps={{
                  style: {
                    fontWeight: "bold",
                    fontSize: "18px",
                    color: "black",
                  },
                }}
                // InputLabelProps={{
                //   style: {
                //     color: "black",
                //   },
                // }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.wellClassification.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="well-classification">
                      Well Classification
                    </InputLabel>
                    <Select
                      labelId="well-classification"
                      id="well-classification"
                      label="Well Classification"
                      name="well_class_ndx"
                      value={state.well_class_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("well_class_ndx", e.target.value);
                      }}
                    >
                      {lookups.wellClassification.map((option) => (
                        <MenuItem
                          key={option.well_class_ndx}
                          value={option.well_class_ndx}
                        >
                          {option.well_class_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.wellSubClass.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="well-subclass">Well Sub-Class</InputLabel>
                    <Select
                      labelId="well-subclass"
                      id="well-subclass"
                      label="Well Sub-Class"
                      name="well_subclass_ndx"
                      value={state.well_subclass_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("well_subclass_ndx", e.target.value);
                      }}
                    >
                      {lookups.wellSubClass.map((option) => (
                        <MenuItem
                          key={option.well_subclass_ndx}
                          value={option.well_subclass_ndx}
                        >
                          {option.well_subclass_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Well Name"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("well_name", e.target.value)}
                value={state.well_name ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={!state.primary_use_ndx}
                required
              >
                {!lookups?.primaryWellUse.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="primary-well-use">
                      Primary Well Use
                    </InputLabel>
                    <Select
                      labelId="primary-well-use"
                      id="primary-well-use"
                      label="Primary Well Use"
                      name="primary_use_ndx"
                      value={state.primary_use_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("primary_use_ndx", e.target.value);
                      }}
                    >
                      {lookups.primaryWellUse.map((option) => (
                        <MenuItem
                          key={option.well_use_ndx}
                          value={option.well_use_ndx}
                        >
                          {option.well_use_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {!state.primary_use_ndx && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={9}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Well Physical Address"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("physical_location_address", e.target.value)
                }
                value={state.physical_location_address ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="City"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("city", e.target.value)}
                value={state.city ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <LatLongMap
                filterValues={state}
                setFilterValues={handleUpdateState}
                isEditorDirty={isDirty}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{
                position: "relative",
              }}
            >
              <TextField
                name="lat_dd"
                required
                error={!state.lat_dd || state.lat_dd > 90 || state.lat_dd < -90}
                helperText={
                  !state.lat_dd && state.lat_dd !== 0
                    ? "*Required field"
                    : state.lat_dd > 90 || state.lat_dd < -90
                    ? "Invalid coordinate"
                    : ""
                }
                variant="outlined"
                label="Latitude"
                style={{ width: "100%" }}
                InputProps={{
                  inputProps: {
                    config: {
                      decimalScale: 5,
                      fixedDecimalScale: true,
                    },
                  },
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) => handleUpdateState("lat_dd", e.target.value)}
                value={state.lat_dd ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{
                position: "relative",
              }}
            >
              <TextField
                name="lon_dd"
                required
                error={
                  !state.lon_dd || state.lon_dd > 180 || state.lon_dd < -180
                }
                helperText={
                  !state.lon_dd && state.lon_dd !== 0
                    ? "*Required field"
                    : state.lon_dd > 180 || state.lon_dd < -180
                    ? "Invalid coordinate"
                    : ""
                }
                variant="outlined"
                label="Longitude"
                style={{ width: "100%" }}
                InputProps={{
                  inputProps: {
                    config: {
                      decimalScale: 5,
                      fixedDecimalScale: true,
                    },
                  },
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) => handleUpdateState("lon_dd", e.target.value)}
                value={state.lon_dd ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{
                position: "relative",
              }}
            >
              <TextField
                type="number"
                variant="outlined"
                label="Recorded Elevation"
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    handleUpdateState("elevation_ft", null);
                  } else {
                    handleUpdateState("elevation_ft", +e.target.value);
                  }
                }}
                value={state.elevation_ft ?? ""}
                InputProps={{
                  inputProps: {
                    onKeyPress: (e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    },
                  },
                }}
              />
            </Grid>

            {state?.well_ndx && (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl style={{ width: "100%" }}>
                      <KeyboardDatePicker
                        disabled
                        format="LLLL do, yyyy"
                        inputVariant="outlined"
                        autoOk
                        id="created-on"
                        label="Created On"
                        value={state?.created_timestamp}
                        onChange={(date) => {
                          handleUpdateState("created_timestamp", date);
                        }}
                        InputAdornmentProps={{
                          "aria-label": "change created date",
                        }}
                        InputProps={{
                          style: {
                            color: "rgba(0,0,0,1)",
                          },
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <TextField
                    disabled
                    variant="outlined"
                    label="Created By"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateState("created_by", e.target.value)
                    }
                    value={state.created_by ?? ""}
                    InputProps={{
                      style: {
                        color: "rgba(0,0,0,1)",
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl style={{ width: "100%" }}>
                      <KeyboardDatePicker
                        disabled
                        format="LLLL do, yyyy"
                        inputVariant="outlined"
                        autoOk
                        id="updated-on"
                        label="Updated On"
                        value={state?.updated_timestamp}
                        onChange={(date) => {
                          handleUpdateState("updated_timestamp", date);
                        }}
                        InputAdornmentProps={{
                          "aria-label": "change updated date",
                        }}
                        InputProps={{
                          style: {
                            color: "rgba(0,0,0,1)",
                          },
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <TextField
                    disabled
                    variant="outlined"
                    label="Updated By"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateState("updated_by", e.target.value)
                    }
                    value={state.updated_by ?? ""}
                    InputProps={{
                      style: {
                        color: "rgba(0,0,0,1)",
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl style={{ width: "100%" }}>
                      <KeyboardDatePicker
                        disabled
                        format="LLLL do, yyyy"
                        inputVariant="outlined"
                        autoOk
                        id="removed-on"
                        label="Removed On"
                        value={state?.removed_timestamp}
                        onChange={(date) => {
                          handleUpdateState("removed_timestamp", date);
                        }}
                        InputAdornmentProps={{
                          "aria-label": "change removed date",
                        }}
                        InputProps={{
                          style: {
                            color: "rgba(0,0,0,1)",
                          },
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <TextField
                    disabled
                    variant="outlined"
                    label="Removed By"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateState("removed_by", e.target.value)
                    }
                    value={state.removed_by ?? ""}
                    InputProps={{
                      style: {
                        color: "rgba(0,0,0,1)",
                      },
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={handleResetState}>
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={
              !isDirty ||
              !state.primary_use_ndx ||
              !state.lat_dd ||
              !state.lon_dd ||
              isFormSubmitting
            }
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting, selectedWell === 0)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default BasicWellInfo;
