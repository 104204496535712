import React, { useRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Link,
  Paper,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import MaterialTable from "material-table";
import CopyIcon from "@material-ui/icons/FileCopy";
import { ExpandMore } from "@material-ui/icons";

import { NavLink } from "react-router-dom";
import { useApp } from "../../../../AppProvider";

import { copyToClipboard, dateFormatter } from "../../../../utils";
import BasicMeterInfo from "./BasicMeterInfo";
import { useMeters } from "./useMeters";
import ManageAssociatedWells from "./ManageAssociatedWells";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;
function MetersManagement() {
  const {
    selectedMeter,
    setSelectedMeter,
    data,
    isFetching,
    error,
    refetch,
    METERS_TABLE_COLUMNS,
  } = useMeters();
  const { doToast } = useApp();
  const targetRef = useRef(null);

  const scrollToTarget = () => {
    if (targetRef.current) {
      const yOffset = targetRef.current.getBoundingClientRect().top - 100;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  const handleScroll = () => {
    setTimeout(scrollToTarget, 1000); // Delay the scroll by 1 second (1000 milliseconds)
  };

  const [triggerRefetch, setTriggerRefetch] = React.useState(false);
  const handleTriggerRefetch = () => setTriggerRefetch(!triggerRefetch);
  const handleRefetch = () => {
    refetch().then(() => {
      handleTriggerRefetch();
    });
  };

  if (error) return "An error has occurred: " + error.message;
  return (
    <React.Fragment>
      <Helmet title="Add and Manage Well Meters" />
      <Typography variant="h3" gutterBottom display="inline">
        Add and Manage Well Meters
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Add and Manage Well Meters</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Box>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box style={{ width: "100%" }}>
              <Typography variant="h3">Meters Selection</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <TableWrapper>
              <MaterialTable
                id="Meters Setup"
                title={`Meters Setup ${dateFormatter(
                  new Date(),
                  "MM/DD/YYYY, h:mm A"
                )}`}
                loading={isFetching || !data}
                columns={METERS_TABLE_COLUMNS}
                data={data}
                localization={{
                  toolbar: { searchPlaceholder: "Meters Selection" },
                }}
                components={{
                  Container: (props) => <div {...props} />,
                }}
                actions={[
                  {
                    icon: CopyIcon,
                    tooltip: "Copy Data",
                    isFreeAction: true,
                    onClick: () => {
                      try {
                        copyToClipboard(data, METERS_TABLE_COLUMNS, () =>
                          doToast(
                            "success",
                            "Data was copied to your clipboard."
                          )
                        );
                      } catch (error) {
                        const message =
                          error?.message ?? "Something went wrong";
                        doToast("error", message);
                      }
                    },
                  },
                  () => ({
                    icon: "edit",
                    tooltip: "Edit",
                    onClick: (event, rowData) => {
                      setSelectedMeter(rowData?.meter_ndx);
                      handleScroll();
                    },
                  }),
                  {
                    icon: "add_box",
                    tooltip: "Add",
                    isFreeAction: true,
                    onClick: () => {
                      setSelectedMeter(0);
                      handleScroll();
                    },
                  },
                ]}
                options={{
                  filtering: true,
                  emptyRowsWhenPaging: false,
                  showTitle: false,
                  columnsButton: true,
                  exportButton: true,
                  exportAllData: true,
                  addRowPosition: "first",
                  pageSize: 30,
                  pageSizeOptions: [10, 30, 50, 100, 200],
                  padding: "dense",
                  searchFieldAlignment: "left",
                  minBodyHeight: "450px",
                  maxBodyHeight:
                    "calc(100vh - 64px - 48px - 106px - 48px - 64px - 204px - 50px)",
                }}
              />
            </TableWrapper>
          </AccordionDetails>
        </Accordion>

        <Box
          style={{
            display: selectedMeter || selectedMeter === 0 ? "block" : "none",
          }}
        >
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4">
                {selectedMeter === 0
                  ? "Creating New Meter"
                  : "Editing Existing Meter"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedMeter(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
          <div ref={targetRef} />
          <BasicMeterInfo
            selectedMeter={selectedMeter}
            setSelectedMeter={setSelectedMeter}
            refetchMeters={handleRefetch}
            triggerRefetch={triggerRefetch}
          />
          <ManageAssociatedWells
            selectedMeter={selectedMeter}
            triggerRefetch={triggerRefetch}
          />
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4">
                {selectedMeter === 0
                  ? "Creating New Meter"
                  : "Editing Existing Meter"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedMeter(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default MetersManagement;
