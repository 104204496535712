import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import { DEFAULT_REVIEW_REGISTRATION_STATE } from "./ReviewRegistrationsConstants";
import useFetchData from "../../hooks/useFetchData";

export const useBasicRegistrationInfo = (
  selectedRegistration,
  triggerRefetch
) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useStateIfMounted(
    DEFAULT_REVIEW_REGISTRATION_STATE
  );
  const [isDirty, setIsDirty] = useStateIfMounted(false);

  const handleUpdateState = (name, value) => {
    if (!isDirty) setIsDirty(true);
    setEditorState((prevState) => {
      if (name === "new_owner") {
        if (value === true) {
          prevState.rolo_ndx = null;
        }
      }

      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const handleResetState = () => {
    setIsDirty(false);
    if (selectedRegistration) {
      setEditorState(data);
    } else {
      setEditorState(DEFAULT_REVIEW_REGISTRATION_STATE);
    }
  };

  const { data, isFetching, error, refetch } = useQuery(
    ["data-registrations", selectedRegistration],
    async () => {
      if (!selectedRegistration) {
        setIsDirty(false);
        setEditorState(DEFAULT_REVIEW_REGISTRATION_STATE);
        return {};
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-registrations/${selectedRegistration}`,
          { headers }
        );
        setIsDirty(false);
        setEditorState(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [lookupCounties] = useFetchData("ui-reg-list-counties", [], false);
  const [lookupWellUse] = useFetchData("ui-reg-list-well-use", [], false);
  const [lookupWellAquifer] = useFetchData(
    "ui-reg-list-aquifer-groups",
    [],
    false
  );
  const [lookupWellOwners] = useFetchData("ui-list-wm-rolo-records", [], true);
  const [lookupWellClassification] = useFetchData(
    "ui-list-wm-well-classification",
    [],
    true
  );
  const [lookupWellSubclass] = useFetchData(
    "ui-list-wm-well-subclass",
    [],
    true
  );
  const [lookupPermitWellRecords] = useFetchData(
    "ui-list-permit-well-records",
    [triggerRefetch],
    true
  );

  return {
    data,
    isLoading: isFetching,
    error,
    refetch,
    isDirty,
    lookups: {
      counties: lookupCounties,
      wellUse: lookupWellUse,
      wellAquifer: lookupWellAquifer,
      wellOwners: lookupWellOwners,
      wellClassification: lookupWellClassification,
      wellSubclass: lookupWellSubclass,
      permitWellRecords: lookupPermitWellRecords,
    },
    handleUpdateState,
    handleResetState,
    state: editorState,
    setter: setEditorState,
  };
};
