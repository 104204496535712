import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import { spacing } from "@material-ui/system";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 64px - 48px - 106px - 48px - 64px);
  position: relative;
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ResourceGuides = () => {
  return (
    <>
      <Helmet title="Resource Guides" />
      <Typography variant="h3" gutterBottom display="inline">
        Resource Guides
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Resource Guides</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Container>
        <iframe
          src="https://drive.google.com/embeddedfolderview?id=1SjQTtE3uzVFEKaJk8Zh4gZQY7DSy0a-N"
          width="100%"
          height="100%"
          title="Resource Guides"
        />
      </Container>
    </>
  );
};

export default ResourceGuides;
