import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { File, Folder, Upload as UploadIcon } from "react-feather";
import {
  Box,
  Chip as MuiChip,
  Typography,
  Button as MuiButton,
  List,
  Grid as MuiGrid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import IconButton from "@material-ui/core/IconButton";
import {
  ArrowDropDown,
  ArrowRight,
  Delete as MuiDelete,
} from "@material-ui/icons";
import { rgba } from "polished";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useApp } from "../../AppProvider";
import Loader from "../Loader";
import { DEFAULT_ATTACHED_DOCUMENTS_STATE } from "../../pages/dataManagement/wells/PermitsManagement/PermitsConstants";

const Grid = styled(MuiGrid)(spacing);
const Button = styled(MuiButton)(spacing);
const Chip = styled(MuiChip)(spacing);

const Delete = styled(MuiDelete)`
  color: #bc1a3d;
`;

const FieldItem = styled.div`
  border-left: 3px solid ${(props) => props.theme.palette.background.toolbar};
  padding-left: 12px;
  &.error {
    border-color: red;
  }
`;

const FieldToggleIcon = styled(IconButton)`
  // position: absolute;
  // left: -22px;
  margin-right: 5px;
  width: 18px;
  height: 18px;
  border-radius: 2px;

  &,
  &:active,
  &:focus,
  &:focus-within {
    background-color: ${(props) => props.theme.palette.background.toolbar};
  }

  svg {
    // position: absolute;
    width: 22px;
    height: 22px;
  }

  @media (hover: none) {
    &:hover {
      background-color: ${(props) => props.theme.palette.background.toolbar};
    }
  }
`;

const FieldIconLess = styled(ArrowRight)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const FieldIconMore = styled(ArrowDropDown)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Label = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  margin-bottom: ${(props) => props.theme.spacing(3)}px;
`;

const UploadPermits = ({
  selectedNdx,
  data,
  isLoading,
  error,
  refetch,
  lookups,
  state,
  setter,
  handleUpdateState,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();

  const [selectedFile, setSelectedFile] = useState(null);
  //attachments accordion
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setSelectedFile(null);
    setConflictAlert(false);
    setFileToDelete({});
  }, [selectedNdx]);

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const endpoint = "assoc-permits-to-attachments";

  const [conflictAlert, setConflictAlert] = useState(false);
  useEffect(() => {
    let conflictNdx;
    if (selectedFile) {
      conflictNdx =
        data?.filter(
          (attachment) => attachment.system_filename === selectedFile.name
        )[0]?.att_ndx || false;
    } else {
      conflictNdx = false;
    }

    setConflictAlert(conflictNdx);
  }, [selectedFile, data]);

  const handleUnselect = () => {
    setSelectedFile(null);
  };

  const [fileToDelete, setFileToDelete] = useState({});
  const handleDeleteModal = (e, file) => {
    e.preventDefault();
    setDeleteDialogOpen(true);
    setFileToDelete(file);
  };

  async function writeUrlToTable(fileName, fileLocation) {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };

      if (conflictAlert) {
        await axios.patch(
          `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}/${conflictAlert}`,
          {},
          { headers }
        );
      }

      //links url of attachment in S3 to endpoint table
      await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`,

        {
          permit_ndx: selectedNdx,
          original_filename: `${fileName}`,
          system_filename: `${fileName}`,
          system_filepath: fileLocation,
          notes: state.notes,
          tags: [state.tags],
        },

        { headers }
      );

      //refetches data to include newly added attachment
      await refetch();
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log(`call was cancelled`);
      } else {
        console.error(err);
      }
    }
  }

  //uploads selected file to s3 bucket
  const AWSconfig = {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_REGION,
  };
  const S3 = new AWS.S3(AWSconfig);
  const [isUploading, setIsUploading] = useState(false);
  const uploadFile = async (file) => {
    if (!file) {
      doToast("warning", "No file selected");
      return null;
    }

    setIsUploading(true);
    S3.upload(
      {
        Body: file,
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: `permits/${selectedNdx}/${file.name}`,
        ContentType: file.type,
      },
      (err, data) => {
        if (err) {
          const message = err?.message ?? "Something went wrong";
          doToast("error", message);
        } else {
          doToast("success", "File was uploaded successfully");
          const fileLocation = data.Location;

          writeUrlToTable(file.name, fileLocation);
        }
        setIsUploading(false);
        setSelectedFile(null);
      }
    );
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleCloseDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    handleCloseDialog();

    try {
      if (fileToDelete) {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        await axios.patch(
          `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}/${fileToDelete.att_ndx}`,
          {},
          { headers }
        );
        //refetches data to include newly added attachment
        await refetch();
        doToast("success", "Record was deleted successfully");
      } else {
        doToast("error", "Something went wrong");
      }
    } catch (err) {
      console.error(err);
      const message = err?.message ?? "Something went wrong";
      doToast("error", message);
    }
  };

  const ConfirmDeleteDialog = () => {
    return (
      <Dialog open={deleteDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle style={{ textAlign: "center" }}>
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            Are you sure you want to delete{" "}
            <strong>{fileToDelete.original_filename}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            variant="outlined"
            onClick={handleDelete}
            className="error"
            autoFocus
          >
            Yes, delete record
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <ConfirmDeleteDialog />

      <Box style={{ width: "100%" }}>
        {isLoading ? (
          <Loader />
        ) : (
          <FieldItem className={error ? "error" : ""}>
            <Grid container justify={"space-between"}>
              <Grid item>
                <FieldToggleIcon
                  size={"small"}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  {isOpen ? <FieldIconMore /> : <FieldIconLess />}
                </FieldToggleIcon>
                <Label
                  color={error ? "error" : "textPrimary"}
                  style={{ display: "inline-block" }}
                >
                  Attachments
                </Label>
              </Grid>
            </Grid>

            {isOpen &&
              (data?.length ? (
                <List style={{ padding: 0 }}>
                  {data.map((item) => {
                    return (
                      <div key={item.att_ndx}>
                        <Chip
                          mb={2}
                          label={item.original_filename}
                          color="primary"
                          icon={
                            <Folder style={{ "&&": { color: "orange" } }} />
                          }
                          clickable={true}
                          component="a"
                          href={item.system_filepath}
                          target="_blank"
                          onDelete={(e) => handleDeleteModal(e, item)}
                          variant="outlined"
                          deleteIcon={<Delete />}
                        />
                      </div>
                    );
                  })}
                </List>
              ) : (
                `No files are currently attached to this permit`
              ))}
          </FieldItem>
        )}

        <Grid container spacing={5} pt={3} style={{ alignItems: "center" }}>
          <Grid
            item
            style={{
              position: "relative",
            }}
          >
            <Button
              component="label"
              startIcon={<File />}
              variant="outlined"
              color="primary"
              type="button"
              disabled={!selectedNdx}
            >
              Select File
              <input
                type="file"
                // accept={null}
                hidden
                onChange={handleFileInput}
                onClick={(event) => {
                  event.target.value = null;
                  setter(DEFAULT_ATTACHED_DOCUMENTS_STATE);
                }}
              />
            </Button>
          </Grid>
          <Grid
            item
            style={{
              position: "relative",
            }}
          >
            <Button
              component="label"
              startIcon={<UploadIcon />}
              variant="contained"
              color="secondary"
              type="button"
              onClick={() => uploadFile(selectedFile)}
              disabled={!selectedFile || !state.tags || !selectedNdx}
            >
              Upload File
            </Button>
          </Grid>

          <Grid
            item
            style={{
              position: "relative",
            }}
          >
            {isUploading ? (
              <Loader />
            ) : selectedFile ? (
              <>
                <Chip
                  label={selectedFile.name}
                  clickable={false}
                  color="primary"
                  icon={<Folder />}
                  onDelete={handleUnselect}
                  variant="outlined"
                  size="small"
                />
              </>
            ) : (
              <Typography component="span" variant="subtitle2">
                Select a file to attach it to the permit
              </Typography>
            )}
          </Grid>
        </Grid>

        {conflictAlert && (
          <Grid
            item
            xs={12}
            style={{
              position: "relative",
            }}
          >
            <Alert severity="warning" style={{ marginTop: "8px" }}>
              <AlertTitle>Warning</AlertTitle>
              <strong>
                **There is already a file with the same name in this location.**
                —{" "}
              </strong>
              proceeding will overwrite the existing file.
            </Alert>
          </Grid>
        )}

        {selectedFile && (
          <Grid container spacing={3} mt={3} style={{ alignItems: "center" }}>
            <Grid
              item
              xs={11}
              sm={8}
              md={6}
              style={{
                position: "relative",
              }}
            >
              {lookups.attachmentTags.length > 0 && (
                <FormControl
                  variant="outlined"
                  style={{ width: "200px" }}
                  required
                >
                  <InputLabel id="document-tag">Document Tag</InputLabel>
                  <Select
                    error={!state.tags}
                    labelId="document-tag-label"
                    id="document-tag"
                    label="Document Tag"
                    value={state.tags || ""}
                    onChange={(e) => handleUpdateState("tags", e.target.value)}
                  >
                    {lookups.attachmentTags.map((tag) => (
                      <MenuItem key={tag.att_tag_ndx} value={tag.att_tag_ndx}>
                        {tag.att_tag_text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid
              item
              xs={1}
              sm={4}
              md={6}
              style={{
                position: "relative",
              }}
            />

            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="File Notes"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("notes", e.target.value)}
                value={state.notes ?? ""}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default UploadPermits;
