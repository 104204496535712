import React from "react";
import { Button, Box } from "@material-ui/core";
import { getIcon } from "./utils";

export const FocusButtons = React.memo(
  ({ options, selectedFocus, setCurrentFocus }) => {
    const handleSelect = (newFocus) => {
      if (selectedFocus === newFocus.ndx) return;
      setCurrentFocus(newFocus);
    };

    return (
      <Box display="flex" flexWrap="wrap" justifyContent="center">
        {Object.values(options).map((config) => {
          const { ndx, icon, label } = config;

          return (
            <Box key={ndx} p={1}>
              <Button
                startIcon={getIcon(icon)}
                variant="contained"
                color={selectedFocus === ndx ? "primary" : "default"}
                onClick={() => handleSelect(config)}
              >
                {label}
              </Button>
            </Box>
          );
        })}
      </Box>
    );
  }
);
