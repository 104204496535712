import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  ClickAwayListener,
  Divider,
  InputAdornment,
  List,
  ListItem,
  Paper,
  Popper,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Fuse from "fuse.js";
import useDebounce from "../../../../hooks/useDebounce";

const SearchResults = ({
  anchorEl,
  open,
  onClose,
  onSelect,
  searchResults,
}) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      style={{ zIndex: 1001 }}
      transition
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper style={{ width: 600, height: 500, overflowY: "auto" }}>
          <List dense component="nav" aria-label="main mailbox folders">
            {searchResults?.slice(0, 49)?.map((result, i) => (
              <React.Fragment key={i}>
                <ListItem
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                  button
                  onClick={(e) => {
                    onSelect(result?.item.rolo_ndx);
                    onClose(e);
                  }}
                >
                  <Typography variant="subtitle1">
                    {result?.item?.r_organization}
                  </Typography>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="caption">Name</Typography>
                      <Typography variant="body1">
                        {result?.item?.r_name || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">Email</Typography>
                      <Typography variant="body1">
                        {result?.item?.r_email || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">Phones</Typography>
                      <Typography variant="body1">
                        {result?.item?.formatted_phones || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">Address</Typography>
                      <Typography variant="body1">
                        {result?.item?.r_mailing_address || "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

const LinkedPermitteeFuzzySearch = ({
  onSelect,
  options,
  disabled = false,
  placeholderSubject = "Permittee",
}) => {
  const searchRef = useRef(null);

  const [value, setValue] = useState("");
  const debouncedSearchValue = useDebounce(value, 200);
  const [searchResults, setSearchResults] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!searchResults?.length);
  }, [searchResults]);

  const fuzzySearcher = useMemo(() => {
    if (options.length) {
      return new Fuse(options, {
        ignoreLocation: true,
        keys: [
          "r_organization",
          "r_name",
          "r_email",
          "formatted_phones",
          "r_mailing_address",
        ],
      });
    }
  }, [options]);

  const handleClose = (event) => {
    if (searchRef.current && searchRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event) => {
    setValue(event?.target?.value);
  };

  useEffect(() => {
    const results = fuzzySearcher && fuzzySearcher.search(debouncedSearchValue);
    setSearchResults(results);
  }, [debouncedSearchValue, fuzzySearcher]);

  return (
    <>
      <TextField
        disabled={disabled}
        id="outlined-basic"
        // label="Permittee"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        autoComplete="off"
        onChange={handleChange}
        onFocus={() => !!value && setOpen(true)}
        placeholder={`Select ${placeholderSubject} from the Rolodex`}
        ref={searchRef}
        style={{ width: "100%", minWidth: "226px" }}
        type="search"
        value={value}
        variant="outlined"
        size="small"
      />
      <SearchResults
        anchorEl={searchRef?.current}
        onClose={handleClose}
        onSelect={onSelect}
        open={open}
        searchResults={searchResults}
      />
    </>
  );
};

export default LinkedPermitteeFuzzySearch;
