import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import useFetchData from "../../../../hooks/useFetchData";
import { DEFAULT_PERMIT_INFO_STATE } from "./PermitsConstants";

export const useBasicPermitInfo = (
  selectedPermit,
  triggerRefetch,
  triggerRefetchPermit
) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useStateIfMounted(
    DEFAULT_PERMIT_INFO_STATE
  );
  const [isDirty, setIsDirty] = useStateIfMounted(false);

  const handleUpdateState = (name, value) => {
    if (!isDirty && name !== "permittee_add_new") setIsDirty(true);
    setEditorState((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;

      if (name === "removed") {
        newFilterValues["should_remove"] = value;
      }

      if (name === "permittee_add_new" && value) {
        newFilterValues["rolo_ndx"] = null;
      }

      if (name === "permittee_add_new" && value === false) {
        newFilterValues["tmp_permittee_name"] = null;
        newFilterValues["tmp_permittee_email"] = null;
        newFilterValues["tmp_permittee_phone"] = null;
        newFilterValues["tmp_permittee_zip"] = null;
        newFilterValues["tmp_permittee_address"] = null;
        newFilterValues["tmp_permittee_city"] = null;
        newFilterValues["tmp_permittee_state"] = null;
        newFilterValues["tmp_permittee_organization"] = null;
        newFilterValues["tmp_permittee_precinct_ndx"] = null;
        newFilterValues["rolo_ndx"] = data?.rolo_ndx ?? null;
      }

      if (
        ["permit_year", "permit_type_ndx"].includes(name) &&
        !newFilterValues.permit_ndx
      ) {
        const permitNumberParts = newPermitNumberPartsLookup?.find(
          (item) =>
            (item.permit_type_ndx === newFilterValues.permit_type_ndx &&
              item.permit_year === +newFilterValues.permit_year) ||
            null
        );
        newFilterValues["permit_number"] = permitNumberParts
          ? `${permitNumberParts.permit_prefix}${
              permitNumberParts?.permit_prefix_num
            }-${newFilterValues.permit_year.slice(-2)}-${
              permitNumberParts?.permit_suffix
            }`
          : null;
      }

      return newFilterValues;
    });
  };

  const handleResetState = () => {
    setIsDirty(false);
    if (selectedPermit) {
      setEditorState(data);
    } else {
      setEditorState(DEFAULT_PERMIT_INFO_STATE);
    }
  };

  const { data, isFetching, error, refetch } = useQuery(
    ["list-permits", selectedPermit, triggerRefetchPermit],
    async () => {
      if (!selectedPermit) {
        setIsDirty(false);
        setEditorState(DEFAULT_PERMIT_INFO_STATE);
        return {};
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-permits/${selectedPermit}`,
          { headers }
        );
        setIsDirty(false);
        setEditorState(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: linkedWellsTableData,
    isFetching: linkedWellsTableIsFetching,
    error: linkedWellsTableError,
    refetch: linkedWellsTableRefetch,
  } = useQuery(
    ["ui-list-permits-linked-wells", selectedPermit, triggerRefetch],
    async () => {
      if (!selectedPermit) {
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-list-permits-linked-wells/${selectedPermit}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: linkedPermitsTableData,
    isFetching: linkedPermitsTableIsFetching,
    error: linkedPermitsTableError,
    refetch: linkedPermitsTableRefetch,
  } = useQuery(
    ["ui-list-permit-assoc-permit-records", selectedPermit, triggerRefetch],
    async () => {
      if (!selectedPermit) {
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-list-permit-assoc-permit-records/${selectedPermit}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: linkedMetersTableData,
    isFetching: linkedMetersTableIsFetching,
    error: linkedMetersTableError,
    refetch: linkedMetersTableRefetch,
  } = useQuery(
    ["ui-list-permits-linked-meters", selectedPermit, triggerRefetch],
    async () => {
      if (!selectedPermit) {
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-list-permits-linked-meters/${selectedPermit}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [permitTypesLookup] = useFetchData(
    "ui-list-permits-permit-types",
    [],
    true
  );

  const [permitStatusLookup] = useFetchData(
    "ui-list-permits-permit-status",
    [],
    true
  );

  const [aquiferGroupsLookup] = useFetchData(
    "ui-list-permits-aquifer-groups",
    [],
    true
  );

  const [newPermitNumberPartsLookup] = useFetchData(
    "ui-lookup-new-permit-number-parts",
    [],
    true
  );

  const [permitUsesLookup] = useFetchData("ui-list-permits-uses", [], true);

  const [directorPrecinctsLookup] = useFetchData(
    "ui-list-director-precincts",
    [],
    true
  );

  const [permitteeContactRecordLookup] = useFetchData(
    "ui-list-permit-rolo-records",
    [triggerRefetch],
    true
  );

  const [allocationDistributionsLookup] = useFetchData(
    "list-allocation-distributions",
    [],
    true
  );

  return {
    data,
    isLoading: isFetching,
    error,
    refetch,
    isDirty,
    lookups: {
      permitTypes: permitTypesLookup,
      permitStatus: permitStatusLookup,
      aquiferGroups: aquiferGroupsLookup,
      permitUses: permitUsesLookup,
      newPermitNumberParts: newPermitNumberPartsLookup,
      directorPrecincts: directorPrecinctsLookup,
      permitteeContactRecords: permitteeContactRecordLookup,
      allocationDistributions: allocationDistributionsLookup,
    },
    tables: {
      linkedWells: {
        data: linkedWellsTableData,
        isFetching: linkedWellsTableIsFetching,
        error: linkedWellsTableError,
        refetch: linkedWellsTableRefetch,
      },
      linkedPermits: {
        data: linkedPermitsTableData,
        isFetching: linkedPermitsTableIsFetching,
        error: linkedPermitsTableError,
        refetch: linkedPermitsTableRefetch,
      },
      linkedMeters: {
        data: linkedMetersTableData,
        isFetching: linkedMetersTableIsFetching,
        error: linkedMetersTableError,
        refetch: linkedMetersTableRefetch,
      },
    },
    handleUpdateState,
    handleResetState,
    state: editorState,
    setter: setEditorState,
  };
};
