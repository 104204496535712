import React, {
  useState,
  useCallback,
  memo,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { useQuery } from "react-query";
import axios from "axios";
import {
  Box,
  Modal as MuiModal,
  TextField,
  Backdrop,
  Fade,
  Link,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Typography as MuiTypography,
  Tooltip,
} from "@material-ui/core";
import {
  SettingsOverscan as CasingIcon,
  MoreHoriz as OtherIcon,
  Speed as MeterIcon,
  Filter9 as Number9Icon,
  LocalDrink as PumpIcon,
  Opacity as WellIcon,
  ViewAgenda as SlabIcon,
  Adjust as WellHeadIcon,
  Place as WellSiteIcon,
  Home as WellHouseIcon,
} from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components/macro";
import Fuse from "fuse.js";
import { FixedSizeGrid as GridWindow } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useAuth0 } from "@auth0/auth0-react";
import _debounce from "lodash.debounce";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { spacing } from "@material-ui/system";
import { Alert } from "@material-ui/lab";

// Configuration constants
const FUSE_OPTIONS = {
  keys: [
    "district_well_id",
    "well_name",
    "well_location_address",
    "well_class_name",
    "well_subclass_name",
    "primary_use_group_desc",
    "secondary_use_group_desc",
    "permits_info",
    "tag",
  ],
  threshold: 0.3,
};
const MIN_COLUMN_WIDTH = 320;
const ROW_HEIGHT = 320;

const TAG_ICONS = {
  Casing: CasingIcon,
  Other: OtherIcon,
  Meter: MeterIcon,
  9: Number9Icon,
  Pump: PumpIcon,
  Well: WellIcon,
  Slab: SlabIcon,
  "Well Head": WellHeadIcon,
  "Well Site": WellSiteIcon,
  "Well House": WellHouseIcon,
};

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

// Styled components
const ImageContainer = styled(Box)`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  height: 300px;
  margin: 10px;
  cursor: pointer;
  &:hover {
    transform: scale(1.03);
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageOverlay = styled(Box)`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 8px;
`;

const StyledModal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalBox = styled(Box)`
  outline: none;
  background-color: #fff;
  border-radius: 8px;
  padding: 8px;
`;

const ModalStyledImg = styled.img`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  object-fit: contain;
  max-height: calc(90vh - 200px); // 200px for description
  max-width: 90vw;
  cursor: pointer;
`;

const ModalDescription = styled(Box)`
  border: 1px solid lightGray;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px;
`;

// Custom hooks
const useFetchRecords = (getAccessTokenSilently) => {
  return useQuery(
    "ui-list-wells-photo-attachments",
    async () => {
      const token = await getAccessTokenSilently();
      const { data } = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/ui-list-wells-photo-attachments`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    },
    { refetchOnWindowFocus: false }
  );
};

const useCancelableImage = (imageUrl) => {
  return useQuery(
    ["image", imageUrl],
    async ({ signal }) => {
      const encodedUrl = encodeUrlSafely(imageUrl);
      const response = await fetch(encodedUrl, { signal });
      if (!response.ok) {
        throw new Error("Failed to fetch image");
      }
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    },
    {
      enabled: !!imageUrl,
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  );
};

// Utility functions
const encodeUrlSafely = (url) => {
  if (!url) return "";
  let encodedUrl = encodeURI(url);
  encodedUrl = encodedUrl
    .replace(/#/g, "%23")
    .replace(/\?/g, "%3F")
    .replace(/&/g, "%26")
    .replace(/=/g, "%3D");
  return encodedUrl;
};

// Components
const PhotoGridSkeleton = ({ columnCount, columnWidth, height, rowHeight }) => {
  return (
    <GridWindow
      columnCount={columnCount}
      columnWidth={columnWidth}
      height={height}
      rowCount={Math.ceil(12 / columnCount)} // Show 12 skeletons
      rowHeight={rowHeight}
      width={columnWidth * columnCount}
    >
      {({ style }) => {
        return (
          <div style={style}>
            <Skeleton variant="rect" width={columnWidth - 20} height={250} />
            <Skeleton variant="text" width={columnWidth - 20} />
            <Skeleton variant="text" width={columnWidth - 20} />
            <Skeleton variant="text" width={columnWidth - 20} />
          </div>
        );
      }}
    </GridWindow>
  );
};

const PhotoGrid = memo(
  ({ photos, columnCount, columnWidth, height, rowHeight, onPhotoClick }) => {
    return (
      <GridWindow
        columnCount={columnCount}
        columnWidth={columnWidth}
        height={height}
        rowCount={Math.ceil(photos.length / columnCount)}
        rowHeight={rowHeight}
        width={columnWidth * columnCount + 10}
      >
        {({ columnIndex, rowIndex, style }) => {
          const index = rowIndex * columnCount + columnIndex;
          const photo = photos[index];

          if (!photo) return null;

          return (
            <div style={style}>
              <PhotoCard photo={photo} onClick={onPhotoClick} />
            </div>
          );
        }}
      </GridWindow>
    );
  }
);

const PhotoCard = memo(({ photo, onClick }) => {
  const {
    data: src,
    isLoading,
    isError,
  } = useCancelableImage(photo?.hyperlink);

  const IconComponent = photo?.tag ? TAG_ICONS[photo.tag] : null;

  return (
    <ImageContainer onClick={() => onClick({ ...photo, src })}>
      {isError ? (
        <Alert severity="error">Failed to load image</Alert>
      ) : isLoading ? (
        <Skeleton animation="wave" variant="rect" width="100%" height="300px" />
      ) : (
        <Image src={src} alt={photo?.well_name || "N/A"} />
      )}
      <ImageOverlay>
        {IconComponent && (
          <Tooltip title={photo.tag || ""} placement="top">
            <IconComponent
              style={{ color: "white", position: "absolute", top: 8, right: 8 }}
            />
          </Tooltip>
        )}
        <Box>
          <Typography variant="subtitle1">
            District Well ID: {photo?.district_well_id || "N/A"}
          </Typography>
          <Typography variant="caption" display="block">
            {photo?.well_name || "N/A"}
          </Typography>
          <Typography variant="caption" display="block">
            {photo?.well_location_address || "N/A"}
          </Typography>
        </Box>
      </ImageOverlay>
    </ImageContainer>
  );
});

const PhotoModal = ({ selectedPhoto, isOpen, handleClose }) => {
  if (!selectedPhoto) return null;

  const IconComponent = selectedPhoto?.tag
    ? TAG_ICONS[selectedPhoto.tag]
    : null;

  return (
    <StyledModal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      aria-hidden={!isOpen}
    >
      <Fade in={isOpen} timeout={{ enter: 1000, exit: 1000 }}>
        <ModalBox>
          <ModalStyledImg
            src={selectedPhoto?.src}
            alt={selectedPhoto?.filename || ""}
            onClick={handleClose}
          />
          <ModalDescription>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body1">
                <strong>Name:</strong> {selectedPhoto?.well_name || "N/A"}
              </Typography>
              {IconComponent && (
                <Box display="flex" alignItems="center">
                  <Typography variant="body1">{selectedPhoto?.tag}</Typography>
                  <IconComponent style={{ color: "black", marginLeft: 8 }} />
                </Box>
              )}
            </Box>
            <Typography variant="body1">
              <strong>District Well ID:</strong>{" "}
              {selectedPhoto?.district_well_id || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Address:</strong>{" "}
              {selectedPhoto?.well_location_address || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Class:</strong> {selectedPhoto?.well_class_name || "N/A"}{" "}
              / <strong>Subclass:</strong>{" "}
              {selectedPhoto?.well_subclass_name || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Permit Info:</strong>{" "}
              {selectedPhoto?.permits_info || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Primary Use:</strong>{" "}
              {selectedPhoto?.primary_use_group_desc || "N/A"} /{" "}
              <strong>Secondary Use:</strong>{" "}
              {selectedPhoto?.secondary_use_group_desc || "N/A"}
            </Typography>
          </ModalDescription>
        </ModalBox>
      </Fade>
    </StyledModal>
  );
};

// Main component
const PhotoGallery = () => {
  const { getAccessTokenSilently } = useAuth0();

  const previousDimensions = useRef({ width: 0, height: 0 });

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [gridDimensions, setGridDimensions] = useState({ width: 0, height: 0 });

  const { data, isLoading, error } = useFetchRecords(getAccessTokenSilently);

  const fuse = useMemo(() => new Fuse(data, FUSE_OPTIONS), [data]);

  // Debounce searchTerm updates
  useEffect(() => {
    const handler = _debounce(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    handler();

    return () => {
      handler.cancel();
    };
  }, [searchTerm]);

  const debouncedUpdateSize = useMemo(
    () =>
      _debounce(({ height, width }) => {
        if (
          width !== previousDimensions.current.width ||
          height !== previousDimensions.current.height
        ) {
          setGridDimensions({ height, width });
          previousDimensions.current = { width, height };
        }
      }, 200),
    []
  );

  // Cleanup for debounced function on component unmount
  useEffect(() => {
    return () => {
      debouncedUpdateSize.cancel();
    };
  }, [debouncedUpdateSize]);

  const filteredPhotos = useMemo(() => {
    if (!debouncedSearchTerm) return data || [];
    return fuse.search(debouncedSearchTerm).map((result) => result.item);
  }, [data, debouncedSearchTerm, fuse]);

  const handleOpenModal = useCallback((photo) => {
    setSelectedPhoto(photo);
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const columnCount = useMemo(
    () => Math.max(Math.floor(gridDimensions.width / MIN_COLUMN_WIDTH), 1),
    [gridDimensions.width]
  );
  const columnWidth = useMemo(
    () => Math.floor(gridDimensions.width / columnCount),
    [columnCount, gridDimensions.width]
  );

  if (error) {
    return (
      <Alert severity="error" fullWidth>
        Failed to load image
      </Alert>
    );
  }

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column">
      <Helmet title="Photo Gallery" />
      <Typography variant="h3" gutterBottom display="inline">
        Wells Photo Gallery
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link
          component={NavLink}
          exact
          to="/dashboard"
          aria-label="Go to Dashboard"
        >
          Dashboard
        </Link>
        <Typography aria-current="page">Wells Photo Gallery</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <TextField
        label="Search"
        id="search"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        margin="normal"
        aria-label="Search by well name, address, or other details"
      />

      <Box flexGrow={1}>
        <AutoSizer>
          {({ height, width }) => {
            debouncedUpdateSize({ width, height });
            return null; // AutoSizer is only used to capture dimensions
          }}
        </AutoSizer>

        {isLoading ? (
          <PhotoGridSkeleton
            columnCount={columnCount}
            columnWidth={columnWidth}
            height={gridDimensions.height}
            rowHeight={ROW_HEIGHT}
          />
        ) : (
          <PhotoGrid
            photos={filteredPhotos}
            columnCount={columnCount}
            columnWidth={columnWidth}
            height={gridDimensions.height}
            rowHeight={ROW_HEIGHT}
            onPhotoClick={handleOpenModal}
          />
        )}
      </Box>

      <PhotoModal
        selectedPhoto={selectedPhoto}
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
      />
    </Box>
  );
};

export default PhotoGallery;
