import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Loader from "../../../../../../components/Loader";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import React from "react";

function AmendmentTracking({ state, handleUpdateState, lookups }) {
  return (
    <>
      <Grid
        container
        spacing={3}
        style={{
          border: "3px solid gray",
          marginBottom: "15px",
          borderRadius: "4px",
          marginTop: "5px",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            position: "relative",
          }}
        >
          <Typography variant="h5">Amendment Tracking</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          xl={3}
          style={{
            position: "relative",
          }}
        >
          <FormControl
            component="fieldset"
            style={{ marginRight: "10px", width: "100%" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={state?.action_public_hearing_date}
                onChange={(date) =>
                  handleUpdateState("action_public_hearing_date", date)
                }
                id="action-public-hearing-date"
                variant="inline"
                format="LLLL do, yyyy"
                autoOk
                inputVariant="outlined"
                label="Public Hearing Date"
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          xl={9}
          style={{
            position: "relative",
          }}
        >
          <TextField
            variant="outlined"
            label="Public Hearing Notes"
            style={{ width: "100%" }}
            onChange={(e) =>
              handleUpdateState("action_public_hearing_notes", e.target.value)
            }
            value={state.action_public_hearing_notes ?? ""}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          xl={3}
          style={{
            position: "relative",
          }}
        >
          <FormControl
            component="fieldset"
            style={{ marginRight: "10px", width: "100%" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={state?.action_soah_prelim_hearing_date}
                onChange={(date) =>
                  handleUpdateState("action_soah_prelim_hearing_date", date)
                }
                id="action-soah-prelim-hearing-date"
                variant="inline"
                format="LLLL do, yyyy"
                autoOk
                inputVariant="outlined"
                label="SOAH Preliminary Hearing"
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          xl={9}
          style={{
            position: "relative",
          }}
        >
          <TextField
            variant="outlined"
            label="SOAH Preliminary Hearing Notes"
            style={{ width: "100%" }}
            onChange={(e) =>
              handleUpdateState(
                "action_soah_prelim_hearing_notes",
                e.target.value
              )
            }
            value={state.action_soah_prelim_hearing_notes ?? ""}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          xl={3}
          style={{
            position: "relative",
          }}
        >
          <FormControl
            component="fieldset"
            style={{ marginRight: "10px", width: "100%" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={state?.action_soah_evidentiary_date}
                onChange={(date) =>
                  handleUpdateState("action_soah_evidentiary_date", date)
                }
                id="action-soah-evidentiary-date"
                variant="inline"
                format="LLLL do, yyyy"
                autoOk
                inputVariant="outlined"
                label="SOAH Evidentiary Hearing"
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          xl={9}
          style={{
            position: "relative",
          }}
        >
          <TextField
            variant="outlined"
            label="SOAH Evidentiary Hearing Notes"
            style={{ width: "100%" }}
            onChange={(e) =>
              handleUpdateState("action_soah_evidentiary_notes", e.target.value)
            }
            value={state.action_soah_evidentiary_notes ?? ""}
          />
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            position: "relative",
          }}
        >
          <TextField
            variant="outlined"
            label="Special Conditions (080117)"
            style={{ width: "100%" }}
            onChange={(e) =>
              handleUpdateState("special_conditions_080117", e.target.value)
            }
            value={state.special_conditions_080117 ?? ""}
          />
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            position: "relative",
          }}
        >
          <TextField
            variant="outlined"
            label="Board Order (080117)"
            style={{ width: "100%" }}
            onChange={(e) =>
              handleUpdateState("board_order_080117", e.target.value)
            }
            value={state.board_order_080117 ?? ""}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          xl={3}
          style={{
            position: "relative",
          }}
        >
          <FormControl
            component="fieldset"
            style={{ marginRight: "10px", width: "100%" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                error={state?.pamend_status_ndx === 2 && !state?.approval_date}
                required={state?.pamend_status_ndx === 2}
                value={state?.approval_date}
                onChange={(date) => handleUpdateState("approval_date", date)}
                id="approval-date"
                variant="inline"
                format="LLLL do, yyyy"
                autoOk
                inputVariant="outlined"
                label="Approval Date"
              />
            </MuiPickersUtilsProvider>
            {state?.pamend_status_ndx === 2 && !state?.approval_date && (
              <FormHelperText error>*Required field</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          xl={3}
          style={{
            position: "relative",
          }}
        >
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            error={state?.pamend_status_ndx === 2 && !state?.approval_body_ndx}
            required={state?.pamend_status_ndx === 2}
          >
            {!lookups?.permitApprovalBody.length > 0 ? (
              <Loader />
            ) : (
              <>
                <InputLabel id="approval-body">Approval Body</InputLabel>
                <Select
                  labelId="approval body"
                  id="approval-body"
                  label="Approval Body"
                  name="approval_body_ndx"
                  value={state.approval_body_ndx ?? ""}
                  onChange={(e) => {
                    handleUpdateState("approval_body_ndx", e.target.value);
                  }}
                >
                  {lookups.permitApprovalBody.map((option) => (
                    <MenuItem
                      key={option.approval_body_ndx}
                      value={option.approval_body_ndx}
                    >
                      {option.approval_body_desc}
                    </MenuItem>
                  ))}
                </Select>
                {state?.pamend_status_ndx === 2 &&
                  !state?.approval_body_ndx && (
                    <FormHelperText error>*Required field</FormHelperText>
                  )}
              </>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          xl={3}
          style={{
            position: "relative",
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl style={{ width: "100%" }}>
              <KeyboardDatePicker
                disabled
                format="LLLL do, yyyy"
                inputVariant="outlined"
                autoOk
                id="created-on"
                label="Created On"
                value={state?.created_timestamp}
                onChange={(date) => {
                  handleUpdateState("created_timestamp", date);
                }}
                InputAdornmentProps={{
                  "aria-label": "change created date",
                }}
                InputProps={{
                  style: {
                    color: "rgba(0,0,0,1)",
                  },
                }}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          xl={3}
          style={{
            position: "relative",
          }}
        >
          <TextField
            disabled
            variant="outlined"
            label="Created By"
            style={{ width: "100%" }}
            onChange={(e) => handleUpdateState("created_by", e.target.value)}
            value={state.created_by ?? ""}
            InputProps={{
              style: {
                color: "rgba(0,0,0,1)",
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AmendmentTracking;
