import React from "react";
import { IconButton, Box, Tooltip } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocationOffIcon from "@material-ui/icons/LocationOff";

const MarkerControl = ({
  open = false,
  onToggle,
  top = 88,
  right = 10,
  title = "Marker Control",
}) => {
  const Icon = open ? LocationOnIcon : LocationOffIcon;

  return (
    <Box
      bgcolor="#ffffff"
      boxShadow="0 0 0 2px rgba(0,0,0,.1)"
      borderRadius={4}
      position="absolute"
      zIndex={1}
      top={top}
      right={right}
      display="flex"
      flexDirection="column"
    >
      <Tooltip title={title}>
        <IconButton
          size="small"
          color={open ? "secondary" : "default"}
          onClick={onToggle}
        >
          <Icon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default MarkerControl;
