import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useMemo } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { InputLabel, TextField } from "@material-ui/core";
import useFetchData from "../../../../hooks/useFetchData";
import LinkedPermitsFuzzySearch from "./LinkedPermitsFuzzySearch";

export const useLinkedPermitsManagement = (selectedPermitGroup) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useStateIfMounted([]);

  const { data, isFetching, error, refetch } = useQuery(
    ["ui-list-permits", selectedPermitGroup],
    async () => {
      if (!selectedPermitGroup) {
        setEditorState([]);
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-list-permits/${selectedPermitGroup}`,
          { headers }
        );
        setEditorState(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [permitsLookup] = useFetchData("ui-list-permits-active", [], true);
  const permitsLookupForTable = useMemo(() => {
    let converted = {};
    if (data?.length > 0) {
      data.forEach((item) => {
        converted[item.permit_ndx] = item.permit_name_for_groups_management;
      });
    }
    return converted;
  }, [data]);

  const LINKED_PERMITS_MANAGEMENT_TABLE_COLUMNS = [
    {
      title: "Permitted",
      field: "permit_ndx",
      cellStyle: { width: "100%" },
      editComponent: (props) => {
        return (
          <>
            <LinkedPermitsFuzzySearch
              options={permitsLookup}
              onSelect={props.onChange}
            />
            <InputLabel style={{ marginTop: "6px" }} id="permits">
              Selected Permit
            </InputLabel>
            <TextField
              disabled
              variant="outlined"
              style={{ width: "100%" }}
              value={permitsLookupForTable[props.value] || ""}
            />
          </>
        );
      },
      lookup: permitsLookupForTable,
    },
  ];

  return {
    isLoading: isFetching,
    error,
    refetch,
    state: editorState,
    setter: setEditorState,
    LINKED_PERMITS_MANAGEMENT_TABLE_COLUMNS,
  };
};
