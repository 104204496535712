import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Info } from "@material-ui/icons";

function Volume({
  currentPermit,
  state,
  handleUpdateState,
  selectedAmendment,
}) {
  return (
    <>
      <Grid
        container
        spacing={3}
        style={{
          border: "3px solid gray",
          marginBottom: "15px",
          borderRadius: "4px",
          marginTop: "5px",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            position: "relative",
          }}
        >
          <Typography variant="h5">Proposed Volume Change</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          xl={4}
          style={{
            position: "relative",
          }}
        >
          <TextField
            disabled
            variant="outlined"
            label="Previous Volume"
            style={{ width: "100%" }}
            value={
              selectedAmendment
                ? state?.saved_previous_volume
                : currentPermit?.authorized_volume_gal ?? ""
            }
            InputProps={{
              style: {
                color: "rgba(0,0,0,1)",
              },
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          xl={4}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip title="Enter a negative Change to reduce the permitted volume.">
            <Info color="secondary" style={{ marginRight: "5px" }} />
          </Tooltip>
          <FormControl variant="outlined" style={{ width: "100%" }} required>
            <TextField
              error={state.proposed_volume_change === null}
              required
              type="number"
              variant="outlined"
              label="Proposed Change (Gal)"
              style={{ width: "100%" }}
              onChange={(e) => {
                if (e.target.value === "") {
                  handleUpdateState("proposed_volume_change", null);
                } else {
                  handleUpdateState(
                    "proposed_volume_change",
                    +e.target.value,
                    selectedAmendment
                      ? state?.saved_previous_volume
                      : currentPermit?.authorized_volume_gal
                  );
                }
              }}
              value={state?.proposed_volume_change ?? ""}
              InputProps={{
                inputProps: {
                  onKeyPress: (e) => {
                    if (e.key === "e") {
                      e.preventDefault();
                    }
                  },
                },
              }}
            />
            {state.proposed_volume_change === null ? (
              <FormHelperText error>*Required field</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          xl={4}
          style={{
            position: "relative",
          }}
        >
          <TextField
            disabled
            variant="outlined"
            label="New Volume"
            style={{ width: "100%" }}
            value={
              ((selectedAmendment
                ? state?.saved_previous_volume
                : currentPermit?.authorized_volume_gal) || 0) +
                (state?.proposed_volume_change || 0) ?? ""
            }
            InputProps={{
              style: {
                color: "rgba(0,0,0,1)",
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Volume;
