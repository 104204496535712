import React from "react";

import CopyIcon from "@material-ui/icons/FileCopy";
import { copyToClipboard, dateFormatter } from "../utils";
import MaterialTable from "material-table";
import { useApp } from "../AppProvider";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const DataAdminTable = ({
  pageSize = 10,
  isLoading = false,
  label,
  columns,
  data,
  height,
  endpoint,
  ndxField,
  selectedNdx,
  handleRefresh = () => {},
  actions = [],
  isDeletable = true,
  canAdd = true,
  search = true,
  identifierNdxField = "well_ndx",
  icons = {},
  localization = {},
}) => {
  const { doToast } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  const handleAdd = (newData) => {
    return (async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        await axios.patch(
          `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}/${selectedNdx}`,
          {
            [identifierNdxField]: [
              ...new Set([
                ...data.map((item) => item[ndxField]),
                newData[ndxField],
              ]),
            ],
          },
          { headers }
        );
        handleRefresh();
        doToast("success", "New entry was saved to the database");
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };
  const handleUpdate = (newData, oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.patch(
            `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}/${selectedNdx}`,
            {
              [identifierNdxField]: [
                ...new Set(
                  data.map((item) =>
                    item[ndxField] === oldData[ndxField]
                      ? newData[ndxField]
                      : item[ndxField]
                  )
                ),
              ],
            },
            { headers }
          );
          handleRefresh();
          doToast("success", "New data was updated to the database");
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleDelete = (oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.patch(
            `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}/${selectedNdx}`,
            {
              [identifierNdxField]: data
                .filter((item) => item[ndxField] !== oldData[ndxField])
                .map((item) => item[ndxField]),
            },
            { headers }
          );

          handleRefresh();
          doToast("success", "This entry was deleted from the database");
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  return (
    <MaterialTable
      localization={localization}
      id={label}
      title={`${label} ${dateFormatter(new Date(), "MM/DD/YYYY, h:mm A")}`}
      columns={columns}
      isLoading={isLoading}
      data={data}
      icons={{
        ...icons,
      }}
      editable={{
        onRowAdd: selectedNdx && canAdd ? handleAdd : undefined,
        onRowUpdate: handleUpdate,
        onRowDelete: isDeletable ? handleDelete : null,
      }}
      components={{
        Container: (props) => <div {...props} />,
      }}
      actions={[
        {
          icon: CopyIcon,
          tooltip: "Copy Data",
          isFreeAction: true,
          onClick: () => {
            try {
              copyToClipboard(data, columns, () =>
                doToast("success", "Data was copied to your clipboard.")
              );
            } catch (error) {
              const message = error?.message ?? "Something went wrong";
              doToast("error", message);
            }
          },
        },
        ...actions,
      ]}
      options={{
        emptyRowsWhenPaging: false,
        columnsButton: true,
        exportButton: true,
        exportAllData: true,
        addRowPosition: "first",
        pageSize: pageSize,
        pageSizeOptions: [5, 10, 30, 60],
        padding: "dense",
        searchFieldAlignment: "left",
        showTitle: false,
        maxBodyHeight: height,
        search: search,
      }}
    />
  );
};

export default DataAdminTable;
