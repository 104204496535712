import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useMemo } from "react";
import useFetchData from "../../../../../hooks/useFetchData";
import { useStateIfMounted } from "use-state-if-mounted";
import FuzzySearch from "../FuzzySearch";
import { Button, InputLabel, Link, TextField } from "@material-ui/core";

export const useContactsManagement = (selectedWell) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useStateIfMounted([]);

  const { isFetching, error, refetch } = useQuery(
    ["list-wells-associated-contacts", selectedWell],
    async () => {
      if (!selectedWell) {
        setEditorState([]);
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-wells-associated-contacts/${selectedWell}`,
          { headers }
        );
        setEditorState(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [contactTypeLookup] = useFetchData("ui-list-wm-rolo-types", [], true);
  const contactTypeLookupForTable = useMemo(() => {
    let converted = {};
    if (contactTypeLookup?.length > 0) {
      contactTypeLookup.forEach((item) => {
        converted[item.rolo_type_ndx] = item.rolo_type_desc;
      });
    }
    return converted;
  }, [contactTypeLookup]);

  const [contactRecordLookup] = useFetchData(
    "ui-list-wm-rolo-records",
    [],
    true
  );
  const contactRecordLookupForTable = useMemo(() => {
    let converted = {};
    if (contactRecordLookup?.length > 0) {
      contactRecordLookup.forEach((item) => {
        converted[item.rolo_ndx] = item.concatenated_contact_info;
      });
    }
    return converted;
  }, [contactRecordLookup]);

  const CONTACTS_MANAGEMENT_TABLE_COLUMNS = [
    {
      title: "Contact Type",
      field: "rolo_type_ndx",
      editable: "never",
      lookup: contactTypeLookupForTable,
    },
    {
      title: "Contact Record",
      field: "rolo_ndx",
      cellStyle: { width: "100%" },
      editComponent: (props) => {
        return (
          <>
            <FuzzySearch
              options={contactRecordLookup}
              onSelect={props.onChange}
            />
            <InputLabel style={{ marginTop: "6px" }} id="selected-contact">
              Selected Contact
            </InputLabel>
            <TextField
              disabled
              variant="outlined"
              style={{ width: "100%" }}
              value={contactRecordLookupForTable[props.value] || ""}
            />
          </>
        );
      },
      lookup: contactRecordLookupForTable,
    },
    {
      title: "Contact Details",
      field: "rolo_ndx",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
      render: (rowData) => {
        return (
          <Button
            disabled={rowData.rolo_ndx === 999999}
            component={Link}
            target="_blank"
            rel="noreferrer noopener"
            href={`/data-management/well-owners/edit-owner-info/${rowData.rolo_ndx}`}
            variant="contained"
            color="secondary"
            style={{ width: "100%" }}
            size="small"
          >
            Edit Owner Info
          </Button>
        );
      },
      editable: "never",
    },
  ];

  return {
    isLoading: isFetching,
    error,
    refetch,
    state: editorState,
    setter: setEditorState,
    CONTACTS_MANAGEMENT_TABLE_COLUMNS,
  };
};
