import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { AccordionActions } from "@mui/material";
import React from "react";
import Loader from "../../../../../components/Loader";
import { useWellCompletion } from "../hooks/useWellCompletion";
import { useSubmitData } from "../../../../../hooks/useSubmitData";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { getButtonIcon, getButtonText } from "../utils";

function WellCompletion({
  selectedWell,
  setSelectedWell,
  isEditMode = false,
  selectedWellLabel,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
    lookups,
  } = useWellCompletion(selectedWell);

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedWell,
    setSelectedWell,
    state,
    "list-wells-completion",
    "well_ndx",
    refetch
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.well_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded={isEditMode}
        disabled={!selectedWell}
        style={{
          display:
            isLoading || (isEditMode && !state?.well_ndx) ? "none" : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">{`Well Construction Basic Info ${
              selectedWellLabel ? `(${selectedWellLabel})` : ""
            }`}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {state?.driller_add_new && (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    position: "relative",
                  }}
                >
                  <TextField
                    required
                    error={!state?.temp_new_driller}
                    variant="outlined"
                    label="New Driller"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateState("temp_new_driller", e.target.value)
                    }
                    value={state.temp_new_driller ?? ""}
                  />
                  {!state?.temp_new_driller && (
                    <FormHelperText
                      style={{ marginLeft: "14px", marginTop: "4px" }}
                      error
                    >
                      *Required field
                    </FormHelperText>
                  )}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.driller.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="driller">Driller</InputLabel>
                    <Select
                      disabled={!selectedWell || state?.driller_add_new}
                      labelId="driller"
                      id="driller"
                      label="Driller"
                      name="driller_ndx"
                      value={state?.driller_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("driller_ndx", e.target.value);
                      }}
                    >
                      {lookups.driller.map((option) => (
                        <MenuItem
                          key={option.driller_ndx}
                          value={option.driller_ndx}
                        >
                          {option.driller_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Button
                disabled={!selectedWell}
                size="large"
                variant="contained"
                color={state.driller_add_new ? "default" : "secondary"}
                onClick={() =>
                  handleUpdateState("driller_add_new", !state.driller_add_new)
                }
              >
                Add New Driller
              </Button>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl style={{ width: "100%" }}>
                  <KeyboardDatePicker
                    disabled={!selectedWell}
                    format="LLLL do, yyyy"
                    inputVariant="outlined"
                    autoOk
                    id="drilled-date"
                    label="Drilled Date"
                    value={state?.drilled_date}
                    onChange={(date) => {
                      handleUpdateState("drilled_date", date);
                    }}
                    InputAdornmentProps={{
                      "aria-label": "change drilled date",
                    }}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled={!selectedWell}
                type="number"
                variant="outlined"
                label="Total Depth (ft)"
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    handleUpdateState("well_depth_ft", null);
                  } else {
                    handleUpdateState("well_depth_ft", +e.target.value);
                  }
                }}
                value={state?.well_depth_ft ?? ""}
                InputProps={{
                  inputProps: {
                    onKeyPress: (e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    },
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled={!selectedWell}
                type="number"
                variant="outlined"
                label="Flow Rate (GPM)"
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    handleUpdateState("pump_gpm", null);
                  } else {
                    handleUpdateState("pump_gpm", +e.target.value);
                  }
                }}
                value={state?.pump_gpm ?? ""}
                InputProps={{
                  inputProps: {
                    onKeyPress: (e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    },
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled={!selectedWell}
                type="number"
                variant="outlined"
                label="Boreholde Diameter (in)"
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    handleUpdateState("bore_diam_in", null);
                  } else {
                    handleUpdateState("bore_diam_in", +e.target.value);
                  }
                }}
                value={state?.bore_diam_in ?? ""}
                InputProps={{
                  inputProps: {
                    onKeyPress: (e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    },
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled={!selectedWell}
                type="number"
                variant="outlined"
                label="Pump Depth (ft)"
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    handleUpdateState("pump_depth_ft", null);
                  } else {
                    handleUpdateState("pump_depth_ft", +e.target.value);
                  }
                }}
                value={state?.pump_depth_ft ?? ""}
                InputProps={{
                  inputProps: {
                    onKeyPress: (e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    },
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled={!selectedWell}
                type="number"
                variant="outlined"
                label="Pump Size (HP)"
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    handleUpdateState("pump_size_hp", null);
                  } else {
                    handleUpdateState("pump_size_hp", +e.target.value);
                  }
                }}
                value={state?.pump_size_hp ?? ""}
                InputProps={{
                  inputProps: {
                    onKeyPress: (e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    },
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled={!selectedWell}
                variant="outlined"
                label="Pump Type"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("pump_type", e.target.value)}
                value={state?.pump_type ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={9}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled={!selectedWell}
                variant="outlined"
                label="Grout Types/Methods Notes"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("notes_grout_types_methods", e.target.value)
                }
                value={state?.notes_grout_types_methods ?? ""}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button
            disabled={!selectedWell}
            size="small"
            onClick={handleResetState}
          >
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={
              !isDirty ||
              (state.driller_add_new && !state.temp_new_driller) ||
              isFormSubmitting
            }
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default WellCompletion;
