import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Paper,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { AccordionActions } from "@mui/material";
import React from "react";
import Loader from "../../../../../components/Loader";
import { useAmendment } from "./useAmendment";
import { getButtonIcon, getButtonText } from "../../wellsManagement/utils";
import Setup from "./sections/Setup";
import Volume from "./sections/Volume";
import PermittedUse from "./sections/PermittedUse";
import Retirement from "./sections/Retirement";
import Permittee from "./sections/Permittee";
import AmendmentTracking from "./sections/AmendmentTracking";
import { useSubmitData } from "./useSubmitData";

function Amendment({
  selectedPermit,
  selectedAmendment,
  setSelectedAmendment,
  handleRefetch,
  triggerRefetch,
}) {
  const {
    dataCurrentPermit,
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
    lookups,
  } = useAmendment(selectedAmendment, selectedPermit, triggerRefetch);

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedAmendment,
    setSelectedAmendment,
    state,
    "data-permit-amendments",
    "pamend_ndx",
    handleRefetch,
    refetch
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading && (
        <Paper style={{ width: "100%", margin: "8px 0" }}>
          <Loader />
        </Paper>
      )}
      <Accordion
        defaultExpanded
        style={{
          display: isLoading ? "none" : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Amendment</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
          <Setup
            selectedAmendment={selectedAmendment}
            lookups={lookups}
            state={state}
            handleUpdateState={handleUpdateState}
          />
          {state?.pamend_type_ndx === 15 && (
            <Volume
              selectedAmendment={selectedAmendment}
              currentPermit={dataCurrentPermit}
              state={state}
              handleUpdateState={handleUpdateState}
            />
          )}
          {state?.pamend_type_ndx === 4 && (
            <PermittedUse
              selectedAmendment={selectedAmendment}
              currentPermit={dataCurrentPermit}
              lookups={lookups}
              state={state}
              handleUpdateState={handleUpdateState}
            />
          )}
          {state?.pamend_type_ndx === 7 && (
            <Retirement
              selectedAmendment={selectedAmendment}
              currentPermit={dataCurrentPermit}
              lookups={lookups}
              state={state}
              handleUpdateState={handleUpdateState}
            />
          )}
          {state?.pamend_type_ndx === 6 && (
            <Permittee
              selectedAmendment={selectedAmendment}
              currentPermit={dataCurrentPermit}
              lookups={lookups}
              state={state}
              handleUpdateState={handleUpdateState}
            />
          )}
          <AmendmentTracking
            selectedAmendment={selectedAmendment}
            state={state}
            handleUpdateState={handleUpdateState}
            lookups={lookups}
          />
        </AccordionDetails>
        <Divider />
        <AccordionActions style={{ justifyContent: "space-between" }}>
          <Box
            p={4}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">
              {selectedAmendment === 0
                ? "Creating New Permit Amendment"
                : "Editing Existing Permit Amendment"}
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={() => setSelectedAmendment(null)}
              style={{ marginRight: "10px" }}
              size="small"
              color="primary"
            >
              Cancel
            </Button>
            <Button size="small" onClick={handleResetState}>
              Reset
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={handleSubmit}
              disabled={
                !isDirty ||
                isFormSubmitting ||
                !state.pamend_type_ndx ||
                !state.effective_date ||
                (state.pamend_type_ndx === 15 &&
                  typeof state.proposed_volume_change !== "number") ||
                (state.pamend_type_ndx === 4 &&
                  !state.proposed_primary_use_ndx &&
                  !state.proposed_secondary_use_ndx) ||
                (state.pamend_status_ndx === 2 && !state.approval_date) ||
                (state.pamend_status_ndx === 2 && !state.approval_body_ndx) ||
                // fix this logic when creating a new permittee
                (state.pamend_type_ndx === 6 &&
                  ((!state.proposed_rolo_ndx && !state.permittee_add_new) ||
                    (state.permittee_add_new &&
                      !state.tmp_permittee_name &&
                      !state.tmp_permittee_email &&
                      !state.tmp_permittee_phone &&
                      !state.tmp_permittee_zip &&
                      !state.tmp_permittee_address &&
                      !state.tmp_permittee_city &&
                      !state.tmp_permittee_state &&
                      !state.tmp_permittee_organization &&
                      !state.tmp_permittee_precinct_ndx)))
              }
              startIcon={getButtonIcon(isFormSubmitting)}
              style={{ width: "100px" }}
            >
              {getButtonText(isFormSubmitting)}
            </Button>
          </Box>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default Amendment;
