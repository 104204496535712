import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from "@material-ui/core";
import { ExpandMore, LinkOff } from "@material-ui/icons";
import React, { forwardRef } from "react";
import Loader from "../../../../components/Loader";
import { TableWrapper } from "../wellsManagement/utils";
import DataAdminTable from "../../../../components/DataAdminTable";
import { useManageAssociatedWells } from "./useManageAssociatedWells";

function ManageAssociatedWells({
  selectedMeter,
  triggerRefetch,
  isEditMode = false,
}) {
  const {
    isLoading,
    error,
    refetch,
    state,
    setter,
    MANAGE_ASSOCIATED_WELLS_TABLE_COLUMNS,
  } = useManageAssociatedWells(selectedMeter, triggerRefetch);

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading && (
        <Paper style={{ width: "100%", margin: "8px 0" }}>
          <Loader />
        </Paper>
      )}
      <Accordion defaultExpanded={isEditMode} disabled={!selectedMeter}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Manage Associated Wells</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <TableWrapper>
            <DataAdminTable
              pageSize={10}
              isLoading={isLoading}
              label="TITLE"
              columns={MANAGE_ASSOCIATED_WELLS_TABLE_COLUMNS}
              data={state}
              height="500px"
              updateHandler={setter}
              endpoint="list-wells-associated-meters"
              ndxField="ndx"
              selectedNdx={selectedMeter}
              handleRefresh={refetch}
              identifierNdxField="meter_ndx"
              icons={{
                Delete: forwardRef((props, ref) => {
                  return <LinkOff {...props} ref={ref} />;
                }),
              }}
              localization={{
                body: {
                  deleteTooltip: "Delink",
                },
              }}
            />
          </TableWrapper>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default ManageAssociatedWells;
