import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from "@material-ui/core";
import { ExpandMore, LinkOff } from "@material-ui/icons";
import React, { forwardRef } from "react";
import Loader from "../../../../components/Loader";
import { TableWrapper } from "../wellsManagement/utils";
import DataAdminTableUpdateArrayField from "../../../../components/DataAdminTableUpdateArrayField";
import { useLinkedPermitsManagement } from "./useLinkedPermitsManagement";

function LinkedPermitsManagement({
  selectedPermitGroup,
  isEditMode = false,
  refetchPermitGroups = () => {},
}) {
  const {
    isLoading,
    error,
    refetch,
    state,
    setter,
    LINKED_PERMITS_MANAGEMENT_TABLE_COLUMNS,
  } = useLinkedPermitsManagement(selectedPermitGroup);

  const triggerRefetch = () => {
    refetchPermitGroups();
    refetch();
  };

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading && (
        <Paper style={{ width: "100%", margin: "8px 0" }}>
          <Loader />
        </Paper>
      )}
      <Accordion defaultExpanded={isEditMode}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Linked Permits</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <TableWrapper>
            <DataAdminTableUpdateArrayField
              pageSize={10}
              isLoading={isLoading}
              label="TITLE"
              columns={LINKED_PERMITS_MANAGEMENT_TABLE_COLUMNS}
              data={state}
              height="300px"
              updateHandler={setter}
              endpoint="list-permit-groups"
              ndxField="permit_ndx"
              identifierNdxField="linked_permits"
              selectedNdx={selectedPermitGroup}
              handleRefresh={triggerRefetch}
              search={false}
              icons={{
                Delete: forwardRef((props, ref) => {
                  return <LinkOff {...props} ref={ref} />;
                }),
              }}
              localization={{
                body: {
                  deleteTooltip: "Delink",
                },
              }}
            />
          </TableWrapper>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default LinkedPermitsManagement;
