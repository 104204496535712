import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { NavLink, useParams } from "react-router-dom";

import BasicPermitGroupInfo from "./BasicPermitGroupInfo";
import { useStateIfMounted } from "use-state-if-mounted";
import LinkedPermitsManagement from "./LinkedPermitsManagement";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

function PermitGroupManagement() {
  let { id } = useParams();
  const [selectedPermitGroup, setSelectedPermitGroup] = useStateIfMounted(id);

  const [triggerRefetch, setTriggerRefetch] = React.useState(false);
  const handleTriggerRefetch = () => setTriggerRefetch(!triggerRefetch);
  const handleRefetch = () => {
    handleTriggerRefetch();
  };

  return (
    <React.Fragment>
      <Helmet title="Permit Groups Management" />
      <Typography variant="h3" gutterBottom display="inline">
        Permit Groups Management
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Link
          component={NavLink}
          exact
          to="/data-management/permit-records/permit-groups-management"
        >
          Permit Groups Management
        </Link>
        <Typography>Edit Permit Group</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <BasicPermitGroupInfo
        selectedPermitGroup={selectedPermitGroup}
        setSelectedPermitGroup={setSelectedPermitGroup}
        isEditMode={true}
        refetchPermitGroups={handleRefetch}
        triggerRefetch={triggerRefetch}
      />
      <LinkedPermitsManagement
        selectedPermitGroup={selectedPermitGroup}
        isEditMode={true}
        refetchPermitGroups={handleRefetch}
      />
    </React.Fragment>
  );
}

export default PermitGroupManagement;
