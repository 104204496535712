import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { NavLink, useParams } from "react-router-dom";

import BasicContactInfo from "./BasicContactInfo";
import { useStateIfMounted } from "use-state-if-mounted";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

function ContactsManagement() {
  let { id } = useParams();
  const [selectedContact, setSelectedContact] = useStateIfMounted(id);

  return (
    <React.Fragment>
      <Helmet title="Edit Owner Info" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Owner Info
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Link
          component={NavLink}
          exact
          to="/data-management/well-owners/edit-owner-info"
        >
          Owners
        </Link>
        <Typography>Edit Owner Info</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <BasicContactInfo
        selectedContact={selectedContact}
        setSelectedContact={setSelectedContact}
        isEditMode={true}
      />
    </React.Fragment>
  );
}

export default ContactsManagement;
