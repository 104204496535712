import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Loader from "../../../../../../components/Loader";

import React from "react";
import { Info } from "@material-ui/icons";

function Retirement({
  currentPermit,
  state,
  handleUpdateState,
  lookups,
  selectedAmendment,
}) {
  return (
    <>
      <Grid
        container
        spacing={3}
        style={{
          border: "3px solid gray",
          marginBottom: "15px",
          borderRadius: "4px",
          marginTop: "5px",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            position: "relative",
          }}
        >
          <Typography variant="h5">Permit Retirement</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          xl={6}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControl variant="outlined" style={{ width: "100%" }}>
            {!lookups?.permitStatus.length > 0 ? (
              <Loader />
            ) : (
              <>
                <TextField
                  disabled
                  variant="outlined"
                  label="Previous Permit Status"
                  style={{ width: "100%" }}
                  value={
                    lookups.permitStatus.find(
                      (item) =>
                        item.permit_status_ndx ===
                        (selectedAmendment
                          ? state?.saved_previous_permit_status_ndx
                          : currentPermit?.permit_status_ndx)
                    )?.permit_status_desc ?? "N/A"
                  }
                  InputProps={{
                    style: {
                      color: "rgba(0,0,0,1)",
                    },
                  }}
                />
              </>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          xl={6}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip title="Retiring a Permit with Inactive-Exempt status will change the sub-class for any associated wells to Exempt.">
            <Info color="secondary" style={{ marginRight: "5px" }} />
          </Tooltip>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            {!lookups?.permitStatusRetired.length > 0 ? (
              <Loader />
            ) : (
              <>
                <InputLabel id="retirement-status">
                  Retirement Status
                </InputLabel>
                <Select
                  labelId="retirement-status"
                  id="retirement-status"
                  label="Retirement Status"
                  name="retirement-status"
                  value={state.proposed_status_ndx ?? ""}
                  onChange={(e) => {
                    handleUpdateState("proposed_status_ndx", e.target.value);
                  }}
                >
                  {lookups.permitStatusRetired.map((option) => (
                    <MenuItem
                      key={option.permit_status_ndx}
                      value={option.permit_status_ndx}
                    >
                      {option.permit_status_desc}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default Retirement;
