import { extractValuesWithKey, filterByCondition } from "../utils";

const permits = {
  name: "permits",
  ndx: 2,
  field: "permit_ndx",
  label: "Permits",
  endpoint: "q-d-data-permits",
  icon: "Assignment",
  inputs: [
    {
      type: "tristateCheckbox",
      label: "Is Active",
      valueField: "is_active",
      formName: "isActive",
      defaultFormValue: true,
    },
    {
      type: "tristateCheckbox",
      label: "Is Deleted",
      valueField: "is_deleted",
      formName: "isDeleted",
      defaultFormValue: false,
    },
    {
      type: "multiselect",
      label: "Permit Group Tier",
      valueField: "permit_permitgroup_tier",
      displayField: "permit_permitgroup_tier",
      formName: "permitGroupTier",
      filterEndpoint:
        "query-and-download/fetch-data-input/q-d-list-permit-permitgroup-tier",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(
          data,
          "permit_permitgroup_tier"
        );
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "multiselect",
      label: "Permit Status",
      valueField: "permit_status",
      displayField: "permit_status",
      formName: "permitStatus",
      filterEndpoint:
        "query-and-download/fetch-data-input/q-d-list-permit-status",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(data, "permit_status");
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "multiselect",
      label: "Permit Type",
      valueField: "permit_type",
      displayField: "permit_type",
      formName: "permitType",
      filterEndpoint:
        "query-and-download/fetch-data-input/q-d-list-permit-type",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(data, "permit_type");
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "multiselect",
      label: "Allocation Distribution Type",
      valueField: "permit_allocation_dist_type",
      displayField: "permit_allocation_dist_type",
      formName: "permitAllocationDistType",
      filterEndpoint:
        "query-and-download/fetch-data-input/q-d-list-permit-allocation-dist-type",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(
          data,
          "permit_allocation_dist_type"
        );
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "multiselect",
      label: "Aquifer Group",
      valueField: "permit_aquifer_group",
      displayField: "permit_aquifer_group",
      formName: "permitAquiferGroup",
      filterEndpoint:
        "query-and-download/fetch-data-input/q-d-list-permit-aquifer-group",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(
          data,
          "permit_aquifer_group"
        );
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "multiselect",
      label: "Primary Use",
      valueField: "permit_primary_use",
      displayField: "permit_primary_use",
      formName: "permitPrimaryUse",
      filterEndpoint:
        "query-and-download/fetch-data-input/q-d-list-permit-primary-use",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(data, "permit_primary_use");
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "checkbox",
      label: "Permittee Contact Info",
      field: "permittee_contact_info",
      formName: "permitteeContactInfo",
      defaultFormValue: true,
      excludePatterns: ["ap_permittee_"],
    },
    {
      type: "checkbox",
      label: "Alternate Contact",
      field: "alternate_contact",
      formName: "alternateContact",
      defaultFormValue: true,
      excludePatterns: ["alt_permittee_"],
    },
    {
      type: "checkbox",
      label: "Billing Contact",
      field: "billing_contact",
      formName: "billingContact",
      defaultFormValue: true,
      excludePatterns: ["bill_permittee_"],
    },
    {
      type: "checkbox",
      label: "Meter Readings Contact",
      field: "meter_readings_contact",
      formName: "meterReadingsContact",
      defaultFormValue: true,
      excludePatterns: ["meter_permittee_"],
    },
    {
      type: "checkbox",
      label: "Past Permittees",
      field: "hist_permittee",
      formName: "histPermittee",
      defaultFormValue: true,
      excludeFields: ["hist_permittee"],
    },
    {
      type: "checkbox",
      label: "Associated Wells",
      field: "wells_list",
      formName: "wellsList",
      defaultFormValue: true,
      excludeFields: ["wells_list"],
    },
    {
      type: "checkbox",
      label: "Associated Meters",
      field: "meters_list",
      formName: "metersList",
      defaultFormValue: true,
      excludeFields: ["meters_list"],
    },
    {
      type: "checkbox",
      label: "Monthly Allocations",
      field: "monthly_allocations",
      formName: "monthlyAllocations",
      defaultFormValue: true,
      excludeFields: ["monthly_allocations"],
    },
    {
      type: "checkbox",
      label: "Legacy Identities",
      field: "legacy_identities",
      formName: "legacyIdentities",
      defaultFormValue: true,
      excludePatterns: ["legacy_"],
    },
    {
      type: "search",
      label: "Permits",
      valueField: "permit_ndx",
      displayField: "permit_number",
      tooltipField: "permit_name",
      formName: "permits",
      endpoint: "query-and-download/q-d-data-permits",
      defaultFormValue: [],
      queryParameter: true,
      fuseKeys: [
        "permit_number",
        "permit_name",
        "permit_group",
        "permittee_name",
      ],
    },
  ],
};

export default permits;
