import area from "@turf/area";
import length from "@turf/length";
import { lineColors } from "./index";

export const handleCopyCoords = (value) => {
  const dummy = document.createElement("input");
  document.body.appendChild(dummy);
  dummy.value = value;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
};

export function updateArea(
  geojson,
  type,
  polygonRef,
  radiusRef,
  pointRef,
  lineRef,
  measurementsContainerRef,
  draw,
  setMeasurementsVisible
) {
  const data = draw.getAll();
  setMeasurementsVisible(true);

  const answerArea = polygonRef.current;
  const answerRadius = radiusRef.current;
  const answerPoint = pointRef.current;
  const answerLength = lineRef.current;

  if (geojson.geometry.type === "LineString" && type !== "draw.delete") {
    const exactLengthFeet = length(geojson, { units: "feet" });
    const roundedLength = exactLengthFeet.toFixed(2);
    answerLength.innerHTML = roundedLength + " ft";
  }

  if (geojson.properties.circleRadius && type !== "draw.delete") {
    const exactRadiusKm = geojson.properties.circleRadius;
    const exactRadiusFeet = exactRadiusKm * 3280.84;
    const roundedRadius = exactRadiusFeet.toFixed(2);
    answerRadius.innerHTML = roundedRadius + " ft";
  }

  if (geojson.geometry.type === "Point" && type !== "draw.delete") {
    answerPoint.innerHTML = `<strong>lat:</strong> ${geojson.geometry.coordinates[1].toFixed(
      5
    )} <br /><strong>long:</strong> ${geojson.geometry.coordinates[0].toFixed(
      5
    )}`;
  }

  if (
    data.features.filter((item) => item.geometry.type === "Point").length === 0
  ) {
    answerPoint.innerHTML = "--";
  }
  if (
    data.features.filter((item) => item.geometry.type === "LineString")
      .length === 0
  ) {
    answerLength.innerHTML = "--";
  }
  if (
    data.features.filter((item) => item.properties.circleRadius).length === 0
  ) {
    answerRadius.innerHTML = "--";
  }

  if (data.features.length > 0) {
    const exactAreaMeters = area(data);
    const exactAreaFeet = exactAreaMeters * 10.7639;
    const exactAreaAcre = exactAreaMeters / 4047;
    const roundedAreaFeet = exactAreaFeet.toFixed(2);
    const roundedAreaAcre = exactAreaAcre.toFixed(2);
    answerArea.innerHTML =
      roundedAreaAcre + " acres or " + roundedAreaFeet + " sq ft";
  } else {
    answerArea.innerHTML = "";
    answerRadius.innerHTML = "";
    answerPoint.innerHTML = "";
    answerLength.innerHTML = "";
    setMeasurementsVisible(false);
    // if (e.type !== "draw.delete") alert("Click the map to draw a polygon.");
  }
}

export const managementAreaFill = {
  id: "management-area-fill",
  name: "Management area",
  type: "fill",
  source: "management-area",
  "source-layer": "BSEACD_Exclusive_and_Shared_T-655lc2",
  paint: {
    "fill-color": [
      "match",
      ["get", "Territory"],
      ["Shared"],
      "#086aea",
      ["Exclusive"],
      "#f37600",
      "black",
    ],
    "fill-opacity": 0.3,
  },
  layout: {
    visibility: "visible",
  },
  lreProperties: {
    popup: {
      titleField: "Territory",
      excludeFields: [
        "GNIS_ID",
        "LoadDate",
        "Shape_Area",
        "Shape_Leng",
        "TNMID",
      ],
    },
  },
};

export const managementAreaLine = {
  id: "management-area-line",
  name: "Management Area",
  type: "line",
  source: "management-area",
  "source-layer": "BSEACD_Exclusive_and_Shared_T-655lc2",
  paint: {
    "line-color": [
      "match",
      ["get", "Territory"],
      ["Shared"],
      "#086aea",
      ["Exclusive"],
      "#f37600",
      "black",
    ],
    "line-width": 2,
  },
  layout: {
    visibility: "visible",
  },
  lreProperties: {
    popup: {
      titleField: "Territory",
      excludeFields: [
        "GNIS_ID",
        "LoadDate",
        "Shape_Area",
        "Shape_Leng",
        "TNMID",
      ],
    },
  },
};

export const managementZonesFill = {
  id: "management-zones-fill",
  name: "Management Zones",
  type: "fill",
  source: "management-zones",
  "source-layer": "BSEACD_ManagementZones_2016-d74joc",
  paint: {
    "fill-color": [
      "match",
      ["get", "OFFICL_NAM"],
      ["Western Edwards"],
      "#98DF8A",
      ["Eastern Edwards"],
      "#FF9896",
      ["Saline Edwards"],
      "#C5B0D5",
      "black",
    ],
    "fill-opacity": 0.3,
  },
  layout: {
    visibility: "none",
  },
  lreProperties: {
    legendGroup: "BSEACD Management",
    layerGroup: "management-zones",
    popup: {
      titleField: "OFFICL_NAM",
      excludeFields: [
        "GNIS_ID",
        "LoadDate",
        "Shape_Area",
        "Shape_Leng",
        "TNMID",
      ],
    },
  },
};

export const managementZonesLine = {
  id: "management-zones-line",
  name: "Management Zones",
  type: "line",
  source: "management-zones",
  "source-layer": "BSEACD_ManagementZones_2016-d74joc",
  paint: {
    "line-color": [
      "match",
      ["get", "OFFICL_NAM"],
      ["Western Edwards"],
      "#98DF8A",
      ["Eastern Edwards"],
      "#FF9896",
      ["Saline Edwards"],
      "#C5B0D5",
      "black",
    ],
    "line-width": 2,
  },
  layout: {
    visibility: "none",
  },
  lreProperties: {
    legendGroup: "BSEACD Management",
    layerGroup: "management-zones",
    popup: {
      titleField: "OFFICL_NAM",
      excludeFields: [
        "GNIS_ID",
        "LoadDate",
        "Shape_Area",
        "Shape_Leng",
        "TNMID",
      ],
    },
  },
};

export const countiesFill = {
  id: "counties-fill",
  name: "Counties",
  type: "fill",
  source: "counties",
  "source-layer": "TX_County_WGS84-58phgk",
  paint: {
    "fill-color": "#5d5d5d",
    "fill-opacity": 0,
  },
  layout: {
    visibility: "none",
  },
  lreProperties: {
    layerGroup: "counties",
    popup: {
      titleField: "OFFICL_NAM",
      excludeFields: [
        "GNIS_ID",
        "LoadDate",
        "Shape_Area",
        "Shape_Leng",
        "TNMID",
      ],
    },
  },
};

export const countiesLine = {
  id: "counties-line",
  name: "Counties",
  type: "line",
  source: "counties",
  "source-layer": "TX_County_WGS84-58phgk",
  paint: {
    "line-color": "#5d5d5d",
    "line-width": 3,
  },
  layout: {
    visibility: "none",
  },
  lreProperties: {
    layerGroup: "counties",
    popup: {
      titleField: "OFFICL_NAM",
      excludeFields: [
        "GNIS_ID",
        "LoadDate",
        "Shape_Area",
        "Shape_Leng",
        "TNMID",
      ],
    },
  },
};

export const locationsLayer = {
  id: "locations",
  type: "circle",
  source: "locations",
  paint: {
    "circle-radius": 8,
    "circle-color": lineColors.lightBlue,
    "circle-stroke-width": 1,
    "circle-stroke-color": "black",
  },
  lreProperties: {
    popup: {
      titleField: "well_id",
      excludeFields: [
        "well_ndx",
        "icon_ndx",
        "icon_shape",
        "icon_background_color",
        "icon_size",
        "icon_background_size",
        "icon_default_color",
        "is_monitoring",
        "in_district",
        "aquifer_group",
        "has_water_levels",
        "Production Data",
        "has_wq_data",
        "location_geometry",
        "well_reference_name",
        "dist_property_line_ft",
        "dist_septic_ft",
        "bore_diam_in",
        "pump_depth_ft",
        "pump_size_hp",
        "pump_type",
        "pump_gpm",
        "notes_grout_types_methods",
        "created_timestamp",
        "created_by",
        "updated_timestamp",
        "updated_by",
        "well_class_ndx",
        "well_subclass_ndx",
        "primary_use_ndx",
        "secondary_use_ndx",
        "mgmtzone_ndx",
        "well_status_ndx",
        "monitoring_status_ndx",
        "district_ndx",
        "county_ndx",
        "aq_completion_ndx",
        "aquifer_ndx",
        "aq_setting_ndx",
        "driller_ndx",
        "watershed_ndx",
        "aquifer_group_ndx",
        "formation_ndx",
      ],
      adminOnlyFields: [
        "is_index_well",
        "aquifer_setting",
        "aquifer",
        "available_logs",
        "inspections_info",
        "enforcements_info",
        "driller_name",
        "directions_to_well",
      ],
    },
  },
};

export const locationsLabelsLayer = {
  id: "locations-labels",
  type: "symbol",
  source: "locations",
  minzoom: 12,
  layout: {
    "text-field": ["get", "cuwcd_well_number"],
    "text-offset": [0, -1.2],
    "text-size": 14,
    "text-font": ["literal", ["Roboto Black", "Arial Unicode MS Bold"]],
  },
  paint: {
    "text-color": "rgb(49,49,49)",
    "text-halo-color": "rgba(255,255,255,1)",
    "text-halo-width": 3,
  },
};

export const locationsRowTitles = {
  cuwcd_well_number: "CUWCD Well #",
  management_zone: "Management Zone",
  exempt: "Exempt?",
  well_name: "Well Name",
  state_well_number: "State Well #",
  well_status: "Well Status",
  source_aquifer: "Source Aquifer",
  well_depth_ft: "Well Depth (ft)",
  elevation_ftabmsl: "Elevation (ft msl)",
  screen_top_depth_ft: "Screen Top Depth (ft)",
  screen_bottom_depth_ft: "Screen Bottom Depth (ft)",
  primary_use: "Primary Use",
  secondary_use: "Secondary Use",
  agg_system_name: "Aggregation System",
  permit_number: "Permit #",
  well_owner: "Well Owner",
  well_owner_address: "Well Owner Address",
  well_owner_phone: "Well Owner Phone",
  well_owner_email: "Well Owner Email",
  well_contact: "Well Contact",
  well_contact_address: "Well Contact Address",
  well_contact_phone: "Well Contact Phone",
  well_contact_email: "Well Contact Email",
  driller: "Driller",
  date_drilled: "Date Drilled",
  drillers_log: "Drillers Log?",
  general_notes: "General Notes",
  well_remarks: "Well Remarks",
  count_production: "Count of Production Entries",
  count_waterlevels: "Count of Water Levels Entries",
  count_wqdata: "Count of WQ Data Entries",
  longitude_dd: "Longitude (dd)",
  latitude_dd: "Latitude (dd)",
  registration_notes: "Registration Notes",
  registration_date: "Registration Date",
  editor_name: "Editor",
  last_edited_date: "Last Edited Date",
  list_of_attachments: "List of Attachments",
  authorized_users: "Authorized Users",
  id: "Location ID",
  is_permitted: "Is Permitted?",
  is_exempt: "Is Exempt?",
  is_monitoring: "Is Monitoring?",
  has_production: "Has Production?",
  has_waterlevels: "Has Water Levels?",
  has_wqdata: "Has Water Quality Data?",
  well_ndx: "Location Index",
  location_geometry: "Geometry",
  is_well_owner: "Well Owner?",
  well_type: "Well Type",
  tableData: "Material Table Id",
};
