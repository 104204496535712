import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { NavLink, useParams } from "react-router-dom";

import BasicMeterInfo from "./BasicMeterInfo";
import { useStateIfMounted } from "use-state-if-mounted";
import ManageAssociatedWells from "./ManageAssociatedWells";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

function MeterManagement() {
  let { id } = useParams();
  const [selectedMeter, setSelectedMeter] = useStateIfMounted(id);

  const [triggerRefetch, setTriggerRefetch] = React.useState(false);
  const handleTriggerRefetch = () => setTriggerRefetch(!triggerRefetch);
  const handleRefetch = () => {
    handleTriggerRefetch();
  };

  return (
    <React.Fragment>
      <Helmet title="Add and Manage Well Meter" />
      <Typography variant="h3" gutterBottom display="inline">
        Add and Manage Well Meter
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Link
          component={NavLink}
          exact
          to="/data-management/meter-readings/add-manage-meters"
        >
          Well Meters
        </Link>
        <Typography>Edit Meter</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <BasicMeterInfo
        selectedMeter={selectedMeter}
        setSelectedMeter={setSelectedMeter}
        isEditMode={true}
        refetchMeters={handleRefetch}
        triggerRefetch={triggerRefetch}
      />
      <ManageAssociatedWells selectedMeter={selectedMeter} isEditMode={true} />
    </React.Fragment>
  );
}

export default MeterManagement;
