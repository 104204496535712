import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { INIT_STYLE_VALUES } from "../../constants";

const useLayerStyles = ({ onLayerStyleChange, isMapLoaded }) => {
  const [styleValues, setStyleValues] = useState(INIT_STYLE_VALUES);

  const { data } = useQuery(
    ["filterData2"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/filters`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setStyleValues((prevState) => ({
        ...prevState,
        // aquiferGroups: {
        //   loaded: true,
        //   ...prevState.aquiferGroups,
        //   paint: {
        //     "icon-color": [
        //       "match",
        //       ["get", "major_aquifer"],
        //       ...data.aquiferGroups.reduce((acc, val) => {
        //         acc.push([val.value]);
        //         acc.push(val.color);
        //         return acc;
        //       }, []),
        //       "#000000",
        //     ],
        //   },
        // },
        wellClass: {
          ...prevState.wellClass,
          paint: {
            "icon-color": [
              "match",
              ["get", "well_class"],
              ...data.wellClass.reduce((acc, val) => {
                acc.push([val.value]);
                acc.push(val.color);
                return acc;
              }, []),
              "#000000",
            ],
          },
        },
        wellSubclass: {
          ...prevState.wellSubclass,
          paint: {
            "icon-color": [
              "match",
              ["get", "well_subclass"],
              ...data.wellSubclass.reduce((acc, val) => {
                acc.push([val.value]);
                acc.push(val.color);
                return acc;
              }, []),
              "#000000",
            ],
          },
        },
        monitoringStatus: {
          ...prevState.monitoringStatus,
          paint: {
            "icon-color": [
              "match",
              ["get", "monitoring_status"],
              ...data.monitoringStatus.reduce((acc, val) => {
                acc.push([val.value]);
                acc.push(val.color);
                return acc;
              }, []),
              "#000000",
            ],
          },
        },
        primaryUse: {
          ...prevState.primaryUse,
          paint: {
            "icon-color": [
              "match",
              ["get", "primary_use"],
              ...data.primaryUse.reduce((acc, val) => {
                acc.push([val.value]);
                acc.push(val.color);
                return acc;
              }, []),
              "#000000",
            ],
          },
        },
        managementZone: {
          ...prevState.managementZone,
          paint: {
            "icon-color": [
              "match",
              ["get", "management_zone"],
              ...data.managementZone.reduce((acc, val) => {
                acc.push([val.value]);
                acc.push(val.color);
                return acc;
              }, []),
              "#000000",
            ],
          },
        },
        aquiferCompletion: {
          loaded: true,
          ...prevState.aquiferCompletion,
          paint: {
            "icon-color": [
              "match",
              ["get", "aquifer_completion"],
              ...data.aquiferCompletion.reduce((acc, val) => {
                acc.push([val.value]);
                acc.push(val.color);
                return acc;
              }, []),
              "#000000",
            ],
          },
        },
        aquiferSetting: {
          ...prevState.aquiferSetting,
          paint: {
            "icon-color": [
              "match",
              ["get", "aquifer_setting"],
              ...data.aquiferSetting.reduce((acc, val) => {
                acc.push([val.value]);
                acc.push(val.color);
                return acc;
              }, []),
              "#000000",
            ],
          },
        },

        // wellDepth: {
        //   ...prevState.wellDepth,
        //   paint: {
        //     "icon-color": [
        //       "case",
        //       ["<", ["get", "well_depth_ft"], 100],
        //       "#FE0000",
        //       ["<", ["get", "well_depth_ft"], 300],
        //       "#FFFB00",
        //       ["<", ["get", "well_depth_ft"], 500],
        //       "#FFA500",
        //       ["<", ["get", "well_depth_ft"], 700],
        //       "#009A00",
        //       ["<", ["get", "well_depth_ft"], 900],
        //       "#0030FF",
        //       [">=", ["get", "well_depth_ft"], 900],
        //       "#800080",
        //       "#000000",
        //     ],
        //   },
        // },
      }));
    }
  }, [data]);

  const [activeStyle, setActiveStyle] = useState(styleValues.aquiferCompletion);

  const styleOptions = Object.entries(styleValues).map(([key, value]) => ({
    display: value.name,
    value: key,
  }));
  const handleActiveStyle = (name) => {
    setActiveStyle(styleValues[name]);
    onLayerStyleChange(styleValues[name]);
  };

  useEffect(() => {
    if (isMapLoaded && styleValues.aquiferCompletion.loaded) {
      handleActiveStyle("aquiferCompletion");
    }
  }, [styleValues, isMapLoaded]); //eslint-disable-line

  return {
    activeStyle,
    handleActiveStyle,
    styleOptions,
    styleValues,
  };
};

export default useLayerStyles;
