import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import Loader from "../../../../components/Loader";
import { TableWrapper } from "../wellsManagement/utils";
import { useMonthlyDistributions } from "./useMonthlyDistributions";
import DataAdminTableSimple from "../../../../components/DataAdminTableSimple";

function MonthlyDistributions({
  selectedPermit,
  triggerRefetch,
  isEditMode = false,
}) {
  const {
    isLoading,
    error,
    refetch,
    data,
    MONTHLY_DISTRIBUTIONS_TABLE_COLUMNS,
    currentValue,
  } = useMonthlyDistributions({ selectedPermit, triggerRefetch });

  const handleRefetch = () => {
    refetch().then();
  };

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading && (
        <Paper style={{ width: "100%", margin: "8px 0" }}>
          <Loader />
        </Paper>
      )}
      <Accordion defaultExpanded={isEditMode} disabled={!selectedPermit}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">
              Monthly Allocation Distributions
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <TableWrapper>
            <Typography variant="h4" gutterBottom>
              Previous Saved Distributed Percent:{" "}
              <strong style={{ color: currentValue === 100 ? "green" : "red" }}>
                {currentValue}%
              </strong>
            </Typography>
            <DataAdminTableSimple
              pageSize={12}
              isLoading={isLoading}
              label="Monthly Allocation Distributions"
              columns={MONTHLY_DISTRIBUTIONS_TABLE_COLUMNS}
              data={data}
              height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 156px)"
              endpoint="data-permits-monthly-distributions"
              ndxField="ndx"
              handleRefresh={() => handleRefetch()}
            />
          </TableWrapper>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default MonthlyDistributions;
