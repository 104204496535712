import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import useFetchData from "../../../../../hooks/useFetchData";
import { DEFAULT_WELL_DETAILS_STATE } from "../constants";
import { useStateIfMounted } from "use-state-if-mounted";

export const useWellDetails = (selectedWell) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useStateIfMounted(
    DEFAULT_WELL_DETAILS_STATE
  );
  const [isDirty, setIsDirty] = useStateIfMounted(false);

  const handleUpdateState = (name, value) => {
    if (!isDirty) setIsDirty(true);
    setEditorState((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const handleResetState = () => {
    setIsDirty(false);
    if (selectedWell) {
      setEditorState(data);
    } else {
      setEditorState(DEFAULT_WELL_DETAILS_STATE);
    }
  };

  const { data, isFetching, error, refetch } = useQuery(
    ["list-wells-details", selectedWell],
    async () => {
      if (!selectedWell) {
        setIsDirty(false);
        setEditorState(DEFAULT_WELL_DETAILS_STATE);
        return {};
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-wells-details/${selectedWell}`,
          { headers }
        );
        setIsDirty(false);
        setEditorState(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: associatedPermitsTableData,
    isFetching: associatedPermitsTableIsFetching,
    error: associatedPermitsTableError,
    refetch: associatedPermitsTableRefetch,
  } = useQuery(
    ["ui-list-wm-associated-permits", selectedWell],
    async () => {
      if (!selectedWell) {
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-list-wm-associated-permits/${selectedWell}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: otherOwnerWellsTableData,
    isFetching: otherOwnerWellsTableIsFetching,
    error: otherOwnerWellsTableError,
    refetch: otherOwnerWellsTableRefetch,
  } = useQuery(
    ["ui-list-wm-other-owner-wells", selectedWell],
    async () => {
      if (!selectedWell) {
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-list-wm-other-owner-wells/${selectedWell}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: linkedMetersTableData,
    isFetching: linkedMetersTableIsFetching,
    error: linkedMetersTableError,
    refetch: linkedMetersTableRefetch,
  } = useQuery(
    ["ui-list-wm-associated-meters-active", selectedWell],
    async () => {
      if (!selectedWell) {
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-list-wm-associated-meters-active/${selectedWell}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [secondaryWellUseLookup] = useFetchData(
    "ui-list-wm-secondary-well-use",
    [],
    true
  );
  const [managementZoneLookup] = useFetchData(
    "ui-list-wm-management-zone",
    [],
    true
  );
  const [wellStatusLookup] = useFetchData("ui-list-wm-well-status", [], true);
  const [monitoringStatusLookup] = useFetchData(
    "ui-list-wm-well-monitoring-status",
    [],
    true
  );

  return {
    data,
    isLoading: isFetching,
    error,
    refetch,
    isDirty,
    lookups: {
      secondaryWellUse: secondaryWellUseLookup,
      managementZone: managementZoneLookup,
      wellStatus: wellStatusLookup,
      monitoringStatus: monitoringStatusLookup,
    },
    tables: {
      associatedPermits: {
        data: associatedPermitsTableData,
        isFetching: associatedPermitsTableIsFetching,
        error: associatedPermitsTableError,
        refetch: associatedPermitsTableRefetch,
      },
      otherOwnerWells: {
        data: otherOwnerWellsTableData,
        isFetching: otherOwnerWellsTableIsFetching,
        error: otherOwnerWellsTableError,
        refetch: otherOwnerWellsTableRefetch,
      },
      siteInspectionRecords: {
        data: [],
        isFetching: false,
        error: undefined,
        refetch: () => {},
      },
      enforcementRecords: {
        data: [],
        isFetching: false,
        error: undefined,
        refetch: () => {},
      },
      linkedMeters: {
        data: linkedMetersTableData,
        isFetching: linkedMetersTableIsFetching,
        error: linkedMetersTableError,
        refetch: linkedMetersTableRefetch,
      },
    },
    handleUpdateState,
    handleResetState,
    state: editorState,
    setter: setEditorState,
  };
};
