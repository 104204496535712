import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from "@material-ui/core";
import Loader from "../Loader";
import styled from "styled-components/macro";

const SearchButtonGroup = styled(ButtonGroup)`
  height: 53px;
  .MuiButton-root {
    width: 80px;
  }
`;

const SearchButton = styled(Button)`
  &:first-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const SimpleSelect = React.memo(
  ({
    name,
    label,
    valueField,
    displayField,
    options,
    isLoading,
    width = "100%",
    multiple = false,
    showSelectButtons = true,
  }) => {
    const {
      control,
      setValue,
      trigger,
      formState: { errors },
    } = useFormContext();

    const handleSelectAll = async () => {
      setValue(
        name,
        options?.map((option) => option[valueField]),
        { shouldDirty: true }
      );

      if (!!Object.keys(errors).length) {
        await trigger(name);
      }
    };

    const handleDeselectAll = async () => {
      setValue(name, [], { shouldDirty: true });

      if (!!Object.keys(errors).length) {
        await trigger(name);
      }
    };

    const selectStyles =
      showSelectButtons && multiple
        ? {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }
        : {};

    const isDisabled = options?.length === 0 || isLoading;

    if (isLoading) return <Loader />;
    return (
      <Box display="flex" flexDirection="row" width={width} alignItems="start">
        <FormControl variant="outlined" fullWidth error={Boolean(errors[name])}>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                labelId={`${name}-label`}
                id={name}
                multiple={multiple}
                label={label}
                error={Boolean(errors[name])}
                value={multiple ? field.value || [] : field.value}
                style={selectStyles}
              >
                {options?.map((option) => (
                  <MenuItem key={option[valueField]} value={option[valueField]}>
                    {option[displayField]}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {!!errors[name] && (
            <FormHelperText>
              {!options?.length
                ? "No options available"
                : errors[name]?.message}
            </FormHelperText>
          )}
        </FormControl>
        {showSelectButtons && multiple && (
          <SearchButtonGroup
            variant="outlined"
            color="primary"
            disabled={isDisabled}
          >
            <SearchButton variant="contained" onClick={handleSelectAll}>
              + All
            </SearchButton>
            <SearchButton onClick={handleDeselectAll}>- None</SearchButton>
          </SearchButtonGroup>
        )}
      </Box>
    );
  }
);
