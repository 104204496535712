import { Button, Link } from "@material-ui/core";

export const DEFAULT_PERMIT_INFO_STATE = {
  rolo_ndx: null,
  permit_name: null,
  permit_ndx: null,
  permit_number: null,
  permit_type_ndx: null,
  aquifer_group_ndx: null,
  permit_year: null,
  primary_use_ndx: null,
  secondary_use_ndx: null,
  special_provisions: null,
  notes: null,
  created_timestamp: null,
  created_by: null,
  updated_timestamp: null,
  updated_by: null,
  removed_timestamp: null,
  removed_by: null,
  removed: false,
  permit_status_ndx: null,
  tmp_permittee_name: null,
  tmp_permittee_email: null,
  tmp_permittee_phone: null,
  tmp_permittee_zip: null,
  tmp_permittee_address: null,
  tmp_permittee_city: null,
  tmp_permittee_state: null,
  tmp_permittee_organization: null,
  tmp_permittee_precinct_ndx: null,
  permittee_add_new: false,
  is_terminated: false,
  authorized_volume_gal: null,
  should_remove: false,
  alternate_rolo_ndx: null,
  billing_rolo_ndx: null,
  meter_rolo_ndx: null,
  allocation_distribution_ndx: null,
};

export const DEFAULT_ATTACHED_DOCUMENTS_STATE = {
  notes: null,
  tags: null,
};

export const LINKED_WELLS_TABLE_COLUMNS = [
  {
    title: "Wells Under this Permit",
    field: "well_ndx",
    render: (rowData) => (
      <Button
        component={Link}
        target="_blank"
        rel="noreferrer noopener"
        href={`/data-management/wells/wells-management/${rowData.well_ndx}`}
        variant="contained"
        color="secondary"
        style={{ width: "100%" }}
        size="small"
      >
        {rowData.linked_well_identifier}
      </Button>
    ),
  },
];

export const LINKED_PERMITS_TABLE_COLUMNS = [
  {
    title: "Other Permits Owned by this Permittee",
    field: "associated_permit_ndx",
    render: (rowData) => (
      <Button
        component={Link}
        target="_blank"
        rel="noreferrer noopener"
        href={`/data-management/permit-records/permits-management/${rowData.associated_permit_ndx}`}
        variant="contained"
        color="secondary"
        style={{ width: "100%" }}
        size="small"
      >
        {rowData.associated_permits_info}
      </Button>
    ),
  },
];

export const LINKED_METERS_TABLE_COLUMNS = [
  {
    title: "Meters Under this Permit",
    field: "meter_ndx",
    render: (rowData) => (
      <Button
        component={Link}
        target="_blank"
        rel="noreferrer noopener"
        href={`/data-management/meter-readings/add-manage-meters/${rowData.meter_ndx}`}
        variant="contained"
        color="secondary"
        style={{ width: "100%" }}
        size="small"
      >
        {rowData.meter_id}
      </Button>
    ),
  },
];
