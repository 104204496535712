import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import Loader from "../../../../../components/Loader";
import DataAdminTable from "../../../../../components/DataAdminTable";
import { useWellConstruction } from "../hooks/useWellConstruction";
import { TableWrapper } from "../utils";

function WellConstruction({
  selectedWell,
  isEditMode = false,
  selectedWellLabel,
}) {
  const {
    isLoading,
    error,
    refetch,
    state,
    setter,
    WELL_CONSTRUCTION_TABLE_COLUMNS,
  } = useWellConstruction(selectedWell);

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading && (
        <Paper style={{ width: "100%", margin: "8px 0" }}>
          <Loader />
        </Paper>
      )}
      <Accordion defaultExpanded={isEditMode} disabled={!selectedWell}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">{`Well Construction Details ${
              selectedWellLabel ? `(${selectedWellLabel})` : ""
            }`}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <TableWrapper>
            <DataAdminTable
              pageSize={10}
              isLoading={isLoading}
              label="TITLE"
              columns={WELL_CONSTRUCTION_TABLE_COLUMNS}
              data={state}
              height="200px"
              updateHandler={setter}
              endpoint="list-wells-completion-components"
              ndxField="ndx"
              selectedNdx={selectedWell}
              handleRefresh={refetch}
            />
          </TableWrapper>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default WellConstruction;
