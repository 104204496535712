import React, { useCallback, useMemo, useState } from "react";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Divider as MuiDivider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import useFetchData from "../../../hooks/useFetchData";
import Loader from "../../../components/Loader";
import { useMutation, useQueryClient } from "react-query";
import MaterialTable from "material-table";
import {
  copyToClipboard,
  dateFormatter,
  filterLookupBasedOnTableData,
  formatter,
} from "../../../utils";
import CopyIcon from "@material-ui/icons/FileCopy";
import { useApp } from "../../../AppProvider";
import { ImportExport, Refresh } from "@material-ui/icons";
import useFormSubmitStatus from "../../../hooks/useFormSubmitStatus";
import FormSnackbar from "../../../components/FormSnackbar";
import InfoIcon from "@material-ui/icons/Info";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const defaultFilters = {
  tiers: [0, 1, 2, 3],
  aquifers: ["Edwards", "Trinity", "Other"],
  isOverpumping: false,
  startYear: new Date().getFullYear().toString(),
  endYear: new Date().getFullYear().toString(),
  startMonth: 1,
  endMonth: 12,
  hasSubmitted: false,
};

const useUpdateDateFilters = (setFilters) => {
  return useCallback(
    (monthsBackStart, monthsBackEnd) => {
      const currentDate = new Date(new Date().toISOString().split("T")[0]);

      currentDate.setUTCMonth(currentDate.getUTCMonth() - monthsBackEnd);
      const endYear = currentDate.getUTCFullYear();
      const endMonth = currentDate.getUTCMonth() + 1;

      const startDate = new Date(new Date().toISOString().split("T")[0]);
      startDate.setUTCMonth(startDate.getUTCMonth() - monthsBackStart);
      const startYear = startDate.getUTCFullYear();
      const startMonth = startDate.getUTCMonth() + 1;

      setFilters((prevState) => ({
        ...prevState,
        startYear: startYear.toString(),
        startMonth: startMonth,
        endYear: endYear.toString(),
        endMonth: endMonth,
      }));
    },
    [setFilters]
  );
};

const DroughtComplianceReport = () => {
  const {
    setWaitingState,
    formSubmitting,
    snackbarOpen,
    snackbarError,
    handleSnackbarClose,
  } = useFormSubmitStatus();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const { doToast } = useApp();

  const [filters, setFilters] = useState(defaultFilters);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);

  const handleUpdateState = (name, value) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const updateDateFilters = useUpdateDateFilters(setFilters);

  const includeCurrentAndPreviousFiveMonths = () => updateDateFilters(5, 0);
  const includePreviousSixMonths = () => updateDateFilters(6, 1);

  const updateDroughtCompliance = async (updatedFilters) => {
    const token = await getAccessTokenSilently();
    return axios.put(
      `${process.env.REACT_APP_ENDPOINT}/api/drought-compliance`,
      updatedFilters,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  // useMutation hook from react-query to handle the PUT request
  const { mutate, isLoading, isError, error, data } = useMutation(
    updateDroughtCompliance,
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries("drought-compliance");
        handleUpdateState("hasSubmitted", true);
      },
    }
  );

  const handleSubmitFilters = () => {
    // Create a payload from the filters state
    const payload = {
      tiers: filters.tiers,
      is_overpumped: filters.isOverpumping,
      start_cal_year: filters.startYear,
      start_cal_month: filters.startMonth,
      end_cal_year: filters.endYear,
      end_cal_month: filters.endMonth,
      aq_group_names: filters.aquifers,
    };

    // Call the mutate function to trigger the PUT request
    mutate(payload);
  };

  const [lastRunDate, isLastRunDateLoading, handleDataUpdate] = useFetchData(
    "drought-compliance/last-run-date"
  );
  const [tiersLookup] = useFetchData("ui-list-permit-tiers");
  const [aquifersLookup] = useFetchData("aquifer-group-summary-names");
  const [yearsLookup] = useFetchData(
    "ui-report-drought-compliance-lookup-years"
  );
  const [monthsLookup] = useFetchData("list-months");
  const [groupingLookup] = useFetchData(
    "ui-report-drought-compliance-static-owner-list"
  );
  const groupingLookupForTable = useMemo(
    () =>
      groupingLookup.reduce((acc, item) => {
        acc[item.grouping_ndx] = item.grouping_name;
        return acc;
      }, {}),
    [groupingLookup]
  );

  const handleRefresh = async () => {
    setWaitingState("in progress");
    try {
      setIsRefreshLoading((state) => !state);
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      axios.post(
        `${process.env.REACT_APP_ENDPOINT}/api/drought-compliance/refresh`,
        {},
        { headers }
      );
      // await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/depletions/refresh`, {}, { headers });
      let count = 0;
      let timeoutHandle = () => {
        let newDate = "";
        const fetchNewDate = async () => {
          const response = await axios.get(
            `${process.env.REACT_APP_ENDPOINT}/api/drought-compliance/last-run-date`,
            {
              headers,
            }
          );
          newDate = response.data;
        };
        fetchNewDate();

        setTimeout(() => {
          if (newDate.last_run === lastRunDate.last_run) {
            //give fail notice if the request takes longer than 3 minutes
            if (count > 36) {
              console.error(
                "Query taking longer than normal. Timeout invoked."
              );
              setIsRefreshLoading((state) => !state);
              setWaitingState("complete", "error");
              return;
            }
            count++;
            timeoutHandle();
          } else {
            handleDataUpdate(newDate);
            setWaitingState("complete", "no error");
            setIsRefreshLoading((state) => !state);
          }
        }, 5000);
      };
      timeoutHandle();
    } catch (err) {
      console.error(err);
      setIsRefreshLoading((state) => !state);
      setWaitingState("complete", "error");
    }
  };

  const handleExport = async () => {
    setWaitingState("in progress");
    try {
      setIsRefreshLoading((state) => !state);
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/api/drought-compliance/export-for-connected-workbooks`,
        {},
        { headers }
      );

      setWaitingState("complete", "no error");
      setIsRefreshLoading((state) => !state);
      handleUpdateState("hasSubmitted", false);
    } catch (err) {
      console.error(err);
      setIsRefreshLoading((state) => !state);
      setWaitingState("complete", "error");
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "Tier",
        field: "permit_tier_name",
      },
      {
        title: "Owner/Permit",
        field: "grouping_ndx",
        lookup: filterLookupBasedOnTableData(
          data?.data?.reports || [],
          groupingLookupForTable,
          "grouping_ndx"
        ),
      },
      {
        title: "Month",
        field: "mo",
      },
      {
        title: "Drought Stage",
        field: "drought_stage",
      },
      {
        title: "Metered Gallons",
        field: "mo_metered_gallons",
        filtering: false,
        render: (rowData) => formatter(rowData.mo_metered_gallons, 0),
        cellStyle: {
          backgroundColor: "lightCyan",
        },
      },
      {
        title: "Baseline Monthly",
        field: "mo_target",
        render: (rowData) => formatter(rowData.mo_target, 0),
        filtering: false,
      },
      {
        title: "Monthly Drought Target",
        field: "mo_reduced_target",
        filtering: false,
        render: (rowData) => formatter(rowData.mo_reduced_target, 0),
      },
      {
        title: "Monthly Drought Reduction",
        field: "drought_reduction",
        render: (rowData) => formatter(rowData.drought_reduction, 0),
        filtering: false,
        cellStyle: {
          backgroundColor: "#F5F5DC",
        },
      },
      {
        title: "% Over/Under Target",
        field: "pct_of_month",
        filtering: false,
        render: (rowData) => `${rowData.pct_of_month}%`,
        cellStyle: (value) => {
          if (value <= 0) {
            // no styling applied
            return {};
          } else if (value > 0 && value <= 25) {
            return { backgroundColor: "PaleGreen" };
          } else if (value > 25 && value <= 100) {
            return { backgroundColor: "Yellow" };
          } else if (value > 100) {
            return { backgroundColor: "Orange" };
          }
        },
      },
      {
        title: "Permitted Pumpage, Gal",
        field: "permitted_gallons",
        render: (rowData) => formatter(rowData.permitted_gallons, 0),
        filtering: false,
      },
      {
        title: "Permitted with Drought Reduction",
        field: "reduced_permitted_gal",
        render: (rowData) => formatter(rowData.reduced_permitted_gal, 0),
        filtering: false,
        cellStyle: {
          backgroundColor: "#F5F5DC",
        },
      },
      {
        title: "YTD Metered Gallons",
        field: "ytd_metered_gal",
        render: (rowData) => formatter(rowData.ytd_metered_gal, 0),
        filtering: false,
        cellStyle: {
          backgroundColor: "#F5F5DC",
        },
      },
      {
        title: "% of Permitted Volumepct_of_annual Pumped",
        field: "pct_of_annual",
        render: (rowData) => `${rowData.pct_of_annual}%`,
        filtering: false,
      },
      {
        title: "% of Permitted Volume w/ Drought Reduction",
        field: "pct_of_reduced_annual",
        render: (rowData) => `${rowData.pct_of_reduced_annual}%`,
        filtering: false,
        cellStyle: {
          backgroundColor: "#F5F5DC",
        },
      },
    ],
    [groupingLookupForTable, data]
  );

  if (isError) return "An error has occurred: " + error.message;
  return (
    <>
      <Modal open={isRefreshLoading}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={"/static/img/loading.svg"}
            style={{ maxWidth: "150px" }}
            alt="loading"
          />
        </div>
      </Modal>

      <FormSnackbar
        open={snackbarOpen}
        error={snackbarError}
        handleClose={handleSnackbarClose}
        successMessage="Data successfully refreshed."
        errorMessage="Error: Data could not be refreshed."
      />

      <Helmet title="Drought Compliance Report" />
      <Typography variant="h3" gutterBottom display="inline">
        Drought Compliance Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Drought Compliance</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Paper style={{ padding: "10px" }}>
        <Box p={2} mb={4} bgcolor="rgb(215, 240, 217)" borderRadius={4}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box flexGrow={1} mb={2}>
                <Typography variant="h6">
                  Refresh Report Data (Optional)
                </Typography>
              </Box>

              <Typography variant="body1" paragraph>
                Click to refresh the data behind these reports. This query could
                take up to a minute to complete.
              </Typography>
              <Typography variant="body2">Last Run</Typography>
              <Typography variant="body1" paragraph>
                {!isLastRunDateLoading && lastRunDate?.last_run
                  ? dateFormatter(
                      new Date(lastRunDate.last_run),
                      "MM/DD/YYYY, h:mm A"
                    )
                  : "N/A"}
              </Typography>
              <Box style={{ alignSelf: "flex-start" }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Refresh />}
                  disabled={formSubmitting}
                  onClick={handleRefresh}
                >
                  Refresh to Add Recent Entries
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box flexGrow={1} mb={2}>
                <Typography variant="h6">
                  Export data with these filters for the linked Excel Drought
                  Compliance Report.
                </Typography>
              </Box>

              <Typography variant="body1" paragraph>
                Note that the Excel report will include the last 6 months of the
                period selected below.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>
                  Click the Submit Filters button below before clicking Export
                  to Excel, if the filters have been changed.
                </strong>
              </Typography>
              <Box style={{ alignSelf: "flex-start" }} mb={3}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ImportExport />}
                  disabled={formSubmitting || !filters.hasSubmitted}
                  onClick={handleExport}
                >
                  Export to Excel
                </Button>
              </Box>
              <Box style={{ alignSelf: "flex-start" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<InfoIcon color="secondary" />}
                  href="https://drive.google.com/file/d/1VxPuZu_3UNeu2mUc7OP-AMUcdBvZxcib/view?usp=drive_link"
                  target="_blank"
                  style={{ borderWidth: "2px" }}
                >
                  How to archive versions of the linked Excel report
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography color="secondary" variant="subtitle1">
              Quick Set Options
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={includeCurrentAndPreviousFiveMonths}
              color="primary"
              variant="outlined"
            >
              6-Months ending THIS month
            </Button>

            <Button
              onClick={includePreviousSixMonths}
              color="primary"
              variant="outlined"
              style={{ marginLeft: 10 }}
            >
              6-Months ending LAST month
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography color="secondary" variant="subtitle1">
              Filters
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              required
              error={!filters.tiers.length}
            >
              {!tiersLookup.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="tiers">Include Tiers</InputLabel>
                  <Select
                    multiple
                    labelId="tierss"
                    id="tiers"
                    label="Include Tiers"
                    name="permit_tier_ndx"
                    value={filters?.tiers ?? []}
                    onChange={(e) => {
                      handleUpdateState("tiers", e.target.value);
                    }}
                  >
                    {tiersLookup.map((option) => (
                      <MenuItem
                        key={option.permit_tier_ndx}
                        value={option.permit_tier_ndx}
                      >
                        {option.permit_tier_name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              {!filters.tiers.length > 0 && (
                <FormHelperText error>*Required field</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              required
              error={!filters.aquifers.length}
            >
              {!aquifersLookup.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="aquifers">Include Aquifers</InputLabel>
                  <Select
                    multiple
                    labelId="aquifers"
                    id="aquifers"
                    label="Include Aquifers"
                    name="aq_group_summary_name"
                    value={filters?.aquifers ?? []}
                    onChange={(e) => {
                      handleUpdateState("aquifers", e.target.value);
                    }}
                  >
                    {aquifersLookup.map((option) => (
                      <MenuItem
                        key={option.aq_group_summary_name}
                        value={option.aq_group_summary_name}
                      >
                        {option.aq_group_summary_name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              {!filters.aquifers.length > 0 && (
                <FormHelperText error>*Required field</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid
            item
            sm={12}
            xl={4}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.isOverpumping}
                  onChange={(e) =>
                    handleUpdateState("isOverpumping", e.target.checked)
                  }
                />
              }
              label={
                <span
                // style={{ color: "rgba(0, 0, 0, 0.87)" }}
                >
                  Only Show Overpumping Permittees
                </span>
              }
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            xl={2}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "100%", backgroundColor: "#fff7e2" }}
              required
              error={filters.startYear > filters.endYear}
            >
              {!yearsLookup.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="tiers">Start Year</InputLabel>
                  <Select
                    labelId="startYear"
                    id="startYear"
                    label="Start Year"
                    name="startYear"
                    value={filters?.startYear ?? ""}
                    onChange={(e) => {
                      handleUpdateState("startYear", e.target.value);
                    }}
                  >
                    {yearsLookup.map((option) => (
                      <MenuItem key={option.c_year} value={option.c_year}>
                        {option.c_year}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              {filters.startYear > filters.endYear && (
                <FormHelperText error>*Invalid date</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "100%", backgroundColor: "#fff7e2" }}
              required
              error={
                filters.startYear === filters.endYear &&
                filters.startMonth > filters.endMonth
              }
            >
              {!monthsLookup.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="tiers">Start Month</InputLabel>
                  <Select
                    labelId="startMonth"
                    id="startMonth"
                    label="Start Month"
                    name="startMonth"
                    value={filters?.startMonth ?? ""}
                    onChange={(e) => {
                      handleUpdateState("startMonth", e.target.value);
                    }}
                  >
                    {monthsLookup.map((option) => (
                      <MenuItem
                        key={option.month_number}
                        value={option.month_number}
                      >
                        {option.month_name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              {filters.startYear === filters.endYear &&
                filters.startMonth > filters.endMonth && (
                  <FormHelperText error>*Invalid date</FormHelperText>
                )}
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            xl={2}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              required
              error={filters.endYear < filters.startYear}
            >
              {!yearsLookup.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="tiers">End Year</InputLabel>
                  <Select
                    labelId="endYear"
                    id="endYear"
                    label="End Year"
                    name="endYear"
                    value={filters?.endYear ?? ""}
                    onChange={(e) => {
                      handleUpdateState("endYear", e.target.value);
                    }}
                  >
                    {yearsLookup.map((option) => (
                      <MenuItem key={option.c_year} value={option.c_year}>
                        {option.c_year}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              {filters.endYear < filters.startYear && (
                <FormHelperText error>*Invalid date</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              required
              error={
                filters.startYear === filters.endYear &&
                filters.endMonth < filters.startMonth
              }
            >
              {!monthsLookup.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="tiers">End Month</InputLabel>
                  <Select
                    labelId="endMonth"
                    id="endMonth"
                    label="End Month"
                    name="endMonth"
                    value={filters?.endMonth ?? ""}
                    onChange={(e) => {
                      handleUpdateState("endMonth", e.target.value);
                    }}
                  >
                    {monthsLookup.map((option) => (
                      <MenuItem
                        key={option.month_number}
                        value={option.month_number}
                      >
                        {option.month_name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              {filters.startYear === filters.endYear &&
                filters.endMonth < filters.startMonth && (
                  <FormHelperText error>*Invalid date</FormHelperText>
                )}
            </FormControl>
          </Grid>
        </Grid>

        <Divider style={{ margin: "20px" }} />
        <Box style={{ display: "flex", justifyContent: "end" }}>
          <Button onClick={() => setFilters(defaultFilters)}>Reset</Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmitFilters}
            disabled={
              !filters.tiers.length ||
              !filters.aquifers.length ||
              filters.startYear > filters.endYear ||
              (filters.startYear === filters.endYear &&
                filters.startMonth > filters.endMonth) ||
              filters.endYear < filters.startYear ||
              (filters.startYear === filters.endYear &&
                filters.endMonth < filters.startMonth) ||
              isLoading
            }
            style={{ width: "230px" }}
          >
            {isLoading ? "Loading..." : "Submit Filters to Build Report"}
          </Button>
        </Box>
      </Paper>
      {!!data?.data?.reports?.length && (
        <Paper style={{ padding: "10px" }}>
          <MaterialTable
            id="Drought Compliance Report"
            title="Drought Compliance Report"
            columns={columns}
            isLoading={isLoading}
            data={data?.data?.reports}
            editable={{}}
            components={{
              Container: (props) => <div {...props} />,
            }}
            actions={[
              {
                icon: CopyIcon,
                tooltip: "Copy Data",
                isFreeAction: true,
                onClick: () => {
                  try {
                    copyToClipboard(data?.data?.reports, columns, () =>
                      doToast("success", "Data was copied to your clipboard.")
                    );
                  } catch (error) {
                    const message = error?.message ?? "Something went wrong";
                    doToast("error", message);
                  }
                },
              },
            ]}
            options={{
              emptyRowsWhenPaging: false,
              exportAllData: true,
              columnsButton: true,
              exportButton: { csv: true },
              pageSize: 30,
              pageSizeOptions: [5, 10, 30, 60],
              padding: "dense",
              searchFieldAlignment: "left",
              showTitle: false,
              maxBodyHeight: "600px",
              filtering: true,
            }}
          />
        </Paper>
      )}
    </>
  );
};

export default DroughtComplianceReport;
