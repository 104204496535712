import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import { useEffect, useMemo, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PdfContent from "./PdfContent";

export const usePrintPdf = (endpoint) => {
  const { getAccessTokenSilently } = useAuth0();

  const printRef = useRef(null);

  const [selectedItems, setSelectedItems] = useStateIfMounted(null);

  const { data, isSuccess } = useQuery(
    [endpoint, selectedItems],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`,
          { permitNdxArray: selectedItems },
          { headers }
        );
        return response.data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      enabled: !!selectedItems?.length,
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const handlePrintClick = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      setSelectedItems(null);
    },
  });

  useEffect(() => {
    if (isSuccess && !!data?.length) {
      handlePrintClick();
    }
  }, [data]); //eslint-disable-line

  const pdfMarkup = useMemo(() => {
    if (isSuccess && !!data?.length) {
      return <PdfContent data={data} ref={printRef} />;
    }
  }, [data, isSuccess]);

  return {
    setSelectedItems,
    pdfMarkup,
  };
};
