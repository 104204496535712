export const DEFAULT_REVIEW_REGISTRATION_STATE = {
  reg_ndx: null,
  first_name: null,
  last_name: null,
  primary_phone: null,
  primary_email: null,
  secondary_phone: null,
  secondary_email: null,
  mailing_name: null,
  mailing_address: null,
  mailing_city: null,
  mailing_state: null,
  mailing_zip: null,
  well_address: null,
  well_city: null,
  county_ndx: null,
  well_use_ndx: null,
  reg_lat_dd: null,
  reg_lon_dd: null,
  reg_well_elev_ft: null,
  aquifer_group_ndx: null,
  well_details_date_drilled: null,
  well_details_driller: null,
  well_details_depth_well_ft: null,
  well_details_depth_pump_ft: null,
  property_well_count: 1,
  scientific_monitoring_interest: true,
  hardcopy_well_records: null,
  other_notes: null,
  new_owner: false,
  rolo_ndx: null,
  create_new_well: false,
  well_ndx: null,
  completed: false,
  well_class_ndx: null,
  well_subclass_ndx: null,
  disregard: false,
};
