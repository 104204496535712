const pumpage = {
  name: "pumpage",
  ndx: 5,
  field: "pumpage_ndx",
  label: "Pumpage",
  endpoint: "query-and-download/q-d-data-pumpage",
  icon: "ShutterSpeed",
  inputs: [],
};

export default pumpage;
