import React from "react";
import { useQuery } from "react-query";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import DataAdminTableOriginal from "../../../../../components/DataAdminTableOriginal";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

//388px
const DroughtReductionFactorsManagement = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isFetching, error, refetch } = useQuery(
    ["list-permit-types"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-permit-types`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  if (error) return "An error has occurred: " + error.message;

  const columns = [
    {
      title: "Permit Type Description",
      field: "permit_type_desc",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      validate: (rowData) =>
        !rowData.permit_type_desc
          ? {
              isValid: false,
              helperText: "This value is required",
            }
          : true,
    },
    {
      title: "Permit Prefix",
      field: "permit_prefix",
      validate: (rowData) =>
        !rowData.permit_prefix
          ? {
              isValid: false,
              helperText: "This value is required",
            }
          : true,
      editComponent: (props) => (
        <TextField
          type="text"
          inputProps={{ maxLength: 1 }} // This will limit the input to one character.
          value={props.value || ""} // Use an empty string as the default value
          onChange={(e) => {
            props.onChange(e.target.value); // Notify MaterialTable of the change.
          }}
          error={!props.value}
          helperText={props.helperText} // This will show the helper text if validation fails.
        />
      ),
    },
    {
      title: "Conditional",
      field: "conditional",
      type: "boolean",
      initialEditValue: false,
    },
    {
      title: "Stage 2 - Alarm",
      field: "d1_pump_reduction",
      type: "numeric",
      initialEditValue: 0,
      validate: (rowData) =>
        ["", null].includes(rowData.d1_pump_reduction)
          ? {
              isValid: false,
              helperText: "This field is required",
            }
          : rowData.d1_pump_reduction > 1
          ? {
              isValid: false,
              helperText: "This field cannot be greater than 1",
            }
          : true,
      editComponent: (props) => (
        <TextField
          defaultValue={props.value ?? 0}
          type="number"
          onChange={(e) => props.onChange(e.target.value)}
          InputProps={{
            inputProps: {
              min: "0",
              max: "1",
              step: "0.1",
              onKeyPress: (e) => {
                if (["e", "-"].includes(e.key)) {
                  e.preventDefault();
                }
              },
            },
          }}
          error={!!props.helperText}
          helperText={props.helperText}
        />
      ),
    },
    {
      title: "Stage 3 - Critical",
      field: "d2_pump_reduction",
      type: "numeric",
      initialEditValue: 0,
      validate: (rowData) =>
        ["", null].includes(rowData.d2_pump_reduction)
          ? {
              isValid: false,
              helperText: "This field is required",
            }
          : rowData.d2_pump_reduction > 1
          ? {
              isValid: false,
              helperText: "This field cannot be greater than 1",
            }
          : true,
      editComponent: (props) => (
        <TextField
          defaultValue={props.value ?? 0}
          type="number"
          onChange={(e) => props.onChange(e.target.value)}
          InputProps={{
            inputProps: {
              min: "0",
              max: "1",
              step: "0.1",
              onKeyPress: (e) => {
                if (["e", "-"].includes(e.key)) {
                  e.preventDefault();
                }
              },
            },
          }}
          error={!!props.helperText}
          helperText={props.helperText}
        />
      ),
    },
    {
      title: "Stage 4 - Exceptional",
      field: "d3_pump_reduction",
      type: "numeric",
      initialEditValue: 0,
      validate: (rowData) =>
        ["", null].includes(rowData.d3_pump_reduction)
          ? {
              isValid: false,
              helperText: "This field is required",
            }
          : rowData.d3_pump_reduction > 1
          ? {
              isValid: false,
              helperText: "This field cannot be greater than 1",
            }
          : true,
      editComponent: (props) => (
        <TextField
          defaultValue={props.value ?? 0}
          type="number"
          onChange={(e) => props.onChange(e.target.value)}
          InputProps={{
            inputProps: {
              min: "0",
              max: "1",
              step: "0.1",
              onKeyPress: (e) => {
                if (["e", "-"].includes(e.key)) {
                  e.preventDefault();
                }
              },
            },
          }}
          error={!!props.helperText}
          helperText={props.helperText}
        />
      ),
    },
    {
      title: "Stage 5 - ERP",
      field: "d4_pump_reduction",
      type: "numeric",
      initialEditValue: 0,
      validate: (rowData) =>
        ["", null].includes(rowData.d4_pump_reduction)
          ? {
              isValid: false,
              helperText: "This field is required",
            }
          : rowData.d4_pump_reduction > 1
          ? {
              isValid: false,
              helperText: "This field cannot be greater than 1",
            }
          : true,
      editComponent: (props) => (
        <TextField
          defaultValue={props.value ?? 0}
          type="number"
          onChange={(e) => props.onChange(e.target.value)}
          InputProps={{
            inputProps: {
              min: "0",
              max: "1",
              step: "0.1",
              onKeyPress: (e) => {
                if (["e", "-"].includes(e.key)) {
                  e.preventDefault();
                }
              },
            },
          }}
          error={!!props.helperText}
          helperText={props.helperText}
        />
      ),
    },
  ];

  return (
    <>
      <Helmet title="Permit Types and Drought Reduction Factors" />
      <Typography variant="h3" gutterBottom display="inline">
        Permit Types and Drought Reduction Factors
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Permit Types and Drought Reduction Factors</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Paper>
        <TableWrapper>
          <DataAdminTableOriginal
            pageSize={30}
            isLoading={isFetching}
            label="Permit Types and Drought Reduction Factors"
            columns={columns}
            data={data}
            height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 120px)"
            handleRefresh={refetch}
            endpoint="list-permit-types"
            ndxField="permit_type_ndx"
          />
        </TableWrapper>
      </Paper>
    </>
  );
};

export default DroughtReductionFactorsManagement;
