import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";

import { useBasicRegistrationInfo } from "./useBasicRegistrationInfo";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useSubmitData } from "../../hooks/useSubmitData";
import Loader from "../../components/Loader";
import {
  getButtonIcon,
  getButtonText,
  NumberFormatCustom,
} from "../dataManagement/wells/wellsManagement/utils";
import RegLatLongMap from "../../components/map/RegLatLongMap";
import { BOOLEAN_OPTIONS } from "../publicWellRegistration/PublicWellRegistration";
import FuzzySearch from "../dataManagement/wells/wellsManagement/FuzzySearch";

function BasicReviewRegistrationInfo({
  selectedRegistration,
  setSelectedRegistration,
  refetchRegistrations = () => {},
  isEditMode = false,
  triggerRefetch,
}) {
  const {
    data,
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
    lookups,
  } = useBasicRegistrationInfo(selectedRegistration, triggerRefetch);

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedRegistration,
    setSelectedRegistration,
    state,
    "data-registrations",
    "reg_ndx",
    refetch,
    refetchRegistrations
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.reg_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.reg_ndx) ? "none" : "inherit",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Public Registration</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          <Grid
            container
            spacing={3}
            style={{
              border: "3px solid gray",
              marginBottom: "15px",
              marginTop: "15px",
              borderRadius: "4px",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{
                padding: "8px 0 0 16px",
                fontWeight: 600,
                width: "100%",
              }}
            >
              Internal Tracking
            </Typography>

            <Grid
              item
              xs={12}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.new_owner}
                    onChange={(e) =>
                      handleUpdateState("new_owner", e.target.checked)
                    }
                    color="primary"
                  />
                }
                label="Owner is New?"
              />
            </Grid>

            <Grid
              item
              xs={12}
              xl={6}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!lookups?.wellOwners.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <FuzzySearch
                    options={lookups?.wellOwners}
                    onSelect={(e) => {
                      handleUpdateState("rolo_ndx", e);
                    }}
                    disabled={state.new_owner}
                  />
                  <InputLabel
                    style={{ marginTop: "6px", marginLeft: "15px" }}
                    id="existing-owner"
                    error={
                      state.create_new_well &&
                      !state.new_owner &&
                      !state.rolo_ndx
                    }
                    required={
                      state.create_new_well &&
                      !state.new_owner &&
                      !state.rolo_ndx
                    }
                  >
                    Existing Owner
                  </InputLabel>
                  {state?.rolo_ndx ? (
                    <Link
                      color="secondary"
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        marginTop: "5px",
                        marginLeft: "15px",
                      }}
                      href={`/data-management/well-owners/edit-owner-info/${state.rolo_ndx}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {lookups?.wellOwners?.find(
                        (well) => well.rolo_ndx === state.rolo_ndx
                      )?.concatenated_contact_info ?? ""}
                    </Link>
                  ) : (
                    <Typography
                      color="primary"
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        marginTop: "5px",
                        marginLeft: "15px",
                      }}
                    >
                      ** No Owner Selected **
                    </Typography>
                  )}
                  {state.create_new_well &&
                    !state.new_owner &&
                    !state.rolo_ndx && (
                      <FormHelperText error>*Required field</FormHelperText>
                    )}
                </>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={data?.create_new_well}
                    checked={state.create_new_well}
                    onChange={(e) =>
                      handleUpdateState("create_new_well", e.target.checked)
                    }
                    color="primary"
                    style={{
                      color: data?.create_new_well
                        ? "rgba(0, 0, 0, 0.54)"
                        : "inherit",
                    }}
                  />
                }
                label="Create Well Record?"
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={state.create_new_well && !state.well_class_ndx}
                required
              >
                {!lookups?.wellClassification.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="well-classification">
                      Well Classification
                    </InputLabel>
                    <Select
                      labelId="well classification"
                      id="well-classification"
                      label="Well Classification"
                      name="well_class_ndx"
                      value={state.well_class_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("well_class_ndx", e.target.value);
                      }}
                    >
                      {lookups.wellClassification.map((option) => (
                        <MenuItem
                          key={option.well_class_ndx}
                          value={option.well_class_ndx}
                        >
                          {option.well_class_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {state.create_new_well && !state.well_class_ndx && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={state.create_new_well && !state.well_subclass_ndx}
                required={state.create_new_well && !state.well_subclass_ndx}
              >
                {!lookups?.wellSubclass.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="well-sub-classification">
                      Well Sub-Classification
                    </InputLabel>
                    <Select
                      labelId="well sub-classification"
                      id="well-classification"
                      label="Well Sub-Classification"
                      name="well_subclass_ndx"
                      value={state.well_subclass_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("well_subclass_ndx", e.target.value);
                      }}
                    >
                      {lookups.wellSubclass.map((option) => (
                        <MenuItem
                          key={option.well_subclass_ndx}
                          value={option.well_subclass_ndx}
                        >
                          {option.well_subclass_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {state.create_new_well && !state.well_subclass_ndx && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <InputLabel
                style={{ marginTop: "6px", marginLeft: "15px" }}
                id="well-record"
              >
                Well Record
              </InputLabel>
              {state?.well_ndx ? (
                <Link
                  color="secondary"
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    marginTop: "5px",
                    marginLeft: "15px",
                  }}
                  href={`/data-management/wells/wells-management/${state.well_ndx}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {lookups?.permitWellRecords?.find(
                    (well) => well.well_ndx === state.well_ndx
                  )?.well_name ?? ""}
                </Link>
              ) : (
                <Typography
                  color="primary"
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    marginTop: "5px",
                    marginLeft: "15px",
                  }}
                >
                  N/A
                </Typography>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <InputLabel
                style={{ marginTop: "6px", marginLeft: "15px" }}
                id="well-record"
              >
                Registration Status
              </InputLabel>

              <Typography
                style={{
                  color: state?.completed ? "green" : "red",
                  fontSize: "18px",
                  fontWeight: "700",
                  marginTop: "5px",
                  marginLeft: "15px",
                }}
              >
                {state?.completed ? "Completed" : "Incomplete"}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.disregard}
                    onChange={(e) =>
                      handleUpdateState("disregard", e.target.checked)
                    }
                    color="primary"
                  />
                }
                label="Disregard?"
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            style={{
              border: "3px solid gray",
              marginBottom: "15px",
              marginTop: "15px",
              borderRadius: "4px",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{
                padding: "8px 0 0 16px",
                fontWeight: 600,
                width: "100%",
              }}
            >
              Owner Info
            </Typography>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                required
                error={!state.first_name}
                variant="outlined"
                label="First Name"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("first_name", e.target.value)
                }
                value={state.first_name ?? ""}
              />
              {!state.first_name ? (
                <FormHelperText error>*Required field</FormHelperText>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                required
                error={!state.last_name}
                variant="outlined"
                label="Last Name"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("last_name", e.target.value)}
                value={state.last_name ?? ""}
              />
              {!state.last_name ? (
                <FormHelperText error>*Required field</FormHelperText>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                required
                error={!state.primary_phone}
                variant="outlined"
                label="Primary Phone"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("primary_phone", e.target.value)
                }
                value={state.primary_phone ?? ""}
              />
              {!state.primary_phone ? (
                <FormHelperText error>*Required field</FormHelperText>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                required
                error={!state.primary_email}
                variant="outlined"
                label="Email Address"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("primary_email", e.target.value)
                }
                value={state.primary_email ?? ""}
              />
              {!state.primary_email ? (
                <FormHelperText error>*Required field</FormHelperText>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                variant="outlined"
                label="Secondary Phone"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("secondary_phone", e.target.value)
                }
                value={state.secondary_phone ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                variant="outlined"
                label="Secondary Email"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("secondary_email", e.target.value)
                }
                value={state.secondary_email ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              xl={6}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                required
                error={!state.mailing_name}
                variant="outlined"
                label="Organization"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("mailing_name", e.target.value)
                }
                value={state.mailing_name ?? ""}
              />
              {!state.mailing_name ? (
                <FormHelperText error>*Required field</FormHelperText>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                required
                error={!state.mailing_address}
                variant="outlined"
                label="Mailing Address"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("mailing_address", e.target.value)
                }
                value={state.mailing_address ?? ""}
              />
              {!state.mailing_address ? (
                <FormHelperText error>*Required field</FormHelperText>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                required
                error={!state.mailing_city}
                variant="outlined"
                label="City"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("mailing_city", e.target.value)
                }
                value={state.mailing_city ?? ""}
              />
              {!state.mailing_city ? (
                <FormHelperText error>*Required field</FormHelperText>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                required
                error={!state.mailing_state}
                variant="outlined"
                label="State"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("mailing_state", e.target.value)
                }
                value={state.mailing_state ?? ""}
              />
              {!state.mailing_state ? (
                <FormHelperText error>*Required field</FormHelperText>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                required
                error={!state.mailing_zip}
                variant="outlined"
                label="ZIP/Postal Code"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("mailing_zip", e.target.value)
                }
                value={state.mailing_zip ?? ""}
              />
              {!state.mailing_zip ? (
                <FormHelperText error>*Required field</FormHelperText>
              ) : null}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            style={{
              border: "3px solid gray",
              marginBottom: "15px",
              marginTop: "15px",
              borderRadius: "4px",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{
                padding: "8px 0 0 16px",
                fontWeight: 600,
                width: "100%",
              }}
            >
              Well Info
            </Typography>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                required
                error={!state.well_address}
                variant="outlined"
                label="Physical Street Address"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("well_address", e.target.value)
                }
                value={state.well_address ?? ""}
              />
              {!state.well_address ? (
                <FormHelperText error>*Required field</FormHelperText>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                required
                error={!state.well_city}
                variant="outlined"
                label="City"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("well_city", e.target.value)}
                value={state.well_city ?? ""}
              />
              {!state.well_city ? (
                <FormHelperText error>*Required field</FormHelperText>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={!state.county_ndx}
                required
              >
                {!lookups.counties.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="county">County</InputLabel>
                    <Select
                      labelId="county"
                      id="county"
                      label="County"
                      name="county_ndx"
                      value={state.county_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("county_ndx", e.target.value);
                      }}
                    >
                      {lookups.counties.map((option) => (
                        <MenuItem
                          key={option.county_ndx}
                          value={option.county_ndx}
                        >
                          {option.county_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {!state.county_ndx && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={!state.well_use_ndx}
                required
              >
                {!lookups.wellUse.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="county">Primary Well Use</InputLabel>
                    <Select
                      labelId="primary-well-use"
                      id="primary-well-use"
                      label="Primary Well Use"
                      name="well_use_ndx"
                      value={state.well_use_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("well_use_ndx", e.target.value);
                      }}
                    >
                      {lookups.wellUse.map((option) => (
                        <MenuItem
                          key={option.well_use_ndx}
                          value={option.well_use_ndx}
                        >
                          {option.well_use_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {!state.well_use_ndx && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <RegLatLongMap
                filterValues={state}
                setFilterValues={handleUpdateState}
                isEditorDirty={false}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                name="lat_dd"
                required
                error={
                  !state.reg_lat_dd ||
                  state.reg_lat_dd > 90 ||
                  state.reg_lat_dd < -90
                }
                helperText={
                  !state.reg_lat_dd && state.reg_lat_dd !== 0
                    ? "*Required field"
                    : state.reg_lat_dd > 90 || state.reg_lat_dd < -90
                    ? "Invalid coordinate"
                    : ""
                }
                variant="outlined"
                label="Latitude"
                style={{ width: "100%" }}
                InputProps={{
                  inputProps: {
                    config: {
                      decimalScale: 5,
                      fixedDecimalScale: true,
                    },
                  },
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) =>
                  handleUpdateState("reg_lat_dd", e.target.value)
                }
                value={state.reg_lat_dd ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                name="lon_dd"
                required
                error={
                  !state.reg_lon_dd ||
                  state.reg_lon_dd > 180 ||
                  state.reg_lon_dd < -180
                }
                helperText={
                  !state.reg_lon_dd && state.reg_lon_dd !== 0
                    ? "*Required field"
                    : state.reg_lon_dd > 180 || state.reg_lon_dd < -180
                    ? "Invalid coordinate"
                    : ""
                }
                variant="outlined"
                label="Longitude"
                style={{ width: "100%" }}
                InputProps={{
                  inputProps: {
                    config: {
                      decimalScale: 5,
                      fixedDecimalScale: true,
                    },
                  },
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) =>
                  handleUpdateState("reg_lon_dd", e.target.value)
                }
                value={state.reg_lon_dd ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                type="number"
                variant="outlined"
                label="Recorded Elevation"
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    handleUpdateState("reg_well_elev_ft", null);
                  } else {
                    handleUpdateState("reg_well_elev_ft", +e.target.value);
                  }
                }}
                value={state.reg_well_elev_ft ?? ""}
                InputProps={{
                  inputProps: {
                    onKeyPress: (e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={!state.aquifer_group_ndx}
                required
              >
                {!lookups.wellAquifer.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="county">Primary Well Aquifer</InputLabel>
                    <Select
                      labelId="primary-well-aquifer"
                      id="primary-well-aquifer"
                      label="Primary Well Aquifer"
                      name="aquifer_group_ndx"
                      value={state.aquifer_group_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("aquifer_group_ndx", e.target.value);
                      }}
                    >
                      {lookups.wellAquifer.map((option) => (
                        <MenuItem
                          key={option.aquifer_group_ndx}
                          value={option.aquifer_group_ndx}
                        >
                          {option.aquifer_group_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {!state.aquifer_group_ndx && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl style={{ width: "100%" }}>
                  <KeyboardDatePicker
                    format="LLLL do, yyyy"
                    inputVariant="outlined"
                    autoOk
                    id="drill date"
                    label="Date Well was Drilled"
                    value={state?.well_details_date_drilled}
                    onChange={(date) => {
                      handleUpdateState("well_details_date_drilled", date);
                    }}
                    InputAdornmentProps={{
                      "aria-label": "drill date",
                    }}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                variant="outlined"
                label="Well Driller"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("well_details_driller", e.target.value)
                }
                value={state.well_details_driller ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  type="number"
                  variant="outlined"
                  label="Well Depth (ft)"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      handleUpdateState("well_details_depth_well_ft", null);
                    } else {
                      handleUpdateState(
                        "well_details_depth_well_ft",
                        +e.target.value
                      );
                    }
                  }}
                  value={state?.well_details_depth_well_ft ?? ""}
                  InputProps={{
                    inputProps: {
                      onKeyPress: (e) => {
                        if (e.key === "e") {
                          e.preventDefault();
                        }
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  type="number"
                  variant="outlined"
                  label="Pump Depth (ft)"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      handleUpdateState("well_details_depth_pump_ft", null);
                    } else {
                      handleUpdateState(
                        "well_details_depth_pump_ft",
                        +e.target.value
                      );
                    }
                  }}
                  value={state?.well_details_depth_pump_ft ?? ""}
                  InputProps={{
                    inputProps: {
                      onKeyPress: (e) => {
                        if (e.key === "e") {
                          e.preventDefault();
                        }
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            style={{
              border: "3px solid gray",
              marginBottom: "15px",
              marginTop: "15px",
              borderRadius: "4px",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{
                padding: "8px 0 0 16px",
                fontWeight: 600,
                width: "100%",
              }}
            >
              Additional Info
            </Typography>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  required
                  error={!state.property_well_count}
                  type="number"
                  variant="outlined"
                  label="Property Well Count"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      handleUpdateState("property_well_count", null);
                    } else {
                      handleUpdateState("property_well_count", +e.target.value);
                    }
                  }}
                  value={state?.property_well_count ?? ""}
                  InputProps={{
                    inputProps: {
                      onKeyPress: (e) => {
                        if (e.key === "e") {
                          e.preventDefault();
                        }
                      },
                    },
                  }}
                />
                {!state.property_well_count && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                required
                variant="outlined"
                style={{ width: "100%" }}
              >
                <InputLabel id="enabled">Scientific Well Monitoring</InputLabel>
                <Select
                  labelId="scientific-well-monitoring"
                  id="scientific-well-monitoring"
                  label="Scientific Well Monitoring"
                  value={state?.scientific_monitoring_interest ?? ""}
                  onChange={(e) =>
                    handleUpdateState(
                      "scientific_monitoring_interest",
                      e.target.value
                    )
                  }
                >
                  {BOOLEAN_OPTIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                required
                variant="outlined"
                style={{ width: "100%" }}
                error={state.hardcopy_well_records === null}
              >
                <InputLabel id="enabled">Hardcopy Well Records</InputLabel>
                <Select
                  required
                  labelId="hardcopy-well-records"
                  id="hardcopy-well-records"
                  label="Hardcopy Well Records"
                  value={state?.hardcopy_well_records ?? ""}
                  onChange={(e) =>
                    handleUpdateState("hardcopy_well_records", e.target.value)
                  }
                >
                  {BOOLEAN_OPTIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.option}
                    </MenuItem>
                  ))}
                </Select>
                {state.hardcopy_well_records === null && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                variant="outlined"
                label="Comments"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("other_notes", e.target.value)
                }
                value={state.other_notes ?? ""}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={handleResetState}>
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isDirty || isFormSubmitting}
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting, selectedRegistration === 0)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default BasicReviewRegistrationInfo;
