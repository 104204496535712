import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";
import BasicWellInfo from "./sections/BasicWellInfo";
import { useStateIfMounted } from "use-state-if-mounted";
import AdditionalLocationDetails from "./sections/AdditionalLocationDetails";
import WellDetails from "./sections/WellDetails";
import Hydrogeology from "./sections/Hydrogeology";
import GeologicalContacts from "./sections/GeologicalContacts";
import WellCompletion from "./sections/WellCompletion";
import WellConstruction from "./sections/WellConstruction";
import AttachedDocumentsAndPhotos from "./sections/AttachedDocumentsAndPhotos";
import ContactsManagement from "./sections/ContactsManagement";
import { useQuery } from "react-query";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

function WellManagement() {
  const { getAccessTokenSilently } = useAuth0();
  let { id } = useParams();
  const [selectedWell, setSelectedWell] = useStateIfMounted(id);
  const [selectedWellLabel, setSelectedWellLabel] = useStateIfMounted(null);

  useQuery(
    ["list-wells-for-label", selectedWell],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-wells/${selectedWell}`,
          { headers }
        );
        setSelectedWellLabel(data?.well_name);
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <React.Fragment>
      <Helmet title="Well Management" />
      <Typography variant="h3" gutterBottom display="inline">
        Well Management
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Link
          component={NavLink}
          exact
          to="/data-management/wells/wells-management"
        >
          Wells Management
        </Link>
        <Typography>Edit Well</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <BasicWellInfo
        selectedWell={selectedWell}
        setSelectedWell={setSelectedWell}
        isEditMode={true}
        selectedWellLabel={selectedWellLabel}
      />
      <AdditionalLocationDetails
        selectedWell={selectedWell}
        setSelectedWell={setSelectedWell}
        isEditMode={true}
        selectedWellLabel={selectedWellLabel}
      />
      <WellDetails
        selectedWell={selectedWell}
        setSelectedWell={setSelectedWell}
        isEditMode={true}
        selectedWellLabel={selectedWellLabel}
      />
      <ContactsManagement
        selectedWell={selectedWell}
        isEditMode={true}
        selectedWellLabel={selectedWellLabel}
      />
      <Hydrogeology
        selectedWell={selectedWell}
        isEditMode={true}
        selectedWellLabel={selectedWellLabel}
      />
      <GeologicalContacts
        selectedWell={selectedWell}
        isEditMode={true}
        selectedWellLabel={selectedWellLabel}
      />
      <WellCompletion
        selectedWell={selectedWell}
        setSelectedWell={setSelectedWell}
        isEditMode={true}
        selectedWellLabel={selectedWellLabel}
      />
      <WellConstruction
        selectedWell={selectedWell}
        isEditMode={true}
        selectedWellLabel={selectedWellLabel}
      />
      <AttachedDocumentsAndPhotos
        selectedWell={selectedWell}
        setSelectedWell={setSelectedWell}
        isEditMode={true}
        selectedWellLabel={selectedWellLabel}
      />
    </React.Fragment>
  );
}

export default WellManagement;
