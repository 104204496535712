import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ExpandMore, Refresh, Warning, Error } from "@material-ui/icons";
import React from "react";

import Loader from "../../../../components/Loader";
import {
  LINKED_METERS_TABLE_COLUMNS,
  LINKED_PERMITS_TABLE_COLUMNS,
  LINKED_WELLS_TABLE_COLUMNS,
} from "./PermitsConstants";
import { useSubmitData } from "../../../../hooks/useSubmitData";
import { useBasicPermitInfo } from "./useBasicPermitInfo";
import { getButtonIcon, getButtonText } from "../wellsManagement/utils";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MaterialTable from "material-table";
import { Alert } from "@material-ui/lab";
import LinkedPermitteeFuzzySearch from "./LinkedPermitteeFuzzySearch";

function BasicPermitInfo({
  selectedPermit,
  setSelectedPermit,
  refetchPermits = () => {},
  isEditMode = false,
  triggerRefetch,
  triggerRefetchPermit,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
    lookups,
    tables,
  } = useBasicPermitInfo(selectedPermit, triggerRefetch, triggerRefetchPermit);

  const handleRefreshAfterSubmit = () => {
    refetchPermits();
  };

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedPermit,
    setSelectedPermit,
    state,
    "list-permits",
    "permit_ndx",
    refetch,
    handleRefreshAfterSubmit
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.permit_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.permit_ndx) ? "none" : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Basic Permit Information</Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails style={{ flexDirection: "column" }}>
          {!state?.permit_ndx ? (
            <Grid
              container
              spacing={3}
              style={{
                border: "3px solid gray",
                marginBottom: "15px",
                borderRadius: "4px",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                style={{
                  padding: "8px 0 0 16px",
                  fontWeight: 600,
                  width: "100%",
                }}
              >
                Permittee
              </Typography>
              <Grid
                item
                xs={12}
                xl={6}
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {!lookups?.permitteeContactRecords.length > 0 &&
                state.rolo_ndx ? (
                  <Loader />
                ) : (
                  <Box px={4} my={3} display="flex" alignItems="center">
                    <div style={{ width: "100%" }}>
                      <LinkedPermitteeFuzzySearch
                        disabled={state?.permittee_add_new}
                        options={lookups?.permitteeContactRecords}
                        onSelect={(e) => {
                          handleUpdateState("rolo_ndx", e);
                        }}
                      />
                      <InputLabel
                        style={{ marginTop: "6px" }}
                        id="selected-permittee"
                      >
                        Permittee
                      </InputLabel>
                      {state?.rolo_ndx ? (
                        <Link
                          color="secondary"
                          style={{
                            fontSize: "18px",
                            fontWeight: "700",
                            marginTop: "5px",
                          }}
                          href={`/data-management/well-owners/edit-owner-info/${state.rolo_ndx}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {lookups?.permitteeContactRecords?.find(
                            (contact) => contact.rolo_ndx === state.rolo_ndx
                          )?.concatenated_contact_info ?? ""}
                        </Link>
                      ) : (
                        <Typography
                          color="primary"
                          style={{
                            fontSize: "18px",
                            fontWeight: "700",
                            marginTop: "5px",
                          }}
                        >
                          ** No Permittee Selected **
                        </Typography>
                      )}
                    </div>
                  </Box>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                xl={6}
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box flexGrow={1} mb={2}>
                  <Alert severity="info" style={{ height: "100%" }}>
                    <strong>
                      Create a new Permittee Record to the Rolodex only
                    </strong>{" "}
                    - if Permittee already exists in the Rolodex, select them in
                    the adjacent Permittee field.
                  </Alert>
                </Box>
                <Button
                  size="large"
                  variant="contained"
                  color={state.permittee_add_new ? "default" : "secondary"}
                  onClick={() =>
                    handleUpdateState(
                      "permittee_add_new",
                      !state.permittee_add_new
                    )
                  }
                >
                  Create a New Permittee
                </Button>
              </Grid>

              {state?.permittee_add_new && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={8}
                    style={{
                      position: "relative",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="Permittee Name or Organization"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        handleUpdateState(
                          "tmp_permittee_organization",
                          e.target.value
                        )
                      }
                      value={state.tmp_permittee_organization ?? ""}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={4}
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      {!lookups?.directorPrecincts.length > 0 ? (
                        <Loader />
                      ) : (
                        <>
                          <InputLabel id="precinct">Precinct</InputLabel>
                          <Select
                            labelId="precinct"
                            id="precinct"
                            label="Precinct"
                            name="tmp_permittee_precinct_ndx"
                            value={state.tmp_permittee_precinct_ndx ?? ""}
                            onChange={(e) => {
                              handleUpdateState(
                                "tmp_permittee_precinct_ndx",
                                e.target.value
                              );
                            }}
                          >
                            {lookups.directorPrecincts.map((option) => (
                              <MenuItem
                                key={option.precinct_ndx}
                                value={option.precinct_ndx}
                              >
                                {option.precinct_label}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      position: "relative",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="Permittee Contact Name"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        handleUpdateState("tmp_permittee_name", e.target.value)
                      }
                      value={state.tmp_permittee_name ?? ""}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={5}
                    style={{
                      position: "relative",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="Email"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        handleUpdateState("tmp_permittee_email", e.target.value)
                      }
                      value={state.tmp_permittee_email ?? ""}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={5}
                    style={{
                      position: "relative",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="Phone"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        handleUpdateState("tmp_permittee_phone", e.target.value)
                      }
                      value={state.tmp_permittee_phone ?? ""}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={2}
                    style={{
                      position: "relative",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="Zip Code"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        handleUpdateState("tmp_permittee_zip", e.target.value)
                      }
                      value={state.tmp_permittee_zip ?? ""}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={7}
                    style={{
                      position: "relative",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="Mailing Address"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        handleUpdateState(
                          "tmp_permittee_address",
                          e.target.value
                        )
                      }
                      value={state.tmp_permittee_address ?? ""}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={3}
                    style={{
                      position: "relative",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="City"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        handleUpdateState("tmp_permittee_city", e.target.value)
                      }
                      value={state.tmp_permittee_city ?? ""}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={2}
                    style={{
                      position: "relative",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="State"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        handleUpdateState("tmp_permittee_state", e.target.value)
                      }
                      value={state.tmp_permittee_state ?? ""}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            <Tooltip
              placement="top-start"
              title={
                selectedPermit ? (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <Warning style={{ marginRight: "8px" }} />
                      Create an Administrative Record to modify this value
                      <Warning style={{ marginLeft: "8px" }} />
                    </>
                  </Box>
                ) : (
                  ""
                )
              }
            >
              <Grid
                item
                xs={12}
                xl={6}
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {!lookups?.permitteeContactRecords.length > 0 &&
                state.rolo_ndx ? (
                  <Loader />
                ) : (
                  <Box
                    style={{ paddingLeft: 0, paddingRight: "16px" }}
                    my={3}
                    display="flex"
                    alignItems="center"
                  >
                    <Error color="secondary" style={{ marginRight: "20px" }} />
                    <div style={{ width: "100%" }}>
                      <InputLabel
                        style={{ marginTop: "6px" }}
                        id="selected-permittee"
                      >
                        Permittee
                      </InputLabel>
                      {state?.rolo_ndx ? (
                        <Link
                          color="secondary"
                          style={{
                            fontSize: "18px",
                            fontWeight: "700",
                            marginTop: "5px",
                          }}
                          href={`/data-management/well-owners/edit-owner-info/${state.rolo_ndx}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {lookups?.permitteeContactRecords?.find(
                            (contact) => contact.rolo_ndx === state.rolo_ndx
                          )?.concatenated_contact_info ?? ""}
                        </Link>
                      ) : (
                        <Typography
                          color="primary"
                          style={{
                            fontSize: "18px",
                            fontWeight: "700",
                            marginTop: "5px",
                          }}
                        >
                          ** No Permittee Selected **
                        </Typography>
                      )}
                    </div>
                  </Box>
                )}
              </Grid>
            </Tooltip>
          )}

          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              xl={6}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Permit Name"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("permit_name", e.target.value)
                }
                value={state.permit_name ?? ""}
                InputProps={{
                  style: {
                    fontWeight: "600",
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                variant="outlined"
                label="Permit Number"
                style={{
                  width: !state?.permit_ndx ? "100%" : "calc(100% - 67px)",
                }}
                onChange={(e) =>
                  handleUpdateState("permit_number", e.target.value)
                }
                value={state.permit_number ?? ""}
                InputProps={{
                  style: {
                    color: "rgba(0,0,0,1)",
                  },
                }}
              />
              {state?.permit_ndx && (
                <Tooltip title="Regenerate Permit Number">
                  <IconButton
                    disabled={
                      !state?.permit_type_ndx || state?.permit_year.length !== 4
                    }
                    style={{ margin: "0 10px" }}
                    color="primary"
                    aria-label="refresh picture"
                    component="label"
                    onClick={() => {
                      const permitNumberParts =
                        lookups?.newPermitNumberParts?.find(
                          (item) =>
                            item.permit_type_ndx === state?.permit_type_ndx &&
                            item.permit_year === +state?.permit_year
                        );
                      handleUpdateState(
                        "permit_number",
                        `${permitNumberParts.permit_prefix}${
                          permitNumberParts?.permit_prefix_num
                        }-${state?.permit_year.slice(-2)}-${
                          permitNumberParts?.permit_suffix
                        }`
                      );
                    }}
                  >
                    <Refresh />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>

            <Tooltip
              title={
                selectedPermit ? (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <Warning style={{ marginRight: "8px" }} />
                      Changing this value will re-classify the permit and change
                      the Permit Number leading letter - click refresh icon to
                      see new permit number before saving
                      <Warning style={{ marginLeft: "8px" }} />
                    </>
                  </Box>
                ) : (
                  ""
                )
              }
            >
              <Grid
                item
                xs={12}
                md={6}
                xl={3}
                style={{
                  position: "relative",
                  backgroundColor: selectedPermit ? "#AFEEEE" : "inherit",
                }}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={!state.permit_type_ndx}
                  required
                >
                  {!lookups?.permitTypes.length > 0 ? (
                    <Loader />
                  ) : (
                    <>
                      <InputLabel id="permit-type">Permit Type</InputLabel>
                      <Select
                        labelId="permit type"
                        id="permit-type"
                        label="Permit Type"
                        name="permit_type_ndx"
                        value={state.permit_type_ndx ?? ""}
                        onChange={(e) => {
                          handleUpdateState("permit_type_ndx", e.target.value);
                        }}
                      >
                        {
                          //removing conditional on aquifer per client
                          //   lookups.permitTypes.filter((PT) =>
                          //   PT.assoc_aquifer_group_ndx.includes(
                          //     state.aquifer_group_ndx
                          //   )
                          // ).length
                          //   ? lookups.permitTypes.filter((PT) =>
                          //       PT.assoc_aquifer_group_ndx.includes(
                          //         state.aquifer_group_ndx
                          //       )
                          //     )
                          //   :
                          lookups.permitTypes.map((option) => (
                            <MenuItem
                              key={option.permit_type_ndx}
                              value={option.permit_type_ndx}
                            >
                              {option.permit_type_desc}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </>
                  )}
                  {!state.permit_type_ndx && (
                    <FormHelperText error>*Required field</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Tooltip>

            <Tooltip
              title={
                selectedPermit ? (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <Warning style={{ marginRight: "8px" }} />
                      Create an Administrative Record to modify this value
                      <Warning style={{ marginLeft: "8px" }} />
                    </>
                  </Box>
                ) : (
                  ""
                )
              }
            >
              <Grid
                item
                xs={12}
                md={6}
                xl={3}
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!!selectedPermit && (
                  <Error color="secondary" style={{ marginRight: "5px" }} />
                )}
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={!state.permit_status_ndx}
                  required
                >
                  {!lookups?.permitStatus.length > 0 ? (
                    <Loader />
                  ) : (
                    <>
                      <InputLabel id="permit-type">Permit Status</InputLabel>

                      <Select
                        labelId="permit status"
                        id="permit-status"
                        label="Permit Status"
                        name="permit_status_ndx"
                        value={state.permit_status_ndx ?? ""}
                        onChange={(e) => {
                          handleUpdateState(
                            "permit_status_ndx",
                            e.target.value
                          );
                        }}
                      >
                        {/*if selectedPermit is true, then only show the options*/}
                        {/*that are not 3 or 7*/}
                        {lookups.permitStatus
                          .filter((item) =>
                            !selectedPermit
                              ? ![3, 7].includes(item.permit_status_ndx)
                              : true
                          )
                          .map((option) => (
                            <MenuItem
                              key={option.permit_status_ndx}
                              value={option.permit_status_ndx}
                            >
                              {option.permit_status_desc}
                            </MenuItem>
                          ))}
                      </Select>
                    </>
                  )}
                  {!state.permit_status_ndx && (
                    <FormHelperText error>*Required field</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Tooltip>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={!state.aquifer_group_ndx}
                required
              >
                {!lookups?.aquiferGroups.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="aquifer-group">Aquifer Group</InputLabel>
                    <Select
                      labelId="aquifer group"
                      id="aquifer-group"
                      label="Aquifer Group"
                      name="aquifer_group_ndx"
                      value={state.aquifer_group_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("aquifer_group_ndx", e.target.value);
                      }}
                    >
                      {
                        //removing conditional logic with permit type per client
                        //   lookups.aquiferGroups.filter((PT) =>
                        //   PT.assoc_permit_type_ndx.includes(state.permit_type_ndx)
                        // ).length
                        //   ? lookups.aquiferGroups.filter((PT) =>
                        //       PT.assoc_permit_type_ndx.includes(
                        //         state.permit_type_ndx
                        //       )
                        //     )
                        //   :
                        lookups.aquiferGroups.map((option) => (
                          <MenuItem
                            key={option.aquifer_group_ndx}
                            value={option.aquifer_group_ndx}
                          >
                            {option.aquifer_group_name}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </>
                )}
                {!state.aquifer_group_ndx && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Tooltip
              title={
                selectedPermit ? (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <Warning style={{ marginRight: "8px" }} />
                      Changing this value will modify the Permit Number - click
                      refresh icon to see new permit number before saving
                      <Warning style={{ marginLeft: "8px" }} />
                    </>
                  </Box>
                ) : (
                  ""
                )
              }
            >
              <Grid
                item
                xs={12}
                md={6}
                xl={3}
                style={{
                  position: "relative",
                  backgroundColor: selectedPermit ? "#AFEEEE" : "inherit",
                }}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  required
                >
                  <TextField
                    required
                    type="number"
                    error={
                      !state.permit_year ||
                      state.permit_year.length !== 4 ||
                      +state.permit_year > new Date().getFullYear() + 1
                    }
                    variant="outlined"
                    label="Permit Year"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateState("permit_year", e.target.value)
                    }
                    value={state.permit_year ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (["e", "-"].includes(e.key)) {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                  {!state.permit_year ? (
                    <FormHelperText error>*Required field</FormHelperText>
                  ) : state.permit_year.length !== 4 ? (
                    <FormHelperText error>*Requires 4 digits</FormHelperText>
                  ) : +state.permit_year > new Date().getFullYear() + 1 ? (
                    <FormHelperText error>{`*Must be before ${
                      new Date().getFullYear() + 2
                    }`}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
            </Tooltip>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.allocationDistributions.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="allocation-distributions">
                      Allocation Distributions
                    </InputLabel>
                    <Select
                      labelId="allocation distributions"
                      id="allocation-distributions"
                      label="Allocation Distributions"
                      name="allocation_distribution_ndx"
                      value={state.allocation_distribution_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState(
                          "allocation_distribution_ndx",
                          e.target.value
                        );
                      }}
                    >
                      {lookups.allocationDistributions.map((option) => (
                        <MenuItem
                          key={option.allocation_distribution_ndx}
                          value={option.allocation_distribution_ndx}
                        >
                          {option.allocation_distribution_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Tooltip
              title={
                selectedPermit ? (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <Warning style={{ marginRight: "8px" }} />
                      Create an Administrative Record to modify this value
                      <Warning style={{ marginLeft: "8px" }} />
                    </>
                  </Box>
                ) : (
                  ""
                )
              }
            >
              <Grid
                item
                xs={12}
                md={6}
                xl={4}
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!!selectedPermit && (
                  <Error color="secondary" style={{ marginRight: "5px" }} />
                )}
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={!state.primary_use_ndx}
                  required
                >
                  {!lookups?.permitUses.length > 0 ? (
                    <Loader />
                  ) : (
                    <>
                      <InputLabel id="primary-use">Primary Use</InputLabel>
                      <Select
                        labelId="primary use"
                        id="primary-use"
                        label="Primary Use"
                        name="primary_use_ndx"
                        value={state.primary_use_ndx ?? ""}
                        onChange={(e) => {
                          handleUpdateState("primary_use_ndx", e.target.value);
                        }}
                      >
                        {lookups.permitUses.map((option) => (
                          <MenuItem
                            key={option.well_use_ndx}
                            value={option.well_use_ndx}
                          >
                            {option.well_use_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                  {!state.primary_use_ndx && (
                    <FormHelperText error>*Required field</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Tooltip>

            <Tooltip
              title={
                selectedPermit ? (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <Warning style={{ marginRight: "8px" }} />
                      Create an Administrative Record to modify this value
                      <Warning style={{ marginLeft: "8px" }} />
                    </>
                  </Box>
                ) : (
                  ""
                )
              }
            >
              <Grid
                item
                xs={12}
                md={6}
                xl={4}
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!!selectedPermit && (
                  <Error color="secondary" style={{ marginRight: "5px" }} />
                )}
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  {!lookups?.permitUses.length > 0 ? (
                    <Loader />
                  ) : (
                    <>
                      <InputLabel id="secondary-use">Secondary Use</InputLabel>
                      <Select
                        labelId="secondary use"
                        id="secondary-use"
                        label="Secondary Use"
                        name="secondary_use_ndx"
                        value={state.secondary_use_ndx ?? ""}
                        onChange={(e) => {
                          handleUpdateState(
                            "secondary_use_ndx",
                            e.target.value
                          );
                        }}
                      >
                        {lookups.permitUses.map((option) => (
                          <MenuItem
                            key={option.well_use_ndx}
                            value={option.well_use_ndx}
                          >
                            {option.well_use_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
            </Tooltip>

            <Grid
              item
              sx={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={state.is_terminated}
                    onChange={(e) =>
                      handleUpdateState("is_terminated", e.target.checked)
                    }
                    style={{
                      color: "rgba(0, 0, 0, .54)", // Replace with your desired color
                    }}
                  />
                }
                label={
                  <span
                  // style={{ color: "rgba(0, 0, 0, 0.87)" }}
                  >
                    TERMINATED (based on Permit Status)
                  </span>
                }
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={9}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Special Provisions"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("special_provisions", e.target.value)
                }
                value={state.special_provisions ?? ""}
              />
            </Grid>

            <Tooltip
              title={
                selectedPermit ? (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <Warning style={{ marginRight: "8px" }} />
                      Create an Administrative Record to modify this value
                      <Warning style={{ marginLeft: "8px" }} />
                    </>
                  </Box>
                ) : (
                  ""
                )
              }
            >
              <Grid
                item
                xs={12}
                md={6}
                xl={3}
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!!selectedPermit && (
                  <Error color="secondary" style={{ marginRight: "5px" }} />
                )}
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  required
                >
                  <TextField
                    error={state.authorized_volume_gal === null}
                    required
                    type="number"
                    variant="outlined"
                    label="Authorized Volume (gal)"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("authorized_volume_gal", null);
                      } else {
                        handleUpdateState(
                          "authorized_volume_gal",
                          +e.target.value
                        );
                      }
                    }}
                    value={state?.authorized_volume_gal ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                  {state.authorized_volume_gal === null ? (
                    <FormHelperText error>*Required field</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
            </Tooltip>

            <Grid
              item
              xs={12}
              md={6}
              xl={9}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Notes"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("notes", e.target.value)}
                value={state.notes ?? ""}
              />
            </Grid>

            <Grid
              item
              sx={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!state.permit_ndx}
                    checked={state.removed}
                    onChange={(e) =>
                      handleUpdateState("removed", e.target.checked)
                    }
                    color="primary"
                  />
                }
                label="REMOVED"
              />
            </Grid>

            {state?.permit_ndx && (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl style={{ width: "100%" }}>
                      <KeyboardDatePicker
                        disabled
                        format="LLLL do, yyyy"
                        inputVariant="outlined"
                        autoOk
                        id="created-on"
                        label="Created On"
                        value={state?.created_timestamp}
                        onChange={(date) => {
                          handleUpdateState("created_timestamp", date);
                        }}
                        InputAdornmentProps={{
                          "aria-label": "change created date",
                        }}
                        InputProps={{
                          style: {
                            color: "rgba(0,0,0,1)",
                          },
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <TextField
                    disabled
                    variant="outlined"
                    label="Created By"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateState("created_by", e.target.value)
                    }
                    value={state.created_by ?? ""}
                    InputProps={{
                      style: {
                        color: "rgba(0,0,0,1)",
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl style={{ width: "100%" }}>
                      <KeyboardDatePicker
                        disabled
                        format="LLLL do, yyyy"
                        inputVariant="outlined"
                        autoOk
                        id="updated-on"
                        label="Updated On"
                        value={state?.updated_timestamp}
                        onChange={(date) => {
                          handleUpdateState("updated_timestamp", date);
                        }}
                        InputAdornmentProps={{
                          "aria-label": "change updated date",
                        }}
                        InputProps={{
                          style: {
                            color: "rgba(0,0,0,1)",
                          },
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <TextField
                    disabled
                    variant="outlined"
                    label="Updated By"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateState("updated_by", e.target.value)
                    }
                    value={state.updated_by ?? ""}
                    InputProps={{
                      style: {
                        color: "rgba(0,0,0,1)",
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl style={{ width: "100%" }}>
                      <KeyboardDatePicker
                        disabled
                        format="LLLL do, yyyy"
                        inputVariant="outlined"
                        autoOk
                        id="removed-on"
                        label="Removed On"
                        value={state?.removed_timestamp}
                        onChange={(date) => {
                          handleUpdateState("removed_timestamp", date);
                        }}
                        InputAdornmentProps={{
                          "aria-label": "change removed date",
                        }}
                        InputProps={{
                          style: {
                            color: "rgba(0,0,0,1)",
                          },
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={2}
                  style={{
                    position: "relative",
                  }}
                >
                  <TextField
                    disabled
                    variant="outlined"
                    label="Removed By"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateState("removed_by", e.target.value)
                    }
                    value={state.removed_by ?? ""}
                    InputProps={{
                      style: {
                        color: "rgba(0,0,0,1)",
                      },
                    }}
                  />
                </Grid>
              </>
            )}

            {state?.permit_ndx && (
              <>
                <Grid item xs={12} sm={4}>
                  <MaterialTable
                    style={{
                      position: "relative",
                      border: "solid gray",
                      height: "214px",
                      overflow: "auto",
                    }}
                    id="linked-wells"
                    title="Wells Under this Permit"
                    loading={
                      tables.linkedWells.isFetching || !tables.linkedWells.data
                    }
                    columns={LINKED_WELLS_TABLE_COLUMNS}
                    data={tables?.linkedWells?.data ?? []}
                    options={{
                      showTitle: false,
                      toolbar: false,
                      columnsButton: false,
                      exportButton: false,
                      exportAllData: false,
                      padding: "dense",
                      search: false,
                      paging: false,
                      cellStyle: { border: "1px solid #eee" },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <MaterialTable
                    style={{
                      position: "relative",
                      border: "solid gray",
                      height: "214px",
                      overflow: "auto",
                    }}
                    id="associated-permits"
                    title="Other Permits Owned by this Permittee"
                    loading={
                      tables.linkedPermits.isFetching ||
                      !tables.linkedPermits.data
                    }
                    columns={LINKED_PERMITS_TABLE_COLUMNS}
                    data={tables?.linkedPermits?.data ?? []}
                    options={{
                      showTitle: false,
                      toolbar: false,
                      columnsButton: false,
                      exportButton: false,
                      exportAllData: false,
                      padding: "dense",
                      search: false,
                      paging: false,
                      cellStyle: { border: "1px solid #eee" },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <MaterialTable
                    style={{
                      position: "relative",
                      border: "solid gray",
                      height: "214px",
                      overflow: "auto",
                    }}
                    id="associated-meters"
                    title="Meters Under this Permit"
                    loading={
                      tables.linkedMeters.isFetching ||
                      !tables.linkedMeters.data
                    }
                    columns={LINKED_METERS_TABLE_COLUMNS}
                    data={tables?.linkedMeters?.data ?? []}
                    options={{
                      showTitle: false,
                      toolbar: false,
                      columnsButton: false,
                      exportButton: false,
                      exportAllData: false,
                      padding: "dense",
                      search: false,
                      paging: false,
                      cellStyle: { border: "1px solid #eee" },
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Grid
            container
            spacing={3}
            style={{
              border: "3px solid gray",
              marginBottom: "15px",
              marginTop: "15px",
              borderRadius: "4px",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{
                padding: "8px 0 0 16px",
                fontWeight: 600,
                width: "100%",
              }}
            >
              Alternate Contacts
            </Typography>
            <Grid
              item
              xs={12}
              xl={6}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!lookups?.permitteeContactRecords.length > 0 &&
              state.alternate_rolo_ndx ? (
                <Loader />
              ) : (
                <Box px={4} my={3} display="flex" alignItems="center">
                  <div style={{ width: "100%" }}>
                    <LinkedPermitteeFuzzySearch
                      disabled={state?.permittee_add_new}
                      options={lookups?.permitteeContactRecords}
                      onSelect={(e) => {
                        handleUpdateState("alternate_rolo_ndx", e);
                      }}
                      placeholderSubject="Contact"
                    />
                    <InputLabel
                      style={{ marginTop: "6px" }}
                      id="alternate-contact"
                    >
                      Alternate Contact
                    </InputLabel>
                    {state?.alternate_rolo_ndx ? (
                      <Link
                        color="secondary"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          marginTop: "5px",
                        }}
                        href={`/data-management/well-owners/edit-owner-info/${state.alternate_rolo_ndx}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lookups?.permitteeContactRecords?.find(
                          (contact) =>
                            contact.rolo_ndx === state.alternate_rolo_ndx
                        )?.concatenated_contact_info ?? ""}
                      </Link>
                    ) : (
                      <Typography
                        color="primary"
                        style={{
                          fontSize: "18px",
                          marginTop: "5px",
                        }}
                      >
                        ** No Alternate Owner Selected **
                      </Typography>
                    )}
                  </div>
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              xl={6}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!lookups?.permitteeContactRecords.length > 0 &&
              state.billing_rolo_ndx ? (
                <Loader />
              ) : (
                <Box px={4} my={3} display="flex" alignItems="center">
                  <div style={{ width: "100%" }}>
                    <LinkedPermitteeFuzzySearch
                      disabled={state?.permittee_add_new}
                      options={lookups?.permitteeContactRecords}
                      onSelect={(e) => {
                        handleUpdateState("billing_rolo_ndx", e);
                      }}
                      placeholderSubject="Contact"
                    />
                    <InputLabel
                      style={{ marginTop: "6px" }}
                      id="billing-contact"
                    >
                      Billing Contact
                    </InputLabel>
                    {state?.billing_rolo_ndx ? (
                      <Link
                        color="secondary"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          marginTop: "5px",
                        }}
                        href={`/data-management/well-owners/edit-owner-info/${state.billing_rolo_ndx}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lookups?.permitteeContactRecords?.find(
                          (contact) =>
                            contact.rolo_ndx === state.billing_rolo_ndx
                        )?.concatenated_contact_info ?? ""}
                      </Link>
                    ) : (
                      <Typography
                        color="primary"
                        style={{
                          fontSize: "18px",
                          marginTop: "5px",
                        }}
                      >
                        ** No Billing Owner Selected **
                      </Typography>
                    )}
                  </div>
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              xl={6}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!lookups?.permitteeContactRecords.length > 0 &&
              state.meter_rolo_ndx ? (
                <Loader />
              ) : (
                <Box px={4} my={3} display="flex" alignItems="center">
                  <div style={{ width: "100%" }}>
                    <LinkedPermitteeFuzzySearch
                      disabled={state?.permittee_add_new}
                      options={lookups?.permitteeContactRecords}
                      onSelect={(e) => {
                        handleUpdateState("meter_rolo_ndx", e);
                      }}
                      placeholderSubject="Contact"
                    />
                    <InputLabel
                      style={{ marginTop: "6px" }}
                      id="meter-readings-contact"
                    >
                      Meter Readings Contact
                    </InputLabel>
                    {state?.meter_rolo_ndx ? (
                      <Link
                        color="secondary"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          marginTop: "5px",
                        }}
                        href={`/data-management/well-owners/edit-owner-info/${state.meter_rolo_ndx}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lookups?.permitteeContactRecords?.find(
                          (contact) => contact.rolo_ndx === state.meter_rolo_ndx
                        )?.concatenated_contact_info ?? ""}
                      </Link>
                    ) : (
                      <Typography
                        color="primary"
                        style={{
                          fontSize: "18px",
                          marginTop: "5px",
                        }}
                      >
                        ** No Meter Owner Selected **
                      </Typography>
                    )}
                  </div>
                </Box>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={handleResetState}>
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={
              !isDirty ||
              !state.permit_number ||
              !state.permit_type_ndx ||
              !state.permit_status_ndx ||
              !state.aquifer_group_ndx ||
              !state.permit_year ||
              state.permit_year.length !== 4 ||
              !state.primary_use_ndx ||
              (state.permittee_add_new &&
                !state.tmp_permittee_name &&
                !state.tmp_permittee_email &&
                !state.tmp_permittee_phone &&
                !state.tmp_permittee_zip &&
                !state.tmp_permittee_address &&
                !state.tmp_permittee_city &&
                !state.tmp_permittee_state &&
                !state.tmp_permittee_organization &&
                !state.tmp_permittee_precinct_ndx) ||
              state.authorized_volume_gal === null ||
              isFormSubmitting
            }
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting, selectedPermit === 0)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default BasicPermitInfo;
