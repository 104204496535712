import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import useFetchData from "../../hooks/useFetchData";
import { useMemo } from "react";

export const useReviewRegistrations = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedRegistration, setSelectedRegistration] =
    useStateIfMounted(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["data-registrations"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-registrations`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [lookupWellUse] = useFetchData("ui-reg-list-well-use", [], false);
  const wellUseLookupForTable = useMemo(() => {
    let converted = {};
    if (lookupWellUse?.length > 0) {
      lookupWellUse.forEach((item) => {
        converted[item.well_use_ndx] = item.well_use_desc;
      });
    }
    return converted;
  }, [lookupWellUse]);

  const [lookupWellAquiferGroups] = useFetchData(
    "ui-reg-list-aquifer-groups",
    [],
    false
  );
  const aquiferGroupsLookupForTable = useMemo(() => {
    let converted = {};
    if (lookupWellAquiferGroups?.length > 0) {
      lookupWellAquiferGroups.forEach((item) => {
        converted[item.aquifer_group_ndx] = item.aquifer_group_name;
      });
    }
    return converted;
  }, [lookupWellAquiferGroups]);

  const REVIEW_REGISTRATIONS_TABLE_COLUMNS = [
    {
      title: "Records?",
      field: "hardcopy_well_records",
      type: "boolean",
    },
    {
      title: "First Name",
      field: "first_name",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
    },
    {
      title: "Last Name",
      field: "last_name",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
    },
    {
      title: "Phone#",
      field: "primary_phone",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Use",
      field: "well_use_ndx",
      lookup: wellUseLookupForTable,
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Aquifer",
      field: "aquifer_group_ndx",
      lookup: aquiferGroupsLookupForTable,
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Date Drilled",
      field: "well_details_date_drilled",
      type: "date",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Complete",
      field: "completed",
      type: "boolean",
      defaultFilter: "unchecked",
    },
    {
      title: "Disregard?",
      field: "disregard",
      type: "boolean",
      defaultFilter: "unchecked",
    },
  ];

  return {
    selectedRegistration,
    setSelectedRegistration,
    data,
    isFetching,
    error,
    refetch,
    REVIEW_REGISTRATIONS_TABLE_COLUMNS,
  };
};
