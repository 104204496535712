import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";

import Loader from "../../../../components/Loader";
import { LINKED_PERMITS_TABLE_COLUMNS } from "./PermitGroupsConstants";
import { useSubmitData } from "../../../../hooks/useSubmitData";
import { getButtonIcon, getButtonText } from "../wellsManagement/utils";
import MaterialTable from "material-table";
import { useBasicPermitGroupInfo } from "./useBasicPermitGroupInfo";

function BasicPermitGroupInfo({
  selectedPermitGroup,
  setSelectedPermitGroup,
  refetchPermitGroups = () => {},
  isEditMode = false,
  triggerRefetch,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
    tables,
  } = useBasicPermitGroupInfo(selectedPermitGroup, triggerRefetch);

  const handleRefreshAfterSubmit = () => {
    refetchPermitGroups();
  };

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedPermitGroup,
    setSelectedPermitGroup,
    state,
    "list-permit-groups",
    "permit_group_ndx",
    refetch,
    handleRefreshAfterSubmit
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.permit_group_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.permit_group_ndx)
              ? "none"
              : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Permit Group Information</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              xl={6}
              style={{
                position: "relative",
              }}
            >
              <TextField
                error={!state.permit_group_name}
                helperText={!state.permit_group_name ? "*Required field" : ""}
                variant="outlined"
                label="Permit Group"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("permit_group_name", e.target.value)
                }
                value={state.permit_group_name ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              xl={6}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Notes"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("notes", e.target.value)}
                value={state.notes ?? ""}
              />
            </Grid>

            {state?.permit_group_ndx && (
              <>
                <Grid item xs={12} sm={6}>
                  <MaterialTable
                    style={{
                      position: "relative",
                      border: "solid gray",
                      height: "214px",
                      overflow: "auto",
                    }}
                    id="linked-permits"
                    title="Permits"
                    loading={
                      tables.linkedPermits.isFetching ||
                      !tables.linkedPermits.data
                    }
                    columns={LINKED_PERMITS_TABLE_COLUMNS}
                    data={tables?.linkedPermits?.data ?? []}
                    options={{
                      showTitle: false,
                      toolbar: false,
                      columnsButton: false,
                      exportButton: false,
                      exportAllData: false,
                      padding: "dense",
                      search: false,
                      paging: false,
                      cellStyle: { border: "1px solid #eee" },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!state.permit_group_ndx}
                        checked={state.removed}
                        onChange={(e) =>
                          handleUpdateState("removed", e.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label="REMOVED"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={handleResetState}>
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isDirty || !state.permit_group_name || isFormSubmitting}
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting, selectedPermitGroup === 0)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default BasicPermitGroupInfo;
