import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useAuth0 } from "@auth0/auth0-react";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

function Default() {
  const { user } = useAuth0();

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
          <Typography variant="subtitle1">
            Welcome back, {user?.name}!
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />
    </React.Fragment>
  );
}

export default Default;
