import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import { useStateIfMounted } from "use-state-if-mounted";
import { DEFAULT_ATTACHED_DOCUMENTS_STATE } from "./PermitsConstants";
import useFetchData from "../../../../hooks/useFetchData";

export const useAttachedDocuments = (selectedPermit) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useStateIfMounted(
    DEFAULT_ATTACHED_DOCUMENTS_STATE
  );

  const handleUpdateState = (name, value) => {
    setEditorState((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const { data, isFetching, error, refetch } = useQuery(
    ["assoc-permits-to-attachments", selectedPermit],
    async () => {
      if (!selectedPermit) {
        setEditorState(DEFAULT_ATTACHED_DOCUMENTS_STATE);
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/assoc-permits-to-attachments/${selectedPermit}`,
          { headers }
        );
        setEditorState(DEFAULT_ATTACHED_DOCUMENTS_STATE);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [attachmentTagsLookup] = useFetchData(
    "ui-list-permit-attachment-tags",
    [],
    true
  );

  return {
    data,
    isLoading: isFetching,
    error,
    refetch,
    lookups: {
      attachmentTags: attachmentTagsLookup,
    },
    state: editorState,
    setter: setEditorState,
    handleUpdateState,
  };
};
