import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { AccordionActions } from "@mui/material";
import React from "react";
import Loader from "../../../../../components/Loader";
import { useAdditionalLocationDetails } from "../hooks/useAdditionalLocationDetails";
import { useSubmitData } from "../../../../../hooks/useSubmitData";
import { BOOLEAN_OPTIONS } from "../constants";
import { getButtonIcon, getButtonText } from "../utils";

function AdditionalLocationDetails({
  selectedWell,
  setSelectedWell,
  isEditMode = false,
  selectedWellLabel,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
    lookups,
  } = useAdditionalLocationDetails(selectedWell);

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedWell,
    setSelectedWell,
    state,
    "list-wells-location-details",
    "well_ndx",
    refetch
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.well_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded={isEditMode}
        disabled={!selectedWell}
        style={{
          display:
            isLoading || (isEditMode && !state?.well_ndx) ? "none" : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography
              variant="h3"
              style={{ fontSize: "1.35rem", marginLeft: "20px" }}
            >{`Additional Location Details ${
              selectedWellLabel ? `(${selectedWellLabel})` : ""
            }`}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="enabled">In District</InputLabel>
                <Select
                  disabled={!selectedWell}
                  labelId="in-district-label"
                  id="in-district"
                  label="In District"
                  value={state?.in_district ?? ""}
                  onChange={(e) =>
                    handleUpdateState("in_district", e.target.value)
                  }
                >
                  {BOOLEAN_OPTIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.groundwaterManagementDistrict.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="groundwater-management-district">
                      Groundwater Management District
                    </InputLabel>
                    <Select
                      disabled={!selectedWell}
                      labelId="groundwater-management-district"
                      id="groundwater-management-district"
                      label="Groundwater Management District"
                      name="district_ndx"
                      value={state?.district_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("district_ndx", e.target.value);
                      }}
                    >
                      {lookups.groundwaterManagementDistrict.map((option) => (
                        <MenuItem
                          key={option.district_ndx}
                          value={option.district_ndx}
                        >
                          {option.district_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.county.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="county">County</InputLabel>
                    <Select
                      disabled={!selectedWell}
                      labelId="county"
                      id="county"
                      label="county"
                      name="county_ndx"
                      value={state?.county_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("county_ndx", e.target.value);
                      }}
                    >
                      {lookups.county.map((option) => (
                        <MenuItem
                          key={option.county_ndx}
                          value={option.county_ndx}
                        >
                          {option.county_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled={!selectedWell}
                variant="outlined"
                label="Well Reference Name"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("well_reference_name", e.target.value)
                }
                value={state?.well_reference_name ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled={!selectedWell}
                type="number"
                variant="outlined"
                label="Property Line Distance (ft)"
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    handleUpdateState("dist_property_line_ft", null);
                  } else {
                    handleUpdateState("dist_property_line_ft", +e.target.value);
                  }
                }}
                value={state?.dist_property_line_ft ?? ""}
                InputProps={{
                  inputProps: {
                    onKeyPress: (e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    },
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled={!selectedWell}
                type="number"
                variant="outlined"
                label="Distance to Septic (ft)"
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    handleUpdateState("dist_septic_ft", null);
                  } else {
                    handleUpdateState("dist_septic_ft", +e.target.value);
                  }
                }}
                value={state?.dist_septic_ft ?? ""}
                InputProps={{
                  inputProps: {
                    onKeyPress: (e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    },
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled={!selectedWell}
                variant="outlined"
                label="Directions to Well"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("directions_to_well", e.target.value)
                }
                value={state?.directions_to_well ?? ""}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button
            disabled={!selectedWell}
            size="small"
            onClick={handleResetState}
          >
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isDirty || isFormSubmitting}
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default AdditionalLocationDetails;
