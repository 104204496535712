import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import useFetchData from "../../../../hooks/useFetchData";
import { DEFAULT_CONTACT_INFO_STATE } from "./ContactsConstants";

export const useBasicContactInfo = (selectedContact) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useStateIfMounted(
    DEFAULT_CONTACT_INFO_STATE
  );
  const [isDirty, setIsDirty] = useStateIfMounted(false);

  const handleUpdateState = (name, value) => {
    if (!isDirty) setIsDirty(true);
    setEditorState((prevState) => {
      let newFilterValues = { ...prevState };
      if (name === "removed") {
        newFilterValues["should_remove"] = value;
      }
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const handleResetState = () => {
    setIsDirty(false);
    if (selectedContact) {
      setEditorState(data);
    } else {
      setEditorState(DEFAULT_CONTACT_INFO_STATE);
    }
  };

  const { data, isFetching, error, refetch } = useQuery(
    ["list-rolodex", selectedContact],
    async () => {
      if (!selectedContact) {
        setIsDirty(false);
        setEditorState(DEFAULT_CONTACT_INFO_STATE);
        return {};
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-rolodex/${selectedContact}`,
          { headers }
        );
        setIsDirty(false);
        setEditorState(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: linkedWellsTableData,
    isFetching: linkedWellsTableIsFetching,
    error: linkedWellsTableError,
    refetch: linkedWellsTableRefetch,
  } = useQuery(
    ["ui-list-rolo-linked-wells", selectedContact],
    async () => {
      if (!selectedContact) {
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-list-rolo-linked-wells/${selectedContact}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: linkedPermitsTableData,
    isFetching: linkedPermitsTableIsFetching,
    error: linkedPermitsTableError,
    refetch: linkedPermitsTableRefetch,
  } = useQuery(
    ["ui-list-rolo-linked-permits", selectedContact],
    async () => {
      if (!selectedContact) {
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-list-rolo-linked-permits/${selectedContact}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [directorPrecinctsLookup] = useFetchData(
    "ui-list-director-precincts",
    [],
    true
  );

  return {
    data,
    isLoading: isFetching,
    error,
    refetch,
    isDirty,
    lookups: {
      directorPrecincts: directorPrecinctsLookup,
    },
    tables: {
      linkedWells: {
        data: linkedWellsTableData,
        isFetching: linkedWellsTableIsFetching,
        error: linkedWellsTableError,
        refetch: linkedWellsTableRefetch,
      },
      linkedPermits: {
        data: linkedPermitsTableData,
        isFetching: linkedPermitsTableIsFetching,
        error: linkedPermitsTableError,
        refetch: linkedPermitsTableRefetch,
      },
    },
    handleUpdateState,
    handleResetState,
    state: editorState,
    setter: setEditorState,
  };
};
