export const DATE_FORMAT = "MMM dd yyyy, hh:mm a";

export const PAGINATION_OPTIONS = [
  10, 25, 50, 100,
  // , "All"
];
export const DEFAULT_PAGINATION = PAGINATION_OPTIONS[2];

export const USE_QUERY_OPTIONS = {
  keepPreviousData: false,
  refetchOnWindowFocus: false,
  staleTime: Infinity,
  cacheTime: Infinity,
};

export const FILENAME = `BartonSpringsData-${new Date()}.csv`;
