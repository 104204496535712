import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import Loader from "../../../../../components/Loader";
import { useAttachedDocumentsAndPhotos } from "../hooks/useAttachedDocumentsAndPhotos";
import UploadWells from "../../../../../components/uploads/UploadWells";

function AttachedDocumentsAndPhotos({
  selectedWell,
  isEditMode = false,
  selectedWellLabel,
}) {
  const {
    data,
    isLoading,
    error,
    refetch,
    lookups,
    state,
    setter,
    handleUpdateState,
  } = useAttachedDocumentsAndPhotos(selectedWell);

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading && (
        <Paper style={{ width: "100%", margin: "8px 0" }}>
          <Loader />
        </Paper>
      )}
      <Accordion
        defaultExpanded={isEditMode}
        disabled={!selectedWell}
        style={{
          display: isLoading ? "none" : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">{`Attached Documents and Photos ${
              selectedWellLabel ? `(${selectedWellLabel})` : ""
            }`}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <UploadWells
            selectedNdx={selectedWell}
            data={data}
            isLoading={isLoading}
            error={error}
            refetch={refetch}
            lookups={lookups}
            state={state}
            setter={setter}
            handleUpdateState={handleUpdateState}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default AttachedDocumentsAndPhotos;
