import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import { InputLabel, Link, TextField } from "@material-ui/core";
import React, { useMemo } from "react";
import useFetchData from "../../../../hooks/useFetchData";
import WellsFuzzySearch from "./WellsFuzzySearch";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format, parseISO } from "date-fns";

export const useManageAssociatedWells = (selectedMeter, triggerRefetch) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useStateIfMounted([]);

  const { isFetching, error, refetch } = useQuery(
    ["list-wells-associated-meters", selectedMeter, triggerRefetch],
    async () => {
      if (!selectedMeter) {
        setEditorState([]);
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-wells-associated-meters/${selectedMeter}`,
          { headers }
        );
        setEditorState(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [wellsLookup] = useFetchData("ui-list-meter-well-records", [], true);
  const wellsLookupForTable = useMemo(() => {
    let converted = {};
    if (wellsLookup?.length > 0) {
      wellsLookup.forEach((item) => {
        converted[item.well_ndx] = item.concatenated_well_info;
      });
    }
    return converted;
  }, [wellsLookup]);

  const MANAGE_ASSOCIATED_WELLS_TABLE_COLUMNS = [
    {
      title: "Well",
      field: "well_ndx",
      cellStyle: {
        width: 500,
        minWidth: 500,
      },
      editComponent: (props) => {
        return (
          <>
            <WellsFuzzySearch options={wellsLookup} onSelect={props.onChange} />
            <InputLabel style={{ marginTop: "6px" }}>
              Associated Well
            </InputLabel>
            <TextField
              disabled
              variant="outlined"
              style={{ width: "100%" }}
              value={wellsLookupForTable[props.value] || ""}
              error={!props.value}
              helperText={props.helperText} // This will show the helper text if validation fails.
            />
          </>
        );
      },
      validate: (rowData) =>
        !rowData.well_ndx
          ? {
              isValid: false,
              helperText: "This value is required",
            }
          : true,
      render: (rowData) => {
        return (
          <Link
            color="secondary"
            href={`/data-management/wells/wells-management/${rowData.well_ndx}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {wellsLookupForTable[rowData.well_ndx] || ""}
          </Link>
        );
      },
    },
    {
      title: "Start Date",
      field: "start_date",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
      editComponent: ({ value, onChange }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            clearable
            value={value ? parseISO(value) : null}
            onChange={(date) =>
              onChange(date ? format(date, "yyyy-MM-dd") : "")
            }
            format="yyyy-MM-dd"
            error={!value}
            helperText={!value ? "This value is required" : ""}
          />
        </MuiPickersUtilsProvider>
      ),
      validate: (rowData) =>
        !rowData.start_date
          ? {
              isValid: false,
            }
          : true,
    },
    {
      title: "End Date",
      field: "end_date",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
      editComponent: ({ value, onChange }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            clearable
            value={value ? parseISO(value) : null}
            onChange={(date) =>
              onChange(date ? format(date, "yyyy-MM-dd") : "")
            }
            format="yyyy-MM-dd"
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      title: "Notes",
      field: "notes",
      cellStyle: {
        width: 400,
        minWidth: 400,
      },
    },
  ];

  return {
    isLoading: isFetching,
    error,
    refetch,
    state: editorState,
    setter: setEditorState,
    MANAGE_ASSOCIATED_WELLS_TABLE_COLUMNS,
  };
};
