import { Button, Link } from "@material-ui/core";

export const CONTACTS_TABLE_COLUMNS = [
  {
    title: "Permittee?",
    field: "is_permitee",
    type: "boolean",
  },
  {
    title: "Name",
    field: "r_name",
    cellStyle: {
      width: 300,
      minWidth: 300,
    },
  },
  {
    title: "Email",
    field: "r_email",
    cellStyle: {
      width: 300,
      minWidth: 300,
    },
  },
  {
    title: "Phone",
    field: "r_phone",
    cellStyle: {
      width: 300,
      minWidth: 300,
    },
  },
  {
    title: "Zip Code",
    field: "r_zipcode",
    cellStyle: {
      width: 200,
      minWidth: 200,
    },
  },
  {
    title: "Mailing Address",
    field: "r_mailing_address",
    cellStyle: {
      width: 400,
      minWidth: 400,
    },
  },
  {
    title: "City",
    field: "r_city",
    cellStyle: {
      width: 200,
      minWidth: 200,
    },
  },
  {
    title: "State",
    field: "r_state",
    cellStyle: {
      width: 100,
      minWidth: 100,
    },
  },
  {
    title: "Organization",
    field: "r_organization",
    cellStyle: {
      width: 300,
      minWidth: 300,
    },
  },
  {
    title: "Notes",
    field: "notes",
    cellStyle: {
      width: 400,
      minWidth: 400,
    },
  },
  {
    title: "Removed",
    field: "removed",
    type: "boolean",
    defaultFilter: "unchecked",
  },
];

export const DEFAULT_CONTACT_INFO_STATE = {
  is_permitee: false,
  rolo_ndx: null,
  r_name: null,
  r_email: null,
  r_phone: null,
  r_zipcode: null,
  r_mailing_address: null,
  r_city: null,
  r_state: null,
  r_organization: null,
  notes: null,
  precinct_num: null,
  created_timestamp: null,
  created_by: null,
  updated_timestamp: null,
  updated_by: null,
  removed_timestamp: null,
  removed_by: null,
  removed: false,
  should_remove: false,
};

export const LINKED_WELLS_TABLE_COLUMNS = [
  {
    title: "Wells",
    field: "well_ndx",
    render: (rowData) => (
      <Button
        component={Link}
        target="_blank"
        rel="noreferrer noopener"
        href={`/data-management/wells/wells-management/${rowData.well_ndx}`}
        variant="contained"
        color="secondary"
        style={{ width: "100%" }}
        size="small"
      >
        {rowData.linked_well_identifier}
      </Button>
    ),
  },
];

export const LINKED_PERMITS_TABLE_COLUMNS = [
  {
    title: "Linked Permits",
    field: "permit_ndx",
    render: (rowData) => (
      <Button
        component={Link}
        target="_blank"
        rel="noreferrer noopener"
        href={`/data-management/permit-records/permits-management/${rowData.permit_ndx}`}
        variant="contained"
        color="secondary"
        style={{ width: "100%" }}
        size="small"
      >
        {rowData.linked_permit_identifier}
      </Button>
    ),
  },
];
