export const DEFAULT_METER_INFO_STATE = {
  meter_ndx: null,
  setup_well_ndx: null,
  meter_id: null,
  is_active: true,
  joined_date: new Date(),
  retired_date: null,
  meter_type: null,
  brand: null,
  meter_serial_number: null,
  notes: null,
  replaces_meter_ndx: null,

  created_by: null,
  updated_timestamp: null,
  updated_by: null,
};
