import React, { useState } from "react";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Divider as MuiDivider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";
import Link from "@material-ui/core/Link";
import { NavLink, useHistory } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import Loader from "../../components/Loader";
import useFetchData from "../../hooks/useFetchData";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getButtonIcon,
  NumberFormatCustom,
} from "../dataManagement/wells/wellsManagement/utils";
import RegLatLongMap from "../../components/map/RegLatLongMap";
import { useStateIfMounted } from "use-state-if-mounted";
import { useApp } from "../../AppProvider";
import { Alert } from "@material-ui/lab";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const DEFAULT_FORM_STATE = {
  rolo_ndx: null,
  first_name: null,
  last_name: null,
  primary_phone: null,
  primary_email: null,
  secondary_phone: null,
  secondary_email: null,
  mailing_name: null,
  mailing_address: null,
  mailing_city: null,
  mailing_state: null,
  mailing_zip: null,
  well_address: null,
  well_city: null,
  county_ndx: null,
  well_use_ndx: null,
  reg_lat_dd: null,
  reg_lon_dd: false,
  reg_well_elev_ft: null,
  aquifer_group_ndx: null,
  well_details_date_drilled: null,
  well_details_driller: null,
  well_details_depth_well_ft: null,
  well_details_depth_pump_ft: null,
  property_well_count: 1,
  scientific_monitoring_interest: true,
  hardcopy_well_records: null,
  other_notes: null,
  sameAsMailing: false,
};

export const BOOLEAN_OPTIONS = [
  {
    value: true,
    option: "Yes",
  },
  {
    value: false,
    option: "No",
  },
];
//388px
const PublicWellRegistration = () => {
  const { doToast } = useApp();
  const history = useHistory();

  const [formState, setFormState] = useState(DEFAULT_FORM_STATE);
  const [isFormSubmitting, setIsFormSubmitting] = useStateIfMounted(false);

  const handleUpdateState = (name, value) => {
    setFormState((prevState) => {
      let newFilterValues = { ...prevState };

      if (newFilterValues["sameAsMailing"] && name === "mailing_address") {
        newFilterValues["well_address"] = value;
      }

      if (newFilterValues["sameAsMailing"] && name === "mailing_city") {
        newFilterValues["well_city"] = value;
      }

      if (name === "sameAsMailing") {
        newFilterValues["well_address"] = newFilterValues["mailing_address"];
        newFilterValues["well_city"] = newFilterValues["mailing_city"];
      }

      newFilterValues[name] = value;

      return newFilterValues;
    });
  };

  const [lookupCounties] = useFetchData("ui-reg-list-counties", [], false);
  const [lookupWellUse] = useFetchData("ui-reg-list-well-use", [], false);
  const [lookupWellAquifer] = useFetchData(
    "ui-reg-list-aquifer-groups",
    [],
    false
  );

  const handleSubmit = () => {
    return (async () => {
      try {
        setIsFormSubmitting(true);

        const { data } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/data-registrations`,
          formState
        );
        setIsFormSubmitting(false);
        console.log(data);
        history.push("/public-well-registration/success");
        doToast("success", "Well registration successfully submitted");
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  return (
    <>
      <Helmet title="Public Well Registration" />
      <Typography variant="h3" gutterBottom display="inline">
        Public Well Registration
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Well Registration</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Alert severity="info">
        <Typography variant="h5">
          Please complete this registration form for the{" "}
          <strong>primary well</strong> on your property.
        </Typography>
      </Alert>

      <Paper style={{ padding: "15px" }}>
        <Typography variant="h6">
          First check this{" "}
          <Link
            component={NavLink}
            exact
            to="/map"
            target="_blank"
            rel="noreferrer noopener"
            color="secondary"
          >
            BSEACD interactive map
          </Link>{" "}
          to determine if you are in the BSEACD jurisdiction or in the HTGCD
          jurisdiction. Well owners in the{" "}
          <Link
            href="https://haysgroundwater.com/forms/well-registration-req-exist/"
            target="_blank"
            rel="noreferrer noopener"
            color="secondary"
          >
            HTGCD should register wells here
          </Link>
          .
        </Typography>

        <Grid
          container
          spacing={3}
          style={{
            border: "3px solid gray",
            marginBottom: "15px",
            marginTop: "15px",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{
              padding: "8px 0 0 16px",
              fontWeight: 600,
              width: "100%",
            }}
          >
            * 1. Property/Well Owner's Info
          </Typography>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              required
              error={!formState.first_name}
              variant="outlined"
              label="First Name"
              style={{ width: "100%" }}
              onChange={(e) => handleUpdateState("first_name", e.target.value)}
              value={formState.first_name ?? ""}
            />
            {!formState.first_name ? (
              <FormHelperText error>*Required field</FormHelperText>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              required
              error={!formState.last_name}
              variant="outlined"
              label="Last Name"
              style={{ width: "100%" }}
              onChange={(e) => handleUpdateState("last_name", e.target.value)}
              value={formState.last_name ?? ""}
            />
            {!formState.last_name ? (
              <FormHelperText error>*Required field</FormHelperText>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              required
              error={!formState.primary_phone}
              variant="outlined"
              label="Primary Phone"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateState("primary_phone", e.target.value)
              }
              value={formState.primary_phone ?? ""}
            />
            {!formState.primary_phone ? (
              <FormHelperText error>*Required field</FormHelperText>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              required
              error={!formState.primary_email}
              variant="outlined"
              label="Email Address"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateState("primary_email", e.target.value)
              }
              value={formState.primary_email ?? ""}
            />
            {!formState.primary_email ? (
              <FormHelperText error>*Required field</FormHelperText>
            ) : null}
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{
            border: "3px solid gray",
            marginBottom: "15px",
            marginTop: "15px",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{
              padding: "8px 0 0 16px",
              fontWeight: 600,
              width: "100%",
            }}
          >
            2. Secondary Contact Info (Optional)
          </Typography>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              variant="outlined"
              label="Secondary Phone"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateState("secondary_phone", e.target.value)
              }
              value={formState.secondary_phone ?? ""}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              variant="outlined"
              label="Secondary Email"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateState("secondary_email", e.target.value)
              }
              value={formState.secondary_email ?? ""}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{
            border: "3px solid gray",
            marginBottom: "15px",
            marginTop: "15px",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{
              padding: "8px 0 0 16px",
              fontWeight: 600,
              width: "100%",
            }}
          >
            * 3. Preferred Mailing Address - for receiving notices and
            correspondence
          </Typography>
          <Grid
            item
            xs={12}
            xl={6}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              variant="outlined"
              label="Organization"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateState("mailing_name", e.target.value)
              }
              value={formState.mailing_name ?? ""}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              required
              error={!formState.mailing_address}
              variant="outlined"
              label="Mailing Address"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateState("mailing_address", e.target.value)
              }
              value={formState.mailing_address ?? ""}
            />
            {!formState.mailing_address ? (
              <FormHelperText error>*Required field</FormHelperText>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              required
              error={!formState.mailing_city}
              variant="outlined"
              label="City"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateState("mailing_city", e.target.value)
              }
              value={formState.mailing_city ?? ""}
            />
            {!formState.mailing_city ? (
              <FormHelperText error>*Required field</FormHelperText>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              required
              error={!formState.mailing_state}
              variant="outlined"
              label="State"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateState("mailing_state", e.target.value)
              }
              value={formState.mailing_state ?? ""}
            />
            {!formState.mailing_state ? (
              <FormHelperText error>*Required field</FormHelperText>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              required
              error={!formState.mailing_zip}
              variant="outlined"
              label="ZIP/Postal COde"
              style={{ width: "100%" }}
              onChange={(e) => handleUpdateState("mailing_zip", e.target.value)}
              value={formState.mailing_zip ?? ""}
            />
            {!formState.mailing_zip ? (
              <FormHelperText error>*Required field</FormHelperText>
            ) : null}
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{
            border: "3px solid gray",
            marginBottom: "15px",
            marginTop: "15px",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{
              padding: "8px 0 0 16px",
              fontWeight: 600,
              width: "100%",
            }}
          >
            * 4. Physical Address of Primary Well (Physical Location)
          </Typography>

          <Grid
            item
            xs={12}
            style={{
              position: "relative",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.sameAsMailing}
                  onChange={(e) =>
                    handleUpdateState("sameAsMailing", e.target.checked)
                  }
                />
              }
              label="Same as Mailing Address"
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              required
              disabled={formState.sameAsMailing}
              error={!formState.well_address}
              variant="outlined"
              label="Physical Street Address"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateState("well_address", e.target.value)
              }
              value={formState.well_address ?? ""}
            />
            {!formState.well_address ? (
              <FormHelperText error>*Required field</FormHelperText>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              required
              disabled={formState.sameAsMailing}
              error={!formState.well_city}
              variant="outlined"
              label="City"
              style={{ width: "100%" }}
              onChange={(e) => handleUpdateState("well_city", e.target.value)}
              value={formState.well_city ?? ""}
            />
            {!formState.well_city ? (
              <FormHelperText error>*Required field</FormHelperText>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              position: "relative",
            }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              error={!formState.county_ndx}
              required
            >
              {!lookupCounties.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="county">County</InputLabel>
                  <Select
                    labelId="county"
                    id="county"
                    label="County"
                    name="county_ndx"
                    value={formState.county_ndx ?? ""}
                    onChange={(e) => {
                      handleUpdateState("county_ndx", e.target.value);
                    }}
                  >
                    {lookupCounties.map((option) => (
                      <MenuItem
                        key={option.county_ndx}
                        value={option.county_ndx}
                      >
                        {option.county_name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              {!formState.county_ndx && (
                <FormHelperText error>*Required field</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{
            border: "3px solid gray",
            marginBottom: "15px",
            marginTop: "15px",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{
              padding: "8px 0 0 16px",
              fontWeight: 600,
              width: "100%",
            }}
          >
            * 5. Primary Well - Water Use (*Select the Primary Use for this
            Well)
          </Typography>
          <Grid
            item
            xs={12}
            style={{
              position: "relative",
            }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              error={!formState.well_use_ndx}
              required
            >
              {!lookupWellUse.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="county">Primary Well Use</InputLabel>
                  <Select
                    labelId="primary-well-use"
                    id="primary-well-use"
                    label="Primary Well Use"
                    name="well_use_ndx"
                    value={formState.well_use_ndx ?? ""}
                    onChange={(e) => {
                      handleUpdateState("well_use_ndx", e.target.value);
                    }}
                  >
                    {lookupWellUse.map((option) => (
                      <MenuItem
                        key={option.well_use_ndx}
                        value={option.well_use_ndx}
                      >
                        {option.well_use_desc}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              {!formState.well_use_ndx && (
                <FormHelperText error>*Required field</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{
            border: "3px solid gray",
            marginBottom: "15px",
            marginTop: "15px",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{
              padding: "8px 0 0 16px",
              fontWeight: 600,
              width: "100%",
            }}
          >
            * 6. Lookup Well Location Coordinates
          </Typography>
          <Grid
            item
            xs={12}
            style={{
              position: "relative",
            }}
          >
            <RegLatLongMap
              filterValues={formState}
              setFilterValues={handleUpdateState}
              isEditorDirty={false}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            style={{
              position: "relative",
            }}
          >
            <TextField
              name="lat_dd"
              required
              error={
                !formState.reg_lat_dd ||
                formState.reg_lat_dd > 90 ||
                formState.reg_lat_dd < -90
              }
              helperText={
                !formState.reg_lat_dd && formState.reg_lat_dd !== 0
                  ? "*Required field"
                  : formState.reg_lat_dd > 90 || formState.reg_lat_dd < -90
                  ? "Invalid coordinate"
                  : ""
              }
              variant="outlined"
              label="Latitude"
              style={{ width: "100%" }}
              InputProps={{
                inputProps: {
                  config: {
                    decimalScale: 5,
                    fixedDecimalScale: true,
                  },
                },
                inputComponent: NumberFormatCustom,
              }}
              onChange={(e) => handleUpdateState("reg_lat_dd", e.target.value)}
              value={formState.reg_lat_dd ?? ""}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            style={{
              position: "relative",
            }}
          >
            <TextField
              name="lon_dd"
              required
              error={
                !formState.reg_lon_dd ||
                formState.reg_lon_dd > 180 ||
                formState.reg_lon_dd < -180
              }
              helperText={
                !formState.reg_lon_dd && formState.reg_lon_dd !== 0
                  ? "*Required field"
                  : formState.reg_lon_dd > 180 || formState.reg_lon_dd < -180
                  ? "Invalid coordinate"
                  : ""
              }
              variant="outlined"
              label="Longitude"
              style={{ width: "100%" }}
              InputProps={{
                inputProps: {
                  config: {
                    decimalScale: 5,
                    fixedDecimalScale: true,
                  },
                },
                inputComponent: NumberFormatCustom,
              }}
              onChange={(e) => handleUpdateState("reg_lon_dd", e.target.value)}
              value={formState.reg_lon_dd ?? ""}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            style={{
              position: "relative",
            }}
          >
            <TextField
              type="number"
              variant="outlined"
              label="Recorded Elevation"
              style={{ width: "100%" }}
              onChange={(e) => {
                if (e.target.value === "") {
                  handleUpdateState("reg_well_elev_ft", null);
                } else {
                  handleUpdateState("reg_well_elev_ft", +e.target.value);
                }
              }}
              value={formState.reg_well_elev_ft ?? ""}
              InputProps={{
                inputProps: {
                  onKeyPress: (e) => {
                    if (e.key === "e") {
                      e.preventDefault();
                    }
                  },
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{
            border: "3px solid gray",
            marginBottom: "15px",
            marginTop: "15px",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{
              padding: "8px 0 0 16px",
              fontWeight: 600,
              width: "100%",
            }}
          >
            * 7. Primary Well - Aquifer
          </Typography>
          <Grid
            item
            xs={12}
            style={{
              position: "relative",
            }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              error={!formState.aquifer_group_ndx}
              required
            >
              {!lookupWellAquifer.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="county">Primary Well Aquifer</InputLabel>
                  <Select
                    labelId="primary-well-aquifer"
                    id="primary-well-aquifer"
                    label="Primary Well Aquifer"
                    name="aquifer_group_ndx"
                    value={formState.aquifer_group_ndx ?? ""}
                    onChange={(e) => {
                      handleUpdateState("aquifer_group_ndx", e.target.value);
                    }}
                  >
                    {lookupWellAquifer.map((option) => (
                      <MenuItem
                        key={option.aquifer_group_ndx}
                        value={option.aquifer_group_ndx}
                      >
                        {option.aquifer_group_name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              {!formState.aquifer_group_ndx && (
                <FormHelperText error>*Required field</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{
            border: "3px solid gray",
            marginBottom: "15px",
            marginTop: "15px",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{
              padding: "8px 0 0 16px",
              fontWeight: 600,
              width: "100%",
            }}
          >
            8. Primary Well - Details (If Known)
          </Typography>
          <Grid
            item
            xs={12}
            md={6}
            xl={3}
            style={{
              position: "relative",
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormControl style={{ width: "100%" }}>
                <KeyboardDatePicker
                  format="LLLL do, yyyy"
                  inputVariant="outlined"
                  autoOk
                  id="drill date"
                  label="Date Well was Drilled"
                  value={formState?.well_details_date_drilled}
                  onChange={(date) => {
                    handleUpdateState("well_details_date_drilled", date);
                  }}
                  InputAdornmentProps={{
                    "aria-label": "drill date",
                  }}
                />
              </FormControl>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            xl={3}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              variant="outlined"
              label="Well Driller"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateState("well_details_driller", e.target.value)
              }
              value={formState.well_details_driller ?? ""}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            xl={3}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl variant="outlined" style={{ width: "100%" }} required>
              <TextField
                type="number"
                variant="outlined"
                label="Well Depth (ft)"
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    handleUpdateState("well_details_depth_well_ft", null);
                  } else {
                    handleUpdateState(
                      "well_details_depth_well_ft",
                      +e.target.value
                    );
                  }
                }}
                value={formState?.well_details_depth_well_ft ?? ""}
                InputProps={{
                  inputProps: {
                    onKeyPress: (e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    },
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            xl={3}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl variant="outlined" style={{ width: "100%" }} required>
              <TextField
                type="number"
                variant="outlined"
                label="Pump Depth (ft)"
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    handleUpdateState("well_details_depth_pump_ft", null);
                  } else {
                    handleUpdateState(
                      "well_details_depth_pump_ft",
                      +e.target.value
                    );
                  }
                }}
                value={formState?.well_details_depth_pump_ft ?? ""}
                InputProps={{
                  inputProps: {
                    onKeyPress: (e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    },
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{
            border: "3px solid gray",
            marginBottom: "15px",
            marginTop: "15px",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{
              padding: "8px 0 0 16px",
              fontWeight: 600,
              width: "100%",
            }}
          >
            * 9. If there is more than one well on your property, then you
            should complete a separate well registration form for the secondary
            well. Please indicate the total number of wells on your property
          </Typography>
          <Grid
            item
            xs={12}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl variant="outlined" required>
              <TextField
                required
                error={!formState.property_well_count}
                type="number"
                variant="outlined"
                label="Property Well Count"
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    handleUpdateState("property_well_count", null);
                  } else {
                    handleUpdateState("property_well_count", +e.target.value);
                  }
                }}
                value={formState?.property_well_count ?? ""}
                InputProps={{
                  inputProps: {
                    onKeyPress: (e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    },
                  },
                }}
              />
              {!formState.property_well_count && (
                <FormHelperText error>*Required field</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{
            border: "3px solid gray",
            marginBottom: "15px",
            marginTop: "15px",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{
              padding: "8px 0 0 16px",
              fontWeight: 600,
              width: "100%",
            }}
          >
            * 10. Would you be interested in having your well monitored for
            scientific purposes? (Please note this will help us identify willing
            well owners that we MAY contact IF we need an additional monitoring
            point in your vicinity.)
          </Typography>
          <Grid
            item
            xs={12}
            style={{
              position: "relative",
            }}
          >
            <FormControl required variant="outlined" style={{ width: "100%" }}>
              <InputLabel id="enabled">Scientific Well Monitoring</InputLabel>
              <Select
                labelId="scientific-well-monitoring"
                id="scientific-well-monitoring"
                label="Scientific Well Monitoring"
                value={formState?.scientific_monitoring_interest ?? ""}
                onChange={(e) =>
                  handleUpdateState(
                    "scientific_monitoring_interest",
                    e.target.value
                  )
                }
              >
                {BOOLEAN_OPTIONS.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{
            border: "3px solid gray",
            marginBottom: "15px",
            marginTop: "15px",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{
              padding: "8px 0 0 16px",
              fontWeight: 600,
              width: "100%",
            }}
          >
            * 11. Do you have any hardcopy well records for your well? (i.e.
            well reports, logs, well depth info)
          </Typography>
          <Grid
            item
            xs={12}
            style={{
              position: "relative",
            }}
          >
            <FormControl
              required
              variant="outlined"
              style={{ width: "100%" }}
              error={formState.hardcopy_well_records === null}
            >
              <InputLabel id="enabled">Hardcopy Well Records</InputLabel>
              <Select
                required
                labelId="hardcopy-well-records"
                id="hardcopy-well-records"
                label="Hardcopy Well Records"
                value={formState?.hardcopy_well_records ?? ""}
                onChange={(e) =>
                  handleUpdateState("hardcopy_well_records", e.target.value)
                }
              >
                {BOOLEAN_OPTIONS.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.option}
                  </MenuItem>
                ))}
              </Select>
              {formState.hardcopy_well_records === null && (
                <FormHelperText error>*Required field</FormHelperText>
              )}
              {formState.hardcopy_well_records && (
                <Alert severity="info" style={{ marginTop: "5px" }}>
                  <Typography variant="h5">
                    Please email all hardcopy well records to{" "}
                    <a href="mailto:bseacd@bseacd.org">bseacd@bseacd.org</a>
                  </Typography>
                </Alert>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{
            border: "3px solid gray",
            marginBottom: "15px",
            marginTop: "15px",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{
              padding: "8px 0 0 16px",
              fontWeight: 600,
              width: "100%",
            }}
          >
            12. Do you have any other comments that you would like to share
          </Typography>
          <Grid
            item
            xs={12}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              variant="outlined"
              label="Comments"
              style={{ width: "100%" }}
              onChange={(e) => handleUpdateState("other_notes", e.target.value)}
              value={formState.other_notes ?? ""}
            />
          </Grid>
        </Grid>

        <Typography variant="h6" style={{ margin: "10px 0" }}>
          Thank you for filling out the Well Registration online! By pressing
          the "SUBMIT WELL REGISTRATION FORM" button below, you certify the
          following:
        </Typography>

        <Typography variant="subtitle2" style={{ marginBottom: "20px" }}>
          <i>
            I hereby register my well with the Barton Springs/Edwards Aquifer
            Conservation District for the purposes indicated above for the water
            well described herein, and I certify that I am the property
            owner/grantor or lessee/grantee or an Authorized Agent, and that
            each and all the statements herein are true and correct, and that I
            will comply with District Rules, Well Construction Standards, and
            groundwater use permit and plan requirements if applicable. With
            prior advanced 72 hr notice, I hereby authorize the District access
            to this property to make any well inspections and/or collect water
            samples as necessary at the well location.{" "}
          </i>
        </Typography>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={
              isFormSubmitting ||
              !formState.first_name ||
              !formState.last_name ||
              !formState.primary_phone ||
              !formState.primary_email ||
              !formState.mailing_address ||
              !formState.mailing_city ||
              !formState.mailing_state ||
              !formState.mailing_zip ||
              !formState.well_address ||
              !formState.well_city ||
              !formState.county_ndx ||
              !formState.well_use_ndx ||
              !formState.reg_lat_dd ||
              !formState.reg_lon_dd ||
              !formState.aquifer_group_ndx ||
              !formState.property_well_count
            }
            startIcon={getButtonIcon(isFormSubmitting)}
          >
            {isFormSubmitting ? "Submitting" : "Submit Well Registration Form"}
          </Button>
        </div>
      </Paper>
    </>
  );
};

export default PublicWellRegistration;
