import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
  Paper,
  Typography,
} from "@material-ui/core";
import LayersIcon from "@material-ui/icons/Layers";
import MapIcon from "@material-ui/icons/Map";
import ExpandMore from "@material-ui/icons/ExpandMore";
import styled from "styled-components/macro";
import LayersControl from "../layersControl";
import BasemapsControl from "../basemapsControl";
import { GroupWork } from "@material-ui/icons";
import AdjacentWellsControl from "../adjacentWellsControl";

const Container = styled(Paper)`
  background: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  left: 49px;
  position: absolute;
  top: 10px;
  max-height: calc(100% - 32px);
  overflow-x: hidden;
  overflow-y: scroll;
  width: 315px;
  z-index: 3;
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  background-color: #fafafa;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  max-height: 384px;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
`;

const MainControl = ({
  activeBasemap,
  basemaps,
  layers,
  onBasemapChange,
  filters,
  onLayerChange,
  onOpacityChange,
  onBooleanChange,
  value,
  activeStyle,
  bufferValuesAdjacentWells,
  onBufferValuesChangeAdjacentWells,
  onClearBuffersAdjacentWells,
  onResetBuffersAdjacentWells,
  onEnableSearchRadiusControlAdjacentWells,
  intersectWellsEnabled,
  setIntersectWellsEnabled,
  addBuffersToMapAdjacentWells,
  map,
}) => {
  const [expandedItem, setExpandedItem] = useState("layers");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedItem(isExpanded ? panel : false);

    if (panel === "adjacent-wells") {
      onEnableSearchRadiusControlAdjacentWells(isExpanded);
    } else {
      onEnableSearchRadiusControlAdjacentWells(false);
    }
  };

  return (
    <Container>
      {value === "attributes_search" && (
        <Accordion
          expanded={expandedItem === "basemaps"}
          onChange={handleChange("basemaps")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box alignItems="center" display="flex" gridColumnGap={8}>
              <MapIcon />
              <Typography variant="subtitle1">Standard Basemaps</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <BasemapsControl
              items={basemaps}
              value={activeBasemap}
              onBasemapChange={onBasemapChange}
              filters={filters}
            />
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion
        expanded={expandedItem === "layers"}
        onChange={handleChange("layers")}
      >
        <AccordionSummary
          aria-controls="layers-content"
          id="layers-header"
          expandIcon={<ExpandMore />}
        >
          <Box alignItems="center" display="flex" gridColumnGap={8}>
            <LayersIcon />
            <Typography variant="subtitle1">Layers</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <LayersControl
            items={layers}
            onLayerChange={onLayerChange}
            onOpacityChange={onOpacityChange}
            onBooleanChange={onBooleanChange}
            activeStyle={activeStyle}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedItem === "adjacent-wells"}
        onChange={handleChange("adjacent-wells")}
      >
        <AccordionSummary
          aria-controls="adjacent-wells-content"
          id="adjacent-wells-content"
          expandIcon={<ExpandMore />}
        >
          <Box alignItems="center" display="flex" gridColumnGap={8}>
            <GroupWork />
            <Typography variant="subtitle1">Adjacent Wells Tool</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails maxheight="384px">
          <AdjacentWellsControl
            bufferValues={bufferValuesAdjacentWells}
            onBufferValuesChange={onBufferValuesChangeAdjacentWells}
            onClearBuffers={onClearBuffersAdjacentWells}
            onResetBuffers={onResetBuffersAdjacentWells}
            intersectWellsEnabled={intersectWellsEnabled}
            setIntersectWellsEnabled={setIntersectWellsEnabled}
            addBuffersToMap={addBuffersToMapAdjacentWells}
            map={map}
          />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default MainControl;
