import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { NavLink, useParams } from "react-router-dom";

import BasicReviewRegistrationInfo from "./BasicReviewRegistrationInfo";
import { useStateIfMounted } from "use-state-if-mounted";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

function ReviewRegistrationManagement() {
  let { id } = useParams();
  const [selectedRegistration, setSelectedRegistration] = useStateIfMounted(id);

  return (
    <React.Fragment>
      <Helmet title="Public Well Registration" />
      <Typography variant="h3" gutterBottom display="inline">
        Review Public Well Registration
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Link
          component={NavLink}
          exact
          to="/data-management/wells/validate-registration-submissions"
        >
          Public Well Registrations
        </Link>
        <Typography>Review Public Well Registration</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <BasicReviewRegistrationInfo
        selectedRegistration={selectedRegistration}
        setSelectedRegistration={setSelectedRegistration}
        isEditMode={true}
      />
    </React.Fragment>
  );
}

export default ReviewRegistrationManagement;
