const wQData = {
  name: "wQData",
  ndx: 6,
  field: "w_q_data_ndx",
  label: "WQ Data",
  endpoint: "query-and-download/q-d-data-w-q-data",
  icon: "Timeline",
  inputs: [],
};

export default wQData;
