import React, { useState, useMemo, useEffect, useRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  Box,
  Chip,
  TextField,
  Button,
  ButtonGroup,
  Tooltip,
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
  Typography,
  Select,
  MenuItem,
  Popper,
  Paper,
  ClickAwayListener,
  List,
  ListItem,
  Grid,
  Divider,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { Search, Cancel } from "@material-ui/icons";
import useDebounce from "../../hooks/useDebounce";
import Loader from "../Loader";
import { Pagination } from "@material-ui/lab";
import {
  DEFAULT_PAGINATION,
  PAGINATION_OPTIONS,
} from "../../pages/ReportsAndAnalysis/queryAndDownload/constants";
import Fuse from "fuse.js";
import { titleize } from "inflected";

const SearchTextField = styled(TextField)`
  .MuiInputBase-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const SearchButtonGroup = styled(ButtonGroup)`
  height: 53px;
  .MuiButton-root {
    width: 80px;
  }
`;

const SearchButton = styled(Button)`
  &:first-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const ChipsContainer = styled.div`
  background-color: #fafafa;
  border: 1px solid
    ${({ theme, error }) => (error ? theme.palette.error.main : "#ddd")};
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 16px;
  margin-top: 5px;
`;

const StyledListItem = styled(ListItem)`
  flex-direction: column;
  align-items: flex-start;
  border-left: 5px solid transparent;
  &:hover {
    background-color: ${(props) => props.theme.palette.action.hover};
    outline: 1px solid rgba(0, 0, 0, 0.12);
  }
  ${(props) =>
    props.selected &&
    `
    background-color: ${props.theme.palette.action.selected};
    border-left: 5px solid #3e485b;
  `}
`;

export const ChipSelect = ({
  name,
  label,
  valueField,
  displayField,
  tooltipField,
  options,
  isLoading,
  debounceDelay = 100,
  fuseKeys,
}) => {
  const {
    watch,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGINATION);
  const [dropdownValue, setDropdownValue] = useState("" + DEFAULT_PAGINATION);
  const [searchTerm, setSearchTerm] = useState("");
  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const debouncedSearchTerm = useDebounce(searchTerm, debounceDelay);
  const watchedValues = watch(name);

  const textFieldRef = useRef(null);
  const fuse = useMemo(() => {
    return new Fuse(options, {
      keys: fuseKeys,
      threshold: 0.3,
    });
  }, [options, fuseKeys]);

  const [popperWidth, setPopperWidth] = useState(null);

  const [observerInitialized, setObserverInitialized] = useState(false);

  useEffect(() => {
    if (options.length && !observerInitialized) {
      setObserverInitialized(true);
    }
  }, [options.length, observerInitialized]);

  useEffect(() => {
    if (observerInitialized) {
      const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target === textFieldRef.current) {
            setPopperWidth(entry.contentRect.width);
          }
        }
      });

      if (textFieldRef.current) {
        observer.observe(textFieldRef.current);
      }

      return () => {
        observer.disconnect();
      };
    }
  }, [observerInitialized]);

  const handleSelectFromPopper = (option) => {
    const newValue = watchedValues.includes(option[valueField])
      ? watchedValues.filter((value) => value !== option[valueField])
      : [...watchedValues, option[valueField]];
    setValue(name, newValue);
    // setSearchTerm(""); // Optionally clear search term on select
  };

  const filteredOptions = useMemo(() => {
    let results = options;

    if (debouncedSearchTerm) {
      results = fuse.search(debouncedSearchTerm).map((item) => item.item);
    }

    return results.filter(
      (option) =>
        !showOnlySelected || watchedValues.includes(option[valueField])
    );
  }, [
    options,
    fuse,
    debouncedSearchTerm,
    showOnlySelected,
    watchedValues,
    valueField,
  ]);

  useEffect(() => {
    const totalPages =
      itemsPerPage === "All"
        ? 1
        : Math.ceil(filteredOptions.length / itemsPerPage);
    if (currentPage > totalPages) setCurrentPage(totalPages || 1);
  }, [filteredOptions.length, currentPage, itemsPerPage]);

  const paginatedOptions = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex =
      itemsPerPage === "All"
        ? filteredOptions.length
        : startIndex + itemsPerPage;
    return filteredOptions.slice(
      startIndex,
      Math.min(endIndex, filteredOptions.length)
    );
  }, [filteredOptions, currentPage, itemsPerPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const toggleSelectedView = () => {
    setShowOnlySelected(!showOnlySelected);
  };

  const handleSelectAll = async () => {
    setValue(
      name,
      filteredOptions.map((option) => option[valueField]),
      { shouldDirty: true }
    );

    if (!!Object.keys(errors).length) {
      await trigger(name);
    }
  };

  const handleDeselectAll = async () => {
    const valuesToDeselect = filteredOptions.map(
      (option) => option[valueField]
    );
    const updatedValues = getValues(name).filter(
      (value) => !valuesToDeselect.includes(value)
    );
    setValue(name, updatedValues, { shouldDirty: true });

    if (!!Object.keys(errors).length) {
      await trigger(name);
    }
  };

  function handleChipClick(field, optionValue) {
    const newValue = field.value.includes(optionValue)
      ? field.value.filter((v) => v !== optionValue)
      : [...field.value, optionValue];
    field.onChange(newValue);
  }

  const isDisabled = options.length === 0 || isLoading;
  if (isLoading) return <Loader />;
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl fullWidth error={Boolean(errors[name])}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <SearchTextField
              fullWidth
              variant="outlined"
              label={`Search ${label}`}
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              disabled={isDisabled}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end" style={{ marginRight: "5px" }}>
                    <IconButton
                      aria-label="clear search"
                      onClick={() => setSearchTerm("")}
                      edge="end"
                    >
                      <Cancel />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              ref={textFieldRef}
            />
            <SearchButtonGroup
              variant="outlined"
              color="primary"
              disabled={isDisabled}
            >
              <SearchButton variant="contained" onClick={handleSelectAll}>
                + All
              </SearchButton>
              <SearchButton onClick={handleDeselectAll}>- None</SearchButton>
            </SearchButtonGroup>
          </Box>
          <ChipsContainer error={Boolean(errors[name])}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={2}
              style={{ width: "100%", gap: "10px" }}
            >
              <Button
                onClick={toggleSelectedView}
                variant="contained"
                color={showOnlySelected ? "primary" : "default"}
              >
                {showOnlySelected ? "Show All" : "Show Selected"}
              </Button>
              <Pagination
                count={
                  itemsPerPage === "All"
                    ? 1
                    : Math.ceil(filteredOptions.length / itemsPerPage)
                }
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
              <FormControl variant="outlined" size="small">
                <Select
                  value={dropdownValue}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setDropdownValue(selectedValue);
                    setItemsPerPage(
                      selectedValue === "All"
                        ? filteredOptions.length
                        : parseInt(selectedValue, 10)
                    );
                    setCurrentPage(1);
                  }}
                  displayEmpty
                >
                  {PAGINATION_OPTIONS.map((number) => (
                    <MenuItem key={number} value={number.toString()}>
                      {number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {filteredOptions?.length > 0 && (
                <Typography variant="subtitle2">
                  {label}: <strong>{filteredOptions?.length}</strong>
                </Typography>
              )}
            </Box>
            {paginatedOptions.length ? (
              paginatedOptions.map((option) => (
                <Tooltip
                  key={option[valueField]}
                  title={option[tooltipField] || ""}
                >
                  <Chip
                    label={option[displayField]}
                    onClick={() => handleChipClick(field, option[valueField])}
                    color={
                      field.value.includes(option[valueField])
                        ? "primary"
                        : "default"
                    }
                    clickable
                  />
                </Tooltip>
              ))
            ) : (
              <Typography
                align="center"
                variant="subtitle1"
                style={{ width: "100%" }}
              >
                No options {showOnlySelected ? "selected" : "available"}
              </Typography>
            )}
          </ChipsContainer>
          {!!errors[name] && (
            <FormHelperText style={{ marginLeft: "16px" }}>
              {errors[name]?.message}
            </FormHelperText>
          )}
          <Popper
            open={Boolean(searchTerm && filteredOptions.length)}
            anchorEl={textFieldRef.current}
            placement="bottom-start"
            style={{ zIndex: 1 }}
            transition
          >
            <ClickAwayListener onClickAway={() => setSearchTerm("")}>
              <Paper
                style={{
                  width: `${popperWidth}px`,
                  height: 470,
                  overflowY: "auto",
                }}
              >
                <List dense component="nav">
                  {filteredOptions?.slice(0, 49)?.map((result, index) => {
                    return (
                      <React.Fragment key={index}>
                        <StyledListItem
                          button
                          onClick={() => handleSelectFromPopper(result)}
                          selected={watchedValues?.includes(result[valueField])}
                        >
                          <Typography variant="subtitle1">
                            {result?.[fuseKeys[0]]}{" "}
                            {/* Always display the first key as subtitle */}
                          </Typography>
                          <Grid container>
                            {fuseKeys.slice(1).map((key) => (
                              <Grid item xs={12} md={6} lg={4} key={key}>
                                <Typography variant="caption">{key}</Typography>
                                <Typography variant="body1">
                                  {result[key] ? titleize(result[key]) : "N/A"}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                        </StyledListItem>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
                </List>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </FormControl>
      )}
    />
  );
};
