export const BASEMAP_STYLES = [
  {
    style: "outdoors-v11",
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11",
  },
  {
    style: "streets-v11",
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
  },
  {
    style: "satellite-streets-v11",
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  {
    style: "light-v10",
    name: "Light",
    url: "mapbox://styles/mapbox/light-v10",
  },
  {
    style: "dark-v10",
    name: "Dark",
    url: "mapbox://styles/mapbox/dark-v10",
  },
];

export const DEFAULT_MAP_CENTER = [-97.85184, 30.16823];

export const INIT_MAP_CONFIG = {
  style: BASEMAP_STYLES[0].url,
  center: DEFAULT_MAP_CENTER,
  zoom: 11,
  preserveDrawingBuffer: true,
};

export const WELLS_LAYER_ID = "bartonsprings-wells-circle-small";
export const WELLS_LABELS_LAYER_ID = "bartonsprings-wells-symbol";
export const INIT_FILTER_VALUES = {
  wellClass: {
    label: "Classification",
    name: "wellClass",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "well_class",
    options: [],
    type: "multi-select",
    value: [],
  },
  wellSubclass: {
    label: "Subclass",
    name: "wellSubclass",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "well_subclass",
    options: [],
    type: "multi-select",
    value: [],
  },
  monitoringStatus: {
    label: "Monitoring Status",
    name: "monitoringStatus",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "monitoring_status",
    options: [],
    type: "multi-select",
    value: [],
  },
  primaryUse: {
    label: "Primary Use",
    name: "primaryUse",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "primary_use",
    options: [],
    type: "multi-select",
    value: [],
  },
  managementZone: {
    label: "Management Zone",
    name: "managementZone",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "management_zone",
    options: [],
    type: "multi-select",
    value: [],
  },
  aquiferCompletion: {
    label: "Aquifer Completion",
    name: "aquiferCompletion",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "aquifer_completion",
    options: [],
    type: "multi-select",
    value: [],
  },
  aquiferSetting: {
    label: "Aquifer Setting",
    name: "aquiferSetting",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "aquifer_setting",
    options: [],
    type: "multi-select",
    value: [],
  },

  isActive: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "is_active",
    type: "boolean",
    value: true,
  },
  inDistrict: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "in_district",
    type: "boolean",
    value: false,
  },
  isPermitted: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "is_permited",
    type: "boolean",
    value: false,
  },
  isMonitoring: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "is_monitoring",
    type: "boolean",
    value: false,
  },
  hasWaterLevels: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "has_water_levels",
    type: "boolean",
    value: false,
  },
  hasWqData: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "has_wq_data",
    type: "boolean",
    value: false,
  },
  hasProductionData: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "Production Data",
    type: "boolean",
    value: false,
  },
  hasGeoLog: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "has_geo_log",
    type: "boolean",
    value: false,
  },

  search: {
    layerId: WELLS_LAYER_ID,
    type: "select",
    value: "attributes_search",
  },
};

const layerId = "bartonsprings-wells-circle-small";
export const INIT_STYLE_VALUES = {
  wellClass: {
    id: "wellClass",
    layerId,
    layerFieldName: "well_class",
    name: "Classification",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "icon-color": [
        "match",
        ["get", "well_class"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  wellSubclass: {
    id: "wellSubclass",
    layerId,
    layerFieldName: "well_subclass",
    name: "Subclass",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "icon-color": [
        "match",
        ["get", "well_subclass"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  monitoringStatus: {
    id: "monitoringStatus",
    layerId,
    layerFieldName: "monitoring_status",
    name: "Monitoring Status",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "icon-color": [
        "match",
        ["get", "monitoring_status"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  primaryUse: {
    id: "primaryUse",
    layerId,
    layerFieldName: "primary_use",
    name: "Primary Use",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "icon-color": [
        "match",
        ["get", "primary_use"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  managementZone: {
    id: "managementZone",
    layerId,
    layerFieldName: "management_zone",
    name: "Management Zone",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "icon-color": [
        "match",
        ["get", "management_zone"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  aquiferCompletion: {
    id: "aquiferCompletion",
    layerId,
    layerFieldName: "aquifer_completion",
    name: "Aquifer Completion",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "icon-color": [
        "match",
        ["get", "aquifer_completion"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  aquiferSetting: {
    id: "aquiferSetting",
    layerId,
    layerFieldName: "aquifer_setting",
    name: "Aquifer Setting",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "icon-color": [
        "match",
        ["get", "aquifer_setting"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },

  // wellDepth: {
  //   id: "wellDepth",
  //   layerId,
  //   layerFieldName: "well_depth_ft",
  //   units: " (ft)",
  //   name: "Well Depth (ft)",
  //   options: [],
  //   type: "multi-select",
  //   value: [],
  //   paint: {
  //     "icon-color": [
  //       "case",
  //       ["<", ["get", "well_depth_ft"], 100],
  //       "#FE0000",
  //       ["<", ["get", "well_depth_ft"], 300],
  //       "#FFFB00",
  //       ["<", ["get", "well_depth_ft"], 500],
  //       "#FFA500",
  //       ["<", ["get", "well_depth_ft"], 700],
  //       "#009A00",
  //       ["<", ["get", "well_depth_ft"], 900],
  //       "#0030FF",
  //       [">=", ["get", "well_depth_ft"], 900],
  //       "#800080",
  //       "#000000",
  //     ],
  //   },
  // },
};
