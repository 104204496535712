import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useState, useMemo } from "react";
import useFetchData from "../../../../hooks/useFetchData";
import { TextField } from "@material-ui/core";
export const useMonthlyDistributions = ({ selectedPermit, triggerRefetch }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [value, setValue] = useState({});

  // const handleUpdateState = useCallback((name, value) => {
  //   setValue((prev) => {
  //     return {
  //       ...prev,
  //       [name]: value,
  //     };
  //   });
  // }, []);

  const { data, isFetching, error, refetch } = useQuery(
    ["data-permits-monthly-distributions", selectedPermit, triggerRefetch],
    async () => {
      if (!selectedPermit) {
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-permits-monthly-distributions/${selectedPermit}`,
          { headers }
        );
        let converted = {};
        data.forEach((item) => {
          converted[item.c_month] = item.alloc_percentage;
        });
        setValue(converted);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [monthsLookup] = useFetchData("list-months", [], true);
  const monthsLookupForTable = useMemo(() => {
    let converted = {};
    if (monthsLookup?.length > 0) {
      monthsLookup.forEach((item) => {
        converted[item.month_number] = item.month_name;
      });
    }
    return converted;
  }, [monthsLookup]);

  const MONTHLY_DISTRIBUTIONS_TABLE_COLUMNS = useMemo(
    () => [
      {
        title: "Calendar Month",
        field: "c_month",
        lookup: monthsLookupForTable,
        editable: "never",
        cellStyle: {
          width: "50%",
          minWidth: "50%",
        },
      },
      {
        title: "Distributed Percent",
        field: "alloc_percentage",
        editable: (value, rowData) => {
          return rowData.is_editable;
        },
        render: (rowData) => {
          const correctedPercentage =
            // Correcting floating point error
            Math.round(rowData.alloc_percentage * 100 * 1e12) / 1e12;

          return `${correctedPercentage}%`;
        },
        editComponent: (props) => {
          return (
            <TextField
              value={props.value}
              type="number"
              onChange={(e) => {
                props.onChange(e.target.value);
              }}
              InputProps={{
                inputProps: {
                  min: "0",
                  max: "1",
                  step: "0.00001",
                  onKeyPress: (e) => {
                    if (["e", "-"].includes(e.key)) {
                      e.preventDefault();
                    }
                  },
                },
              }}
            />
          );
        },
        cellStyle: {
          width: "50%",
          minWidth: "50%",
        },
      },
    ],
    [monthsLookupForTable, data] //eslint-disable-line
  );

  return {
    isLoading: isFetching,
    error,
    refetch,
    data,
    MONTHLY_DISTRIBUTIONS_TABLE_COLUMNS,
    currentValue:
      Math.round(
        Object.values(value).reduce((sum, value) => sum + value, 0) * 100 * 1e12
      ) / 1e12,
  };
};
