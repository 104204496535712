import { extractValuesWithKey, filterByCondition } from "../utils";

const wells = {
  name: "wells",
  ndx: 1,
  field: "well_ndx",
  label: "Wells",
  endpoint: "q-d-data-wells",
  icon: "Opacity",
  inputs: [
    {
      type: "tristateCheckbox",
      label: "Is Permitted",
      valueField: "is_permitted",
      formName: "isPermitted",
      defaultFormValue: null,
    },
    {
      type: "tristateCheckbox",
      label: "In District",
      valueField: "in_district",
      formName: "inDistrict",
      defaultFormValue: null,
    },
    {
      type: "tristateCheckbox",
      label: "Has Production Data",
      valueField: "has_production_data",
      formName: "hasProductionData",
      defaultFormValue: null,
    },
    {
      type: "tristateCheckbox",
      label: "Has Geo Log",
      valueField: "has_geo_log",
      formName: "hasGeoLog",
      defaultFormValue: null,
    },
    {
      type: "multiselect",
      label: "Well Classification",
      valueField: "well_class",
      displayField: "well_class",
      formName: "wellClass",
      filterEndpoint: "query-and-download/fetch-data-input/q-d-list-well-class",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(data, "well_class");
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "multiselect",
      label: "Well Sub-Class",
      valueField: "well_subclass",
      displayField: "well_subclass",
      formName: "wellSubclass",
      filterEndpoint:
        "query-and-download/fetch-data-input/q-d-list-well-subclass",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(data, "well_subclass");
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "multiselect",
      label: "Primary Well Use",
      valueField: "well_primary_use",
      displayField: "well_primary_use",
      formName: "wellPrimaryUse",
      filterEndpoint:
        "query-and-download/fetch-data-input/q-d-list-well-primary-use",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(data, "well_primary_use");
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "multiselect",
      label: "Well Status",
      valueField: "well_status",
      displayField: "well_status",
      formName: "wellStatus",
      filterEndpoint:
        "query-and-download/fetch-data-input/q-d-list-well-status",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(data, "well_status");
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "multiselect",
      label: "Monitoring Status",
      valueField: "well_monitoring_status",
      displayField: "well_monitoring_status",
      formName: "wellMonitoringStatus",
      filterEndpoint:
        "query-and-download/fetch-data-input/q-d-list-well-monitoring-status",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(
          data,
          "well_monitoring_status"
        );
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "multiselect",
      label: "Secondary Well Use",
      valueField: "well_secondary_use",
      displayField: "well_secondary_use",
      formName: "wellSecondaryUse",
      filterEndpoint:
        "query-and-download/fetch-data-input/q-d-list-well-secondary-use",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(data, "well_secondary_use");
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "multiselect",
      label: "Groundwater Management District",
      valueField: "loc_district",
      displayField: "loc_district",
      formName: "locDistrict",
      filterEndpoint:
        "query-and-download/fetch-data-input/q-d-list-loc-district",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(data, "loc_district");
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "multiselect",
      label: "County",
      valueField: "loc_county",
      displayField: "loc_county",
      formName: "locCounty",
      filterEndpoint: "query-and-download/fetch-data-input/q-d-list-loc-county",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(data, "loc_county");
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "multiselect",
      label: "Management Zone",
      valueField: "loc_mgmtzone",
      displayField: "loc_mgmtzone",
      formName: "locMgmtzone",
      filterEndpoint:
        "query-and-download/fetch-data-input/q-d-list-loc-mgmtzone",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(data, "loc_mgmtzone");
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "multiselect",
      label: "Aquifer Group",
      valueField: "hyg_aquifer_group",
      displayField: "hyg_aquifer_group",
      formName: "hygAquiferGroup",
      filterEndpoint:
        "query-and-download/fetch-data-input/q-d-list-hyg-aquifer-group",
      defaultFormValue: [],
      onSuccess: (data) => {
        const extractedData = extractValuesWithKey(data, "hyg_aquifer_group");
        const filterFunc = filterByCondition(() => true);
        return filterFunc(extractedData);
      },
    },
    {
      type: "checkbox",
      label: "Owner Information",
      field: "owner_info",
      formName: "includeOwnerInfo",
      defaultFormValue: true,
      excludeFields: ["owner_info"],
    },
    {
      type: "checkbox",
      label: "Permit Information",
      field: "permits_info",
      formName: "includePermitInfo",
      defaultFormValue: true,
      excludeFields: ["permits_info"],
    },
    {
      type: "checkbox",
      label: "Location Information",
      field: "loc_info",
      formName: "includeLocationInfo",
      defaultFormValue: true,
      excludePatterns: ["loc_"],
    },
    {
      type: "checkbox",
      label: "Drilling/Plugging Information",
      field: "drilling_plugging_info",
      formName: "includeDrillingPluggingInfo",
      defaultFormValue: true,
      excludePatterns: ["dr_"],
    },
    {
      type: "checkbox",
      label: "Hydrogeologic Information",
      field: "hydrogeologic_info",
      formName: "includeHydrogeologicInfo",
      defaultFormValue: true,
      excludePatterns: ["hyg_"],
    },
    {
      type: "search",
      label: "Wells",
      valueField: "well_ndx",
      displayField: "well_id",
      tooltipField: "well_name",
      formName: "wells",
      endpoint: "query-and-download/q-d-data-wells",
      defaultFormValue: [],
      queryParameter: true,
      fuseKeys: [
        "well_id",
        "well_formName",
        "owner_info",
        "permits_info",
        "loc_well_address",
        "well_city",
        "loc_county",
        "well_report_tracking_num",
        "dr_plugging_report_tracking_num",
        "driller_formName",
      ],
    },
  ],
};

export default wells;
