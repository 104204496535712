import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useMemo } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { InputLabel, TextField } from "@material-ui/core";
import useFetchData from "../../../../hooks/useFetchData";
import LinkedWellsFuzzySearch from "./LinkedWellsFuzzySearch";

export const useLinkedWellsManagement = (selectedPermit) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useStateIfMounted([]);

  const { isFetching, error, refetch } = useQuery(
    ["list-permits-associated-wells", selectedPermit],
    async () => {
      if (!selectedPermit) {
        setEditorState([]);
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-permits-associated-wells/${selectedPermit}`,
          { headers }
        );
        setEditorState(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [wellsLookup] = useFetchData("ui-list-permit-well-records", [], true);
  const wellsLookupForTable = useMemo(() => {
    let converted = {};
    if (wellsLookup?.length > 0) {
      wellsLookup.forEach((item) => {
        converted[item.well_ndx] = item.concatenated_well_info;
      });
    }
    return converted;
  }, [wellsLookup]);

  const LINKED_WELLS_MANAGEMENT_TABLE_COLUMNS = [
    {
      title: "Permitted Well Record",
      field: "well_ndx",
      editable: "onAdd",
      cellStyle: { width: "100%" },
      editComponent: (props) => {
        return (
          <>
            <LinkedWellsFuzzySearch
              options={wellsLookup}
              onSelect={props.onChange}
            />
            <InputLabel style={{ marginTop: "6px" }} id="wells">
              Selected Well
            </InputLabel>
            <TextField
              disabled
              variant="outlined"
              style={{ width: "100%" }}
              value={wellsLookupForTable[props.value] || ""}
            />
          </>
        );
      },
      lookup: wellsLookupForTable,
    },
    {
      title: "Start Date",
      field: "start_date",
      type: "date",
      validate: (rowData) => {
        if (!rowData.start_date) {
          return false; // Return an error message if the start_date is empty
        }
        return true; // Return true if the start_date is valid
      },
      editComponent: (props) => (
        <TextField
          type="date"
          required
          value={props.value || ""}
          onChange={(e) => props.onChange(e.target.value)}
          error={!props.value}
          helperText={!props.value ? "Required" : ""}
        />
      ),
    },
    {
      title: "End Date",
      field: "end_date",
      editComponent: (props) => (
        <TextField
          type="date"
          value={props.value || ""}
          onChange={(e) => {
            const newValue = e.target.value === "" ? null : e.target.value;
            props.onChange(newValue);
          }}
        />
      ),
    },
  ];

  return {
    isLoading: isFetching,
    error,
    refetch,
    state: editorState,
    setter: setEditorState,
    LINKED_WELLS_MANAGEMENT_TABLE_COLUMNS,
  };
};
