import { CircularProgress } from "@material-ui/core";
import styled from "styled-components/macro";
import NumberFormat from "react-number-format";

export const getButtonIcon = (isFormSubmitting) => {
  if (isFormSubmitting) {
    return <CircularProgress color="inherit" size={20} />;
  }
};

export const getButtonText = (isFormSubmitting, isNewWell = false) => {
  if (isFormSubmitting) {
    if (isNewWell) {
      return "Creating";
    } else {
      return "Saving";
    }
  }
  if (isNewWell) {
    return "Create";
  } else {
    return "Save";
  }
};

export const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

export function NumberFormatCustom(props) {
  const { config, inputRef, ...other } = props;
  return (
    <NumberFormat
      getInputRef={inputRef}
      decimalScale={config.decimalScale ?? 0}
      fixedDecimalScale={config.fixedDecimalScale ?? false}
      max={5}
      {...other}
    />
  );
}
