import React, { useMemo } from "react";
import { useQuery } from "react-query";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import DataAdminTableOriginal from "../../../components/DataAdminTableOriginal";
import useFetchData from "../../../hooks/useFetchData";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

//388px
const DroughtStage = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isFetching, error, refetch } = useQuery(
    ["history-drought-stage"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/history-drought-stage`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [monthsLookup] = useFetchData("list-months", [], true);
  const monthsLookupForTable = useMemo(() => {
    let converted = {};
    if (monthsLookup?.length > 0) {
      monthsLookup.forEach((item) => {
        converted[item.month_number] = item.month_name;
      });
    }
    return converted;
  }, [monthsLookup]);

  const [droughtStagesLookup] = useFetchData("list-drought-stages", [], true);
  const droughtStagesLookupForTable = useMemo(() => {
    let converted = {};
    if (droughtStagesLookup?.length > 0) {
      droughtStagesLookup.forEach((item) => {
        converted[item.drought_stage_ndx] = item.drought_stage_name;
      });
    }
    return converted;
  }, [droughtStagesLookup]);

  const columns = [
    {
      title: "Calendar Year",
      field: "d_year",
      editable: "never",
      defaultFilter: new Date().getFullYear().toString(),
    },
    {
      title: "Month",
      field: "d_month",
      lookup: monthsLookupForTable,
      editable: "never",
    },
    {
      title: "Drought Stage",
      field: "drought_stage_ndx",
      lookup: droughtStagesLookupForTable,
    },
    // {
    //   title: "Notes",
    //   field: "notes",
    //   editComponent: (props) => (
    //     <TextField
    //       fullWidth
    //       value={props.value || ""} // Use an empty string as the default value
    //       onChange={(e) => props.onChange(e.target.value)}
    //     />
    //   ),
    // },
  ];

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <Helmet title="Drought Stage" />
      <Typography variant="h3" gutterBottom display="inline">
        Drought Stage
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Drought Stage</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Paper>
        <TableWrapper>
          <DataAdminTableOriginal
            pageSize={30}
            isLoading={isFetching}
            label="Drought Stage"
            columns={columns}
            data={data}
            height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 120px)"
            handleRefresh={refetch}
            endpoint="history-drought-stage"
            ndxField="ndx"
            options={{ filtering: true }}
            canAdd={false}
            canDelete={false}
          />
        </TableWrapper>
      </Paper>
    </>
  );
};

export default DroughtStage;
