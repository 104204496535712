import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { AccordionActions } from "@mui/material";
import React from "react";
import Loader from "../../../../../components/Loader";
import { useWellDetails } from "../hooks/useWellDetails";
import { useSubmitData } from "../../../../../hooks/useSubmitData";
import {
  BOOLEAN_OPTIONS,
  ASSOCIATED_PERMITS_TABLE_COLUMNS,
  ENFORCEMENT_RECORDS_TABLE_COLUMNS,
  OTHER_OWNER_WELLS_TABLE_COLUMNS,
  SITE_INSPECTION_RECORDS_TABLE_COLUMNS,
  METERS_TABLE_COLUMNS,
} from "../constants";
import { getButtonIcon, getButtonText } from "../utils";
import MaterialTable from "material-table";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function WellDetails({
  selectedWell,
  setSelectedWell,
  isEditMode = false,
  selectedWellLabel,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
    lookups,
    tables,
  } = useWellDetails(selectedWell);

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedWell,
    setSelectedWell,
    state,
    "list-wells-details",
    "well_ndx",
    refetch
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.well_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded={isEditMode}
        disabled={!selectedWell}
        style={{
          display:
            isLoading || (isEditMode && !state?.well_ndx) ? "none" : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography
              variant="h3"
              style={{ fontSize: "1.2rem", marginLeft: "40px" }}
            >{`Well Details ${
              selectedWellLabel ? `(${selectedWellLabel})` : ""
            }`}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="enabled">Index Well</InputLabel>
                <Select
                  disabled={!selectedWell}
                  labelId="index-well"
                  id="index-well"
                  label="Index well"
                  value={state?.index_well ?? ""}
                  onChange={(e) =>
                    handleUpdateState("index_well", e.target.value)
                  }
                >
                  {BOOLEAN_OPTIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.wellStatus.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="well-status">Well Status</InputLabel>
                    <Select
                      disabled={!selectedWell}
                      labelId="well-status"
                      id="well-status"
                      label="Well Status"
                      name="well_status_ndx"
                      value={state?.well_status_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("well_status_ndx", e.target.value);
                      }}
                    >
                      {lookups.wellStatus.map((option) => (
                        <MenuItem
                          key={option.well_status_ndx}
                          value={option.well_status_ndx}
                        >
                          {option.well_status_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.secondaryWellUse.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="secondary-well-use">
                      Secondary Well Use
                    </InputLabel>
                    <Select
                      disabled={!selectedWell}
                      labelId="secondary-well-use"
                      id="secondary-well-use"
                      label="secondary-well-use"
                      name="secondary_use_ndx"
                      value={state?.secondary_use_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("secondary_use_ndx", e.target.value);
                      }}
                    >
                      {lookups.secondaryWellUse.map((option) => (
                        <MenuItem
                          key={option.well_use_ndx}
                          value={option.well_use_ndx}
                        >
                          {option.well_use_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.monitoringStatus.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="well-status">Monitoring Status</InputLabel>
                    <Select
                      disabled={!selectedWell}
                      labelId="monitoring-status"
                      id="monitoring-status"
                      label="Monitoring Status"
                      name="monitoring_status_ndx"
                      value={state?.monitoring_status_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState(
                          "monitoring_status_ndx",
                          e.target.value
                        );
                      }}
                    >
                      {lookups.monitoringStatus.map((option) => (
                        <MenuItem
                          key={option.monitoring_status_ndx}
                          value={option.monitoring_status_ndx}
                        >
                          {option.monitoring_status_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="enabled">Permitted Well</InputLabel>
                <Select
                  disabled={!selectedWell}
                  labelId="permitted-well"
                  id="permitted-well"
                  label="Permitted well"
                  value={state?.permitted_well ?? ""}
                  onChange={(e) =>
                    handleUpdateState("permitted_well", e.target.value)
                  }
                >
                  {BOOLEAN_OPTIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.managementZone.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="management-zone">
                      Management Zone
                    </InputLabel>
                    <Select
                      disabled={!selectedWell}
                      labelId="management-zone"
                      id="management-zone"
                      label="Management Zone"
                      name="district_ndx"
                      value={state?.mgmtzone_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("mgmtzone_ndx", e.target.value);
                      }}
                    >
                      {lookups.managementZone.map((option) => (
                        <MenuItem
                          key={option.mgmtzone_ndx}
                          value={option.mgmtzone_ndx}
                        >
                          {option.mgmtzone_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="enabled">Has Geo Log</InputLabel>
                <Select
                  disabled={!selectedWell}
                  labelId="has-geo-log"
                  id="has-geo-log"
                  label="Has Geo Log"
                  value={state?.has_geo_log ?? ""}
                  onChange={(e) =>
                    handleUpdateState("has_geo_log", e.target.value)
                  }
                >
                  {BOOLEAN_OPTIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled={!selectedWell}
                variant="outlined"
                label="Well Report Tracking #"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("well_report_tracking_num", e.target.value)
                }
                value={state?.well_report_tracking_num ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled={!selectedWell}
                variant="outlined"
                label="Plugging Report #"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState(
                    "plugging_report_tracking_num",
                    e.target.value
                  )
                }
                value={state?.plugging_report_tracking_num ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl style={{ width: "100%" }}>
                  <KeyboardDatePicker
                    format="LLLL do, yyyy"
                    inputVariant="outlined"
                    autoOk
                    id="plugging-date"
                    label="Plugging Date"
                    value={state?.plugging_date}
                    onChange={(date) => {
                      handleUpdateState("plugging_date", date);
                    }}
                    InputAdornmentProps={{
                      "aria-label": "change plugging date",
                    }}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                variant="outlined"
                label="Last Updated:"
                style={{ width: "100%" }}
                value="Placeholder"
                InputProps={{
                  style: {
                    color: "rgba(0,0,0,1)",
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                variant="outlined"
                label="Updated By:"
                style={{ width: "100%" }}
                value="Placeholder"
                InputProps={{
                  style: {
                    color: "rgba(0,0,0,1)",
                  },
                }}
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <MaterialTable
                style={{
                  position: "relative",
                  border: "solid gray",
                  height: "214px",
                  overflow: "auto",
                }}
                id="associated-permits"
                title="Associated Permits"
                loading={
                  tables.associatedPermits.isFetching ||
                  !tables.associatedPermits.data
                }
                columns={ASSOCIATED_PERMITS_TABLE_COLUMNS}
                data={tables?.associatedPermits?.data ?? []}
                options={{
                  showTitle: false,
                  toolbar: false,
                  columnsButton: false,
                  exportButton: false,
                  exportAllData: false,
                  padding: "dense",
                  search: false,
                  paging: false,
                  cellStyle: { border: "1px solid #eee" },
                }}
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <MaterialTable
                style={{
                  position: "relative",
                  border: "solid gray",
                  height: "214px",
                  overflow: "auto",
                }}
                id="other-owner-wells"
                title="Other Owner Wells"
                loading={
                  tables.otherOwnerWells.isFetching ||
                  !tables.otherOwnerWells.data
                }
                columns={OTHER_OWNER_WELLS_TABLE_COLUMNS}
                data={tables?.otherOwnerWells?.data ?? []}
                options={{
                  showTitle: false,
                  toolbar: false,
                  columnsButton: false,
                  exportButton: false,
                  exportAllData: false,
                  padding: "dense",
                  search: false,
                  paging: false,
                  cellStyle: { border: "1px solid #eee" },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <MaterialTable
                style={{
                  position: "relative",
                  border: "solid gray",
                  height: "214px",
                  overflow: "auto",
                }}
                id="associated-meters"
                title="Meters Attached to this Well"
                loading={
                  tables.linkedMeters.isFetching || !tables.linkedMeters.data
                }
                columns={METERS_TABLE_COLUMNS}
                data={tables?.linkedMeters?.data ?? []}
                options={{
                  showTitle: false,
                  toolbar: false,
                  columnsButton: false,
                  exportButton: false,
                  exportAllData: false,
                  padding: "dense",
                  search: false,
                  paging: false,
                  cellStyle: { border: "1px solid #eee" },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <MaterialTable
                style={{
                  position: "relative",
                  border: "solid gray",
                  height: "214px",
                  overflow: "auto",
                }}
                id="site-inspection-records"
                title="Site Inspection Records"
                loading={
                  tables.siteInspectionRecords.isFetching ||
                  !tables.siteInspectionRecords.data
                }
                columns={SITE_INSPECTION_RECORDS_TABLE_COLUMNS}
                data={tables?.siteInspectionRecords?.data ?? []}
                options={{
                  showTitle: false,
                  toolbar: false,
                  columnsButton: false,
                  exportButton: false,
                  exportAllData: false,
                  padding: "dense",
                  search: false,
                  paging: false,
                  cellStyle: { border: "1px solid #eee" },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <MaterialTable
                style={{
                  position: "relative",
                  border: "solid gray",
                  height: "214px",
                  overflow: "auto",
                }}
                id="enforcement-records"
                title="Enforcement Records"
                loading={
                  tables.enforcementRecords.isFetching ||
                  !tables.enforcementRecords.data
                }
                columns={ENFORCEMENT_RECORDS_TABLE_COLUMNS}
                data={tables?.enforcementRecords?.data ?? []}
                options={{
                  showTitle: false,
                  toolbar: false,
                  columnsButton: false,
                  exportButton: false,
                  exportAllData: false,
                  padding: "dense",
                  search: false,
                  paging: false,
                  cellStyle: { border: "1px solid #eee" },
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button
            disabled={!selectedWell}
            size="small"
            onClick={handleResetState}
          >
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isDirty || isFormSubmitting}
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default WellDetails;
