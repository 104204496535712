const wLData = {
  name: "wLData",
  ndx: 7,
  field: "w_l_data_ndx",
  label: "WL Data",
  endpoint: "query-and-download/q-d-data-w-l-data",
  icon: "LocalDrink",
  inputs: [],
};

export default wLData;
