export const DEFAULT_AMENDMENT_STATE = {
  permit_ndx: null,
  pamend_ndx: null,
  pamend_type_ndx: null,
  action_staff_reviewer: null,
  action_complete: null,
  pamend_status_ndx: 1,
  action_general_manager: null,
  action_complete_extension: null,
  effective_date: new Date(),
  amendment_notes: null,
  action_incomplete_expired: null,
  authorized_volume_gal: null,
  proposed_volume_change: null,
  new_volume: null,
  primary_use_ndx: null,
  proposed_primary_use_ndx: null,
  secondary_use_ndx: null,
  proposed_secondary_use_ndx: null,
  permit_status_ndx: null,
  proposed_status_ndx: null,
  proposed_rolo_ndx: null,
  tmp_permittee_name: null,
  tmp_permittee_email: null,
  tmp_permittee_phone: null,
  tmp_permittee_zip: null,
  tmp_permittee_address: null,
  tmp_permittee_city: null,
  tmp_permittee_state: null,
  tmp_permittee_organization: null,
  tmp_permittee_precinct_ndx: null,
  permittee_add_new: false,
  action_public_hearing_date: null,
  action_public_hearing_notes: null,
  action_soah_prelim_hearing_date: null,
  action_soah_prelim_hearing_notes: null,
  action_soah_evidentiary_date: null,
  action_soah_evidentiary_notes: null,
  special_conditions_080117: null,
  board_order_080117: null,
  approval_date: null,
  approval_body_ndx: null,
  created_by: null,
  created_timestamp: null,
  saved_previous_volume: null,
  saved_previous_primary_use_ndx: null,
  saved_previous_secondary_use_ndx: null,
  saved_previous_permit_status_ndx: null,
  saved_previous_rolo_ndx: null,
};
