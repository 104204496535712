import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Link,
  Paper,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import MaterialTable from "material-table";
import CopyIcon from "@material-ui/icons/FileCopy";
import { ExpandMore } from "@material-ui/icons";

import { NavLink } from "react-router-dom";
import { useApp } from "../../../../AppProvider";

import { copyToClipboard, dateFormatter } from "../../../../utils";
import { CONTACTS_TABLE_COLUMNS } from "./ContactsConstants";
import BasicContactInfo from "./BasicContactInfo";
import { useContacts } from "./useContacts";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;
function ContactsManagement() {
  const {
    selectedContact,
    setSelectedContact,
    data,
    isFetching,
    error,
    refetch,
  } = useContacts();
  const { doToast } = useApp();

  if (error) return "An error has occurred: " + error.message;
  return (
    <React.Fragment>
      <Helmet title="Edit Owner Info" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Owner Info
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Owners</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Box>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box style={{ width: "100%" }}>
              <Typography variant="h3">Contacts Selection</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <TableWrapper>
              <MaterialTable
                id="Contacts Setup"
                title={`Contacts Setup ${dateFormatter(
                  new Date(),
                  "MM/DD/YYYY, h:mm A"
                )}`}
                loading={isFetching || !data}
                columns={CONTACTS_TABLE_COLUMNS}
                data={data}
                localization={{
                  toolbar: { searchPlaceholder: "Contacts Selection" },
                }}
                components={{
                  Container: (props) => <div {...props} />,
                }}
                actions={[
                  {
                    icon: CopyIcon,
                    tooltip: "Copy Data",
                    isFreeAction: true,
                    onClick: () => {
                      try {
                        copyToClipboard(data, CONTACTS_TABLE_COLUMNS, () =>
                          doToast(
                            "success",
                            "Data was copied to your clipboard."
                          )
                        );
                      } catch (error) {
                        const message =
                          error?.message ?? "Something went wrong";
                        doToast("error", message);
                      }
                    },
                  },
                  () => ({
                    icon: "edit",
                    tooltip: "Edit",
                    onClick: (event, rowData) => {
                      setSelectedContact(rowData?.rolo_ndx);
                    },
                  }),
                  {
                    icon: "add_box",
                    tooltip: "Add",
                    isFreeAction: true,
                    onClick: () => {
                      setSelectedContact(0);
                    },
                  },
                ]}
                options={{
                  filtering: true,
                  emptyRowsWhenPaging: false,
                  showTitle: false,
                  columnsButton: true,
                  exportButton: true,
                  exportAllData: true,
                  addRowPosition: "first",
                  pageSize: 10,
                  pageSizeOptions: [10, 30, 50, 100, 200],
                  padding: "dense",
                  searchFieldAlignment: "left",
                  minBodyHeight: "250px",
                  maxBodyHeight:
                    "calc(100vh - 64px - 48px - 106px - 48px - 64px - 204px - 100px)",
                }}
              />
            </TableWrapper>
          </AccordionDetails>
        </Accordion>

        <Box
          style={{
            display:
              selectedContact || selectedContact === 0 ? "block" : "none",
          }}
        >
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4">
                {selectedContact === 0
                  ? "Creating New Contact"
                  : "Editing Existing Contact"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedContact(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
          <BasicContactInfo
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
            refetchContacts={refetch}
          />
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4">
                {selectedContact === 0
                  ? "Creating New Contact"
                  : "Editing Existing Contact"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedContact(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default ContactsManagement;
