import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from "@material-ui/core";
import { ExpandMore, LinkOff } from "@material-ui/icons";
import React, { forwardRef } from "react";
import Loader from "../../../../components/Loader";
import { TableWrapper } from "../wellsManagement/utils";
import DataAdminTable from "../../../../components/DataAdminTable";
import { useLinkedWellsManagement } from "./useLinkedWellsManagement";

function LinkedWellsManagement({
  selectedPermit,
  isEditMode = false,
  refetchBoxTables,
}) {
  const {
    isLoading,
    error,
    refetch,
    state,
    setter,
    LINKED_WELLS_MANAGEMENT_TABLE_COLUMNS,
  } = useLinkedWellsManagement(selectedPermit);

  const handleRefetch = () => {
    refetch().then(() => {
      refetchBoxTables();
    });
  };

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading && (
        <Paper style={{ width: "100%", margin: "8px 0" }}>
          <Loader />
        </Paper>
      )}
      <Accordion defaultExpanded={isEditMode} disabled={!selectedPermit}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Manage Linked Wells</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <TableWrapper>
            <DataAdminTable
              pageSize={10}
              isLoading={isLoading}
              label="Manage Linked Wells"
              columns={LINKED_WELLS_MANAGEMENT_TABLE_COLUMNS}
              data={state}
              height="300px"
              updateHandler={setter}
              endpoint="list-permits-associated-wells"
              ndxField="ndx"
              identifierNdxField="permit_ndx"
              selectedNdx={selectedPermit}
              handleRefresh={() => handleRefetch()}
              search={false}
              isDeletable={false}
              icons={{
                Delete: forwardRef((props, ref) => {
                  return <LinkOff {...props} ref={ref} />;
                }),
              }}
              localization={{
                body: {
                  deleteTooltip: "Delink",
                },
              }}
              options={{
                rowStyle: (rowData) => ({
                  backgroundColor: rowData.end_date ? "#f6f6f6" : "inherit",
                }),
              }}
            />
          </TableWrapper>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default LinkedWellsManagement;
