import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Loader from "../../../../../../components/Loader";
import React from "react";
import LinkedPermitteeFuzzySearch from "../../LinkedPermitteeFuzzySearch";
import { Alert } from "@material-ui/lab";
import { Info } from "@material-ui/icons";

function Permittee({
  currentPermit,
  state,
  handleUpdateState,
  lookups,
  selectedAmendment,
}) {
  return (
    <>
      <Grid
        container
        spacing={3}
        style={{
          border: "3px solid gray",
          marginBottom: "15px",
          borderRadius: "4px",
          marginTop: "5px",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            position: "relative",
          }}
        >
          <Typography variant="h5">Change Permit Holder (Permittee)</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControl variant="outlined" style={{ width: "100%" }}>
            {!lookups?.permitRoloRecords.length > 0 ? (
              <Loader />
            ) : (
              <>
                <TextField
                  disabled
                  variant="outlined"
                  label="Previous Permit Holder"
                  style={{ width: "100%" }}
                  value={
                    lookups.permitRoloRecords.find(
                      (item) =>
                        item.rolo_ndx ===
                        (selectedAmendment
                          ? state?.saved_previous_rolo_ndx
                          : currentPermit?.rolo_ndx)
                    )?.concatenated_contact_info ?? "N/A"
                  }
                  InputProps={{
                    style: {
                      color: "rgba(0,0,0,1)",
                    },
                  }}
                />
              </>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          xl={6}
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!lookups?.permitteeContactRecords.length > 0 && state.rolo_ndx ? (
            <Loader />
          ) : (
            <Box my={3} display="flex" alignItems="center">
              <Tooltip title="Changing the Permit Holder will not change the Well Owner - Please edit the well record(s) separately.">
                <Info
                  color="secondary"
                  style={{ fontSize: 48, marginRight: 16 }}
                />
              </Tooltip>
              <div style={{ width: "100%" }}>
                <LinkedPermitteeFuzzySearch
                  disabled={state?.permittee_add_new}
                  options={lookups?.permitteeContactRecords}
                  onSelect={(e) => {
                    handleUpdateState("proposed_rolo_ndx", e);
                  }}
                />
                <InputLabel
                  style={{ marginTop: "6px" }}
                  id="selected-permittee"
                >
                  New Permit Holder
                </InputLabel>
                {state?.proposed_rolo_ndx ? (
                  <Link
                    color="secondary"
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      marginTop: "5px",
                    }}
                    href={`/data-management/well-owners/edit-owner-info/${state.proposed_rolo_ndx}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {lookups?.permitteeContactRecords?.find(
                      (contact) => contact.rolo_ndx === state.proposed_rolo_ndx
                    )?.concatenated_contact_info ?? ""}
                  </Link>
                ) : (
                  <Typography
                    color="primary"
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      marginTop: "5px",
                    }}
                  >
                    ** No New Permittee Selected **
                  </Typography>
                )}
              </div>
            </Box>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          xl={6}
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box flexGrow={1} mb={2}>
            <Alert severity="info" style={{ height: "100%" }}>
              <strong>Create a new Permittee Record to the Rolodex only</strong>{" "}
              - if Permittee already exists in the Rolodex, select them in the
              adjacent Permittee field.
            </Alert>
          </Box>
          <Button
            size="large"
            variant="contained"
            color={state.permittee_add_new ? "default" : "secondary"}
            onClick={() =>
              handleUpdateState("permittee_add_new", !state.permittee_add_new)
            }
          >
            Create a New Permittee
          </Button>
        </Grid>

        {state?.permittee_add_new && (
          <>
            <Grid
              item
              xs={12}
              md={6}
              xl={8}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Permittee Name or Organization"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState(
                    "tmp_permittee_organization",
                    e.target.value
                  )
                }
                value={state.tmp_permittee_organization ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.directorPrecincts.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="precinct">Precinct</InputLabel>
                    <Select
                      labelId="precinct"
                      id="precinct"
                      label="Precinct"
                      name="tmp_permittee_precinct_ndx"
                      value={state.tmp_permittee_precinct_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState(
                          "tmp_permittee_precinct_ndx",
                          e.target.value
                        );
                      }}
                    >
                      {lookups.directorPrecincts.map((option) => (
                        <MenuItem
                          key={option.precinct_ndx}
                          value={option.precinct_ndx}
                        >
                          {option.precinct_label}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Permittee Contact Name"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("tmp_permittee_name", e.target.value)
                }
                value={state.tmp_permittee_name ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={5}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Email"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("tmp_permittee_email", e.target.value)
                }
                value={state.tmp_permittee_email ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={5}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Phone"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("tmp_permittee_phone", e.target.value)
                }
                value={state.tmp_permittee_phone ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={2}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Zip Code"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("tmp_permittee_zip", e.target.value)
                }
                value={state.tmp_permittee_zip ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={7}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Mailing Address"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("tmp_permittee_address", e.target.value)
                }
                value={state.tmp_permittee_address ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="City"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("tmp_permittee_city", e.target.value)
                }
                value={state.tmp_permittee_city ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={2}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="State"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("tmp_permittee_state", e.target.value)
                }
                value={state.tmp_permittee_state ?? ""}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default Permittee;
