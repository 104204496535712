const owners = {
  name: "owners",
  ndx: 3,
  field: "owner_ndx",
  label: "Owners",
  endpoint: "query-and-download/q-d-data-owners",
  icon: "Person",
  inputs: [],
};

export default owners;
