import React from "react";

import CopyIcon from "@material-ui/icons/FileCopy";
import { copyToClipboard, dateFormatter } from "../utils";
import MaterialTable from "material-table";
import { useApp } from "../AppProvider";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const DataAdminTableBulkUpdateMeterReadings = ({
  pageSize = 10,
  isLoading = false,
  label,
  columns,
  data,
  height,
  endpoint,
  ndxField,
  handleRefresh = () => {},
  actions = [],
  search = true,
  icons = {},
  localization = {},
  options = {},
}) => {
  const { doToast } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  const handleBulkUpdate = (changes) => {
    return (async () => {
      if (!Object.keys(changes).length) return null;
      try {
        // Merge changes with the original data
        const updatedData = [...data]; // Assuming 'data' is the original dataset
        Object.values(changes).forEach((change) => {
          const index = updatedData.findIndex(
            (item) => item[ndxField] === change.newData[ndxField]
          );
          if (index > -1) {
            updatedData[index] = change.newData;
          }
        });

        // Continue with the bulk update as before
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        await axios.put(
          `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`,
          Object.values(changes).map((change) => change.newData), // Now sending only the changed rows for update
          { headers }
        );

        handleRefresh();
        doToast("success", "Data was updated in the database");
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  return (
    <MaterialTable
      localization={localization}
      id={label}
      title={`${label} ${dateFormatter(new Date(), "MM/DD/YYYY, h:mm A")}`}
      columns={columns}
      isLoading={isLoading}
      data={data}
      icons={{
        ...icons,
      }}
      editable={{
        onBulkUpdate: handleBulkUpdate,
      }}
      components={{
        Container: (props) => <div {...props} />,
      }}
      actions={[
        {
          icon: CopyIcon,
          tooltip: "Copy Data",
          isFreeAction: true,
          onClick: () => {
            try {
              copyToClipboard(data, columns, () =>
                doToast("success", "Data was copied to your clipboard.")
              );
            } catch (error) {
              const message = error?.message ?? "Something went wrong";
              doToast("error", message);
            }
          },
        },
        ...actions,
      ]}
      options={{
        emptyRowsWhenPaging: false,
        columnsButton: true,
        exportButton: true,
        exportAllData: true,
        addRowPosition: "first",
        pageSize: pageSize,
        pageSizeOptions: [5, pageSize, 30, 60],
        padding: "dense",
        searchFieldAlignment: "left",
        showTitle: false,
        maxBodyHeight: height,
        search: search,
        actionsColumnIndex: -1,
        filtering: true,
        ...options,
      }}
    />
  );
};

export default DataAdminTableBulkUpdateMeterReadings;
