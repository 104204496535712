import wells from "./wells";
import permits from "./permits";
import owners from "./owners";
import meters from "./meters";
import pumpage from "./pumpage";
import wQData from "./wQData";
import wLData from "./wLData";

export const CONFIG = {
  wells,
  permits,
  owners,
  meters,
  pumpage,
  wQData,
  wLData,
};

export default CONFIG;
