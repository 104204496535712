import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import { useMemo } from "react";
import useFetchData from "../../../../../hooks/useFetchData";

export const useAmendments = (selectedPermit) => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedAmendment, setSelectedAmendment] = useStateIfMounted(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["data-permit-amendments/permit", selectedPermit],
    async () => {
      if (!selectedPermit) {
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-permit-amendments/permit/${selectedPermit}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [amendmentTypesLookup] = useFetchData(
    "ui-list-permit-amendment-types",
    [],
    true
  );
  const amendmentTypesLookupForTable = useMemo(() => {
    let converted = {};
    if (amendmentTypesLookup?.length > 0) {
      amendmentTypesLookup.forEach((item) => {
        converted[item.pamend_type_ndx] = item.pamend_type_desc;
      });
    }
    return converted;
  }, [amendmentTypesLookup]);

  const [amendmentStatusLookup] = useFetchData(
    "ui-list-permit-amendment-status",
    [],
    true
  );
  const amendmentStatusLookupForTable = useMemo(() => {
    let converted = {};
    if (amendmentStatusLookup?.length > 0) {
      amendmentStatusLookup.forEach((item) => {
        converted[item.pamend_status_ndx] = item.pamend_status_desc;
      });
    }
    return converted;
  }, [amendmentStatusLookup]);

  const AMENDMENTS_TABLE_COLUMNS = [
    {
      title: "Effective Date",
      field: "effective_date",
      type: "date",
      // defaultFilter: "desc",
    },
    {
      title: "Amendment Type",
      field: "pamend_type_ndx",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
      lookup: amendmentTypesLookupForTable,
    },
    {
      title: "Amendment Status",
      field: "pamend_status_ndx",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
      lookup: amendmentStatusLookupForTable,
    },
    {
      title: "Complete Date",
      field: "action_complete_date",
      type: "date",
    },
    {
      title: "Extension Date",
      field: "action_complete_extension",
      type: "date",
    },
    {
      title: "Expired Date",
      field: "action_incomplete_expired",
      type: "date",
    },
  ];

  return {
    selectedAmendment,
    setSelectedAmendment,
    data,
    isFetching: isFetching,
    error: error,
    refetch,
    AMENDMENTS_TABLE_COLUMNS,
  };
};
