import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useMemo } from "react";
import useFetchData from "../../../../../hooks/useFetchData";
import { useStateIfMounted } from "use-state-if-mounted";

export const useWellConstruction = (selectedWell) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useStateIfMounted([]);

  const { isFetching, error, refetch } = useQuery(
    ["list-wells-completion-components", selectedWell],
    async () => {
      if (!selectedWell) {
        setEditorState([]);
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-wells-completion-components/${selectedWell}`,
          { headers }
        );
        setEditorState(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [wellComponentLookup] = useFetchData(
    "ui-list-wm-well-construction-components",
    [],
    true
  );
  const wellComponentLookupForTable = useMemo(() => {
    let converted = {};
    if (wellComponentLookup?.length > 0) {
      wellComponentLookup.forEach((item) => {
        converted[item.well_component_ndx] = item.well_component_desc;
      });
    }
    return converted;
  }, [wellComponentLookup]);

  const [casingMaterialLookup] = useFetchData(
    "ui-list-wm-well-casing-material",
    [],
    true
  );
  const casingMaterialLookupForTable = useMemo(() => {
    let converted = {};
    if (casingMaterialLookup?.length > 0) {
      casingMaterialLookup.forEach((item) => {
        converted[item.casing_material_ndx] = item.casing_material_desc;
      });
    }
    return converted;
  }, [casingMaterialLookup]);

  const WELL_CONSTRUCTION_TABLE_COLUMNS = [
    {
      title: "Well Component",
      field: "well_component_ndx",
      lookup: wellComponentLookupForTable,
    },
    {
      title: "Top Depth (ft)",
      field: "top_depth_ft",
    },
    {
      title: "Bottom Depth (ft)",
      field: "bottom_depth_ft",
    },
    {
      title: "Diameter (in)",
      field: "diameter_inches",
    },
    {
      title: "Well Component",
      field: "casing_material_ndx",
      lookup: casingMaterialLookupForTable,
    },
    {
      title: "Screen Material Notes",
      field: "screen_material_notes",
    },
  ];

  return {
    isLoading: isFetching,
    error,
    refetch,
    state: editorState,
    setter: setEditorState,
    WELL_CONSTRUCTION_TABLE_COLUMNS,
  };
};
