import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import styled from "styled-components/macro";

const CheckboxContainer = styled(FormControl)`
  margin-vertical: 10px; // Adjust the margin as needed
`;

export const SimpleCheckbox = ({ name, label }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <CheckboxContainer error={Boolean(errors[name])} component="fieldset">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox {...field} checked={field.value} color="primary" />
            }
            label={label}
          />
        )}
      />
      {!!errors[name] && (
        <FormHelperText>{errors[name]?.message}</FormHelperText>
      )}
    </CheckboxContainer>
  );
};

export const TriStateCheckbox = React.memo(({ name, label }) => {
  const { control } = useFormContext(); // Hook into useFormContext to gain access to control

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref } }) => {
        const handleChange = () => {
          const newState =
            value === null ? true : value === true ? false : null;
          onChange(newState);
        };

        const isChecked = value === true;
        const isIndeterminate = value === null;

        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                indeterminate={isIndeterminate}
                onChange={handleChange}
                inputRef={ref}
                color="primary"
              />
            }
            label={label}
          />
        );
      }}
    />
  );
});
