import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  ClickAwayListener,
  Divider,
  InputAdornment,
  List,
  ListItem,
  Paper,
  Popper,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Fuse from "fuse.js";
import useDebounce from "../../../../hooks/useDebounce";

const SearchResults = ({
  anchorEl,
  open,
  onClose,
  onSelect,
  searchResults,
}) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      style={{ zIndex: 1001 }}
      transition
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper style={{ width: 600, height: 500, overflowY: "auto" }}>
          <List dense component="nav" aria-label="main mailbox folders">
            {searchResults?.slice(0, 49)?.map((result, i) => (
              <React.Fragment key={i}>
                <ListItem
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                  button
                  onClick={(e) => {
                    onSelect(result?.item.well_ndx);
                    onClose(e);
                  }}
                >
                  <Typography variant="subtitle1">
                    {result?.item?.district_well_id}
                  </Typography>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="caption">Name</Typography>
                      <Typography variant="body1">
                        {result?.item?.well_name || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">Well Contact</Typography>
                      <Typography variant="body1">
                        {result?.item?.well_contact || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="caption">Well Class</Typography>
                      <Typography variant="body1">
                        {result?.item?.well_class || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="caption">Well Sub-class</Typography>
                      <Typography variant="body1">
                        {result?.item?.well_subclass || "N/A"}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="caption">Management Zone</Typography>
                      <Typography variant="body1">
                        {result?.item?.management_zone || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">District</Typography>
                      <Typography variant="body1">
                        {result?.item?.district || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="caption">QA Completion</Typography>
                      <Typography variant="body1">
                        {result?.item?.qa_completion || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="caption">Position</Typography>
                      <Typography variant="body1">
                        {result?.item?.position || "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

const LinkedWellsFuzzySearch = ({ onSelect, options }) => {
  const searchRef = useRef(null);

  const [value, setValue] = useState("");
  const debouncedSearchValue = useDebounce(value, 200);
  const [searchResults, setSearchResults] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!searchResults?.length);
  }, [searchResults]);

  const fuzzySearcher = useMemo(() => {
    if (options.length) {
      return new Fuse(options, {
        ignoreLocation: true,
        keys: [
          "district_well_id",
          "well_name",
          "well_contact",
          "well_class",
          "well_subclass",
          "management_zone",
          "district",
          "qa_completion",
          "position",
        ],
      });
    }
  }, [options]);

  const handleClose = (event) => {
    if (searchRef.current && searchRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event) => {
    setValue(event?.target?.value);
  };

  useEffect(() => {
    const results = fuzzySearcher && fuzzySearcher.search(debouncedSearchValue);
    setSearchResults(results);
  }, [debouncedSearchValue, fuzzySearcher]);

  return (
    <>
      <TextField
        id="outlined-basic"
        label="Wells Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        autoComplete="off"
        onChange={handleChange}
        onFocus={() => !!value && setOpen(true)}
        placeholder="Well Details"
        ref={searchRef}
        style={{ width: "100%", minWidth: "226px" }}
        type="search"
        value={value}
        variant="outlined"
        size="small"
      />
      <SearchResults
        anchorEl={searchRef?.current}
        onClose={handleClose}
        onSelect={onSelect}
        open={open}
        searchResults={searchResults}
      />
    </>
  );
};

export default LinkedWellsFuzzySearch;
