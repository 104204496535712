import React, { useCallback, useMemo, useState } from "react";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import useFetchData from "../../../hooks/useFetchData";
import Loader from "../../../components/Loader";
import { useQuery } from "react-query";
import MaterialTable from "material-table";
import { copyToClipboard, formatter } from "../../../utils";
import CopyIcon from "@material-ui/icons/FileCopy";
import { useApp } from "../../../AppProvider";
import { Alert } from "@material-ui/lab";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const defaultFilters = {
  startYear: 2008,
  endYear: new Date().getFullYear(),
  reportYearOption: "",
  aquiferGroup: "",
  aquifer: "All",
  managementZone: "All",
};

const tableOptions = {
  emptyRowsWhenPaging: false,
  exportAllData: true,
  columnsButton: true,
  exportButton: { csv: true },
  pageSize: 30,
  pageSizeOptions: [5, 10, 30, 60],
  padding: "dense",
  searchFieldAlignment: "left",
  showTitle: false,
  maxBodyHeight: "600px",
  filtering: true,
};

const PumpageReport = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();

  const [filters, setFilters] = useState(defaultFilters);

  const handleUpdateState = useCallback((name, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
      ...(name === "aquiferGroup" && { aquifer: "All" }),
    }));
  }, []);

  const {
    data,
    isFetching: isLoading,
    error,
    refetch,
  } = useQuery(
    "pumpage-report",
    async () => {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/api/${filters.reportYearOption}`,
        { filters },
        { headers }
      );
      return response.data;
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const [reportYearOptionsLookup] = useFetchData(
    "ui-report-pumpage-report-options-list",
    [],
    true
  );
  const [aquiferGroupsLookup] = useFetchData(
    "ui-lookup-list-aquifer-group",
    [],
    true
  );
  const [aquifersLookup] = useFetchData("ui-lookup-list-aquifer", [], true);
  const [managementZonesLookup] = useFetchData(
    "ui-lookup-list-management-zones",
    [],
    true
  );

  const columnsFiscal = [
    {
      title: "District Well ID",
      field: "district_well_id",
    },
    {
      title: "Fiscal Year",
      field: "fiscal_year",
      filtering: false,
    },
    {
      title: "September",
      field: "sep_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.sep_gallons, 0),
    },
    {
      title: "October",
      field: "oct_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.oct_gallons, 0),
    },
    {
      title: "November",
      field: "nov_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.nov_gallons, 0),
    },
    {
      title: "December",
      field: "dec_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.dec_gallons, 0),
    },
    {
      title: "January",
      field: "jan_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.jan_gallons, 0),
    },
    {
      title: "February",
      field: "feb_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.feb_gallons, 0),
    },
    {
      title: "March",
      field: "mar_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.mar_gallons, 0),
    },
    {
      title: "April",
      field: "apr_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.apr_gallons, 0),
    },
    {
      title: "May",
      field: "may_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.may_gallons, 0),
    },
    {
      title: "June",
      field: "jun_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.jun_gallons, 0),
    },
    {
      title: "July",
      field: "jul_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.jul_gallons, 0),
    },
    {
      title: "August",
      field: "aug_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.aug_gallons, 0),
    },
    {
      title: "Total",
      field: "tot_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.tot_gallons, 0),
    },
    {
      title: "Aquifer Group",
      field: "aquifer_group_ndx",
      filtering: false,
    },
    {
      title: "Aquifer",
      field: "aquifer_name",
      filtering: false,
    },
    {
      title: "Management Zone",
      field: "mgmtzone_name",
      filtering: false,
    },
    {
      title: "assoc meters",
      field: "associated_meters",
    },
    {
      title: "assoc permits",
      field: "associated_permits",
    },
  ];

  const columnsCalendar = [
    {
      title: "District Well ID",
      field: "district_well_id",
    },
    {
      title: "Calendar Year",
      field: "c_year",
      filtering: false,
    },
    {
      title: "January",
      field: "jan_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.jan_gallons, 0),
    },
    {
      title: "February",
      field: "feb_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.feb_gallons, 0),
    },
    {
      title: "March",
      field: "mar_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.mar_gallons, 0),
    },
    {
      title: "April",
      field: "apr_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.apr_gallons, 0),
    },
    {
      title: "May",
      field: "may_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.may_gallons, 0),
    },
    {
      title: "June",
      field: "jun_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.jun_gallons, 0),
    },
    {
      title: "July",
      field: "jul_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.jul_gallons, 0),
    },
    {
      title: "August",
      field: "aug_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.aug_gallons, 0),
    },
    {
      title: "September",
      field: "sep_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.sep_gallons, 0),
    },
    {
      title: "October",
      field: "oct_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.oct_gallons, 0),
    },
    {
      title: "November",
      field: "nov_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.nov_gallons, 0),
    },
    {
      title: "December",
      field: "dec_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.dec_gallons, 0),
    },
    {
      title: "Total",
      field: "tot_gallons",
      filtering: false,
      render: (rowData) => formatter(rowData.tot_gallons, 0),
    },
    {
      title: "Aquifer",
      field: "aquifer_name",
    },
    {
      title: "Management Zone",
      field: "mgmtzone_name",
    },
  ];

  const columns = useMemo(() => {
    // Assuming columnsFiscal and columnsCalendar are functions that generate columns
    if (!data?.length) return [];
    return "fiscal_year" in data?.[0] ? columnsFiscal : columnsCalendar;
  }, [data]); //eslint-disable-line

  const isSubmitDisabled = useMemo(
    () =>
      !filters.startYear ||
      +filters.startYear < 1998 ||
      +filters.startYear > new Date().getFullYear() + 1 ||
      !filters.endYear ||
      +filters.endYear < 1998 ||
      +filters.endYear > new Date().getFullYear() + 1 ||
      +filters.endYear < +filters.startYear ||
      !filters.reportYearOption ||
      !filters.aquiferGroup ||
      isLoading,
    [filters, isLoading]
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      <Helmet title="Pumpage Report" />
      <Typography variant="h3" gutterBottom display="inline">
        Pumpage Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Pumpage</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Paper style={{ padding: "10px", marginBottom: "20px" }}>
        <Grid container spacing={3}>
          <Grid
            item
            sx={12}
            md={6}
            xl={4}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControl variant="outlined" style={{ width: "100%" }} required>
              <TextField
                required
                type="number"
                error={
                  !filters.startYear ||
                  +filters.startYear < 1997 ||
                  +filters.startYear > new Date().getFullYear() + 1
                }
                variant="outlined"
                label="From Year"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("startYear", e.target.value)}
                value={filters.startYear ?? ""}
                InputProps={{
                  inputProps: {
                    onKeyPress: (e) => {
                      if (["e", "-"].includes(e.key)) {
                        e.preventDefault();
                      }
                    },
                  },
                }}
              />
              {!filters.startYear ? (
                <FormHelperText error>*Required field</FormHelperText>
              ) : +filters.startYear < 1998 ? (
                <FormHelperText error>*Must be after 1997</FormHelperText>
              ) : +filters.startYear > new Date().getFullYear() + 1 ? (
                <FormHelperText error>{`*Must be before ${
                  new Date().getFullYear() + 2
                }`}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>

          <Grid
            item
            sx={12}
            md={6}
            xl={4}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControl variant="outlined" style={{ width: "100%" }} required>
              <TextField
                required
                type="number"
                error={
                  !filters.endYear ||
                  +filters.endYear < 1998 ||
                  +filters.endYear > new Date().getFullYear() + 1 ||
                  +filters.endYear < +filters.startYear
                }
                variant="outlined"
                label="To Year"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("endYear", e.target.value)}
                value={filters.endYear ?? ""}
                InputProps={{
                  inputProps: {
                    onKeyPress: (e) => {
                      if (["e", "-"].includes(e.key)) {
                        e.preventDefault();
                      }
                    },
                  },
                }}
              />
              {!filters.endYear ? (
                <FormHelperText error>*Required field</FormHelperText>
              ) : +filters.endYear < 1998 ? (
                <FormHelperText error>*Must be after 1997</FormHelperText>
              ) : +filters.endYear > new Date().getFullYear() + 1 ? (
                <FormHelperText error>{`*Must be before ${
                  new Date().getFullYear() + 2
                }`}</FormHelperText>
              ) : +filters.endYear < +filters.startYear ? (
                <FormHelperText error>
                  *Must be greater than start year
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              required
              error={!filters.reportYearOption}
            >
              {!reportYearOptionsLookup.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="report-year-option">
                    Report Year Option
                  </InputLabel>
                  <Select
                    labelId="report-year-option"
                    id="report-year-option"
                    label="Report Year Option"
                    name="report_option_ndx"
                    value={filters?.reportYearOption ?? []}
                    onChange={(e) => {
                      handleUpdateState("reportYearOption", e.target.value);
                    }}
                  >
                    {reportYearOptionsLookup.map((option) => (
                      <MenuItem
                        key={option.report_option_ndx}
                        value={option.data_source}
                      >
                        {option.report_option_desc}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              {!filters.reportYearOption && (
                <FormHelperText error>*Required field</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              required
              error={!filters.aquiferGroup}
            >
              {!aquiferGroupsLookup.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="aquifer-groups">Aquifer Group</InputLabel>
                  <Select
                    labelId="aquifer-group"
                    id="aquifer-group"
                    label="Aquifer Group"
                    name="aquifer_group_ndx"
                    value={filters?.aquiferGroup ?? []}
                    onChange={(e) => {
                      handleUpdateState("aquiferGroup", e.target.value);
                    }}
                  >
                    {aquiferGroupsLookup.map((option) => (
                      <MenuItem
                        key={option.aquifer_group_ndx}
                        value={option.aquifer_group_ndx}
                      >
                        {option.aquifer_group_name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              {!filters.aquiferGroup && (
                <FormHelperText error>*Required field</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl variant="outlined" style={{ width: "100%" }}>
              {!aquifersLookup.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="aquifers">Aquifer</InputLabel>
                  <Select
                    labelId="aquifer"
                    id="aquifer"
                    label="Aquifer"
                    name="aquifer_ndx"
                    value={filters?.aquifer ?? []}
                    onChange={(e) => {
                      handleUpdateState("aquifer", e.target.value);
                    }}
                  >
                    <MenuItem value="All">
                      All Aquifers within Selected Aquifer Group
                    </MenuItem>
                    {aquifersLookup
                      .filter(
                        (aquifer) =>
                          aquifer.aquifer_group_ndx === filters.aquiferGroup
                      )
                      .map((option) => (
                        <MenuItem
                          key={option.aquifer_ndx}
                          value={option.aquifer_name}
                        >
                          {option.aquifer_name}
                        </MenuItem>
                      ))}
                  </Select>
                </>
              )}
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl variant="outlined" style={{ width: "100%" }}>
              {!managementZonesLookup.length > 0 ? (
                <Loader />
              ) : (
                <>
                  <InputLabel id="management-zones">Management Zone</InputLabel>
                  <Select
                    labelId="management-zones"
                    id="management-zones"
                    label="Management Zones"
                    name="mgmtzone_ndx"
                    value={filters?.managementZone ?? []}
                    onChange={(e) => {
                      handleUpdateState("managementZone", e.target.value);
                    }}
                  >
                    <MenuItem value="All">All Management Zones</MenuItem>
                    {managementZonesLookup.map((option) => (
                      <MenuItem
                        key={option.mgmtzone_ndx}
                        value={option.mgmtzone_ndx}
                      >
                        {option.mgmtzone_name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Divider style={{ margin: "20px" }} />
        <Box style={{ display: "flex", justifyContent: "end" }}>
          <Button onClick={() => setFilters(defaultFilters)}>Reset</Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => refetch()}
            disabled={isSubmitDisabled}
            style={{ width: "230px" }}
          >
            {isLoading ? "Loading..." : "Submit Filters to Build Report"}
          </Button>
        </Box>
      </Paper>
      <Paper style={{ padding: "10px" }}>
        {isLoading ? (
          <Loader />
        ) : !data?.length > 0 ? (
          <Alert severity="info">
            <Typography
              variant="h6"
              component="div"
              style={{ padding: "16px" }}
            >
              No records available for these search parameters. Please try
              again.
            </Typography>
          </Alert>
        ) : (
          <MaterialTable
            id="Pumpage Report"
            title="Pumpage Report"
            columns={columns}
            isLoading={isLoading}
            data={data}
            options={tableOptions}
            actions={[
              {
                icon: CopyIcon,
                tooltip: "Copy Data",
                isFreeAction: true,
                onClick: () => {
                  try {
                    copyToClipboard(data, columns, () =>
                      doToast("success", "Data was copied to your clipboard.")
                    );
                  } catch (error) {
                    const message = error?.message ?? "Something went wrong";
                    doToast("error", message);
                  }
                },
              },
            ]}
          />
        )}
      </Paper>
    </>
  );
};

export default PumpageReport;
