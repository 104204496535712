import { Button, Link } from "@material-ui/core";

export const DEFAULT_PERMIT_GROUP_INFO_STATE = {
  permit_group_ndx: null,
  permit_group_name: null,
  removed: false,
  should_remove: false,
  notes: null,
  linked_permits: [],
};

export const LINKED_PERMITS_TABLE_COLUMNS = [
  {
    title: "Linked Permits",
    field: "permit_ndx",
    render: (rowData) => (
      <Button
        component={Link}
        target="_blank"
        rel="noreferrer noopener"
        href={`/data-management/permit-records/permits-management/${rowData.permit_ndx}`}
        variant="contained"
        color="secondary"
        style={{ width: "100%" }}
        size="small"
      >
        {rowData.permit_name_for_groups_management}
      </Button>
    ),
  },
];
