import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useMemo } from "react";
import useFetchData from "../../../../../hooks/useFetchData";
import { useStateIfMounted } from "use-state-if-mounted";
import { CustomOrderedFilterDropdown } from "../../../../../utils";

export const useGeologicalContacts = (selectedWell) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useStateIfMounted([]);

  const { isFetching, error, refetch } = useQuery(
    ["list-wells-hydrogeology-formations", selectedWell],
    async () => {
      if (!selectedWell) {
        setEditorState([]);
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-wells-hydrogeology-formations/${selectedWell}`,
          { headers }
        );
        setEditorState(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [formationLookup] = useFetchData("ui-list-wm-formation", [], true);
  const formationLookupForTable = useMemo(() => {
    let converted = {};
    if (formationLookup?.length > 0) {
      formationLookup.forEach((item) => {
        converted[item.formation_ndx] = item.formation_name;
      });
    }
    return converted;
  }, [formationLookup]);

  const GEOLOGICAL_CONTACTS_TABLE_COLUMNS = [
    {
      title: "Formation",
      field: "formation_ndx",
      lookup: formationLookupForTable,
      editComponent: (props) =>
        CustomOrderedFilterDropdown(
          props,
          formationLookup,
          "formation_ndx",
          "formation_name"
        ),
    },
    {
      title: "Top Depth (ft)",
      field: "ft_to_top",
    },
    {
      title: "Bottom Depth (ft)",
      field: "ft_to_bottom",
    },
  ];

  return {
    isLoading: isFetching,
    error,
    refetch,
    state: editorState,
    setter: setEditorState,
    GEOLOGICAL_CONTACTS_TABLE_COLUMNS,
  };
};
