import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import useFetchData from "../../../../../hooks/useFetchData";
import { useStateIfMounted } from "use-state-if-mounted";
import { DEFAULT_AMENDMENT_STATE } from "./constants";

export const useAmendment = (
  selectedAmendment,
  selectedPermit,
  triggerRefetch
) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useStateIfMounted(
    DEFAULT_AMENDMENT_STATE
  );
  const [isDirty, setIsDirty] = useStateIfMounted(false);

  const handleUpdateState = (name, value, oldValue = null) => {
    if (!isDirty) setIsDirty(true);
    setEditorState((prevState) => {
      let newFilterValues = { ...prevState };

      if (
        name === "proposed_volume_change" &&
        !isNaN(value) &&
        !isNaN(oldValue)
      ) {
        newFilterValues["new_volume"] = value + oldValue;
      }

      if (name === "pamend_type_ndx") {
        if (selectedAmendment) {
          return {
            ...data,
            [name]: value,
          };
        } else {
          return {
            ...DEFAULT_AMENDMENT_STATE,
            permit_ndx: selectedPermit,
            [name]: value,
          };
        }
      }

      if (name === "permittee_add_new" && value) {
        newFilterValues["proposed_rolo_ndx"] = null;
      }

      if (name === "permittee_add_new" && value === false) {
        newFilterValues["tmp_permittee_name"] = null;
        newFilterValues["tmp_permittee_email"] = null;
        newFilterValues["tmp_permittee_phone"] = null;
        newFilterValues["tmp_permittee_zip"] = null;
        newFilterValues["tmp_permittee_address"] = null;
        newFilterValues["tmp_permittee_city"] = null;
        newFilterValues["tmp_permittee_state"] = null;
        newFilterValues["tmp_permittee_organization"] = null;
        newFilterValues["tmp_permittee_precinct_ndx"] = null;
        newFilterValues["proposed_rolo_ndx"] = data?.rolo_ndx ?? null;
      }

      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const handleResetState = () => {
    setIsDirty(false);
    if (selectedAmendment) {
      setEditorState(data);
    } else {
      setEditorState(DEFAULT_AMENDMENT_STATE);
    }
  };

  const { data, isFetching, error, refetch } = useQuery(
    ["data-permit-amendments-amendment", selectedAmendment],
    async () => {
      if (!selectedAmendment) {
        setEditorState(DEFAULT_AMENDMENT_STATE);
        return {};
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-permit-amendments/${selectedAmendment}`,
          { headers }
        );
        setIsDirty(false);
        setEditorState(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: dataCurrentPermit,
    isFetching: isFetchingCurrentPermit,
    error: errorCurrentPermit,
    refetch: refetchCurrentPermit,
  } = useQuery(
    ["list-permits", selectedPermit],
    async () => {
      if (!selectedPermit) {
        return {};
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-permits/${selectedPermit}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [amendmentTypesLookup] = useFetchData(
    "ui-list-permit-amendment-types",
    [],
    true
  );
  const [amendmentStatusLookup] = useFetchData(
    "ui-list-permit-amendment-status",
    [],
    true
  );
  const [permitUsesLookup] = useFetchData("ui-list-permits-uses", [], true);
  const [permitStatusLookup] = useFetchData(
    "ui-list-permits-permit-status",
    [],
    true
  );
  const [permitStatusRetiredLookup] = useFetchData(
    "ui-list-permits-permit-status-retired",
    [],
    true
  );
  const [permitRoloRecordsLookup] = useFetchData(
    "ui-list-permit-rolo-records",
    [],
    true
  );
  const [permitApprovalBodyLookup] = useFetchData(
    "list-permit-approval-body",
    [],
    true
  );
  const [permitteeContactRecordLookup] = useFetchData(
    "ui-list-permit-rolo-records",
    [triggerRefetch],
    true
  );
  const [directorPrecinctsLookup] = useFetchData(
    "ui-list-director-precincts",
    [],
    true
  );

  return {
    data,
    dataCurrentPermit,
    isLoading: isFetching || isFetchingCurrentPermit,
    error: error || errorCurrentPermit,
    refetch: () => {
      refetch();
      refetchCurrentPermit();
    },
    isDirty,
    lookups: {
      amendmentTypes: amendmentTypesLookup,
      amendmentStatus: amendmentStatusLookup,
      permitUses: permitUsesLookup,
      permitStatus: permitStatusLookup,
      permitStatusRetired: permitStatusRetiredLookup,
      permitRoloRecords: permitRoloRecordsLookup,
      permitApprovalBody: permitApprovalBodyLookup,
      permitteeContactRecords: permitteeContactRecordLookup,
      directorPrecincts: directorPrecinctsLookup,
    },
    handleUpdateState,
    handleResetState,
    state: editorState,
    setter: setEditorState,
  };
};
